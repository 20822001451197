import React from "react";
import branding from "../branding/branding";
import { IconDefaultAvatar } from "../ui/Icons"

const AvatarDefaultContent = () => {
    return(
        <span> {IconDefaultAvatar({fill: branding.communicationAreaDefaultAvatarColor || "lightgrey" })} </span>
    )
}

export default AvatarDefaultContent