import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { trackEventDateDetailsPageVisit, loadExhibitorData, UserRestrictedAreaAccess, BackendServiceError, getUserRestrictedAreaAccess, RestrictedAreaTypes, loadEventDateDetails, createPrivacyUserQuestionId, trackOnDemandDetailsPageVisit } from "../../backendServices/BackendServices"
import { EventDate, Person, Attachment, ShareTargetType, Category, EntityType, Company } from "../../backendServices/Types"
import branding, { getPluralString } from "../../branding/branding"
import { Row, Col } from "react-bootstrap"
import BookmarkWithToggle from "../../ui/BookmarkWithToggle"
import { useParams } from "react-router-dom"
import CenteredLoader from "../../ui/CenteredLoader"
import { useLanguageState } from "../../globalStates/LanguageState"
import TopBar from '../../navigationArea/TopBar';
import Breadcrumb, { BreadcrumbItem } from '../../navigationArea/Breadcrumb';
import { IconVideo, IconShare, IconFile, IconDownload, VGIcon, IconLiveStream, IconPlay, IconRoundTable } from "../../ui/Icons";
import { DetailNavLink, buildDetailLink } from "./DetailNavLink"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import { usePrivacyPolicyModal } from "../../ui/PrivacyPolicyModal"
import FileSaver from "file-saver"
import RecommendOrganizationModal from "../../ui/RecommendOrganizationModal"
import ChatRoomAreaTab from "../../chatRoomArea/ChatRoomArea"
import { useNavigationSource } from "../../tracking/NavigationSource"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { AvatarWithPresenceState } from "../../ui/AvatarWithPresenceState"
import { isEventDateLive } from "../../utils/DateUtils"
import moment from "moment"
import { useChimeContext } from "../../conference/context/ChimeContext"
import { Spinner } from "react-bootstrap"
import RequestAccessModal from "../../ui/RequestAccessModal"
import { getNow } from "../../globalStates/EventState"
import { PointsBadge } from "../program/EventDateEntry"
import VideoPlayer from "../videoPlayer/VideoPlayer"
import OrgaLogoWithConnectionFunc from "../../ui/OrgaLogoWithConnectionFunc"
import { saveEvent } from "../../communicationArea/ICal"
import { format } from "date-fns"
import { isSoftOpeningPhase, isLivePhase, isPostEventPhase } from "../../utils/EventPhaseChecker"
import { Links } from "./components/DetailPageSections"
import { trackNavigation } from "../../utils/GTMTracking"

const germanLocale = require('date-fns/locale/de');

/* #region LiveEvent */

interface LiveEventDateProps {
    title: string
    backgroundImage?: string
    channelIcon: VGIcon
    streamingUrl: string
    isLive: boolean
    videoOnDemandUrl?: string
    showPrivacyPolicyModal(organizationId: string, callback: (accepted: boolean) => void): void
    organizationId: string | undefined
    eventDateId: string | undefined
    pageTitle: string
}

const LiveTileHeader = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row;
  padding: 15px;
  width: 100%;
  color: white;
  z-index: 2;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  background-blend-mode: multiply;

`

const LiveTileRoot = styled.div <{ isLive: boolean }>`
 position: relative;
    display: flex;
    flex-flow: column;
    ${props => !props.isLive ? "pointer-events: none;" : ""}
    ${props => props.isLive ? "cursor: pointer;" : ""}
    

    & h2 {
        font-family: ${branding.font1};
        font-style: normal;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        display: flex;
        margin-left: 25px;
        flex: 1;
        text-align: center;
    }

`

const VideoOnDemandRoot = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;

    & h2 {
        font-family: ${branding.font1};
        font-style: normal;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        display: flex;
        margin-left: 25px;
        flex: 1;
        text-align: center;
    }
`

const LiveEventRoot = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
`

const LiveStreamingImagePreview = styled.div<{ $backgroundImage?: string }>`
    position: relative;
    display: flex;
    height: 500px;
    ${props => props.$backgroundImage ? `background: url("${props.$backgroundImage}");` : "background: url('/branding/no-event-default-image.png');"}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
    align-items: center;
    justify-content: center;
`

const LiveStreamingImagePreviewPlayButton = styled.div<{ $backgroundImage?: string }>`
    display: flex;
`


const LiveEvent: React.FunctionComponent<LiveEventDateProps> = (props) => {
    let content: JSX.Element | null = null
    //privacy policy
    const userLink = useLoggedInState()
    const questionId = createPrivacyUserQuestionId(props.organizationId ?? "")
    const alreadyAccepted = userLink.getPrivacyUserAnswer(questionId)?.accepted
    const [disablePlaying, setDisablePlaying] = useState(!alreadyAccepted && branding.configuration.showPrivacyModelForEventDates === true) //play the video if user already accepted the policy
    const [modalShown, setModalShown] = useState(false)

    if (!props.isLive && props.videoOnDemandUrl) {
        if (props.organizationId && !disablePlaying) {
            trackOnDemandDetailsPageVisit(userLink.user()?.profileId!, props.organizationId, props.eventDateId!)
        }
        content = (
            <VideoOnDemandRoot onClick={(e) => {
                if (props.organizationId && !modalShown)
                    props.showPrivacyPolicyModal(props.organizationId, (accepted) => {
                        setDisablePlaying(false)
                        setModalShown(true)
                    })
                else {
                    setDisablePlaying(false)
                }

                if (props.pageTitle) {
                    trackNavigation(props.pageTitle + " - Live Video")
                }

            }}>
                <LiveTileHeader>
                    <div>{props.channelIcon}</div>
                    <h2>{props.title}</h2>
                </LiveTileHeader>
                <VideoPlayer src={props.videoOnDemandUrl} controls={true} live={false} disablePlaying={disablePlaying} />
            </VideoOnDemandRoot>
        )
    } else {
        content = (
            <LiveTileRoot isLive={props.isLive && !isPostEventPhase} onClick={(e) => {
                if (props.organizationId && !modalShown && branding.configuration.showPrivacyModelForEventDates === true)
                    props.showPrivacyPolicyModal(props.organizationId, (accepted) => {
                        setModalShown(true)
                        window.open(`/channel/${props.streamingUrl}`, `_self`)
                    })
                else
                    window.open(`/channel/${props.streamingUrl}`, `_self`)
            }}>
                <LiveTileHeader>
                    <div>{props.channelIcon}</div>
                    <h2>{props.title}</h2>
                </LiveTileHeader>
                <LiveStreamingImagePreview $backgroundImage={props.backgroundImage}>
                    {props.isLive && !isPostEventPhase && <LiveStreamingImagePreviewPlayButton>
                        {IconPlay({})}
                    </LiveStreamingImagePreviewPlayButton>}
                </LiveStreamingImagePreview>
            </LiveTileRoot>
        )
    }
    return (
        <LiveEventRoot>
            <Col xs={8} className="p-0">
                {content}
            </Col>
        </LiveEventRoot>)

}
/* #endregion LiveEvent */

/* #region  Share and bookmark icons component */
const ShareIcon = styled.div`
    cursor: pointer;
    margin-right:20px;
    color: ${branding.primaryColor};
`
interface ShareBookmarkIconsProps {
    person?: Person
    eventdate?: EventDate
    type: EntityType
    setShowRecommendOrganizationModal: (toggled: boolean) => void
    setTargetId: (target: string) => void
    setLink: (link: string) => void
    setType: (type: ShareTargetType) => void
}

const ShareBookmarkIcons: React.FunctionComponent<ShareBookmarkIconsProps> = (props) => {
    if (!props.eventdate && !props.person)
        return null

    const targetId = props.eventdate ? props.eventdate.id : props.person!.id;
    const type = props.eventdate ? ShareTargetType.EVENTDATE : ShareTargetType.PERSON;
    const link = props.eventdate ? buildDetailLink(targetId, props.eventdate.name, "eventdate") : buildDetailLink(props.person!.id, `/person/${props.person!.firstName}_${props.person!.lastName}`, "person")

    return (
        <>
            {props.eventdate &&
                <ShareIcon onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    saveEvent(props.eventdate!)
                }}>
                    {IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                </ShareIcon>
            }
            <ShareIcon onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.setTargetId(targetId);
                props.setType(type);
                props.setLink(link);
                props.setShowRecommendOrganizationModal(true);
            }}>
                {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
            </ShareIcon>
            <BookmarkWithToggle newBookmarkItem={true} fontSize={"25px"} color={branding.sideIconBar.sideIconColorDark} favIconBasic={true} type={props.type} id={props.type === "person" ? props.person?.id! : props.eventdate?.id!} />
        </>
    )
}
/* #endregion */


/* #region  Person and attachment data component */
const PersonAttachmentRow = styled(Row)`
    display: flex;
`

const PersonAttachmentImageCol = styled(Col)`
    display: auto;
    align-items: center;
`

const PersonColInfo = styled(Col)`
 font-family: ${branding.font1};
    display:flex;
    align-items:center;
`

const PersonInfoDiv = styled.div`
    margin-left: 18px;
    font-family: ${branding.font1};
`

const PersonAttachmentTitle = styled.h5`
 font-family: ${branding.font1};
`

const PersonPosition = styled.p`
    margin-top:3px;
    font-family: ${branding.font1};
`

const PersonAttachmentColIcons = styled(Col)`
    display:flex;
    justify-content:flex-end;
    align-items:center;
`

const AttachmentExstensionDiv = styled.div`
    border:1px solid ${branding.mainInfoColor};
    border-radius: 5px;
    padding:3px 5px;
    margin-left:20px;
`

const ExstensionText = styled.p`
    font-size:18px;
    font-family: ${branding.font1};
`

const DownloadIconDiv = styled.div`
    cursor: pointer;
`

interface PersonAttachmentDataProps {
    person?: Person
    attachment?: Attachment
    type: "person" | "attachment"
    modalToggle?(): void
    startDownload?: boolean
    setSelectedAttachment?(value: Attachment): void
    setShowRecommendOrganizationModal?: (toggled: boolean) => void
    setTargetId?: (target: string) => void
    setLink?: (link: string) => void
    setType?: (type: ShareTargetType) => void
}

const PersonAttachmentData: React.FunctionComponent<PersonAttachmentDataProps> = (props) => {
    const strings = useLanguageState().getStrings()

    function handleClick() {
        if (props.setSelectedAttachment)
            props.setSelectedAttachment(props.attachment!);

        if (!props.startDownload && props.modalToggle)
            props.modalToggle();
    }

    if (props.type === "person" && props.person) {
        return (
            <PersonAttachmentRow style={{ width: "100%" }}>
                <PersonAttachmentImageCol xs={2}>
                    {props.person.userId && <DetailNavLink id={props.person.id} type={"person"} name={`/person/${props.person.firstName}_${props.person.lastName}`}>
                        <AvatarWithPresenceState badgeSize={15} showAvatarBadge={true} badgeRight={9} badgeTop={31} userId={props.person.userId} avatarSize={46} content={{ pictureUrl: props.person.logoUrl || props.person.userLogoUrl, alt: [props.person.firstName, props.person.lastName].filter(Boolean).join(" ") ?? "#" }} />
                    </DetailNavLink>}
                    {!props.person.userId && <AvatarWithDefault size={50} src={props.person.userLogoUrl ?? props.person.logoUrl ?? undefined} alt={[props.person.firstName, props.person.lastName].filter(Boolean).join(" ") ?? "#"} backgroundSize="cover" />}
                </PersonAttachmentImageCol>
                <PersonColInfo xs={7}>
                    <PersonInfoDiv>
                        {props.person.userId && <DetailNavLink id={props.person.id} type={"person"} name={`/person/${props.person.firstName}_${props.person.lastName}`}>
                            <PersonAttachmentTitle>{[props.person.title, props.person.firstName, props.person.lastName].filter(Boolean).join(" ")}</PersonAttachmentTitle>
                            <PersonPosition>{[props.person.position, props.person.organization]
                                .filter(Boolean)
                                .join(" " + strings.communicationArea.personCompanyLink + " ")}</PersonPosition>
                        </DetailNavLink>}
                        {!props.person.userId &&
                            <DetailNavLink id={props.person.id} type={"person"} name={`/person/${props.person.firstName}_${props.person.lastName}`}>
                                <PersonAttachmentTitle>{[props.person.title, props.person.firstName, props.person.lastName].filter(Boolean).join(" ")}</PersonAttachmentTitle>
                                <PersonPosition>{[props.person.position, props.person.organization]
                                    .filter(Boolean)
                                    .join(" " + strings.communicationArea.personCompanyLink + " ")}</PersonPosition>
                            </DetailNavLink>}
                    </PersonInfoDiv>
                </PersonColInfo>
                <PersonAttachmentColIcons xs={3}>
                    <ShareBookmarkIcons
                        type="person"
                        person={props.person}
                        setShowRecommendOrganizationModal={props.setShowRecommendOrganizationModal!}
                        setLink={props.setLink!}
                        setTargetId={props.setTargetId!}
                        setType={props.setType!} />
                </PersonAttachmentColIcons>
            </PersonAttachmentRow>
        )
    } else if (props.attachment) {
        let index = props.attachment.url.lastIndexOf(".");
        let exstension = props.attachment.url.substring(index + 1).toUpperCase();
        return (
            <PersonAttachmentRow>
                <PersonAttachmentImageCol xs={1}>
                    {(IconFile({ width: "20px", height: "20px" }))}
                </PersonAttachmentImageCol>
                <PersonColInfo xs={8}>
                    <PersonAttachmentTitle>{props.attachment.title ? props.attachment.title : "NO TITLE"}</PersonAttachmentTitle>
                    <AttachmentExstensionDiv>
                        <ExstensionText>{exstension}</ExstensionText>
                    </AttachmentExstensionDiv>
                </PersonColInfo>
                <PersonAttachmentColIcons xs={3}>
                    <DownloadIconDiv onClick={handleClick}>
                        {IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                    </DownloadIconDiv>
                </PersonAttachmentColIcons>
            </PersonAttachmentRow>
        )
    } else {
        return (
            <>
            </>
        )
    }
}
/* #endregion */


/* #region  All persons content */
const PersonsAttachmentsContentRow = styled(Row)`
    font-family: ${branding.font1};
`

const PersonsAttachmentsContentCol = styled(Col)`
    font-family: ${branding.font1};
    border-bottom: 1px solid ${branding.mainInfoColor};
    padding: 20px 0;
`

interface PersonsAttachmentsHeaderPositionRowProps {
    type: "person" | "attachment"
}

const PersonsAttachmentsHeaderPositionRow = styled(Row) <PersonsAttachmentsHeaderPositionRowProps>`
    margin:${props => props.type === "person" ? "50px 0 30px 0" : "50px 0 10px 0"};
`

const PersonsAttachmentsHeaderTitle = styled.h3`
    font-family: ${branding.font1};
`

interface PersonsProps {
    persons: Array<Person>
    setShowRecommendOrganizationModal: (toggled: boolean) => void
    setTargetId: (target: string) => void
    setLink: (link: string) => void
    setType: (type: ShareTargetType) => void
}

const Persons: React.FunctionComponent<PersonsProps> = (props) => {
    let strings = useLanguageState().getStrings()
    let lastPosition: string | undefined = ""
    var personsWithFunctionNameSorted = props.persons.sort((a, b) => {
        if (a.personFunctionTargetName === undefined && b.personFunctionTargetName === undefined) {
            return 0
        } else if (a.personFunctionTargetName === undefined) {
            return 1
        } else if (b.personFunctionTargetName === undefined) {
            return -1
        } else {
            return a.personFunctionTargetName > b.personFunctionTargetName ? -1 : (a.personFunctionTargetName < b.personFunctionTargetName ? 1 : 0)
        }
    })

    function getPositionHeader(positionName: string | undefined) {
        let count = personsWithFunctionNameSorted.filter(person => person.personFunctionTargetName === positionName).length
        if (positionName === undefined) {
            return strings.eventDateDetailPageContent.noSectionTitle + " (" + count + ")";
        } else {
            return positionName + " (" + count + ")";
        }
    }

    return (
        <PersonsAttachmentsContentRow>
            {
                personsWithFunctionNameSorted.map(person => {
                    if (person.personFunctionTargetName !== lastPosition) {
                        lastPosition = person.personFunctionTargetName
                        let positionHeader = getPositionHeader(person.personFunctionTargetName);
                        return (
                            <PersonsAttachmentsContentCol xs={12} key={person.id}>
                                <PersonsAttachmentsHeaderPositionRow type="person">
                                    <PersonsAttachmentsHeaderTitle>{positionHeader}</PersonsAttachmentsHeaderTitle>
                                </PersonsAttachmentsHeaderPositionRow>
                                <PersonAttachmentData
                                    person={person}
                                    type="person"
                                    setShowRecommendOrganizationModal={props.setShowRecommendOrganizationModal}
                                    setLink={props.setLink}
                                    setTargetId={props.setTargetId}
                                    setType={props.setType}
                                />
                            </PersonsAttachmentsContentCol>
                        )
                    } else {
                        return (
                            <PersonsAttachmentsContentCol xs={12} key={person.id}>
                                <PersonAttachmentData
                                    person={person}
                                    type="person"
                                    setShowRecommendOrganizationModal={props.setShowRecommendOrganizationModal!}
                                    setLink={props.setLink!}
                                    setTargetId={props.setTargetId!}
                                    setType={props.setType!} />
                            </PersonsAttachmentsContentCol>
                        )
                    }
                })
            }
        </PersonsAttachmentsContentRow>
    )
}
/* #endregion */


/* #region  All attachments data component */
interface AttachmentsProps {
    attachments: Attachment[]
    modalToggle(): void
    startDownload?: boolean
    setSelectedAttachment?(value: Attachment): void
}

const Attachments: React.FunctionComponent<AttachmentsProps> = (props) => {
    if (!props.attachments || props.attachments.length === 0)
        return null
    return (
        <PersonsAttachmentsContentRow>
            <PersonsAttachmentsHeaderPositionRow type="attachment">
                <PersonsAttachmentsHeaderTitle>{props.attachments.length > 1 ? "Downloads (" + props.attachments.length + ")" : "Download (1)"}</PersonsAttachmentsHeaderTitle>
            </PersonsAttachmentsHeaderPositionRow>
            {
                props.attachments.map(att => {
                    return (
                        <PersonsAttachmentsContentCol xs={12} key={att.title}>
                            <PersonAttachmentData attachment={att} type="attachment" modalToggle={props.modalToggle} startDownload={props.startDownload} setSelectedAttachment={props.setSelectedAttachment} />
                        </PersonsAttachmentsContentCol>
                    )
                })
            }
        </PersonsAttachmentsContentRow>
    )
}
/* #endregion */


/* #region  Main content */
const EventDateRowHeader = styled(Row)`
    font-size: 20px;
    margin: 50px 0 75px 0;
`

const FormattedDateCol = styled(Col)`
    display: flex;
    align-items:center;
    font-size:20px;
    margin-top: 20px;
    padding-left: 10px;

    @media (max-width: 1000px) {
        flex-direction: column;  
        align-items: flex-start;
    }
`

const EventDateDetails = styled(Col)`
    margin: 20px 0;
`

const OrganizationLogoDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    padding: 20px;
    margin-top: 60px;
    z-index: 100;

    @media(max-width: 1600px) {
        width: 120px;
        height: 120px;
        padding: 25px;
        margin-top: 40px;
    }

    @media(max-width: 1400px) {
        width: 100px;
        height: 100px;
        padding: 15px;
        margin-top: 30px;
    }

    @media(max-width: 1200px) {
        width: 80px;
        height: 80px;
        padding: 10px;
        margin-top: 20px;
    }
`

const LocationCol = styled(Col)`
    display: flex;
    align-items:center;
    font-size:20px;
    padding: 50px 0 0 10px;

    @media (max-width: 1000px) {
        flex-direction: column;  
        align-items: flex-start;
    }

`

const EventDateLocation = styled.p`
    margin-left:20px;
    font-family: ${branding.font1};

    @media (max-width: 1000px) {
        margin: 0;    
    }

    @media (max-width: 1600px) {
        font-size: 0.9rem;
    }

    @media (max-width: 1400px) {
        font-size: 0.85rem;
    }

    @media (max-width: 1200px) {
        font-size: 0.8rem;
    }

`

const ShareBookmarkDiv = styled.div`
    float: right;
    display: flex; 
    margin: 50px 5px 0 50px;
    font-size: 20px;
`

const EventDateFormattedDate = styled.p`
    font-family: ${branding.font1};


    @media (max-width: 1600px) {
        font-size: 0.9rem;
    }

    @media (max-width: 1400px) {
        font-size: 0.85rem;
    }

    @media (max-width: 1200px) {
        font-size: 0.8rem;
    }
`

const EventDateNameTeaserCol = styled(Col)`
    font-family: ${branding.font1};
`

const EventDateName = styled.h2`
    margin-bottom:  10px;
    font-family: ${branding.font1};

    @media (max-width: 1600px) {
        font-size: 22px; 
        line-height: 35px;    
    }

    @media (max-width: 1200px) {
        font-size: 18px; 
        line-height: 30px;    
    }
`

const EventDateTeaser = styled.p`
    @media (max-width: 1600px) {
        font-size: 18px; 
    }

    @media (max-width: 1200px) {
        font-size: 16px;     
    }

    p {
        margin-top: 20px;
    }

`

const EventDateDetailBodyRoot = styled.div<{ guestBannerHeight: number }>`
    font-family: ${branding.font1};
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 110px - ${props => props.guestBannerHeight + "px"});

    .ScrollbarsCustom-Content {
        padding: 0 !important;
    }

    .container-root{
        padding-bottom: 40px;
    }

    
`
const RoundTableAccessDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
    border: 1px solid ${branding.mainInfoColor};
    height: 100px;
    font-size: 16px;
    position: relative;

    @media(max-width: 1600px){
        font-size: 14px;
    }
`
const ActionButtonCol = styled(Col)`
    background: ${branding.mainInfoColor};
    border-radius: 25px;
    height: 70%; 
    color: #FFF;
    display: flex; 
    justify-content: center;
    align-items: center; 
    text-align: center;
    cursor: pointer;
    ${props => props.disabled ? "opacity: 0.5; pointer-events: none;" : ""}
`
const LinksRow = styled(Row)`
    margin-left: 0;
    margin-right: 0;

    .col-12 {
        padding-left: 0;
        padding-right: 0;
        margin-left: -4px;
    }
    .pt-3 {
        padding-top: 0 !important;
    }
    .pb-3 {
        padding-bottom: 0 !important;
    }
    .mb-4 {
        display: none;
    }
`

enum EventDateTiming {
    BEFORE, LIVE, AFTER
}

interface EventDateDetailBodyProps {
    eventDate: EventDate
    setShowRecommendOrganizationModal: (toggled: boolean) => void
    setTargetId: (target: string) => void
    setLink: (link: string) => void
    setType: (type: ShareTargetType) => void
    removeChat?: boolean
    removePreviewImage?: boolean
    guestBannerHeight: number
    showPrivacyPolicyModal(organizationId: string, callback: (accepted: boolean) => void): void
    pageTitle?: string
}

export const EventDateDetailBody: React.FunctionComponent<EventDateDetailBodyProps> = (props) => {
    const [startDownload, setStartDownload] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedAttachment, setSelectedAttachment] = useState<Attachment>();
    const [company, setCompany] = useState<Company>()
    const strings = useLanguageState().getStrings();
    const lang = useLanguageState().getLanguage();

    function startDownloadProcess(attachment: Attachment) {
        if (attachment) {
            const fileName = attachment.title;
            FileSaver.saveAs(attachment.url, fileName);
        }
    }

    useEffect(() => {
        if (props.eventDate.organizationId) {
            loadExhibitorData({
                organizationid: props.eventDate.organizationId,
                hideNewsdata: false
            }).then((resp) => {
                setCompany(resp.content)
            }).catch((e: { message: React.SetStateAction<string> }) => {
                // Error logged in Backend Services
            })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (startDownload) {
            const container = containerRef.current;
            if (container)
                container.style.filter = "none";
            if (selectedAttachment)
                startDownloadProcess(selectedAttachment!);
        }

        //eslint-disable-next-line
    }, [startDownload, selectedAttachment])



    //returns date-time in format: Wed., 12 Sept., 2020, 17:00 - 18:30
    function getFormattedDate(date: string) {

        return format(moment(date).toDate(), strings.eventTiming.eventDaysFormatPatternEvtDetailPage, { locale: lang === 'de' ? germanLocale : null }) + ", " + props.eventDate.start + " - " + props.eventDate.end
    }

    const pointsBadgeData = (props.eventDate.categories as unknown as Category[])?.filter(x => x.id === branding.programSchedule.pointsBadgeCategoryAlias)[0]
    const organizationId = props.eventDate && props.eventDate.organizationId

    return (
        <EventDateDetailBodyRoot className="eventDate-root" guestBannerHeight={props.guestBannerHeight}>
            <ContentScrollContainer containerWidth={props.removeChat ? "100%" : "75%"} adjustForHeaderWith={110 + props.guestBannerHeight + "px"}>
                {!props.removePreviewImage &&
                    <LiveEvent title={props.eventDate?.channel ? props.eventDate.channel.name : ""}
                        backgroundImage={props.eventDate?.liveStreamingPreviewUrl}
                        streamingUrl={props.eventDate.channel?.id || props.eventDate.id}
                        channelIcon={IconLiveStream({ fill: "currentColor" })} videoOnDemandUrl={props.eventDate.videoOnDemandUrl}
                        isLive={props.eventDate ? props.eventDate.type !== "roundtable" && isEventDateLive(props.eventDate) : false}
                        showPrivacyPolicyModal={props.showPrivacyPolicyModal}
                        organizationId={organizationId}
                        pageTitle={props.pageTitle || ""}
                        eventDateId={props.eventDate.id} />}
                <div className="container-root" style={{ position: "relative", color: branding.mainInfoColor }}>
                    <Row>
                        <Col xs={2} />
                        <LocationCol xs={4}>
                            <div style={{ color: branding.primaryColor }}>
                                {IconVideo({ fill: branding.sideIconBar.sideIconColorDark })}
                            </div>
                            <EventDateLocation>{props.eventDate.location}</EventDateLocation>
                        </LocationCol>
                        <Col xs={4} className="p-0">
                            <ShareBookmarkDiv>
                                <ShareBookmarkIcons
                                    type="eventdate"
                                    eventdate={props.eventDate}
                                    setShowRecommendOrganizationModal={props.setShowRecommendOrganizationModal}
                                    setLink={props.setLink}
                                    setTargetId={props.setTargetId}
                                    setType={props.setType} />
                            </ShareBookmarkDiv>
                        </Col>
                        <Col xs={2} />
                    </Row>
                    <Row>
                        <Col xs={2} />
                        <FormattedDateCol xs={8}>
                            <EventDateFormattedDate >{getFormattedDate(props.eventDate.date)} </EventDateFormattedDate>
                        </FormattedDateCol>
                        <Col xs={2} />
                    </Row>
                    <Row>
                        <Col xs={2} />
                        <Col xs={8} className="p-0">
                            <EventDateRowHeader>
                                <EventDateDetails xs={12} md={3}>
                                    <OrganizationLogoDiv style={{ pointerEvents: props.eventDate?.organizationId! ? "auto" : "none" }}>
                                        {props.eventDate?.organizationId && company &&
                                            <OrgaLogoWithConnectionFunc
                                                organization={company} />
                                        }
                                    </OrganizationLogoDiv>
                                </EventDateDetails>
                                <EventDateNameTeaserCol xs={12} md={7}>
                                    <EventDateName>{props.eventDate.name}</EventDateName>
                                    {branding.eventDateDetailPageContent.linksVisible && props.eventDate?.links && <LinksRow>
                                        <Links
                                            organizationId={props.eventDate.organizationId}
                                            links={props.eventDate.links}
                                            tooltipMessage={"Copy to clipboard"}
                                            colXs={12}  >
                                        </Links>
                                    </LinksRow>}
                                    {pointsBadgeData &&
                                        <PointsBadge
                                            className={'mt-3'}
                                            textColor={pointsBadgeData.colorText}
                                            fillColor={pointsBadgeData.colorFill}
                                            borderColor={pointsBadgeData.colorBorder} >
                                            {strings.programSchedule.pointsBadgeText}
                                        </PointsBadge>}
                                    <EventDateTeaser>{props.eventDate.text?.split(/\n+/).map((item, i) => <p>{item}</p>)}</EventDateTeaser>
                                </EventDateNameTeaserCol>
                            </EventDateRowHeader>

                        </Col>
                        <Col xs={2} />
                    </Row>
                    <Row>
                        <Col xs={2} />
                        <Col xs={8}>
                            <RoundTableAccessView eventDate={props.eventDate} />
                            <Persons
                                persons={props.eventDate.persons}
                                setShowRecommendOrganizationModal={props.setShowRecommendOrganizationModal}
                                setLink={props.setLink}
                                setTargetId={props.setTargetId}
                                setType={props.setType}
                            />
                            <Attachments attachments={props.eventDate.attachments} modalToggle={() => setStartDownload(true)} startDownload={startDownload} setSelectedAttachment={setSelectedAttachment} />
                        </Col>
                        <Col xs={2} />
                    </Row>
                    {/*
                    <Row>
                        <Col xs={2} />
                        <Col xs={8} >
                            {props.eventDate?.organizationId && company &&
                                <CompanyDetails
                                    organization={company} />
                            }
                        </Col>
                        <Col xs={2} />
                    </Row>
                    */
                    }
                </div>
            </ContentScrollContainer >
            {!props.removeChat && <div style={{ width: "25%" }}>
                <ChatRoomAreaTab chatRoom={props.eventDate.id} slidoName={props.eventDate.channel?.slidoName ?? props.eventDate.location} hideQnA={true} />
            </div>}
        </EventDateDetailBodyRoot>
    )
}
/* #endregion */


interface RoundTableAccessViewProps {
    eventDate: EventDate
}

const RoundTableAccessView: React.FC<RoundTableAccessViewProps> = (props) => {
    const [userRoundTableAccess, setUserRoundTableAccess] = useState<UserRestrictedAreaAccess | undefined | null>() // null -> no access, undefined -> unknown (request pending or erroneous)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [showRequestAccessModal, setShowRequestAccessModal] = useState<boolean>(false)
    const [eventDateTiming, setEventDateTiming] = useState<EventDateTiming>()
    const [timeToLive, setTimeToLive] = useState<number>(-1)
    const loggedInUserId = useLoggedInState().user()?.profileId
    const strings = useLanguageState().getStrings();
    const chime = useChimeContext()
    const lang = useLanguageState().getLanguage()

    const roundTableOpenStart = moment(props.eventDate.startdatetime).subtract(branding.configuration.roundTableOpenBeforeMinutes ?? 5, "minutes")
    const roundTableOpenEnd = moment(props.eventDate.enddatetime).add(branding.configuration.roundTableOpenAfterMinutes ?? 5, "minutes")

    useEffect(() => {
        if (props.eventDate.type !== "roundtable") {
            return
        }

        const now = getNow();

        if (now.isAfter(roundTableOpenEnd)) {
            setUserRoundTableAccess(null)
            setIsLoading(false)
            return
        }

        getUserRestrictedAreaAccess(loggedInUserId!, props.eventDate.id, RestrictedAreaTypes.RoundTable).then((data) => {
            setIsLoading(true)
            const errorStatus = (data as BackendServiceError).httpStatus
            if (errorStatus) {
                if (errorStatus === 404)
                    setUserRoundTableAccess(null)
                else
                    setUserRoundTableAccess(undefined)
            } else {
                setUserRoundTableAccess(data as UserRestrictedAreaAccess)
            }
            setIsLoading(false)
        }).catch(err => {
            setUserRoundTableAccess(undefined)
            setIsLoading(false)
        });
    }, [props.eventDate.id]) // eslint-disable-line

    useEffect(() => {
        const now = getNow();
        if (now.isBefore(roundTableOpenStart)) {
            setEventDateTiming(EventDateTiming.BEFORE)
            const timeToLive = roundTableOpenStart.diff(now)
            if (timeToLive < 1000 * 60 * 60 * 24) {
                const handle = setTimeout(() => { setEventDateTiming(EventDateTiming.LIVE) }, timeToLive)
                return () => clearTimeout(handle)
            }
        } else if (now.isBefore(roundTableOpenEnd)) {
            setEventDateTiming(EventDateTiming.LIVE)
            const timeToEnd = roundTableOpenEnd.diff(now)
            if (timeToEnd < 1000 * 60 * 60 * 24) {
                const handle = setTimeout(() => { setEventDateTiming(EventDateTiming.AFTER) }, timeToEnd)
                return () => clearTimeout(handle)
            }
        } else {
            setEventDateTiming(EventDateTiming.AFTER)
        }
    }, [props.eventDate.id]) // eslint-disable-line

    useEffect(() => {
        if (eventDateTiming === EventDateTiming.BEFORE) {
            const now = getNow();
            const timeToLive = roundTableOpenStart.diff(now)
            setTimeToLive(timeToLive)
        }
    }, [eventDateTiming]) // eslint-disable-line

    useEffect(() => {
        if (eventDateTiming === EventDateTiming.BEFORE && timeToLive > 0) {
            const now = getNow();
            const updatedTimeToLive = roundTableOpenStart.diff(now)
            const tickLength = updatedTimeToLive % 1000 === 0 ? 1000 : updatedTimeToLive % 1000
            const handle = setTimeout(() => {
                setTimeToLive(updatedTimeToLive - tickLength)
            }, tickLength);
            return () => { clearTimeout(handle) }
        }
    }, [timeToLive, eventDateTiming]) // eslint-disable-line

    if (props.eventDate.type !== "roundtable") {
        return null
    }

    function onJoinMeetingButtonClicked() {
        chime.createOrJoinMeeting(props.eventDate.id, 'roundtable')
    }

    const onRequestAccessClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        setShowRequestAccessModal(true)
    }

    let actionButtonField = null
    let roundTableText = strings.eventDateDetailPageContent.eventDateRoundTableNoAccessText
    if (eventDateTiming === EventDateTiming.AFTER) {
        roundTableText = strings.eventDateDetailPageContent.eventDateRoundTablePostEventText
    } else if (userRoundTableAccess?.status === "GRANTED") {
        const startDateTime = moment(props.eventDate.date + " " + props.eventDate.start)
        roundTableText = strings.eventDateDetailPageContent.eventDateRoundTableGrantedAccessText.split('{$startTime}').join(format(moment(startDateTime).toDate(), strings.eventTiming.eventDaysFormatPatternEvtDetailPage, { locale: lang === 'de' ? germanLocale : null }) + (lang === "de" ? " um " : " at ") + props.eventDate.start)
    } else {
        roundTableText = strings.eventDateDetailPageContent.eventDateRoundTableNoAccessText
    }

    if (isLoading) {
        actionButtonField = <Col xs={2} style={{ height: "70%", display: "flex", justifyContent: "center" }}>
            <Spinner animation="border" variant="dark" />
        </Col>
    } else if (userRoundTableAccess === undefined) {
        // TODO better error handling?
        actionButtonField = <Col xs={2} style={{ height: "70%", display: "flex", justifyContent: "center" }}>
            <Spinner animation="border" variant="dark" />
        </Col>
    } else if (eventDateTiming === EventDateTiming.AFTER) {
        actionButtonField = <ActionButtonCol xs={2} disabled={true}>
            <p className="user-select-none">{strings.eventDateDetailPageContent.eventDateRoundTableButtonPostEvent}</p>
        </ActionButtonCol>
    } else {
        if (userRoundTableAccess?.status === "GRANTED") {
            if (timeToLive > 0 && eventDateTiming === EventDateTiming.BEFORE) {
                const hoursToLive = timeToLive / (60 * 60 * 1000)
                const daysToLive = hoursToLive < 24 ? 0 : Math.round(hoursToLive / 24)
                const countdownText = hoursToLive < 1 ? moment.utc(timeToLive).format("mm:ss") : hoursToLive < 24 ? moment.utc(timeToLive).format("HH:mm:ss") : getPluralString(strings.eventDateDetailPageContent.eventDateRoundTableButtonCountdown, daysToLive, "days")
                actionButtonField = <ActionButtonCol xs={2} onClick={onJoinMeetingButtonClicked} disabled={true}>
                    <p className="user-select-none">{countdownText}</p>
                </ActionButtonCol>
            } else {
                actionButtonField = <ActionButtonCol xs={2} onClick={onJoinMeetingButtonClicked} disabled={eventDateTiming !== EventDateTiming.LIVE}>
                    <p className="user-select-none">{strings.eventDateDetailPageContent.eventDateRoundTableButtonAccessGrantedLive}</p>
                </ActionButtonCol>
            }
        } else if (userRoundTableAccess?.status === "REQUESTED") {
            actionButtonField = <ActionButtonCol xs={2} disabled={true}>
                <p className="user-select-none">{strings.globalTexts.requestSent}</p>
            </ActionButtonCol>
        } else {
            actionButtonField = <ActionButtonCol xs={2} onClick={onRequestAccessClick}>
                <p className="user-select-none">{strings.globalTexts.requestAccess}</p>
            </ActionButtonCol>
        }
    }

    return <>
        {showRequestAccessModal &&
            <RequestAccessModal
                restrictedAreaId={props.eventDate.id}
                restrictedAreaType={RestrictedAreaTypes.RoundTable}
                onHide={() => { setShowRequestAccessModal(false) }}
                onUserRequestLoadedSet={(isLoaded) => setIsLoading(!isLoaded)}
                onUserRestrictedAreaAccessSet={setUserRoundTableAccess}>
            </RequestAccessModal>}
        <RoundTableAccessDiv>
            <Col xs={1}>{IconRoundTable({ width: "30", height: "30", fill: branding.mainInfoColor })}</Col>
            <Col xs={9}>{roundTableText}</Col>
            {actionButtonField}
        </RoundTableAccessDiv>
    </>
}


const EventDateDetailPageContent: React.FunctionComponent = (props) => {
    let { eventDateId }: any = useParams()
    const [eventDate, setEventDate] = useState<EventDate>();
    const [error, setError] = useState("")
    const [isLoaded, setIsLoaded] = useState(false)
    const langState = useLanguageState()
    const strings = langState.getStrings()
    const lang = langState.getLanguage()
    const navi = useNavigationSource()
    const profileId = useLoggedInState().user()?.profileId

    //Recommend modal
    const [targetId, setTargetId] = useState<string>("")
    const [organizationId, setOrganizationId] = useState<string>()
    const [link, setLink] = useState<string>("")
    const [type, setType] = useState<ShareTargetType>()
    const [showRecommendOrganizationModal, setShowRecommendOrganizationModal] = useState(false)
    // const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false)
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal(!branding.configuration.showPrivacyModelForEventDates)
    //add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()

    // const policyCallback = (accepted: boolean) => {
    //     setShowPrivacyPolicyModal(false)
    // }

    // redirecting user to the channel page when session starts (without reloading the page)
    useEffect(() => {
        if (!eventDate || !eventDate.channel || eventDate.type === "roundtable")
            return

        const now = getNow();
        const eventdateStart = moment(eventDate.startdatetime)
        const eventdateEnd = moment(eventDate.enddatetime)

        if (!isSoftOpeningPhase && !isLivePhase)
            return

        if (now.isAfter(eventdateEnd))
            return

        if (now.isAfter(eventdateStart) && now.isBefore(eventdateEnd))
            window.open(`/channel/${eventDate.channel?.id || eventDate.id}`, '_self')

        if (now.isBefore(eventdateStart)) {
            const timeToLive = eventdateStart.diff(now)
            if (timeToLive < 1000 * 60 * 60 * 24) {
                const handle = setTimeout(() => { window.open(`/channel/${eventDate.channel?.id || eventDate.id}`, '_self') }, timeToLive)
                return () => clearTimeout(handle)
            }
        }

    }, [eventDate])



    useEffect(() => {
        loadEventDateDetails({
            "id": eventDateId
        }).then((resp) => {
            setIsLoaded(true)
            setEventDate(resp.content)
            setOrganizationId(resp.content.organizationId)
            if (resp.content.organizationId && profileId) {
                trackEventDateDetailsPageVisit(profileId!, resp.content.organizationId, navi.get(), resp.content.id)
            }
            navi.set("UNKNOWN")
        }).catch((e: { message: React.SetStateAction<string> }) => {
            setIsLoaded(true)
            setError(e.message)
        })
        // eslint-disable-next-line
    }, [eventDateId, error, profileId, lang]);

    useEffect(() => {
        if (organizationId !== undefined && branding.configuration.showPrivacyModelForEventDates)
            showPrivacyPolicyModal(organizationId, () => { })

        // setShowPrivacyPolicyModal(organizationId !== undefined && branding.configuration.showPrivacyModelForEventDates === true)
    }, [organizationId])//eslint-disable-line


    const breadcrumb: BreadcrumbItem[] = []
    if (eventDate) {
        const locations = calcBreadcrumbLocations(strings)
        breadcrumb.push(
            { to: locations[0] + "#schedule", name: strings.programPageContent.scheduleTabTitle },
            { to: locations[1], name: eventDate.name }
        )
    }

    let content: JSX.Element | null = null

    if (isLoaded && eventDate) {
        content =
            <EventDateDetailBody
                eventDate={eventDate}
                setShowRecommendOrganizationModal={setShowRecommendOrganizationModal}
                setLink={setLink}
                setTargetId={setTargetId}
                setType={setType}
                guestBannerHeight={guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0}
                removeChat={false}
                removePreviewImage={false}
                showPrivacyPolicyModal={showPrivacyPolicyModal}
                pageTitle={eventDate.name}
            />
    }
    else {
        content = <CenteredLoader />
    }

    return (
        <>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar />
            <div style={{ borderBottom: "1px solid currentColor" }}>
                <Breadcrumb breadcrumb={breadcrumb} />
            </div>
            {content}
            {showRecommendOrganizationModal && <RecommendOrganizationModal targetId={targetId} type={type as ShareTargetType} link={`https://${window.location.hostname}` + link} sotUser={[]} close={() => setShowRecommendOrganizationModal(false)}></RecommendOrganizationModal>}
            {/* {showPrivacyPolicyModal && <PrivacyPolicyModal callback={policyCallback} organizationId={organizationId!} />} */}
            <PrivacyModal />
        </>


    );
}

export default EventDateDetailPageContent
