import styled from "styled-components"
import React, { useEffect, useState } from "react"
import CenteredLoader from "../ui/CenteredLoader"
import { loadExhibitorData, syncFavorites, trackVisit } from "../backendServices/BackendServices"
import { useFavoriteState } from "../globalStates/Favorites"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import moment from "moment"
import { useLanguageState } from "../globalStates/LanguageState"
import { CompaniesTilesLayout } from "../ui/CompaniesTilesLayout"
import TopBar from "../navigationArea/TopBar";
import Breadcrumb from "../navigationArea/Breadcrumb";
import SearchBookmarkHeader from "../ui/SearchBookmarkHeader"
import { calcBreadcrumbLocations } from "../tracking/RouteTracker"
import EmptyTile from "./reception/EmptyTile"
import BackendError from "../ui/BackendError"
import TopBanner from './advertisingBanner/TopBanner'
import TopBannerSharedState from '../contentArea/advertisingBanner/TopBannerSharedState'
import branding from "../branding/branding"
import FilterBar from "../ui/FilterBar";
import VisibilitySensor from "react-visibility-sensor"
import { ResultListLayout } from "../ui/CompaniesListLayout"
import ScrollContainer from "../ui/ScrollContainer"
import useWindowDimensions from "../ui/WindowDimensionsHook"
import GuestUserBanner from "./guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "./guestUserBanner/GuestUserBannerSharedState"
import GuestModal from "../ui/GuestModal"
import SayHelloModal from "../ui/SayHelloModal"
import { hasAccessToOrganization } from "../utils/Authorization";
import { buildDetailLink } from "./detailPages/DetailNavLink";
import { isExplorationOrPostEventPhase } from "../utils/EventPhaseChecker"
import { EntityType, ShareTargetType } from "../backendServices/Types";
import CalendarEntryModal, { CalendarEntryModalViewMode } from "../ui/CalendarEntryModal";
import RecommendOrganizationModal from "../ui/RecommendOrganizationModal";
import PrivacyPolicyModal from "../ui/PrivacyPolicyModal";
import { joinShowroom } from "./detailPages/OrganizationDetailPageContent";
import { useChimeContext } from "../conference/context/ChimeContext";
import { useMeetingContext } from "../conference/context/MeetingContext";
import { Sections, useSearch } from "./useSearch"
import CrsMultiSwitch from '../ui/CrsMultiSwitch';
import { useAppState } from "../globalStates/AppState"
import { showfloorPageRoute, exhibitorStartupPageRoute, mediaPartnerPageRoute, exhibitorsPageRoute, productsPageRoute, trademarksPageRoute } from "../navigationArea/RoutePaths"
import Select, { ActionMeta, OptionTypeBase, ValueType } from 'react-select'
import { SelectThemeCustom } from "./myprofile/EditMyProfileLayout"
import { trackNavigation } from "../utils/GTMTracking"



/*********************************************************************************************
 * variable definitions
**********************************************************************************************/
export enum ViewMode { LIST, TILES }


/* #region  Header */
const HeaderContainer = styled.div`
    display: flex;
    width: 100%;

    .dropdown-toggle::after {
       display: none;
    }

    .headerRoot{
        width: 100%;

        @media (max-width: 1800px) {
            width: 100%; //58%
        }

        @media (max-width: 1600px) {
            width: 100%; //54%
        }

        @media (max-width: 1400px) {
            width: 100%; //50%
        }
        
    }
`


/* #region  Dropdown section */
interface DropdownComponentProps {
    categories: any[]
    defaultTitle: string
    searchParamFilterValue: string | null | undefined
    onClick(value: string | null): void
}

const DropdownComponent: React.FC<DropdownComponentProps> = (props) => {
    const options = props.categories.map(cat => { return { label: cat.name, value: cat.alias } })
    const categoryLabel = props.categories.find(x => x.alias === props.searchParamFilterValue)?.name ?? props.defaultTitle
    const value = props.searchParamFilterValue ? { label: categoryLabel, value: props.searchParamFilterValue } : null

    return (
        <StyledSelect
            placeholder={props.defaultTitle}
            isMulti={false}
            isSearchable={true}
            isClearable={true}
            options={options}
            value={value}
            onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                if (value !== null && action.action === "select-option") {
                    const option = value as OptionTypeBase
                    if (props.searchParamFilterValue !== option.value)
                        props.onClick(option.value)
                } else
                    props.onClick(null)
            }}
            theme={SelectThemeCustom}
        />
    )
}

export const StyledSelect = styled(Select)`
    width: 250px;
    margin-right: 20px;
    z-index: 10;
    font-size: 14px;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
`
/* #endregion */

const ContentRoot = styled.div<{ bannerVisible: boolean, topBannerHeight: number, guestBannerHeight: number }>`
  display: flex;
  flex-direction: column;
  height: ${props => props.bannerVisible ? `calc(86vh - ${props.topBannerHeight}px - ${props.guestBannerHeight}px - 92px)` : `calc(86vh - ${props.guestBannerHeight}px - 92px)`};
  overflow-x: hidden;

  .ScrollbarsCustom-Scroller{
      overflow-x: hidden !important;
      padding-bottom: 20px;
  }

  .ScrollbarsCustom-Content{
      overflow: hidden;
  }

  @media(max-height: 1000px){
    height: ${props => props.bannerVisible ? `calc(82vh - ${props.topBannerHeight}px - ${props.guestBannerHeight}px - 124px)` : `calc(82vh - ${props.guestBannerHeight}px - 124px)`};
  }
`;

const ContainerRoot = styled.div`
`

const FilterBarContainer = styled.div`
    padding: 25px 25px;
`


const NextPageLoader = styled(CenteredLoader)`
    height: 120px;
`

const DropdownsRoot = styled.div`
    width: 33%;
    
    @media(max-width: 1160px){
        width: 40%;
    }
`


const SwitcherRoot = styled.div`
    width: 33%;
    
    @media(max-width: 1160px){
        width: 30%;
    }
`

const ButtonRoot = styled.div`
    width: 33%;
    
    @media(max-width: 1160px){
        width: 30%;
    }
`


/*********************************************************************************************
 * site assembly
**********************************************************************************************/
const ExhibitorsContainer = styled.div<{ viewMode: ViewMode }>`
    height: 100vh - 40px;
    overflow: hidden;

    .infinite-scroll-component{
        padding-bottom: ${props => props.viewMode === ViewMode.TILES ? "250px" : "100px"};
    }
`

export interface SimpleOrganization {
    id: string
    name: string
}



interface ExhibitorsPageProps {
    /**
    * @title isSponsorsPage option  
    * @description Enable / disable isSponsorsPage option 
    * @title_de isSponsorsPage option     
    * @description_de Enable / disable isSponsorsPage option 
    */
    isSponsorsPage?: boolean
    /**
    * @title isMediaPartnerPage option  
    * @description Enable / disable isMediaPartnerPage option 
    * @title_de isMediaPartnerPage option     
    * @description_de Enable / disable isMediaPartnerPage option 
    */
    isMediaPartnerPage?: boolean
    /**
    * @title showOnlyBookmarks option  
    * @description Enable / disable showOnlyBookmarks option 
    * @title_de showOnlyBookmarks option     
    * @description_de Enable / disable showOnlyBookmarks option 
    */
    showOnlyBookmarks?: boolean
    /**
    * @title isStartupPage option  
    * @description Enable / disable isStartupPage option 
    * @title_de isStartupPage option     
    * @description_de Enable / disable isStartupPage option 
    */
    isStartupPage?: boolean
}

const ExhibitorsPageContent: React.FunctionComponent<ExhibitorsPageProps> = (props: ExhibitorsPageProps) => {
    const userLink = useLoggedInState()
    const loggedInUserId = userLink.user()?.profileId
    const chime = useChimeContext()
    const meeting = useMeetingContext()
    // Add banner state 
    const { showBanner, setShowBanner, topBarRef, setTopBarRef, hideOnScroll, setHideOnScroll } = TopBannerSharedState()
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()

    const appState = useAppState()

    let isSponsor: boolean = props.isSponsorsPage ?? false
    let isMediaPartner: boolean = props.isMediaPartnerPage ?? false
    let isStartup: boolean = props.isStartupPage ?? false

    const languageState = useLanguageState();
    const strings = languageState.getStrings()
    const favoriteState = useFavoriteState()

    const includeFilterCategories = (branding.exhibitorsPageContent.includeFirstFilterCategories || branding.exhibitorsPageContent.includeSecondFilterCategories) ?? false
    const firstFilterCategories = strings.exhibitorsPageContent.firstFilterCategories
    const secondFilterCategories = strings.exhibitorsPageContent.secondFilterCategories

    const { searchParams, results, isLoading, searchFunctions } = useSearch(props.isSponsorsPage, props.showOnlyBookmarks, props.isMediaPartnerPage, props.isStartupPage)
    const [isError, setIsError] = useState("")

    const [targetId, setTargetId] = useState<string>()

    const [showGuestModal, setShowGuestModal] = useState<boolean>(false)
    const [showRequestOrganizationModal, setShowRequestOrganizationModal] = useState<boolean>(false)

    const [viewMode, setViewMode] = useState(props.showOnlyBookmarks ? ViewMode.LIST : ViewMode.TILES)

    const [resetScroll, setResetScroll] = useState<boolean>(false)

    const [link, setLink] = useState<string>("")
    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState<boolean>(false)
    const [showRecommendOrganizationModal, setShowRecommendOrganizationModal] = useState<boolean>(false)
    const [action, setAction] = useState<"EXPO" | "MEETING" | "INTEREST" | "RECOMMEND">()
    const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState<boolean>(false)
    const windowSize = useWindowDimensions();

    useEffect(() => setResetScroll(true), [searchParams.entityType, viewMode])
    useEffect(() => setResetScroll(false), [resetScroll])

    useEffect(() => {
        if (window.location.href.includes("product")) {
            searchFunctions.setEntityFilter("product")
        }
        else if (window.location.href.includes("trademark")) {
            searchFunctions.setEntityFilter("trademark")
        }
        else {
            searchFunctions.setEntityFilter("organization")
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        switch (searchParams.entityType) {
            case "organization":
                trackNavigation(strings.sideIconBar.showfloorMenuText + " - " + strings.networkingArea.exhibitorsTitle)
                break
            case "product":
                trackNavigation(strings.sideIconBar.showfloorMenuText + " - " + strings.navigationArea.productsItemTitle)
                break
            case "trademark":
                trackNavigation(strings.sideIconBar.showfloorMenuText + " - " + strings.navigationArea.trademarksItemTitle)
                break
            default:
        }

        // eslint-disable-next-line
    }, [searchParams.entityType])

    useEffect(() => {
        if (loggedInUserId) {
            syncFavorites({
                profileId: loggedInUserId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // "2020-01-01 12:00:00",
                    lastSyncTime: favoriteState.getLastSyncTime()
                }
            }).then((resp) => {
                favoriteState.setLastSyncTime(new Date())
                const temp: Array<any> = resp.content.favorites;
                temp.forEach(item => {
                    if (!item.deleted && !favoriteState.is("organization", item.id)) {
                        favoriteState.add("organization", item.id)
                    }
                });
            }).catch((e: { message: React.SetStateAction<string> }) => {
                setIsError(e.message)
            })
        }
    }, [loggedInUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    const onScroll = (scrollValues: any) => {
        if (showBanner) {
            if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
                setHideOnScroll(scrollValues.scrollTop > 0)
            } else {
                setHideOnScroll(true)
            }
        }
    }

    const onAction = (action: "EXPO" | "MEETING" | "INTEREST" | "RECOMMEND", organization: SimpleOrganization) => {
        if (loggedInUserId) {
            if (!hasAccessToOrganization(userLink.user()!, organization.id)) {
                setShowGuestModal(true)
                return
            }
            switch (action) {
                case 'EXPO':
                    if (!isExplorationOrPostEventPhase) {
                        trackVisit(loggedInUserId, organization.id, "EXPO")
                        setAction(action)
                        setTargetId(organization.id)
                        setShowPrivacyPolicyModal(true)
                    }
                    break
                case 'MEETING':
                    trackVisit(loggedInUserId, organization.id, "CALENDARENTRY#CLICK")
                    setAction(action)
                    setTargetId(organization.id)
                    setShowPrivacyPolicyModal(true)
                    break
                case 'INTEREST':
                    trackVisit(loggedInUserId, organization.id, "INTEREST#CLICK")
                    setAction(action)
                    setTargetId(organization.id)
                    setShowPrivacyPolicyModal(true)
                    break
                case 'RECOMMEND':
                    trackVisit(loggedInUserId, organization.id, "RECOMMENDATION#CLICK")
                    setAction(undefined)
                    setTargetId(organization.id)
                    setLink(buildDetailLink(organization.id, organization.name, 'organization'))
                    setShowRecommendOrganizationModal(true);
                    break

            }
        }
    }


    const policyCallback = (accepted: boolean) => {

        switch (action) {
            case 'MEETING':
                setShowRequestMeetingModal(true)
                break
            case 'INTEREST':
                setShowRequestOrganizationModal(true)
                break
            case 'EXPO':
                loadExhibitorData({
                    organizationid: targetId!,
                    hideNewsdata: true
                }).then((resp) => {
                    joinShowroom(resp.content, userLink.user()!, chime, meeting)
                })
                break
        }
        setAction(undefined)
        setShowPrivacyPolicyModal(false)
    }


    function getCurrentBreadcrumb(): any {
        if (searchParams.entityType === "organization") {
            return { to: exhibitorsPageRoute, name: strings.navigationArea.companiesItemTitle }
        }
        else if (searchParams.entityType === "product") {
            return { to: productsPageRoute, name: strings.sideIconBar.productsSearchDrawerText }
        }
        else if (searchParams.entityType === "trademark") {
            return { to: trademarksPageRoute, name: strings.sideIconBar.trademarksSearchDrawerText }
        }
    }

    let content: JSX.Element
    let totalCount = 0
    Object.values(results).forEach(result => {
        if (result)
            totalCount += result.count
    })
    if (isError) {
        content = <div style={{ marginTop: "25%" }}><BackendError /></div>
    } else if (isLoading) {
        content = <div style={{ marginTop: "25%" }}><CenteredLoader /></div>
    } else {
        const hasMoreData = searchFunctions.hasMoreData()
        const searchKrit = (searchParams.firstFilterCategory || '_') + '##' + (searchParams.secondFilterCategory || '_') + '##' + (searchParams.searchValue || '_')
        content = <ContentRoot
            bannerVisible={showBanner && !hideOnScroll}
            topBannerHeight={topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0}
            guestBannerHeight={guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0}
        >
            <ScrollContainer scrollTop={resetScroll ? 0 : undefined} onScroll={onScroll}>
                <ContentElements countExhibitors={totalCount} onlyBookmarks={searchParams.showOnlyBookmarks} hasMoreData={hasMoreData}
                    entityType={searchParams.entityType}
                    isMainShowFloorPage={!props.showOnlyBookmarks}
                    viewMode={searchParams.entityType === "organization" ? viewMode : ViewMode.LIST}
                    sections={results} onAction={onAction} isSponsor={isSponsor} isMediaPartner={isMediaPartner} isStartup={isStartup} searchKrit={searchKrit} />
                {hasMoreData && <VisibilitySensor partialVisibility={true} onChange={(visible) => { if (visible) searchFunctions.nextPage() }}><NextPageLoader /></VisibilitySensor>}
            </ScrollContainer>
        </ContentRoot >
    }

    const locations = calcBreadcrumbLocations(strings)

    const bookmarkedExhibitorsBreadcrumb = [{ to: locations[0], name: strings.sideIconBar.lobbyMenuText }]
    bookmarkedExhibitorsBreadcrumb.push(
        { to: "/", name: strings.receptionPage.receptionPageMyHeaderTitle },
        { to: "/", name: strings.exhibitorsPageContent.bookmarkedExhibitorsTitle }
    )

    let emptyOnlyBookmarksPage = props.showOnlyBookmarks && totalCount === 0 && !isLoading
    var entityFilterItems: { label: string, value: EntityType }[] = [{ label: strings.navigationArea.companiesItemTitle, value: "organization" }]
    if (branding.navigationArea.showProduct) {
        entityFilterItems.push({ label: strings.navigationArea.productsItemTitle, value: "product" })
    }
    if (branding.navigationArea.showTrademark) {
        entityFilterItems.push({ label: strings.navigationArea.trademarksItemTitle, value: "trademark" })
    }

    let isShowfloor = !isSponsor && !props.isMediaPartnerPage && !props.showOnlyBookmarks


    return <>
        <div style={{ background: "#fff" }}>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar />
            {!props.showOnlyBookmarks && <TopBanner type={isSponsor ? "partners_sponsors" : isMediaPartner ? "mediapartner" : isStartup ? "startup" : "showfloor"} setRef={setTopBarRef} hideOnScroll={hideOnScroll} onHideBanner={() => { setShowBanner(false) }} />}
            {!emptyOnlyBookmarksPage && <FilterBar activeFilter={searchParams.alpha} onFilterChanged={searchFunctions.setAlpha} />}
            <Breadcrumb breadcrumb={props.showOnlyBookmarks ? bookmarkedExhibitorsBreadcrumb : props.isMediaPartnerPage ? [{ to: mediaPartnerPageRoute, name: strings.mediaPartnerPageContent.mediaPartnerName }] : props.isStartupPage ? [{ to: exhibitorStartupPageRoute, name: strings.exhibitorsPageContent.exhibitorsStartupPageName }] : [{ to: locations[0], name: isSponsor ? strings.sideIconBar.mediapartnersMenuText : strings.sideIconBar.showfloorMenuText }, getCurrentBreadcrumb()]} disableVirtPagePath={isShowfloor} />
            <ExhibitorsContainer viewMode={viewMode}>

                {emptyOnlyBookmarksPage &&
                    <div style={{ marginTop: "20%" }}>
                        <EmptyTile header={strings.receptionPage.noBookmarkedExhibitors} buttonNavLink={showfloorPageRoute} buttonMessage={strings.receptionPage.showFloorNavLinkText} hideButton={true} bgColor="transparent" />
                    </div>
                }
                {!emptyOnlyBookmarksPage && <ContainerRoot>
                    <FilterBarContainer
                        className={!isSponsor && !isMediaPartner && !isStartup && includeFilterCategories ? "d-flex justify-content-between align-items-end" : ""}>
                        <HeaderContainer>
                            <DropdownsRoot>
                                {!isSponsor && !isMediaPartner && !isStartup && includeFilterCategories &&
                                    <div className="d-flex">
                                        {branding.exhibitorsPageContent.includeFirstFilterCategories && <DropdownComponent
                                            categories={firstFilterCategories}
                                            defaultTitle={strings.exhibitorsPageContent.firstFilterTitle}
                                            searchParamFilterValue={searchParams.firstFilterCategory}
                                            onClick={searchFunctions.setFirstFilterCategory} />
                                        }
                                        {branding.exhibitorsPageContent.includeSecondFilterCategories && <DropdownComponent
                                            categories={secondFilterCategories}
                                            defaultTitle={strings.exhibitorsPageContent.secondFilterTitle}
                                            searchParamFilterValue={searchParams.secondFilterCategory}
                                            onClick={searchFunctions.setSecondFilterCategory} />
                                        }
                                    </div>}
                            </DropdownsRoot>
                            <SwitcherRoot>
                                {!isSponsor && !props.showOnlyBookmarks && !isMediaPartner && !isStartup &&
                                    CrsMultiSwitch({
                                        items: entityFilterItems,
                                        activeItem: searchParams.entityType !== "organization" && searchParams.entityType !== "product" && searchParams.entityType !== "trademark" ? "organization" : searchParams.entityType,
                                        onItemClick: (value: any) => searchFunctions.setEntityFilter(value),
                                        style: { mainWidth: '250px', msRootItem: { padding: '1px', zIndex: 0, width: "250px", margin: "6px auto" } }
                                    })}
                            </SwitcherRoot>
                            <ButtonRoot>
                                <SearchBookmarkHeader
                                    bookmarkFilter={searchParams.showOnlyBookmarks}
                                    setBookmarkFilter={searchFunctions.setBookmarksOnly}
                                    placeholder={strings.exhibitorsPageContent.navigationSearchPlaceholderCompanies}
                                    bookmarkText={strings.programSpeakers.onlyShowBookmarksTitle}
                                    viewMode={searchParams.entityType === "organization" ? viewMode : undefined}
                                    onViewModeChange={(viewMode) => setViewMode(viewMode)}
                                    responsive={true}
                                    hideBookmarkButton={props.showOnlyBookmarks ?? false}
                                    currentSearchText={searchParams.searchTitle}
                                    clearCurrentSearch={() => appState.clearSuggestParam()}
                                    searchBarWidth={windowSize.width < 1400 ? "100%" : windowSize.width < 1600 ? "80%" : windowSize.width < 1800 ? "60%" : "45%"}
                                    isShowfloor={true}
                                />
                            </ButtonRoot>
                        </HeaderContainer>


                    </FilterBarContainer>

                    {content}
                </ContainerRoot>}
            </ExhibitorsContainer >
        </div>
        {showGuestModal && <GuestModal close={() => setShowGuestModal(false)} />}
        {showRequestOrganizationModal && <SayHelloModal organizationModal={true} targetId={targetId!} handleClose={() => setShowRequestOrganizationModal(false)}></SayHelloModal>}
        {showRequestMeetingModal && <CalendarEntryModal viewMode={CalendarEntryModalViewMode.CREATE} organization={targetId!} sotUser={[]} close={() => setShowRequestMeetingModal(false)} />}
        {showRecommendOrganizationModal && <RecommendOrganizationModal targetId={targetId!} type={ShareTargetType.ORGANIZATION} link={`https://${window.location.hostname}` + link} sotUser={[]} close={() => setShowRecommendOrganizationModal(false)}></RecommendOrganizationModal>}
        {showPrivacyPolicyModal && <PrivacyPolicyModal callback={policyCallback} organizationId={targetId!} />}
    </>
}


interface ContentElementsProps {
    countExhibitors: number
    onlyBookmarks: boolean
    hasMoreData: boolean
    viewMode: ViewMode
    sections: Sections
    onAction: (action: "EXPO" | "MEETING" | "INTEREST" | "RECOMMEND", organization: SimpleOrganization) => void
    isSponsor: boolean
    isMediaPartner: boolean
    isStartup: boolean
    searchKrit?: string
    isMainShowFloorPage: boolean
    entityType: string
}

const ContentElements: React.FC<ContentElementsProps> = (props) => {
    const windowSize = useWindowDimensions();

    const strings = useLanguageState().getStrings()

    function getNoBookmarksMessage() {
        if (props.entityType === "product") {
            return strings.productsPageContent.noBookmarksMessage
        }
        else if (props.entityType === "trademark") {
            return strings.trademarksPageContent.noBookmarksMessage
        }
        else {
            return strings.receptionPage.noBookmarkedExhibitors
        }
    }


    if (props.countExhibitors === 0 && props.onlyBookmarks && props.hasMoreData === false) {
        return <div style={{ marginTop: "20%" }}>
            <EmptyTile header={getNoBookmarksMessage()} buttonNavLink={showfloorPageRoute} buttonMessage={strings.receptionPage.showFloorNavLinkText} hideButton={true} bgColor="transparent" />
            {/* hideButton={props.isMainShowFloorPage}  */}
        </div>
    }
    if (props.countExhibitors === 0 && props.hasMoreData === false) {
        return <div style={{ marginTop: "20%" }}>
            <EmptyTile header={strings.sideIconBar.emptyResultMessage} bgColor="transparent" hideButton={true} />
        </div>
    }
    if (props.viewMode === ViewMode.LIST || windowSize.width < 1350) {
        return <ResultListLayout sections={props.sections} onAction={props.onAction} isSponsor={props.isSponsor} isMediaPartner={props.isMediaPartner} isStartup={props.isStartup} searchKrit={props.searchKrit} />
    } else {
        return <CompaniesTilesLayout sections={props.sections} type={'organization'} onAction={props.onAction} isSponsor={props.isSponsor} isMediaPartner={props.isMediaPartner} isStartup={props.isStartup} searchKrit={props.searchKrit} />
    }
}


export default ExhibitorsPageContent
