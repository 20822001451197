import React, { useEffect, useState } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import * as uuid from 'uuid'
import { createNewUserSession, UserSessionProps, updateUserSessionData, trackStartOfCall, DynamoDBErrors, trackEndOfCall, trackOrganizationDetailsPageVisit, closeUserSession, trackStartOfStream, trackLiveStreamDetailsPageVisit } from '../backendServices/BackendServices'
import { useLanguageState } from '../globalStates/LanguageState'
import { useChimeContext, MeetingStatusCode, getMeetingKindPrefix } from '../conference/context/ChimeContext'
import { useNavigationSource } from './NavigationSource'
import { organizationDetailPageRoute, eventDateDetailPageRoute } from '../navigationArea/RoutePaths'
import { defaultLogger as logger, useAppState } from "../globalStates/AppState"
import { UserActionType } from '../API'

var lastUpdateString = window.sessionStorage.getItem("lastUpdate")
var lastUpdate: Date | undefined = lastUpdateString ? new Date(lastUpdateString) : undefined
var sessionId = window.sessionStorage.getItem('sessionId') || uuid.v4()
window.sessionStorage.removeItem('sessionId')
window.onbeforeunload = () => {
    window.sessionStorage.setItem('sessionId', sessionId)
    if (lastUpdate) {
        window.sessionStorage.setItem('lastUpdate', lastUpdate.toUTCString())
    }
}

const HEARTBEAT = 5 * 60 * 1000

const VC_PREFIX = getMeetingKindPrefix("virtualCafe")

var currentLocation: string
var heartbeat: number
var userId: string

interface RouteTrackerprops {
    profileId: string,
    countryCode?: string,
    userType?: string
}

export const endSession = async () => {
    await closeUserSession(sessionId)
}

const findGroupname = (strings: any, name: string | null) => {
    if (name) {
        const match = name.startsWith(VC_PREFIX) ? name.substring(VC_PREFIX.length) : name
        for (const meetingRoomGroup of strings.meetingRoomGroups) {
            for (const meetingRoom of meetingRoomGroup.meetingRooms) {
                if (meetingRoom.id === match) {
                    return meetingRoomGroup.id
                }
            }
        }
    }
    return ""
}

const createOrUpdateSession = (params: UserSessionProps) => {
    var update = false
    if (lastUpdate) {
        const diff = (new Date().getMilliseconds() - lastUpdate.getMilliseconds()) / 1000
        if (diff < HEARTBEAT + 60) {
            update = true
        }
    }
    if (update) {
        updateUserSessionData(params, true).then(resp => {
            if (resp) {
                lastUpdate = new Date()
            }
        })
    } else {
        createNewUserSession(params, true).then(resp => {
            if (resp) {
                lastUpdate = new Date()
            }
        })
    }
}

const RouteTracker: React.FC<RouteTrackerprops> = ({ profileId, countryCode, userType }) => {
    const location = useLocation()
    const strings = useLanguageState().getStrings()
    const navigationSource = useNavigationSource()
    userId = profileId


    useEffect(() => {
        if (currentLocation !== location.pathname) {
            const params = calcLocationLevels(location.pathname, profileId, strings, !currentLocation ? 'NEW' : navigationSource.get(), countryCode, userType)
            createOrUpdateSession(params)
            const orgRoute = matchPath<{ organizationName: string, organizationId: string }>(location.pathname, organizationDetailPageRoute)
            if (orgRoute && orgRoute.isExact && orgRoute.params.organizationId) {
                (async () => {
                    await trackOrganizationDetailsPageVisit(profileId, orgRoute.params.organizationId, navigationSource.get(), navigationSource.getKrit())
                    navigationSource.setKrit(undefined)
                })()
            }
            const eventDateRoute = matchPath<{ eventdateName: string, eventDateId: string }>(location.pathname, eventDateDetailPageRoute)
            if (!eventDateRoute || !eventDateRoute.isExact || !eventDateRoute.params.eventDateId) {
                navigationSource.set('UNKNOWN')
            }
            currentLocation = location.pathname
            heartbeat = window.setInterval(() => {
                createOrUpdateSession(params)
            }, HEARTBEAT)
            return () => {
                clearInterval(heartbeat)
            }
        }
    }, [location, profileId]) //eslint-disable-line

    return null
}

export const calcBreadcrumbLocations: ((myStrings: any) => string[]) = (myStrings) => {
    const param = calcLocationLevels(window.location.pathname, userId, myStrings)
    const arr = [param.locationLevel1!]
    if (param.locationLevel2 !== '') {
        arr.push(param.locationLevel2!)
        if (param.locationLevel3 !== '') {
            arr.push(param.locationLevel3!)
        }
    }
    return arr;
}

// calculate the sublevels of a location for tracking
const calcLocationLevels = (location: string, profileId: string, strings: any, source?: string, countryCode?: string, userType?: string) => {
    const param: UserSessionProps = {
        id: sessionId,
        userId: profileId,
        location: location,
        locationLevel1: '-',
        locationLevel2: '-',
        locationLevel3: '-',
        source: source,
        queryHelper: 'X',
        countryCode,
        userType,
    }
    if (location.length > 1 && location.endsWith('/')) {
        location = location.substr(0, location.length - 1)
    }
    const arr = location.split('/')

    // Top-Level Seiten
    if (arr.length === 2) {
        param.locationLevel1 = location
    } else if (arr.length === 3) {
        switch (arr[1]) {
            // /eventdate/:eventdateName--:eventDateId => /program
            case 'eventdate':
                param.locationLevel1 = '/program'
                break
            // /person/:personName--:personId--:userId => /networking
            case 'person':
                param.locationLevel1 = '/networking'
                break
            case 'company':
                param.locationLevel1 = '/organization'
                break
            // /organization/:organizationName--:organizationId
            // /product/:productName--:productId
            // /news/:newsName--:newsId
            // /meetings/:groupId
            default:
                param.locationLevel1 = '/' + arr[1]


            // nicht geklärt:
            // /trademark/:trademarkName--:trademarkId
            // /coupon/:couponName--:couponId
        }
        param.locationLevel2 = location
    } else if (arr.length > 3) {
        // /meetings/:kind/:roomName/:action => /meetings/{meetingRoomGroup}
        if (arr[1] === 'meeting') {
            if (arr[2].startsWith(VC_PREFIX)) {
                param.locationLevel1 = '/meetings'
                param.locationLevel2 = '/meetings/' + findGroupname(strings, arr[2])
                param.locationLevel3 = location
            } else {
                param.locationLevel1 = location
            }
        } else if (arr[1] === 'company') {
            param.locationLevel1 = '/organization'
            param.locationLevel2 = '/' + arr[1] + '/' + arr[2]
            param.locationLevel3 = location
        } else {
            logger.error('Route Tracker unknown location: ' + location)
        }
    }
    return param
}

export default RouteTracker

interface CallTrackerProps {
    profileId: string
    countryCode?: string,
    userType?: string
}

var chatId: string | null
var streamId: string | null

export const CallTracker: React.FC<CallTrackerProps> = ({ profileId, countryCode, userType }) => {
    const strings = useLanguageState().getStrings()
    const chime = useChimeContext()
    const meetingstatus = chime.getMeetingStatus().meetingStatus;
    const name = chime.getExternalMeetingId()
    const kind = chime.getKind()


    useEffect(() => {
        (async () => {
            const match = name?.startsWith(getMeetingKindPrefix("virtualCafe")) ? name.substring(3) : name
            const chatName = kind + "#" + (kind === "virtualCafe" ? findGroupname(strings, name) + "/" : "") + match
            switch (meetingstatus) {
                case MeetingStatusCode.Succeeded:
                    if (chatId) {
                        await trackEndOfCall(chatId, UserActionType.CALL)
                        chatId = null
                    }
                    const result = await trackStartOfCall(sessionId, profileId, chatName, countryCode, userType)
                    if (!(result as DynamoDBErrors).errors) {
                        chatId = result as string
                    }
                    break
                case MeetingStatusCode.Ended:
                case MeetingStatusCode.Disconnected:
                case MeetingStatusCode.TimeUp:
                    if (chatId) {
                        await trackEndOfCall(chatId, UserActionType.CALL)
                        chatId = null
                    }
                    break
            }
        })()
    }, [kind, name, meetingstatus]) //eslint-disable-line 


    return null
}

export const StreamTracker: React.FC<CallTrackerProps> = ({ profileId, countryCode, userType }) => {
    const event = useAppState().liveStreamChannel?.eventDate
    const currentEventId = event?.id
    const currentOrgId = event?.organizationId
    const [eventId, setEventId] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (currentEventId) {
            if (currentEventId !== eventId) {
                if (streamId) {
                    trackEndOfCall(streamId, UserActionType.STREAM)
                    streamId = null
                }
                if (currentOrgId) {
                    trackLiveStreamDetailsPageVisit(profileId, currentOrgId, currentEventId)
                }
                trackStartOfStream(sessionId, profileId, currentEventId, countryCode, userType).then(data => {
                    if ((data as DynamoDBErrors).errors) {
                        streamId = null
                    } else {
                        streamId = data as string
                    }
                })
                setEventId(currentEventId)
            }
        } else {
            if (streamId) {
                trackEndOfCall(streamId, UserActionType.STREAM)
                setEventId(undefined)
                streamId = null
            }
        }

        // eslint-disable-next-line
    }, [currentEventId])


    return null
}


