import React, { useState } from "react"
import branding from "../../branding/branding"
import ViewMyProfileLayout from "./ViewMyProfileLayout"
import EditMyProfilePageLayout from "./EditMyProfileLayout"
import TopBar from "../../navigationArea/TopBar"
import Breadcrumb from "../../navigationArea/Breadcrumb"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import { useLanguageState } from "../../globalStates/LanguageState"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"



export enum MyProfilePageMode { VIEW, EDIT }

const MyProfilePageContent: React.FunctionComponent = (props) => {
    const params = new URLSearchParams(window.location.search).get('mode')
    const [myProfileMode, setMyProfileMode] = useState<MyProfilePageMode>(params && params === "EDIT" ? MyProfilePageMode.EDIT : MyProfilePageMode.VIEW)
    const strings = useLanguageState().getStrings()
    const locations = calcBreadcrumbLocations(strings)
    // Add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState();
    const guestBannerHeight = guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0;

    let breadcrumb = [{ to: "/", name: strings.sideIconBar.lobbyMenuText },
    { to: "/", name: strings.receptionPage.receptionPageMyHeaderTitle },
    { to: locations[0], name: strings.myProfilePageContent.myProfileTitle }]


    return <div style={{ fontFamily: branding.font1, backgroundColor: "white" }}>
        <GuestUserBanner setRef={setGuestUserBannerRef} />
        <TopBar />
        <Breadcrumb breadcrumb={breadcrumb} />
        {
            myProfileMode === MyProfilePageMode.VIEW &&
            <ViewMyProfileLayout setMyProfileMode={setMyProfileMode} guestBannerHeight={guestBannerHeight} />
        }
        {
            myProfileMode === MyProfilePageMode.EDIT &&
            <EditMyProfilePageLayout setMyProfileMode={setMyProfileMode} guestBannerHeight={guestBannerHeight} />
        }
    </div>
}

export default MyProfilePageContent
