import React from "react"
import { meetingPageRoute } from "../navigationArea/RoutePaths"
import { useRouteMatch } from "react-router-dom"
import { IconSearch, IconSearchSmall, IconClearSearchItem, IconClearSearchItemLarge } from "./Icons"
import branding from "../branding/branding"
import { TextField } from "./TextField"
import { useAppState } from "../globalStates/AppState"

interface SearchBarProps {
    setSearchParam: (setSearchParam: string) => void;
    searchValue: string
    placeholder: string;
    width: string;
    bgColor?: string
    isSideBarSearchBar?: boolean
    largeIcon?: boolean
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
    clearIconPressed?: () => void
}

const SearchBar: React.FunctionComponent<SearchBarProps> = (props: SearchBarProps) => {
    let isMeetingPage = useRouteMatch(meetingPageRoute)
    const appState = useAppState()

    function handleClick() {
        appState.clearSuggestParam()
        props.setSearchParam("")

        if (props.clearIconPressed)
            props.clearIconPressed()
    }

    return <TextField
        backgroundColor={(props.isSideBarSearchBar && isMeetingPage) ? branding.mainInfoColor ?? "black" : "transparent"}
        textColor={(props.isSideBarSearchBar && isMeetingPage) ? "white" : branding.mainInfoColor ?? "black"}
        width={props.width}
        placeholder={props.placeholder}
        fontSize={branding.searchBarFontSize}
        height="25px"
        fontFamily={branding.searchBarFontFamily}
        textTransform={branding.searchBarTextTransform}
        fontWeight={branding.searchBarFontWeight}
        letterSpacing={branding.searchBarLetterSpacing}
        value={props.searchValue || ""}
        setValue={props.setSearchParam}
        search={true}
        onKeyPress={props.onKeyPress}
        startAdornment={
            <>
                <span id="searchIcon" style={{ 'color': props.isSideBarSearchBar ? (isMeetingPage ? 'white' : branding.primaryColor ?? "black") : branding.mainInfoColor ?? "black" }}>
                    {props.largeIcon ? IconSearch({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor }) : IconSearchSmall({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                </span>
            </>
        }
        endAdornment={
            <>
                <span style={{ 'color': props.isSideBarSearchBar ? (isMeetingPage ? 'white' : branding.mainInfoColor ?? "black") : branding.mainInfoColor ?? "black", paddingRight: "7px", cursor: "pointer", visibility: props.searchValue !== "" ? "visible" : "hidden", float: "right" }} onClick={() => handleClick()} >
                    {props.largeIcon ? IconClearSearchItemLarge({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor }) : IconClearSearchItem({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                </span>
            </>
        } />
}

export default SearchBar;
