import React, { useRef, useEffect, useState, SyntheticEvent } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import styled from "styled-components";
import { keyframes } from "styled-components";
import Draggable from 'react-draggable';
import { useHistory } from "react-router-dom";
import { useAppState } from '../../globalStates/AppState';
import { buildDetailLink } from '../detailPages/DetailNavLink';
import { BackendServiceError, EventDateChannelFirstDetailsResponse, loadChannelFirstEventDate } from '../../backendServices/BackendServices';
import moment from 'moment';
import { useLanguageState } from '../../globalStates/LanguageState';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import { useChimeContext, MeetingStatusCode } from '../../conference/context/ChimeContext';
import './Video-js.css'
import { accessMyPresenceState, EventType } from '../../ui/PresenceIndicator';

/* mini-version of VideoPlayer.tsx for Picture-In-Picture-Mode,
livestream-capable video-player which uses the open source library video.js
video.js implements HLS-Streaming
the UI is styled by changing the default CSS-skin that video.js provides */

/* ################### video player logic #################################################################################################*/

const usePlayer = (channelId: string | undefined, url: string | undefined, controls: boolean) => {
    const appState = useAppState()
    const history = useHistory()
    const strings = useLanguageState().getStrings()
    const chime = useChimeContext()

    const videoRef = useRef(null);
    const [player, setPlayer] = useState<VideoJsPlayer | null>(null);

    // instantiate and dispose of player only once
    // similar to componentDidMount / componentDidUpdate / componentWillUnmount
    // do something after render
    useEffect(() => {
        if (!channelId || !url) {
            return undefined
        }
        const options = {
            autoplay: false,
            controls: controls,
            preload: "auto",
            liveui: true,
            fluid: true,
            userActions: {
                // doubleClick: handlePlayerDoubleClick, 
                hotkeys: {
                    fullscreenKey: () => false,
                }
            },
            html5: {
                vhs: {
                    withCredentials: true,
                    enableLowInitialPlaylist: true,
                    smoothQualityChange: true,
                    overrideNative: true,
                },
                nativeAudioTracks: false,
                nativeVideoTracks: false,
            },
            sources: [{
                src: url,
                type: 'application/x-mpegURL'
            }],
            controlBar: {
                children: [
                    'PlayToggle',
                    'CloseButton'
                ]
            }
        };

        // pass player ready callback as third argument
        const vjsPlayer = videojs(videoRef.current, { ...options }, function (this: VideoJsPlayer) {
            // read volume level, mute-state and play/pause-state from appState, so it stays the same when user switches 
            // between Video- and PiP-Player

            /* the following lines deal with the feature, that video.js-Player allows a state where volume is 0 but mute 
            is set to false and vice versa, this is probably due to the fact that muting can be toggled via two UI-Elements:
            volume-slider and mute-toggle-button,
            this is probably on purpose so the user can mute player via mute button and then switch back to the previous
            volume, when mute is turned off again */
            const tmpAppstate = appState.videoPlayerStatus
            if (tmpAppstate) {
                if (tmpAppstate.volume === 0) {
                    this.volume(0);
                    this.muted(true);
                    // this.trigger('volumechange');   // update UI-Element Volume-Bar
                } else if (tmpAppstate.isMuted === true) {
                    this.muted(true);
                    // this.trigger('volumechange');   // update UI-Element Volume-Bar
                } else {
                    if (tmpAppstate.volume) {
                        this.volume(tmpAppstate.volume);
                    }
                    this.muted(false);
                    // this.trigger('volumechange');   // update UI-Element Volume-Bar
                }

                if (tmpAppstate.isPaused !== undefined) {
                    if (appState.videoPlayerStatus?.isPaused) {
                        vjsPlayer.pause();
                    } else {
                        vjsPlayer.play();
                    }
                }
            }

            // { IWillNotUseThisInPlugins: true } suppresses warning about tech usage
            const audioTrackList = vjsPlayer.tech({ IWillNotUseThisInPlugins: true }).audioTracks();

            audioTrackList.addEventListener('addtrack', function(e: Event) {
                // if audio track was previously set in appState, choose this audio track
                // don't do anything, if only one audio track was added
                const audioTrackFromAppState = appState.videoPlayerStatus?.audioTrack
                if(audioTrackFromAppState && audioTrackList.length > 1) {
                    for (let i = 0; i < audioTrackList.length; i++) {
                        let track = audioTrackList[i];
                        if(track !== undefined ) {
                            const trackAny = track as any
                            if(trackAny.label === audioTrackFromAppState) {
                                // setting enabled to true does not seem to trigger a change event, so we trigger it ourselves
                                trackAny.enabled = true;
                                audioTrackList.trigger("change");
                            }
                            else{
                                trackAny.enabled = false;
                            }
                        }
                    }
                }
            });

            
            audioTrackList.addEventListener('change', function(e: Event) {
                // todo: this only works for two audio tracks
                // prevent activating two audio tracks at the same time
                if(audioTrackList.length > 1) {
                    const trackAny0 = audioTrackList[0] as any
                    const trackAny1 = audioTrackList[1] as any
                    if(trackAny0.enabled === trackAny1.enabled) {
                        e.stopImmediatePropagation()
                    }
                }
            });

        }); // end player init

        // load error is displayed using appstrings from branding.js
        videojs.addLanguage('de', {
            'The media could not be loaded, either because the server or network failed or because the format is not supported.': strings.videoPlayerBranding.pipStreamErrorMessage
        });

        videojs.addLanguage('en', {
            'The media could not be loaded, either because the server or network failed or because the format is not supported.': strings.videoPlayerBranding.pipStreamErrorMessage
        });

        vjsPlayer.src(url);

        const controlBar = vjsPlayer.getChild('ControlBar');

        if (vjsPlayer) {
            const bigPlayButton = vjsPlayer.getChild('BigPlayButton');
            if (bigPlayButton) {
                vjsPlayer.removeChild(bigPlayButton);
            }
        }
        if (controlBar) {
            controlBar.on('click', handlePlayClick);
            controlBar.on('dblclick', handlePlayerDoubleClick);

            const closeButton = controlBar.getChild('CloseButton');
            if (closeButton) {
                closeButton.on('click', handleCloseButtonClick);
            }
        }

        // attach resize-handles to control-bar so they become clickable and disappear on inactivity
        const domControlBar = document.getElementsByClassName("vjs-control-bar").item(0);
        const resizeHandles = document.querySelectorAll(`.react-resizable-handle`);
        resizeHandles.forEach(function (resizeHandle) {
            resizeHandle?.parentNode?.removeChild(resizeHandle);
            domControlBar?.appendChild(resizeHandle);
        })

        vjsPlayer.addClass('vjs-miniplayer');

        setPlayer(vjsPlayer);

        //changing tooltip
        const closeBtn = document.querySelector(`#virtualGuide .vjs-button[title="Close"]`)
        closeBtn?.setAttribute('title', strings.chatBranding.closeText)

        // returned function is called as cleanup
        return () => {
            // write volume level, mute-state and play/pause-state into appState, so it stays the same when user switches 
            // between Video- and PiP-Player

            appState.setVideoPlayerStatus({
                volume: vjsPlayer.volume(),
                isMuted: vjsPlayer.muted(),
                isPaused: vjsPlayer.paused(),
                audioTrack: appState.videoPlayerStatus?.audioTrack
            })
            vjsPlayer.dispose();
        }
        // passing empty array as second argument will result in the effect and clean up running only once (on mount and unmount)
        // effect doesn't depend on any value from props or state
    }, []); //eslint-disable-line

    if (!channelId || !url) {
        return undefined
    }

    function handleCloseButtonClick(this: React.MouseEvent) {
        appState.setLiveStreamChannel(null)
        if (chime.getMeetingStatus().meetingStatus !== MeetingStatusCode.Succeeded) {
            accessMyPresenceState.updateMyPresence(EventType.EVENT_END)
        }
    }

    function handlePlayerDoubleClick(event: React.MouseEvent) {
        if (player) {
            player.dispose();
        }
        if (channelId) {
            history.push(buildDetailLink(channelId, "", 'channel'))
        }
    }

    function handlePlayClick() {
        if (!player) {
            return
        }
        if (player.paused()) {
            player.play()
        } else {
            player.pause()
        }
    }

    return { player: player, videoRef: videoRef };
};  // end usePlayer()

function calculateChange(currentEventEndDate: string, nextEventStartDate: string) {
    return moment(moment(nextEventStartDate, "HH:mm")).diff(moment(currentEventEndDate, "HH:mm")) / 2
}

function calculateEnd(currentEventEndDate: string) {
    return moment(moment(currentEventEndDate, "HH:mm")).diff(moment())
}

const PictureInPictureVideoPlayer = (props: { channel: { id: string, url: string }, controls: boolean }) => {
    const appState = useAppState()
    const playerRef = usePlayer(appState.liveStreamChannel?.id, appState.liveStreamChannel?.url, props.controls)
    const chime = useChimeContext()
    const strings = useLanguageState().getStrings()
    const [shouldClose, setShouldClose] = useState(false)
    interface ResizeState {
        absoluteWidth: number;
        absoluteHeight: number;
        absoluteLeft?: number | any;
        absoluteTop?: number | any;
    }
    const initialResizeState: ResizeState = {
        absoluteWidth: 426,     // size to match conference overlay: height:135 width:240
        absoluteHeight: 240,    // optimal for 240p: height:240 width:426 

    }

    const [resizeState, setResizeState] = useState<ResizeState>(initialResizeState);

    const onResizeAbsolute = (e: SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {

        setResizeState((prevState: ResizeState) => {

            let newLeft = prevState.absoluteLeft;
            let newTop = prevState.absoluteTop;
            const deltaHeight = data.size.height - prevState.absoluteHeight;
            const deltaWidth = data.size.width - prevState.absoluteWidth;

            if (newLeft === undefined) {
                newLeft = 0;
            }
            if (newTop === undefined) {
                newTop = 0;
            }

            if (data.handle[0] === 'n') {
                newTop -= deltaHeight;
            } else if (data.handle[0] === 's') {

            }
            if (data.handle[data.handle.length - 1] === 'w') {
                newLeft -= deltaWidth;
            } else if (data.handle[data.handle.length - 1] === 'e') {

            }

            const newResizeState: ResizeState = {
                absoluteWidth: data.size.width,
                absoluteHeight: data.size.height,
                absoluteLeft: newLeft,
                absoluteTop: newTop,
            }

            return newResizeState;
        });
    };

    useEffect(() => {
        function loadData() {
            if (appState.liveStreamChannel) {
                loadChannelFirstEventDate(appState.liveStreamChannel?.id).then((data) => {
                    if ((data as BackendServiceError).httpStatus) {
                        //
                    } else {
                        const resp = data as EventDateChannelFirstDetailsResponse
                        appState.setLiveStreamChannel(Object.assign({}, appState.liveStreamChannel, { eventDate: resp.currentEventDate }))
                        if (resp.nextEventDate) {
                            const remainingCurrentEventDate = calculateEnd(resp.currentEventDate.end)
                            if (remainingCurrentEventDate > 0) {
                                remainingCurrentEventDateTimer = window.setTimeout(() => {  // Trigger on event end
                                    const changeExecution = calculateChange(resp.currentEventDate.end, resp.nextEventDate!.start)
                                    changeExecutionTimer = window.setTimeout(() => { //Trigger data load
                                        loadData()
                                    }, changeExecution);
                                }, remainingCurrentEventDate);
                            }
                        }
                    }
                })
            }
        }

        let remainingCurrentEventDateTimer: number
        let changeExecutionTimer: number


        loadData()
        return () => {
            clearTimeout(remainingCurrentEventDateTimer)
            clearTimeout(changeExecutionTimer)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (shouldClose) {
            closePlayer()
        }
        // eslint-disable-next-line
    }, [shouldClose])

    if (!playerRef) {
        return <></>
    }

    // when live stream is not active a click on the player closes it
    function closePlayer() {
        appState.setLiveStreamChannel(null);
        if (chime.getMeetingStatus().meetingStatus !== MeetingStatusCode.Succeeded) {
            accessMyPresenceState.updateMyPresence(EventType.EVENT_END)
        }
    }

    function handleClick() {
        const player = playerRef?.player
        if (!player) {
            return
        }
        if (player.paused()) {
            player.play()
        } else {
            player.pause()
        }
    }

    let startClickX = 0
    let startClickY = 0
    let resizing = false;

    // position Player in bottom right corner in vertical alignment with the conference overlay
    const initialPositionX = window.innerWidth - resizeState.absoluteWidth - 40;
    const initialPositionY = window.innerHeight - resizeState.absoluteHeight - 48;

    return (
        <Draggable
            defaultPosition={{ x: initialPositionX, y: initialPositionY }}
            onStart={(e) => {

                if (resizing) {
                    return false;
                }
                if ((e as any).hasOwnProperty("nativeEvent")) {
                    e = (e as any).nativeEvent
                }

                if (e instanceof MouseEvent) {
                    const me = e as MouseEvent
                    startClickX = me.screenX
                    startClickY = me.screenY
                }
                e.stopPropagation()
                e.preventDefault()

            }}
            onStop={(e) => {
                if (e instanceof MouseEvent) {
                    const me = e as MouseEvent
                    // only handle click if not dragging
                    if (startClickX === me.screenX && startClickY === me.screenY) {
                        if (playerRef?.player?.error()) {
                            // when live stream is not active a click on the player closes it
                            setShouldClose(true)
                        } else {
                            handleClick()
                        }
                    }
                }
                e.stopPropagation()
            }}>
            <ResizableRoot>
                <PictureInPicturePlayerRoot
                    title={strings.videoPlayerBranding.pipDoubleClickHint}
                    onMouseOver={() => {
                        if (playerRef?.player?.error()) {
                            const modalDialog = document.querySelector(`#virtualGuide .vjs-modal-dialog-content`)
                            modalDialog?.setAttribute('title', strings.chatBranding.closeText)
                        }
                    }}
                >
                    <Resizable
                        width={resizeState.absoluteWidth}
                        height={resizeState.absoluteHeight}
                        className="box hover-handles absolutely-positioned"
                        resizeHandles={['sw', 'se', 'nw', 'ne']}    // handles in all for corners
                        // resizeHandles={['sw', 'se', 'nw', 'ne', 's', 'e', 'w', 'n']}     // handles in four corners and on four edges
                        axis="both"
                        handleSize={[8, 8]}
                        lockAspectRatio={true}
                        minConstraints={[240, 135]}
                        maxConstraints={[2400, 1350]}
                        onResize={onResizeAbsolute}
                        onResizeStart={(event) => {
                            resizing = true;
                            event.stopPropagation();
                            event.preventDefault();
                        }}
                        onResizeStop={(event) => {
                            resizing = false;
                            event.stopPropagation();
                            event.preventDefault();
                        }}

                    >
                        <div data-vjs-player style={{
                            width: resizeState.absoluteWidth,
                            height: resizeState.absoluteHeight,
                            top: resizeState.absoluteTop,
                            left: resizeState.absoluteLeft
                        }}>
                            <video ref={playerRef.videoRef} className="video-js"></video>
                        </div>
                    </Resizable>
                </PictureInPicturePlayerRoot>
            </ResizableRoot>
        </Draggable>
    );

}; // end PictureInPicturePlayer

/* ################### UI styling #########################################################################################################*/


// keyframes for fading animations (pause and play)

// pause animation
const screenFadePause = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0.25;
    }
`;

const logoFadePause = keyframes`
    from {
        opacity: 0.25;
        transform: scale(2);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
`;

// play animation
const screenFadePlay = keyframes`
    from {
        opacity: 0.25;
    }

    to {
        opacity: 1;
    }
`;

const logoFadePlay = keyframes`
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0.25;
        transform: scale(2);
    }
`;

interface PictureInPicturePlayerRootProps {
    title?: string
}



export const PictureInPicturePlayerRoot = styled.div<PictureInPicturePlayerRootProps>`

    z-index: 1400;
    position: relative;
    display:flex;
    justify-content: center; // centered horizontally
    align-items: center;     // centered vertically


    /* height: 135px !important;
    width: 240px !important;         */

    /* video player  */
    #virtualGuide & .video-js.vjs-miniplayer {
        /* height: 135px !important;
        width: 240px !important;*/
        cursor: pointer;         
    }

    /* video element */
    #virtualGuide & .vjs-miniplayer .vjs-tech {
        background-color: #000;
        box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.4);
    }

    /* paused: fade out to darkened screen and fade in logo when paused */
    #virtualGuide & .vjs-miniplayer.vjs-paused .vjs-tech {
        animation: ${screenFadePause} 0.2s linear;
        opacity: 0.25; 
    }

    #virtualGuide & .vjs-miniplayer.vjs-paused.vjs-has-started .vjs-play-control {
        animation: ${logoFadePause} 0.2s linear;
    }

    /* playing: reverse on-pause-animation when playback resumes*/
    #virtualGuide & .vjs-miniplayer.vjs-playing .vjs-tech { 
        animation: ${screenFadePlay} 0.2s linear;
    }

    #virtualGuide & .vjs-miniplayer.vjs-playing.vjs-has-started .vjs-play-control {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        animation: ${logoFadePlay} 0.2s linear;
        opacity: 0;
    }

    #virtualGuide & .vjs-play-control {
        pointer-events: none;
    }

    #virtualGuide & #vjs_video_3:hover .vjs-big-play-button{
        background-color: transparent;
    }

    /* control bar */
    #virtualGuide & .vjs-control-bar {
        /* pointer-events: none; */
        background: transparent;
        height: 100%;

        // center child elements that have position:absolute
        position: absolute;
        display:flex;
        justify-content: center; // horizontally centered
        align-items: center;     // vertically centered
        
    }

    /* control buttons - icons */
    #virtualGuide & .vjs-big-play-button {
        background: url(/videoPlayerIcons/play.svg) no-repeat;
        border-style: none !important;
        border-radius: 1px;
        padding: 0 0 0 0;

        /* center play button */
        position: absolute !important;
        /* left: 50%;
        top: 50%; */
            
    }

    #virtualGuide & .vjs-button[title="Play"] {
        background: url(/videoPlayerIcons/play.svg) no-repeat; 
        /* background: ${props => props.theme.background}; */
        /* margin-left: 32px !important; */
        width: 23px !important;
    }

    #virtualGuide & .vjs-button[title="Pause"] {
        background: url(/videoPlayerIcons/pause.svg) no-repeat;
    }

    #virtualGuide & .vjs-button[title="Pause"], 
    #virtualGuide & .vjs-button[title="Play"], 
    #virtualGuide & .vjs-big-play-button
    {
        position: absolute;
        text-indent: -9999px;
        width: 26px;
        height: 26px;
        background-size: contain;
        filter: invert(100%);
    }

    #virtualGuide & .vjs-button[title="Mute"] {
        background: url(/videoPlayerIcons/volume-3.svg) no-repeat;
    }

    #virtualGuide & .vjs-mute-control[title="Unmute"] {
        background: url(/videoPlayerIcons/volume-mute.svg) no-repeat;
    }

    #virtualGuide & .vjs-button[title="Replay"] {
        background: url(/videoPlayerIcons/skip-back.svg) no-repeat;
    }

    #virtualGuide & .vjs-button[title="Captions"] {
        background: url(/videoPlayerIcons/settings.svg) no-repeat;
    }

    #virtualGuide & .vjs-theatermode-control {
        background: url(/videoPlayerIcons/theatre-mode.svg) no-repeat;
        height: 38px !important;
        width: 38px !important;
        margin-top: 1px !important;
    }

    /* animate volume icon for different levels */ 
    #virtualGuide & .vjs-icon-volume-low {
        background: url(/videoPlayerIcons/volume-low.svg) no-repeat;
    }
    
    #virtualGuide & .vjs-button.vjs-vol-1[title="Mute"] {
        background: url(/videoPlayerIcons/volume-1.svg) no-repeat;
        
    }
    #virtualGuide & .vjs-button.vjs-vol-2[title="Mute"] {
        background: url(/videoPlayerIcons/volume-2.svg) no-repeat;
    }

    /* headphone button when different audio tracks available */
    #virtualGuide & .vjs-button[title="Audio Track"] {
        background: url(/videoPlayerIcons/music-note.svg) no-repeat;
    }

    /* remove yellow border from buttons when hovered over or clicked */
    #virtualGuide & .vjs-button:active, 
    #virtualGuide & .vjs-button:hover, 
    #virtualGuide & .vjs-button:focus, 
    #virtualGuide & .vjs-button:visited, 
    #virtualGuide & .vjs-big-play-button:active,
    #virtualGuide & .vjs-big-play-button:hover,
    #virtualGuide & .vjs-big-play-button:focus,
    #virtualGuide & .vjs-big-play-button:visited, 
    #virtualGuide & .vjs-control-bar:focus
    {
        border-style: none !important;
        outline-style: none !important;
        background-color: transparent !important;
    }

    #virtualGuide & button.vjs-close-button.vjs-control.vjs-button{
        background: url(/videoPlayerIcons/close.svg) no-repeat;
        background-size: contain;
        margin-top: 10px !important;
        margin-right: 15px !important;
        
    }
    /* #virtualGuide & .vjs-button[title="Close"]:hover {
        background-color: #adadad !important;
    } */

    /* styling that all buttons in control bar have in common*/ 
    #virtualGuide & .vjs-button[title="Picture-in-Picture"],
    #virtualGuide & .vjs-button[title="Exit Picture-in-Picture"],
    #virtualGuide & .vjs-button[title="Fullscreen"], 
    #virtualGuide & .vjs-button[title="Mute"],
    #virtualGuide & .vjs-mute-control[title="Unmute"],
    #virtualGuide & .vjs-button[title="Non-Fullscreen"],
    #virtualGuide & .vjs-button[title="Audio Track"], 
    #virtualGuide & .vjs-button.vjs-vol-1[title="Mute"],
    #virtualGuide & .vjs-button.vjs-vol-2[title="Mute"], 
    #virtualGuide & .vjs-button[title="Audio Track"], 
    #virtualGuide & .vjs-button[title="Replay"],
    #virtualGuide & .vjs-button[title="Captions"], 
    #virtualGuide & .vjs-theatermode-control, 
    #virtualGuide & button.vjs-close-button.vjs-control.vjs-button
    {
        text-indent: -9999px;
        width: 26px;
        height: 26px;
        background-size: contain;
        filter: invert(100%);
        margin-top: 6.5px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        border-radius: 1px;
        padding: 0 0 0 0;
    }

    /* live button */
    #virtualGuide & .vjs-seek-to-live-control {
        border: transparent !important;
        height: 31px;
        display: block;
        margin-top: 5px;
    }

    #virtualGuide & .vjs-seek-to-live-control:focus {
        text-shadow: none;
        border-style: none !important;
        outline-style: none !important;
    }

    /* live button hover Effekte ?*/
    /* #virtualGuide & .vjs-seek-to-live-control:focus,
    #virtualGuide & .vjs-seek-to-live-control:hover,
    #virtualGuide & .vjs-seek-to-live-control:active,
    #virtualGuide & .vjs-seek-to-live-control:visited
    {
        background: blue;
    } */

    /* live text */
    #virtualGuide & .vjs-seek-to-live-text {
        user-select: none;
        font-size: 16px;
        overflow: hidden;
        margin-top: 10px;
    }

    #virtualGuide & .vjs-seek-to-live-text:focus {
        outline: none;
    }

    /* red dot */
    #virtualGuide & .vjs-icon-placeholder {
        font-size: 16px;
        overflow: hidden;
        vertical-align: -2px;    
    }

    /* progress bar */
    #virtualGuide & .vjs-progress-control {
        bottom: 10px;
    }

    /* white slide-bar */
    #virtualGuide & .vjs-play-progress.vjs-slider-bar { 
    }

    #virtualGuide & .vjs-progress-holder {
    }

    /* white dot */
    #virtualGuide & .vjs-load-progress {
    }

    /* little line that attaches to cursor as you hover the progress bar */
    #virtualGuide & .vjs-mouse-display {
    }

    /* white progress bar and little circle at current position*/
    #virtualGuide & .vjs-play-progress {
    }

    /* volume slider */
    #virtualGuide & .vjs-volume-horizontal {
        margin-top: 5px;
    }


`;

export const ResizableRoot = styled.div`
position: absolute !important;
z-index:1500 !important;

/* bottom: 40px;
right: 50px; */

#virtualGuide & .layoutRoot {
  display: flex;
  background: #eee;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
#virtualGuide & .absoluteLayout {
  height: 600px;
  position: relative;
  justify-content: center;
  align-items: center;
}
#virtualGuide & .scaledLayout {
  width: 125%;
  left: -12.5%;
  transform: scale(0.75);
  margin-top: -7.5%;
}

#virtualGuide & .box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: black;
  /* border: 1px solid black; */
  text-align: center;
  /* padding: 10px; */
  box-sizing: border-box;
  /* margin-bottom: 10px; */
  overflow: hidden;
  position: relative;
  /* margin: 20px; */
}
#virtualGuide & .box .text {
  text-align: center;
}

#virtualGuide & .hover-handles .react-resizable-handle {
  display: none;
}
#virtualGuide & .hover-handles:hover .react-resizable-handle {
  display: block;
}
#virtualGuide & .absolutely-positioned {
  position: absolute !important;
}
#virtualGuide & .left-aligned {
  left: 0;
}
#virtualGuide & .right-aligned {
  right: 0;
}
#virtualGuide & .top-aligned {
  top: 0;
}
#virtualGuide & .bottom-aligned {
  bottom: 0;
}

#virtualGuide & .custom-box {
  overflow: visible;
}
#virtualGuide & .custom-handle {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #1153aa;
  opacity: 0.75;
  border-radius: 4px;
}
#virtualGuide & .custom-handle-sw {
  bottom: -4px;
  left: -4px;
  cursor: sw-resize;
}
#virtualGuide & .custom-handle-se {
  bottom: -4px;
  right: -4px;
  cursor: se-resize;
}
#virtualGuide & .custom-handle-nw {
  top: -4px;
  left: -4px;
  cursor: nw-resize;
}
#virtualGuide & .custom-handle-ne {
  top: -4px;
  right: -4px;
  cursor: ne-resize;
}
#virtualGuide & .custom-handle-w,
#virtualGuide & .custom-handle-e {
  top: 50%;
  margin-top: -4px;
  cursor: ew-resize;
}
#virtualGuide & .custom-handle-w {
  left: -4px;
}
#virtualGuide & .custom-handle-e {
  right: -4px;
}
#virtualGuide & .custom-handle-n,
#virtualGuide & .custom-handle-s {
  left: 50%;
  margin-left: -4px;
  cursor: ns-resize;
}
#virtualGuide & .custom-handle-n {
  top: -4px;
}
#virtualGuide & .custom-handle-s {
  bottom: -4px;
}

/* styles.css */

#virtualGuide & .react-resizable {
    position: relative;
  }
  #virtualGuide & .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background: url('/videoPlayerIcons/arrow-head-down.svg');
    filter: invert(100%);
    /* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+'); */
    background-position: bottom right;
    padding: 0 3px 3px 0;
  }
  #virtualGuide & .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(45deg);
  }
  #virtualGuide & .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
    transform: rotate(-45deg);
  }
  #virtualGuide & .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(135deg);
  }
  #virtualGuide & .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(225deg);
  }
  #virtualGuide & .react-resizable-handle-w,
  #virtualGuide & .react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  #virtualGuide & .react-resizable-handle-w {
    left: 0;
    transform: rotate(90deg);
  }
  #virtualGuide & .react-resizable-handle-e {
    right: 0;
    transform: rotate(270deg);
  }
  #virtualGuide & .react-resizable-handle-n {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
    transform: rotate(180deg);
  }

  #virtualGuide & .react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
    
  }
  #virtualGuide & .react-resizable-handle-n {
    top: 0;
    transform: rotate(180deg);
  }
  #virtualGuide & .react-resizable-handle-s {
    bottom: 0;
    /* transform: rotate(45deg); */
  }

  /* PictureInPicturePlayerRoot*/
  
  
`

export default PictureInPictureVideoPlayer;