import React, { useState } from "react";
import styled from "styled-components";
import { Person } from "../backendServices/Types";
import { Row } from "react-bootstrap";
import branding from "../branding/branding";
import { useLanguageState } from "../globalStates/LanguageState"
import { showfloorPageRoute } from "../navigationArea/RoutePaths"

const PersonsRow = styled(Row) <DivProps>`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: ${props => props.justifycontent ? props.justifycontent : "center"};
    width: 100%;
    margin: 0;
`
const PersonsListRow = styled(Row)`
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
`
const PersonsListCard = styled.div`
    display: grid;
    
    grid-template-columns: auto 1fr;
    margin-bottom: 10px;
`
const MorePersonsRoot = styled.div`
    display: flex;
    justify-content: center;
`

const EventDateEntryMorePersonsRoot = styled.div`
    display: flex;
    justify-content: start;
`

const PersonImageDiv = styled.div`
    margin-right: 10px;
`
interface DivProps {
    width?: string
    minWidth?: string
    height?: string
    fontSize?: string
    $justifycontent?: string
    color?: string
    src?: string
    size?: number
}

const PersonImage = styled.div<DivProps>`
    width: ${props => props.width ? props.width : "60px"};
    height: ${props => props.height ? props.height : "60px"};
    border-radius: 50%;
    padding: 2px;
    border: 1px solid #0000FF;
    object-fit: cover;
    object-position: top;
    background-image: url("${(props) => props.src}");
    background-size: cover;
    background-position: center;
    background-color: lightgrey;
    font-size: ${(props) => props.size ? props.size / 2.2 : 0}px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1800px) { 
        width: 50px;
        height: 50px;
    } 

    @media (max-width: 1650px) { 
        width: 45px;
        height: 45px;
    }

    @media (max-width: 1600px) { 
        width: 40px;
        height: 40px;
        font-size: 16px !important;
    }

    @media (max-width: 1450px) { 
        width: 35px;
        height: 35px;
        font-size: 14px !important;
    }

    @media (max-width: 1200px) { 
        width: 35px;
        height: 35px;
    }
`
const PersonText = styled.div<DivProps>`
    font-family: ${branding.font1};
    font-size: 10px;
`
const PersonTitle = styled(PersonText)`
    font-weight: bold;
    font-size: 12px;
`
const PersonDetails = styled(PersonText)`
`

const MoreContactsDiv = styled.div<DivProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: ${props => props.width ? props.width : "30px"};
    width: ${props => props.width ? props.width : "60px"};
    height: ${props => props.height ? props.height : "60px"};
    border-radius: 50%;
    border: ${props => props.color ? "1px solid" + props.color : "1px solid #FFF"};
    color: ${props => props.color ? props.color : "#FFF"};
    font-size: ${props => props.fontSize ? props.fontSize : "18px"};
    box-sizing: border-box;
    @media (max-width: 1800px) { 
        min-width: 25px;
        width: 50px;
        height: 50px;
        font-size: 20px;
    }

    @media (max-width: 1650px) { 
        min-width: 22px;
        width: 45px;
        height: 45px;
        font-size: 18px;
    }

    @media (max-width: 1600px) { 
        min-width: 22px;
        width: 40px;
        height: 40px;
        font-size: 16px;
    }

    @media (max-width: 1450px) { 
        min-width: 20px;
        width: 35px;
        height: 35px;
        font-size: 14px;
    }

    @media (max-width: 1000px) { 
        min-width: 15px;
        width: 35px;
        height: 35px;
        font-size: 14px;
    }
`
const MoreContactsText = styled.p<DivProps>`
    margin-bottom: 0rem;
`;

const RotateSvg = styled.div`
  transform: rotate(180deg);
  transform-origin: 50% 50%;
`;
interface CompaniesContactsPersonsProps {
    persons: Person[]
    personsToShow: number
    width?: string
    minWidth?: string
    height?: string
    fontSize?: string
    justifycontent?: string
    layoutType: number
    showModalPopup: boolean
    color?: string
    sotUserPriority?: boolean
    onUserClick?: (userId: string) => void
    eventDateEntryContent?: boolean
}


const CrsPersons: React.FunctionComponent<CompaniesContactsPersonsProps> = (props) => {
    const [layout, setLayout] = useState<number>(props.persons && props.eventDateEntryContent && props.persons.length <= 3 ? 2 : props.layoutType)
    const strings = useLanguageState().getStrings()

    if (props.persons) {
        let tempContacts = props.persons;
        let moreContactsText: string = "";
        //let lessContactsText: string = "";
        if (props.persons.length > props.personsToShow) {
            tempContacts = tempContacts.filter((value, index) => { return index < props.personsToShow + 1 });
            moreContactsText = "+" + (props.persons.length - props.personsToShow) as string;
            //lessContactsText = "-" + (props.persons.length - props.personsToShow) as string;
        }

        if (layout === 1) {
            return (
                props.eventDateEntryContent ?
                    <PersonsListRow>
                        {
                            props.persons.map((contact, index) => {
                                let nameFromFirstAndLast = [contact.firstName, contact.lastName].join(" ")
                                let alt = (contact.name) ? contact.name.split(" ").map((value, index) => index <= 1 && value && value.length > 0 ? value.substr(0, 1).toUpperCase() : null).join("") : "";
                                let altFromFirstAndLast = (nameFromFirstAndLast) ? nameFromFirstAndLast.split(" ").map((value, index) => index <= 1 && value && value.length > 0 ? value.substr(0, 1).toUpperCase() : null).join("") : "";
                                let logo = contact.userId && props.sotUserPriority ? contact.userLogoUrl : contact.logoUrl

                                if (index === props.personsToShow) {

                                    return (
                                        <EventDateEntryMorePersonsRoot key={contact.id}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (props.showModalPopup)
                                                    setLayout(2);
                                            }
                                            }>
                                            <MoreContactsDiv width={props.width} height={props.height} fontSize={props.fontSize} color={props.color ? props.color : ""}>
                                                <MoreContactsText>{moreContactsText}</MoreContactsText>
                                            </MoreContactsDiv>
                                        </EventDateEntryMorePersonsRoot>
                                    )

                                }
                                else if (index > props.personsToShow) {
                                    return null
                                }
                                else {
                                    return (
                                        <PersonsListCard key={contact.id} onClick={(event) => { event.preventDefault(); event.stopPropagation(); if (props.onUserClick) props.onUserClick(contact.id) }}>
                                            <PersonImageDiv>
                                                <PersonImage src={logo} width={props.width} height={props.height} size={35} fontSize={props.fontSize}>
                                                    {logo == null ? (alt ? alt : altFromFirstAndLast) : ""}
                                                </PersonImage>
                                            </PersonImageDiv>
                                            <div>
                                                <PersonTitle>
                                                    {[contact?.title, contact?.firstName, contact?.lastName].filter(Boolean).join(" ")}
                                                </PersonTitle>
                                                <PersonDetails>
                                                    {[contact.position, contact.organization]
                                                        .filter(Boolean)
                                                        .join(" " + strings.communicationArea.personCompanyLink + " ")}
                                                </PersonDetails>
                                            </div>
                                        </PersonsListCard>
                                    )
                                }
                            })
                        }
                    </PersonsListRow>
                    :
                    <PersonsRow className="pers-row" justifycontent={props.justifycontent}>
                        {
                            tempContacts.map((contact, index) => {
                                let nameFromFirstAndLast = [contact.firstName, contact.lastName].join(" ")
                                let alt = (contact.name) ? contact.name.split(" ").map((value, index) => index <= 1 && value && value.length > 0 ? value.substr(0, 1).toUpperCase() : null).join("") : "";
                                let altFromFirstAndLast = (nameFromFirstAndLast) ? nameFromFirstAndLast.split(" ").map((value, index) => index <= 1 && value && value.length > 0 ? value.substr(0, 1).toUpperCase() : null).join("") : "";
                                let logo = contact.userLogoUrl ? contact.userLogoUrl : contact.logoUrl

                                if (index === props.personsToShow) {
                                    return (
                                        <MorePersonsRoot key={contact.id}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (props.showModalPopup)
                                                    setLayout(2);
                                            }
                                            }>
                                            <MoreContactsDiv width={props.width} minWidth={props.minWidth || "30px"} height={props.height} fontSize={props.fontSize} color={props.color ? props.color : ""}>
                                                <MoreContactsText>{moreContactsText}</MoreContactsText>
                                            </MoreContactsDiv>
                                        </MorePersonsRoot>
                                    )
                                } else {
                                    return (
                                        <div key={contact.id}>
                                            <PersonImageDiv onClick={(event) => { event.preventDefault(); event.stopPropagation(); if (props.onUserClick) props.onUserClick(contact.id) }}>
                                                <PersonImage src={logo} width={props.width} height={props.height} size={window.location.pathname === showfloorPageRoute ? 50 : 30} fontSize={props.fontSize}>
                                                    {logo == null ? (alt ? alt : altFromFirstAndLast) : ""}
                                                </PersonImage>
                                            </PersonImageDiv>
                                        </div>
                                    )
                                }
                            })
                        }</PersonsRow>
            )
        }
        if (layout === 2) {
            return (
                <PersonsListRow>
                    {
                        props.persons.map((contact, index) => {
                            let nameFromFirstAndLast = [contact.firstName, contact.lastName].join(" ")
                            let alt = (contact.name) ? contact.name.split(" ").map((value, index) => index <= 1 && value && value.length > 0 ? value.substr(0, 1).toUpperCase() : null).join("") : "";
                            let altFromFirstAndLast = (nameFromFirstAndLast) ? nameFromFirstAndLast.split(" ").map((value, index) => index <= 1 && value && value.length > 0 ? value.substr(0, 1).toUpperCase() : null).join("") : "";

                            let logo = contact.userLogoUrl ? contact.userLogoUrl : contact.logoUrl
                            return (
                                <PersonsListCard key={contact.id} onClick={(event) => { event.preventDefault(); event.stopPropagation(); if (props.onUserClick) props.onUserClick(contact.id) }}>
                                    <PersonImageDiv>
                                        <PersonImage src={logo} width={props.width} height={props.height} size={30} fontSize={props.fontSize}>
                                            {logo == null ? (alt ? alt : altFromFirstAndLast) : ""}
                                        </PersonImage>
                                    </PersonImageDiv>
                                    <div>
                                        <PersonTitle>
                                            {(props.eventDateEntryContent && branding.programSchedule.showSpeakersTitles) ?
                                                [contact.title, contact.firstName, contact.lastName].join(" ") :
                                                [contact.firstName, contact.lastName].join(" ")}
                                        </PersonTitle>
                                        <PersonDetails>
                                            {[contact.position, contact.organization]
                                                .filter(Boolean)
                                                .join(" " + strings.communicationArea.personCompanyLink + " ")}
                                        </PersonDetails>
                                    </div>
                                </PersonsListCard>
                            )
                        })
                    }

                    {props.showModalPopup && props.persons.length > 3 &&
                        <PersonsListCard
                            onClick={(event) => {
                                event.preventDefault();
                                setLayout(1);
                            }}
                        >
                            <RotateSvg>
                                <svg width="30" height="30" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11" cy="11" r="10.5" fill="black" stroke="white" />
                                    <path d="M15 9.5L11 13.5L7 9.5" stroke="white" strokeMiterlimit="10" />
                                </svg>
                            </RotateSvg>

                        </PersonsListCard>
                    }
                </PersonsListRow>
            )
        }
    }
    return (
        <>
            <p></p>
        </>
    )
}

export default CrsPersons