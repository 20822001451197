import { useLanguageState } from "../globalStates/LanguageState"
import React from "react"
import { Dropdown } from "react-bootstrap"
import styled from "styled-components"


const LanguageDropDownPanel = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;

    & .dropdown{
        width: 50px;
    }

    & .dropdown-toggle{
        width: 100%;
        padding: 0;
    }

    & .dropdown-menu{
        width: 100%;
        min-width: unset;
        padding: .4rem 0;
    }

    & .dropdown-item{
        padding: .25rem .5rem;
    }

    & .dropdown-item.active{
        background-color: rgba(0, 0, 0, .2);
        color: #000;
    }

    & .dropdown-item:focus{
        background-color: transparent;
        color: #000;
    }
`

const LanguageSelector: React.FC = (props) => {
    const languageState = useLanguageState()
    const availableLanguages = languageState.getAvailableLanguages()
    const selectedLanguage = languageState.getLanguage()
    const onSelect = (eventKey: any, event: any) => {
        const language = event.target.text
        languageState.setLanguage(language)
    }

    var dropdownItems: any[] = [];
    for (let index = 0; index < availableLanguages.length; index++) {
        const language = availableLanguages[index];
        dropdownItems[index] = <Dropdown.Item key={index} active={language === selectedLanguage}>{language}</Dropdown.Item>
    }

    return (
        <LanguageDropDownPanel>
            <Dropdown title={selectedLanguage} onSelect={onSelect}>
                <Dropdown.Toggle variant="">{selectedLanguage}</Dropdown.Toggle>
                <Dropdown.Menu>
                    {dropdownItems}
                </Dropdown.Menu>
            </Dropdown>
        </LanguageDropDownPanel>
    )
}







export default LanguageSelector
