import { BackendServiceError, loadUserData, SpeakerResponse } from '../backendServices/BackendServices'

//loading person details and connection status with specified user

export function GetConnectionDetails(loggedInUserId: string, sotUserId: string) {
    return new Promise((resolve, reject) => {
        if (sotUserId) {
            loadUserData({
                targetProfileId: sotUserId,
                loggedInUserId: loggedInUserId
            }).then((response) => {
                if ((response as BackendServiceError).httpStatus) {
                    // TODO ERROR
                    reject(response)
                } else {
                    const tempStatus = (response as SpeakerResponse).content.myConnectionStatus;
                    resolve(tempStatus);
                }
            })
        }
    })
}

export const ConnectionStates = {
    OPENREQUEST: "OPENREQUEST",
    REQUESTED: "REQUESTED",
    IGNORED: "IGNORED",
    CONNECTED: "CONNECTED",
    UNRELATED: "UNRELATED",
    BLOCKED: "BLOCKED"
}

export function setInitialStates(state: string) {
    var obj = {
        requested: false,
        openRequest: false,
        accepted: false,
        ignored: false,
        blocked: false,
        tooltipMessage: "Send a request.",
        icon: "user-plus",
        messageType: "sendRequestMessage"
    };

    if (state === ConnectionStates.REQUESTED) {
        obj.requested = true;
        obj.tooltipMessage = "Cancel your request.";
        obj.icon = "user-times";
        obj.messageType = "cancelRequestMessage";
    }
    if (state === ConnectionStates.OPENREQUEST) {
        obj.tooltipMessage = "You have a request.";
        obj.openRequest = true;
        obj.ignored = false;
        obj.icon = "send";
        obj.messageType = "openRequestMessage";
    }
    if (state === ConnectionStates.CONNECTED) {
        obj.tooltipMessage = "Cancel your connection.";
        obj.accepted = true;
        obj.icon = "user-times";
        obj.messageType = "cancelConnectionMessage";
    }
    if (state === ConnectionStates.IGNORED) {
        obj.tooltipMessage = "Request is ignored.";
        obj.ignored = true;
        obj.openRequest = false;
        obj.icon = "ban";
        obj.messageType = "ignoredMessage";
    }
    if (state === ConnectionStates.BLOCKED) {
        obj.tooltipMessage = "Request is blocked.";
        obj.ignored = true;
        obj.blocked = true;
        obj.openRequest = false;
        obj.icon = "ban";
        obj.messageType = "blockedMessage";
    }
    return obj;
}

