import React from "react"
import styled, { keyframes } from "styled-components"
import branding from "../branding/branding"


/* #region  Loader wrapper */
const LoaderWrapper = styled.div` 
    position: relative;
    width: 100%;
    height: 100%;
`
/* #endregion */

/* #region  Loader */
const spin = keyframes`
    0% { 
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% { 
        -webkit-transform: rotate(360deg); 
        transform: rotate(360deg);
    }
`

const loaderSize = {
    xs: "1rem",
    sm: "2rem",
    md: "3rem",
    lg: "4rem"
}

const loaderSpeed = {
    slow: "2s",
    normal: "1s",
    fast: ".5s"
}

const Loader = styled.div<{ size: string, speed: string }>`
    border: 3px solid ${branding.loaderBgColor};
    border-radius: 50%;
    border-top: 3px solid ${branding.loaderSpinColor};
    width: ${props => props.size};
    height: ${props => props.size};
    -webkit-animation: ${spin} ${props => props.speed} linear infinite;
    animation: ${spin} ${props => props.speed} linear infinite;

    /* positioning loader at the center of parent element */
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: ${props => `calc(${props.size} / -2)`};
    margin-top: ${props => `calc(${props.size} / -2)`};
`
/* #endregion */

type CenteredLoaderProps = {
    size?: 'xs' | 'sm' | 'md' | 'lg',
    speed?: 'slow' | 'normal' | 'fast'
    className?: string,
    loaderWrapperStyle?: React.CSSProperties | undefined
}
const CenteredLoader: React.FunctionComponent<CenteredLoaderProps> = React.memo((props) => {

    return (
        <LoaderWrapper style={props.loaderWrapperStyle} className={props.className}>
            <Loader className="customLoader"
                size={props.size ? loaderSize[props.size] : loaderSize['lg']}
                speed={props.speed ? loaderSpeed[props.speed] : loaderSpeed['normal']} />
        </LoaderWrapper>
    )
})

export default CenteredLoader
