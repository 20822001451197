import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import branding from '../branding/branding';
import { NavLink } from 'react-router-dom';
import { VGIcon, IconArrowBreadcrumb } from './Icons';
import ScrollBarComponent from './ScrollBar';
import { useAppState } from "../globalStates/AppState";


/* #region  SideIconBar and CommunicationArea values */
const widthValues = {
    sideIconBarWidth: 60,
    communicationAreaWidth: {
        open: 320,
        closed: 60
    }
}
/* #endregion */

/* #region  Main Containers */
const TileRowRoot = styled.div<{ margin?: string }>` 
    display: flex;
    flex-direction: column;
    margin: ${props => props.margin ?? "50px 25px 0 25px"};

    .icon-container {
        padding-left: 0;
        width: 40px;
    }
`

const TileRowContentContainer = styled.div<{ isMyFairPage?: boolean, width: string, rounded?: boolean }>`
    ${props => props.isMyFairPage ? "margin-right: 0px;" : ""}; 
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    padding-top: 5px;
    ::-webkit-scrollbar {
        display: ${props => props.rounded ? "none" : ""};
    }
    width: ${props => props.width};
`

const TileWrapper = styled.div`
    display: flex;
    flex-flow: row;
`
/* #endregion */

/* #region  Header section */

const HeaderRow = styled.div`
    display: flex;
    position: relative;
    flex-flow: row;
    justify-content: flex-end;
    height: 60px;
    color: ${branding.mainInfoColor};
`

const HeaderTitleTemplate = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex: 1;
    align-items: center;
    font-family: ${branding.font1};
`

const HeaderTitleLobbyType = styled(HeaderTitleTemplate)`
    font-family: ${branding.receptionPage.tileRowTitleFont};
    margin-top: 22px;
    font-size: ${branding.receptionPage.topBarTitleFontSize ?? "18px"};
    line-height: 24px;
    font-weight: ${branding.receptionPage.topBarTitleFontWeight ?? "normal"};
`
const HeaderTitleOrgaDetailType = styled(HeaderTitleTemplate)`
  margin-top: 22px;
  font-size: 28px;

  @media(max-width: 1600px) {
       font-size: 24px;
  }
`

const HeaderIcon = styled.div`
    width: 70px;
    padding: 18px;
    margin-top: 3px;
    color: ${branding.primaryColor};
`
const TopBarLinkRoot = styled.span`
    border-bottom: ${branding.receptionPage.topBarLinkRoot.borderBottom ? branding.receptionPage.topBarLinkRoot.borderBottom as string : ""};
    margin-bottom: ${branding.receptionPage.topBarLinkRoot.marginBottom ? branding.receptionPage.topBarLinkRoot.marginBottom as string : ""};
    font-size: ${branding.receptionPage.topBarLinkRoot.fontSize ? branding.receptionPage.topBarLinkRoot.fontSize as string : ""};
    
    @media(max-width: 2200px){  
        white-space: nowrap;
        margin-left: 5px;
    }
`
const TopBarLink = styled(NavLink)`
    margin-top: 20px;
    font-family: ${branding.font1};
    display: flex;
    color: rgb(0,0,0);
    justify-content: flex-end;
    height: 0;
    color: ${branding.mainInfoColor ?? "#000"};

    &:hover {
        color: rgb(0,0,0);
        text-decoration: none;
    }
`
const TopBarLinkHeaderTitle = styled(NavLink)`
  text-decoration: ${branding.receptionPage.receptionPageHeaderTitleTextDecoration ?? "none"};
  color: ${branding.mainInfoColor ?? "#000"};
    &:hover {
        text-decoration: none;
        color: ${branding.mainInfoColor ?? "#000"};
    }
`

const TopBarLinkHeaderSubTitle = styled(NavLink)`
  font-family: ${branding.receptionPage.tileRowSubtitleFont};
  font-size: 14px;
  margin-left: 20px;
  margin-top: 25px;
  font-weight: 400;
  letter-spacing: 5.5px;
  text-transform: ${branding.receptionPage.tileRowSubtitleTextTransform};
  text-decoration: ${branding.receptionPage.receptionPageHeaderTitleTextDecoration ?? "none"};
  color: ${branding.mainInfoColor ?? "#000"};
    &:hover {
        text-decoration: none;
        color: ${branding.mainInfoColor ?? "#000"};
    }
`
/* #endregion */


type TitleRowProps = {
    title: string;
    height?: string;
    icon?: VGIcon;
    navLink?: string;
    navLinkText?: string;
    scrollBar?: boolean;
    isMyFairPage?: boolean;
    tileRowMargin?: string
    type?: "orgaDetail" | "lobby"
    offsetLeft?: number
    defaultScrollbar?: boolean //displays default scrollbar instead of rounded
    childWidth: number // Needed for Scrollbar Calculation
    childCount: number // Needed for Scrollbar Calculation
};

const TileRow: React.FunctionComponent<TitleRowProps> = (props) => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState(0)
    const [value, setValue] = useState(0)

    //calculating width offset
    const appState = useAppState()
    const [tileRowContentContainerWidth, setTileRowContentContainerWidth] = useState<string>("auto")

    //every time when communication center is open/closed, or window width is changed
    //width of the content container should be precalculated (just for orgaDetail type)
    useEffect(() => {
        const communicationAreaWidth = appState.isNetworkingOpen() ? widthValues.communicationAreaWidth.open : widthValues.communicationAreaWidth.closed
        const adjustWidth = widthValues.sideIconBarWidth + (props.offsetLeft ?? 0) + communicationAreaWidth //left side bar + container left offset + communication area 
        const width = props.type === "orgaDetail" ? `calc(100vw - ${adjustWidth}px)` : "auto"
        setTileRowContentContainerWidth(width)

        //eslint-disable-next-line
    }, [props.offsetLeft])

    useEffect(() => {
        scrollRef.current?.scrollTo(position, 0)
    }, [position])

    const content = props.scrollBar ? <TileWrapper>{props.children}</TileWrapper> : props.children
    const headerTitle = props.type && props.type === "orgaDetail" ?
        <HeaderTitleOrgaDetailType>{props.title}</HeaderTitleOrgaDetailType> :
        <HeaderTitleLobbyType>{props.title}</HeaderTitleLobbyType>


    return (
        <TileRowRoot margin={props.tileRowMargin}>

            {/* Header */}
            <HeaderRow>
                <HeaderTitleTemplate>
                    <div style={{ borderBottom: branding.receptionPage.tileRowBottomBorder, display: "flex", flexDirection: "row", height: "55px" }}>
                        {props.icon && <HeaderIcon className="icon-container">{props.icon}</HeaderIcon>}
                        {props.navLink && <TopBarLinkHeaderTitle to={props.navLink ? props.navLink : "/"}>{headerTitle}</TopBarLinkHeaderTitle>}
                        {props.navLink && branding.receptionPage.tileRowShowNavLinkNextToTitle && <TopBarLinkHeaderSubTitle to={props.navLink ? props.navLink : "/"}>{props.navLinkText}</TopBarLinkHeaderSubTitle>}
                        {props.navLink && branding.receptionPage.tileRowShowNavLinkNextToTitle && <TopBarLinkHeaderSubTitle style={{ marginLeft: "10px" }} to={props.navLink ? props.navLink : "/"}>{IconArrowBreadcrumb({ fill: branding.receptionPage.tileRowArrowColor })}</TopBarLinkHeaderSubTitle>}
                    </div>
                </HeaderTitleTemplate>
                {props.navLink && !branding.receptionPage.tileRowShowNavLinkNextToTitle && <TopBarLinkRoot><TopBarLink to={props.navLink ? props.navLink : "/"}>{props.navLinkText}</TopBarLink></TopBarLinkRoot>}
            </HeaderRow>

            {/* Content */}
            <TileRowContentContainer
                ref={scrollRef}
                isMyFairPage={props.isMyFairPage}
                width={tileRowContentContainerWidth}
                rounded={!props.defaultScrollbar}
                onScroll={() => setValue(scrollRef.current?.scrollLeft!)}>
                {content}
            </TileRowContentContainer>

            {/* Scrollbar (hide scrollbar if there is no need to scroll) */}
            {props.scrollBar && !props.defaultScrollbar && <ScrollBarComponent value={value} marginRight={props.isMyFairPage ? "0px" : "7px"} maxScrollRange={(props.childCount * props.childWidth) - (scrollRef.current?.offsetWidth ?? 0)} onScroll={(val: number) => { setPosition(val); setValue(val) }}></ScrollBarComponent>}
        </TileRowRoot>
    )
}

export default TileRow 