import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import branding from "../../branding/branding"
import LanguageToggler from "../../ui/LanguageToggle"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useHistory } from "react-router"
import { systemCheckPageRoute } from "../../navigationArea/RoutePaths"
import { TextField } from "../../ui/TextField"
import { AppLoadingStatus } from "../../App"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import { BackendServiceError, cognitoLogin, connectProfile, createNewUser, DataPrivacyDoc, getUserById, loginWithPassword, PresenceType, ProfileResponse, registerProfile, updateUserValues, UserResponse, syncFavorites as syncFavoritesBackend, createUnreadCounterUser } from "../../backendServices/BackendServices"
import { trackLogInFailure, trackLogInSuccess, trackNavigation } from "../../utils/GTMTracking"
import { ProfileResponseStatus } from "./LoginRegistrationSite"
import { useLoggedInState, User } from "../../globalStates/LoggedInUser"
import { useFavoriteState } from "../../globalStates/Favorites"
import moment from "moment"
import { defaultLogger as logger } from "../../globalStates/AppState"
import CenteredLoader from "../../ui/CenteredLoader"
import { useRef } from "react"

const LoginRegistrationSiteRoot = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    overflow-y: hidden;
    height: 100vh;
    font-family: ${branding.font1};
`

const LoginRegistrationLoginStyleOverlay = styled.div`
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgb(229, 229, 229, 0.9);
`

const LoginRegistrationStyleContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("/branding/login-bg.jpg");
    background-size: cover;
    width: 100%;
    z-index: 2;
`

const HeaderRoot = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    position: relative;
    z-index: 10;

    & img {
        max-width: ${branding.loginRegistrationSite.centerLoginLogoMaxWidth ?? "150px"};
        max-height: ${branding.loginRegistrationSite.centerLoginLogoMaxHeight ?? "50px"};
        object-fit: contain;
        user-select: none;
    }
`

const LoginFormRoot = styled.div`
    position: relative;
    height: 100vh;
`

const ScrollContainerRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
`

const LoaderAttributes: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3
}

const LoginFormContainer = styled.div` 
    margin: 0;
    position: absolute;
    bottom: 15%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 90%;

    @media (min-width: 700px) {
        width: 600px;
    }

    @media (max-height: 900px) {
        bottom: 0;
        top: 40%;
    }

    @media (max-height: 670px) {
        bottom: 0;
        top: 33%;
        width: 320px;
    }

    @media (max-height: 640px) {
        left: 47.5%;
    }

    @media (max-height: 600px) {
        width: 300px;
    }
`

const LoginFormTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 20px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const LoginInputSection = styled.div`
    margin-bottom: 30px;
`

const GetYourTicketSection = styled.div`
    border-top: 1px solid ${branding.loginRegistrationSite.getYourTicketSectionTopBorderColor};
`

const LoginFormText = styled.div`
    font-size: 16px;
    line-height: 18.75px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const LoginFormTextFieldContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`

const LoginFormSubmitButton = styled.button`
    flex: 0 0 auto;
    //margin-top: 10px;
    margin-bottom: 20px;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color:  ${branding.loginRegistrationSite.mobileLoginButtonColor};;
    color: #fff;
    transition: 0.5s;
    cursor: pointer;
    width: 100%;
    outline: none;

    &:focus { outline: none; }

    ${props => props.disabled
        ? css`
            opacity: 0.5;
            transition-property: none;
        `
        : css`
            &:hover { opacity: 0.7; }
        `
    };
`

const LoginErrorMessage = styled.div`
    color: ${branding.dangerButtonColor};;
`

const LoginFormSimpleButton = styled.div`
    color:  ${branding.loginRegistrationSite.mobileLoginButtonColor};;
    cursor: pointer;
    font-weight: 700;
    width: 100%;
    //margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        text-decoration: underline;
    }
`

const FooterRoot = styled.div`
    bottom: 0;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
`

const FooterContainer = styled.div`
    text-decoration: underline;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
    font-family: ${branding.font1};
    font-size: 14px;
    text-align: center;
    margin-bottom: 14px;
    cursor: pointer;
`

const CheckboxParent = styled.div`
    display: flex;
    align-items: center;
    font: ${branding.font1};
    
    & a{
        color: ${branding.loginRegistrationSite.mobileLoginTextColor};
        text-decoration: underline;
    }
`

const CheckboxInput = styled.input`
    width: 15px;
    height: 15px;
    margin-bottom: 5px;
    background: #FFF;
    border: 1px solid ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const Checkbox = (props: any) => (
    <CheckboxInput type="checkbox" {...props} />
)

enum TokenMessagePanelType {
    NONE,
    ERROR_TOKEN_INVALID,
    ERROR_TOKEN_INVALIDATED,
    ERROR_CREATE_TOKEN_FAILED,
    SUCCESS_CREATE_TOKEN,
}

function calcTokenMessagePanelType(registerStatus: ProfileResponseStatus, connectStatus: ProfileResponseStatus) {
    const registerResponseFailed = registerStatus === ProfileResponseStatus.FAILURE ||
        registerStatus === ProfileResponseStatus.FAILURE_EMAIL_INVALID ||
        registerStatus === ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED

    if (registerResponseFailed)
        return TokenMessagePanelType.ERROR_CREATE_TOKEN_FAILED

    if (registerStatus === ProfileResponseStatus.SUCCESS)
        return TokenMessagePanelType.SUCCESS_CREATE_TOKEN

    if (connectStatus === ProfileResponseStatus.TOKEN_INVALIDATED) {
        return TokenMessagePanelType.ERROR_TOKEN_INVALIDATED
    } else if (connectStatus === ProfileResponseStatus.WRONG_TOKEN) {
        return TokenMessagePanelType.ERROR_TOKEN_INVALID
    }


    return TokenMessagePanelType.NONE
}

interface TokenMessagePanelProps {
    type: TokenMessagePanelType
    clickListener: () => any
}

function RenderErrorMessages(registerResponse: any, registerWithPasswordResponse: any, strings: any, localLogoutReason: string | null) {
    const invalidEmail = branding.loginRegistrationSite.invalidEmailPanelWithLink.visible ?
        <p>{strings.loginRegistrationSite.invalidEmailPanelWithLink.befLinkPart}
            <a className="invalid-email-link" href={strings.loginRegistrationSite.invalidEmailPanelWithLink.link} target="_blank" rel="noopener noreferrer">
                {strings.loginRegistrationSite.invalidEmailPanelWithLink.text}
            </a>
            {strings.loginRegistrationSite.invalidEmailPanelWithLink.aftLinkPart}
        </p>
        :
        <p>{strings.loginRegistrationSite.invalidEmailPanel}</p>



    return <LoginFormText>
        <LoginErrorMessage>
            {(localLogoutReason)
                && <p>{localLogoutReason}</p>}

            {(registerResponse.status === ProfileResponseStatus.FAILURE || registerWithPasswordResponse.status === ProfileResponseStatus.FAILURE)
                && <p>{strings.loginRegistrationSite.registerEmailFailed}</p>}

            {(registerResponse.status === ProfileResponseStatus.FAILURE_EMAIL_INVALID || registerWithPasswordResponse.status === ProfileResponseStatus.FAILURE_EMAIL_INVALID)
                && invalidEmail}

            {(registerResponse.status === ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED || registerWithPasswordResponse.status === ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED)
                && <p>{strings.loginRegistrationSite.outdatedPrivacyDocsPanel}</p>}

            {(registerResponse.status === ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND || registerWithPasswordResponse.status === ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND)
                && <p>{strings.loginRegistrationSite.thirdPartyUserNotFound}</p>}

            {(registerWithPasswordResponse.status === ProfileResponseStatus.WRONG_PASSWORD)
                && <p>{strings.loginRegistrationSite.wrongPasswordPanel}</p>}

        </LoginErrorMessage>
    </LoginFormText>
}

function TokenMessageDisplay(props: TokenMessagePanelProps) {
    const strings = useLanguageState().getStrings()

    return <LoginFormText>
        <LoginErrorMessage>
            {props.type === TokenMessagePanelType.ERROR_TOKEN_INVALID
                && <p>{strings.loginRegistrationSite.wrongToken}</p>}

            {props.type === TokenMessagePanelType.ERROR_TOKEN_INVALIDATED
                && <div>
                    <p>{strings.loginRegistrationSite.wrongToken}</p>
                    <LoginFormSimpleButton onClick={props.clickListener}>
                        {strings.loginRegistrationSite.createNewToken}
                    </LoginFormSimpleButton>
                </div>}

            {props.type === TokenMessagePanelType.ERROR_CREATE_TOKEN_FAILED
                && <p>{strings.loginRegistrationSite.createTokenFailed}</p>}
        </LoginErrorMessage>
        {props.type === TokenMessagePanelType.SUCCESS_CREATE_TOKEN
            && <p>{strings.loginRegistrationSite.createTokenSuccess}</p>}
    </LoginFormText>

}

interface InputDisplayProps {
    strings: any,
    registerProfile: (email: string) => Promise<ProfileResponseStatus>,
    registerWithPassword: (email: string, password: string) => Promise<ProfileResponseStatus>,
    connectProfile: (token: string) => Promise<ProfileResponseStatus>,
    email?: string,
    setUser: (user?: User) => void,
    dataPrivacyDoc: DataPrivacyDoc | undefined,
    abortSignal: AbortSignal
    //loginLayoutType?: LoginLayoutType
}

const InputDisplay: React.FunctionComponent<InputDisplayProps> = (props: InputDisplayProps) => {
    const strings = props.strings
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [token, setToken] = useState("")
    const [registerResponse, setRegisterResponse] = useState({ status: ProfileResponseStatus.NONE })
    const [registerWithPasswordResponse, setRegisterWithPasswordResponse] = useState({ status: ProfileResponseStatus.NONE })
    const [connectResponse, setConnectResponse] = useState({ status: ProfileResponseStatus.NONE })
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [isLoaded, setIsLoaded] = useState(true)

    const privacyCheckboxVisible = branding.loginRegistrationSite.privacyDocsCheckbox.visible
    const [privacyDocsAccepted, setPrivacyDocsAccepted] = useState(!privacyCheckboxVisible || false)

    const history = useHistory()

    const messageType = calcTokenMessagePanelType(registerResponse.status, connectResponse.status)

    const userEmail = props.email
    const loginWithPassword = branding.loginRegistrationSite.loginWithPassword

    function onSubmitButtonClicked() {
        if (submitDisabled || !privacyDocsAccepted) return;

        setIsLoaded(false)
        if (loginWithPassword) {
            setSubmitDisabled(true)
            const registerWithPassword = props.registerWithPassword(email, password)
            registerWithPassword.then(resp => {
                if (!props.abortSignal.aborted) {
                    setSubmitDisabled(false)
                    setIsLoaded(true)
                    setRegisterWithPasswordResponse({ status: resp })
                }
                localStorage.removeItem("logoutReason")
            })
        } else {
            setSubmitDisabled(true)
            const registerResponse = props.registerProfile(email)
            registerResponse.then(resp => {
                if (!props.abortSignal.aborted) {
                    setSubmitDisabled(false)
                    setIsLoaded(true)
                    setRegisterResponse({ status: resp })
                }
                localStorage.removeItem("logoutReason")
            })
        }
    }

    useEffect(() => {
        onPasswordOrEmailChanged(email, password)
        // eslint-disable-next-line
    }, [email, password])

    function onPasswordOrEmailChanged(email: string, password: string) {
        const emailRegex = new RegExp('^.+@.+\\.[A-Za-z]{2,20}$')
        const disabled = !emailRegex.test(email) || (loginWithPassword && password.length === 0)

        setSubmitDisabled(disabled)
        setConnectResponse({ status: ProfileResponseStatus.NONE })
        setRegisterResponse({ status: ProfileResponseStatus.NONE })
        setRegisterWithPasswordResponse({ status: ProfileResponseStatus.NONE })
    }

    function onTokenChanged() {
        setConnectResponse({ status: ProfileResponseStatus.NONE })
        setRegisterResponse({ status: ProfileResponseStatus.NONE })
    }

    function onTokenSubmitButtonClicked() {
        const connectResponse = props.connectProfile(token)
        connectResponse.then(cr => setConnectResponse({ status: cr }))
        setToken("")
    }

    function onCreateNewTokenButtonClicked() {
        if (!userEmail) return
        const resp = props.registerProfile(userEmail)
        resp.then(rr => setRegisterResponse({ status: rr }))

        setConnectResponse({ status: ProfileResponseStatus.NONE })
        setToken("")
    }

    function onChangeEmailButtonClicked() {
        props.setUser(undefined)
        setToken("")
    }

    const tokenInputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let password = params.get(branding.loginRegistrationSite.passwordQueryParam);
        let email = params.get(branding.loginRegistrationSite.emailQueryParam);
        if (password && email) {
            params.delete(branding.loginRegistrationSite.passwordQueryParam)
            params.delete(branding.loginRegistrationSite.emailQueryParam)
            history.replace({
                search: params.toString(),
            })
            setIsLoaded(false)
            props.registerWithPassword(email, password).then(resp => {
                if (!props.abortSignal.aborted) {
                    setIsLoaded(true)
                    setRegisterWithPasswordResponse({ status: resp })
                }
                localStorage.removeItem("logoutReason")
            })
        }
        tokenInputRef.current?.focus()
    }, []) // eslint-disable-line

    return <LoginInputSection>
        {!isLoaded && <CenteredLoader loaderWrapperStyle={LoaderAttributes}></CenteredLoader>}
        <LoginFormTitle>
            {strings.loginRegistrationSite.loginRegistrationTitle}
        </LoginFormTitle>
        <LoginFormText style={{ textAlign: "left" }}>
            {strings.loginRegistrationSite.centerLoginStyleTitle}
        </LoginFormText>

        {
            loginWithPassword &&
            <>
                <LoginFormTextFieldContainer>
                    <TextField
                        placeholder={strings.loginRegistrationSite.enterEmail}
                        setValue={setEmail}
                        autoComplete="on"
                        paddingLeft="8px"
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === "Enter")
                                onSubmitButtonClicked()
                        }}
                        focused={true}
                        width="100%"
                        fontSize="16px"
                        height="40px"
                        backgroundColor="#fff"
                        borderAround="1px solid #000"
                        outline="2px solid"
                        fontFamily={branding.font1}
                    />
                </LoginFormTextFieldContainer>
                <LoginFormTextFieldContainer>
                    <TextField
                        type="password"
                        value={password}
                        setValue={setPassword}
                        autoComplete="on"
                        paddingLeft="8px"
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === "Enter")
                                onSubmitButtonClicked()
                        }}
                        placeholder={strings.loginRegistrationSite.enterPassword}
                        width="100%"
                        fontSize="16px"
                        height="40px"
                        backgroundColor="#fff"
                        borderAround="1px solid #000"
                        outline="2px solid"
                        fontFamily={branding.font1}
                    />
                </LoginFormTextFieldContainer>
                <LoginFormSubmitButton disabled={submitDisabled} onClick={onSubmitButtonClicked}>
                    {strings.loginRegistrationSite.submitTokenButton}
                </LoginFormSubmitButton>
                {
                    RenderErrorMessages(registerResponse, registerWithPasswordResponse, strings, localStorage.getItem("logoutReason") === null ? null : (localStorage.getItem("logoutReason") === "logoutReasonSessionTimeout" ? strings.loginRegistrationSite.logoutReasonSessionTimeout : strings.loginRegistrationSite.logoutReasonForbidden))
                }
            </>
        }

        {
            !loginWithPassword &&
            <>
                {userEmail &&
                    <>
                        <div style={{ textAlign: "left" }}>
                            <TextField
                                value={userEmail}
                                paddingLeft="8px"
                                disabled={true}
                                width="100%"
                                fontSize="16px"
                                height="40px"
                                backgroundColor="#fff"
                                borderAround="1px solid #000"
                                outline="2px solid"
                                fontFamily={branding.font1}
                            />
                        </div>
                        <LoginFormTextFieldContainer>
                            <TextField
                                placeholder={strings.loginRegistrationSite.enterToken}
                                value={token}
                                focused={true}
                                paddingLeft="8px"
                                setValue={setToken}
                                onChange={() => onTokenChanged()}
                                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (e.key === "Enter")
                                        onTokenSubmitButtonClicked()
                                }}
                                width="100%"
                                fontSize="16px"
                                height="40px"
                                backgroundColor="#fff"
                                borderAround="1px solid #000"
                                outline="2px solid"
                                fontFamily={branding.font1}
                            />
                        </LoginFormTextFieldContainer>

                        <LoginFormSubmitButton disabled={token.length === 0} onClick={() => onTokenSubmitButtonClicked()}>
                            {strings.loginRegistrationSite.submitTokenButton}
                        </LoginFormSubmitButton>

                        <LoginFormSimpleButton>
                            <p onClick={onChangeEmailButtonClicked}>
                                {strings.loginRegistrationSite.changeEmailButton}
                            </p>
                        </LoginFormSimpleButton>
                        <LoginFormText>
                            {strings.loginRegistrationSite.noToken}
                        </LoginFormText>
                        <LoginFormSimpleButton>
                            <p onClick={onCreateNewTokenButtonClicked}>
                                {strings.loginRegistrationSite.resendToken}
                            </p>
                        </LoginFormSimpleButton>
                    </>
                }

                {!userEmail &&
                    <>
                        <LoginFormTextFieldContainer>
                            <TextField
                                placeholder={strings.loginRegistrationSite.enterEmail}
                                setValue={setEmail}
                                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => { if (e.key === "Enter") onSubmitButtonClicked() }}
                                width="100%"
                                autoComplete="on"
                                fontSize="16px"
                                paddingLeft="8px"
                                height="40px"
                                backgroundColor="#fff"
                                borderAround="1px solid #000"
                                outline="2px solid"
                                fontFamily={branding.font1}
                            />
                        </LoginFormTextFieldContainer>
                        {privacyCheckboxVisible && <CheckboxParent className="mt-3">
                            <Checkbox checked={privacyDocsAccepted} onChange={() => {
                                setPrivacyDocsAccepted(!privacyDocsAccepted)
                            }} />
                            <div style={{ color: branding.loginRegistrationSite.mobileLoginTextColor, textAlign: "left", marginBottom: "20px" }} className="ml-3">
                                {strings.loginRegistrationSite.privacyDocsCheckbox.labelBeforeLink}<a href={props.dataPrivacyDoc?.url} target="_blank" rel="noopener noreferrer">{strings.loginRegistrationSite.privacyDocsCheckbox.linkTitle}</a>{strings.loginRegistrationSite.privacyDocsCheckbox.labelAfterLink}
                            </div>
                        </CheckboxParent>}
                        <LoginFormSubmitButton disabled={submitDisabled || !privacyDocsAccepted} onClick={onSubmitButtonClicked}>
                            Send code
                        </LoginFormSubmitButton>
                    </>
                }

                <TokenMessageDisplay type={messageType} clickListener={onCreateNewTokenButtonClicked} />
            </>
        }



    </LoginInputSection>
}

interface LoginRegistrationSiteProps {
    dataPrivacyDoc: DataPrivacyDoc | undefined,
    loadingStatus: AppLoadingStatus
}



const MobileLoginRegistrationSite: React.FunctionComponent<LoginRegistrationSiteProps> = (props: LoginRegistrationSiteProps) => {
    const strings = useLanguageState().getStrings()
    const history = useHistory()
    const userState = useLoggedInState()
    const loginAbortController = new AbortController();
    const favorites = useFavoriteState()

    useEffect(() => {
        trackNavigation("Login")
        localStorage.removeItem("presenceBeforeRefresh")
        return () => {
            loginAbortController.abort();
        }
        // eslint-disable-next-line
    }, [])

    const syncFavorites = (userId: string) => {
        syncFavoritesBackend({
            profileId: userId,
            body: {
                currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // "2020-01-01 12:00:00",
                lastSyncTime: favorites.getLastSyncTime()
            }
        }).then((resp) => {
            const temp: Array<any> = resp.content.favorites;
            favorites.setLastSyncTime(new Date())
            temp.forEach(item => {
                if (!item.deleted && !favorites.is("person", item.id)) {
                    favorites.add("person", item.id)
                }
                if (!item.deleted && !favorites.is("organization", item.id)) {
                    favorites.add("organization", item.id)
                }
                if (!item.deleted && !favorites.is("eventdate", item.id)) {
                    favorites.add("eventdate", item.id)
                }
                if (!item.deleted && !favorites.is("product", item.id)) {
                    favorites.add("product", item.id)
                }
                if (!item.deleted && !favorites.is("trademark", item.id)) {
                    favorites.add("trademark", item.id)
                }
                if (!item.deleted && !favorites.is("sotuser", item.id)) {
                    favorites.add("sotuser", item.id)
                }
            });
        }).catch((e: { message: React.SetStateAction<string> }) => {
            // syncFavorites failed, logged in BackendServices
        })
    }

    const createAppSyncUser = async (user: User) => {
        const appSyncUser = await getUserById(user.profileId)
        const userName = [user!.firstName, user!.middleName, user!.lastName].filter(Boolean).join(" ")
        const startPresenceType = PresenceType.AVAILABLE
        if ((appSyncUser as UserResponse)?.getUser) {
            await updateUserValues({ id: user!.profileId, name: userName, pictureUrl: user!.logoUrl, presenceStatus: startPresenceType, lastConnected: new Date().toISOString() })
        } else {
            await createNewUser({ id: user!.profileId, name: userName, pictureUrl: user!.logoUrl, presenceStatus: startPresenceType, lastConnected: new Date().toISOString() })
            await createUnreadCounterUser({ id: user!.profileId, requests: 0, contacts: 0, conversations: 0, schedules: 0 })
        }
    }

    const registerWithPassword = (email: string, password: string): Promise<ProfileResponseStatus> => {
        return (async () => {
            if (!props.dataPrivacyDoc) {
                trackLogInFailure()
                return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED
            }

            const resp = await loginWithPassword(email, password, { dataPrivacyDocs: [props.dataPrivacyDoc] })
            const error = (resp as BackendServiceError);
            if (error.httpStatus) {
                trackLogInFailure()
                const json = error.responseJson;
                if (json?.errorCode === "thirdPartyUserNotFound") {
                    return ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND
                }
                if (json?.emailInvalid)
                    return ProfileResponseStatus.FAILURE_EMAIL_INVALID
                if (json?.dataPrivacyDocsOutdated)
                    return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED
                if (error.httpStatus === 401)
                    return ProfileResponseStatus.WRONG_PASSWORD

                return ProfileResponseStatus.FAILURE
            } else {
                const profileResp = resp as ProfileResponse
                try {
                    await cognitoLogin(profileResp.profile.profileId, profileResp.beConnectionToken, email)
                } catch (error) {
                    // should never happen because we have a valid beConnectionToken here
                    logger.error({ message: "LoginRegistrationSite cognito login failed", errorMessage: error.message, errorStack: error.stack })
                    return ProfileResponseStatus.FAILURE
                }
                userState.setUserState({ jwtToken: profileResp.beConnectionToken, user: profileResp.profile })
                syncFavorites(profileResp.profile.profileId)
                await createAppSyncUser(profileResp.profile)
                return ProfileResponseStatus.SUCCESS
            }
        })()
    }

    const register = (email: string): Promise<ProfileResponseStatus> => {
        return (async () => {
            if (!props.dataPrivacyDoc) {
                trackLogInFailure()
                return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED
            }

            const resp = await registerProfile(email, { dataPrivacyDocs: [props.dataPrivacyDoc] })
            const error = (resp as BackendServiceError);
            if (error.httpStatus) {
                trackLogInFailure()
                const json = error.responseJson;
                if (json?.errorCode === "thirdPartyUserNotFound") {
                    return ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND
                }
                if (json?.emailInvalid)
                    return ProfileResponseStatus.FAILURE_EMAIL_INVALID
                if (json?.dataPrivacyDocsOutdated)
                    return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED

                return ProfileResponseStatus.FAILURE
            } else {
                const profileIdResp = resp as { profileId: string }
                userState.setUser({ email: email, profileId: profileIdResp.profileId })
                return ProfileResponseStatus.SUCCESS
            }
        })()
    }

    const connect = (token: string): Promise<ProfileResponseStatus> => {
        return (async () => {
            const profileId = userState.user()?.profileId
            if (profileId === undefined) {
                trackLogInFailure()
                return ProfileResponseStatus.FAILURE
            }

            const resp = await connectProfile(profileId, token)
            if ((resp as BackendServiceError).httpStatus) {
                const errorResp = resp as BackendServiceError
                trackLogInFailure()
                // Bad Request, token was input wrong > 3, registerProfile has to be called again to prepare the profile for connection and create a new token
                if (+errorResp.httpStatus === 400) {
                    return ProfileResponseStatus.TOKEN_INVALIDATED
                }
                return ProfileResponseStatus.WRONG_TOKEN
            } else {
                const profileResp = resp as ProfileResponse
                try {
                    await cognitoLogin(profileId, profileResp.beConnectionToken, profileResp.profile.email)
                } catch (error) {
                    // should never happen because we have a valid beConnectionToken here
                    logger.error({ message: "LoginRegistrationSite ", errorMessage: error.message, errorStack: error.stack })
                    return ProfileResponseStatus.FAILURE
                }
                userState.setUserState({ jwtToken: profileResp.beConnectionToken, user: profileResp.profile })
                syncFavorites(profileResp.profile.profileId)
                await createAppSyncUser(profileResp.profile)
                trackLogInSuccess()
                return ProfileResponseStatus.SUCCESS
            }
        })()
    }

    const onBuyTicketButtonClick = () => {
        window.open(`${strings.ticketSale.ticketsUrl}`, "_blank")
    }


    const onCheckYourSystemButtonClick = () => {
        history.push(systemCheckPageRoute)
    }

    if (props.loadingStatus !== AppLoadingStatus.SUCCESS) {
        return null
    }

    return <LoginRegistrationSiteRoot>

        <LoginRegistrationStyleContainer>
            <LoginRegistrationLoginStyleOverlay>
                <HeaderRoot>
                    <img src="./branding/logo_dark.png" alt={strings.configuration.ogTitle} />
                    <LanguageToggler color={branding.loginRegistrationSite.mobileLoginTextColor} fullToggler={true} margin="0" />
                </HeaderRoot>
                <LoginFormRoot>
                    <ScrollContainerRoot>
                        <ContentScrollContainer>
                            <LoginFormContainer>
                                <InputDisplay strings={strings} abortSignal={loginAbortController.signal} registerProfile={register} registerWithPassword={registerWithPassword} connectProfile={connect} email={userState.user()?.email} setUser={userState.setUser} dataPrivacyDoc={props.dataPrivacyDoc} />

                                <GetYourTicketSection>
                                    <LoginFormText>
                                        {strings.loginRegistrationSite.centerLoginStyleSubtitleShort}
                                    </LoginFormText>
                                    <LoginFormSimpleButton>
                                        <p onClick={onBuyTicketButtonClick}>
                                            {strings.ticketSale.getYourTicketButton}
                                        </p>
                                    </LoginFormSimpleButton>
                                </GetYourTicketSection>
                            </LoginFormContainer>
                        </ContentScrollContainer>
                    </ScrollContainerRoot>
                </LoginFormRoot>
                <FooterRoot>
                    <FooterContainer onClick={onCheckYourSystemButtonClick}>
                        {strings.ticketSale.checkYourSystemButton}
                    </FooterContainer>
                </FooterRoot>
            </LoginRegistrationLoginStyleOverlay>
        </LoginRegistrationStyleContainer>

    </LoginRegistrationSiteRoot>
}

export default MobileLoginRegistrationSite