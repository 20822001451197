import React from "react"
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { defaultLogger as logger } from "../../globalStates/AppState";
import branding from "../../branding/branding";


const NetworkingTabErrorBoundaryPanel = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 15px;
    height: calc(100vh - 40px);
    text-align: center;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor ?? '#000'};
`

const ReloadButton = styled(Button)`
    margin-top: 45px;
    width: 100%;
    max-width: 300px;
    height: 40px;
`

interface NetworkingTabErrorBoundaryProps {
    errorMessage: string
    reloadButtonText: string
}

class NetworkingTabErrorBoundary extends React.Component<NetworkingTabErrorBoundaryProps> {
    state = {
        error: null
    }

    static getDerivedStateFromError(error: any) {
        return { error: error };
    }

    componentDidCatch(error: any, errorInfo: any) {
        logger.error(error, errorInfo);
    }

    render() {
        const resetErrorState = (): void => {
            this.setState({ error: null })

        }

        if (this.state.error) {
            return (
                <NetworkingTabErrorBoundaryPanel>
                    <h4>{this.props.errorMessage}</h4>
                    <ReloadButton onClick={resetErrorState}>{this.props.reloadButtonText}</ReloadButton>
                </NetworkingTabErrorBoundaryPanel>
            )
        }

        return this.props.children;
    }
}

export default NetworkingTabErrorBoundary
