import React, { useState } from "react"
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { IconReport, IconClose } from "./Icons";
import CrsMultiSwitch from "./CrsMultiSwitch";
import { reportUser } from "../backendServices/BackendServices";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import { useLanguageState } from "../globalStates/LanguageState";
import branding from "../branding/branding";

const ReportModalRoot = styled(Modal)`
    font-family: ${branding.font1};

    .btn-secondary  {
    color: ${branding.primaryColor ?? "#000"} !important;
    display: inline-block;
    background-color: white;
    font-family: ${branding.font1};
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    border-width: initial;
    border-style: none;
    border-image: initial;
    border-color: white;
    cursor: pointer;
    }
`
const ReportModalHeader = styled(Modal.Header)`
    display: flex;
    justify-content: space-between;
    align-content: center; 
    align-items: stretch;
    color: ${branding.primaryColor};
    svg {
        color: ${branding.primaryColor};
    }
`
const ReportModalFooter = styled(Modal.Footer)`
        justify-content: space-between;
        border: none;
        padding: 0px 15px;
        margin-bottom: 10px;
`
const ReportModalTitle = styled.span`
    display: inline-block;
    font-size: 1.3rem;
    font-weight: bold;
    color: ${branding.primaryColor};
`

const ReportModalBody = styled(Modal.Body)`
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1.5rem 1rem; 
`
const CloseModalBtn = styled.div`
    display: inline-block;
    cursor: pointer;
    svg {
        color: ${branding.primaryColor};
    }
`

const SubmitModalBtn = styled.div`
    display: inline-block;
    cursor: pointer;
    /* margin: 0; */
    width: 40%;
    height: 30px;
    border-radius: 20px; 
    background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
    color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"}; 
    border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"};
    transition: background-color .3s, border-color .3s;
    /* padding: .3rem; */
    text-align: center;
    font-family: ${branding.font1};
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;

    &:hover {
      background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor ?? "#e8e8e8"} !important; 
      color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor ?? "#000"} !important;
    }
`

interface ReportModalProps {
    show: boolean
    onHide: () => void
    userToReportId: string
}

enum reportType {
    REPORT_USER = 1,
    CLAIM_ACCOUNT = 2
}

// const CancelButton = styled.div`
//     width: 30%;
//     height: 30px;
//     font-size: 12px;
//     justify-content: left;
//     border: none;
//     background: white;
//     color: ${branding.primaryColor ?? "#fff"};
//     font-family: ${branding.font1};
//     border-radius: 25px;
//     cursor: pointer;

//     &:active {
//         background-color: transparent;
//         border: 1px solid transparent;
//     }

//     &:hover{
//         text-decoration: none;
//     }

// `
const DisagreeButton = styled.div`
    width: 50%;
    height: 30px;
    cursor: pointer;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
    
    &:hover{
        text-decoration: none;
    }
`


const ReportModal: React.FC<ReportModalProps> = (props) => {
    const userState = useLoggedInState()
    const strings = useLanguageState().getStrings()
    const [reportDescription, setReportDescription] = useState('')
    const reportTypeData = [{ value: reportType.REPORT_USER, label: strings.communicationArea.userReportText }, { value: reportType.CLAIM_ACCOUNT, label: strings.communicationArea.claimAccountText }]
    const [currentReportType, setCurrentReportType] = useState(reportType.REPORT_USER)


    const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReportDescription(e.target.value)
    }


    const onReportSubmit = () => {
        reportUser({
            reporterId: userState.user()?.profileId || '',
            reportProfileId: props.userToReportId,
            reportType: currentReportType === reportType.REPORT_USER ? strings.communicationArea.userReportText : strings.communicationArea.claimAccountText,
            message: reportDescription
        }).then(res => {
            props.onHide()
        }).catch(err => {
            // Logged in BackendServices
        })
    }

    return (
        <ReportModalRoot show={props.show}
            onHide={props.onHide}
            backdrop="static"
            centered
            animation={false}>
            <div onClick={e => e.stopPropagation()}>
                <ReportModalHeader>
                    <div className={'d-flex align-items-center'}>
                        {IconReport({fill: branding.sideIconBar.sideIconColorDark})}
                        <ReportModalTitle className={'ml-3'}>{strings.communicationArea.userReportTextTitle}</ReportModalTitle>
                    </div>
                    <CloseModalBtn onClick={() => props.onHide()}>{IconClose({fill: branding.sideIconBar.sideIconColorDark})}</CloseModalBtn>
                </ReportModalHeader>
                <ReportModalBody>
                    {CrsMultiSwitch({
                        items: reportTypeData,
                        activeItem: currentReportType,
                        onItemClick: setCurrentReportType,

                        style: {
                            msActiveItem: { zIndex: 0 },
                            msItem: { position: 'relative', zIndex: 1 },
                            msRootItem: { width: '75%', fontSize: '0.8rem', margin: '0 auto 1.5rem auto' }
                        },
                    })}

                    <textarea
                        style={{ 'height': '250px', fontSize: "12px" }}
                        value={reportDescription}
                        name="reportDescription"
                        className="form-control"
                        onChange={onDescriptionChange}
                        placeholder={strings.globalTexts.descriptionText}></textarea>
                </ReportModalBody>
                <ReportModalFooter>
                <DisagreeButton className="d-flex align-items-center" onClick={() => props.onHide()}>
                        {/* <BootstrapButton variant="secondary" onClick={() => props.onHide()}> */}
                        {strings.globalTexts.cancel}
                        {/* </BootstrapButton> */}
                    </DisagreeButton>
                    <SubmitModalBtn onClick={onReportSubmit} className="d-flex align-items-center justify-content-center">{strings.communicationArea.sendReportText}</SubmitModalBtn>
                </ReportModalFooter>
            </div>
        </ReportModalRoot>
    )
}

export default ReportModal