import React, { useEffect, useRef, useState } from "react"
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import styled from "styled-components"
import { Category, Channel, Company, Description, EntityType, EventDate, Link, Person, Product, ShareTargetType, SocialMedia, Trademark } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { IconCameraJoin, IconCoupon, IconEmail, IconFacebook, IconInstagram, IconLink, IconLinkedIn, IconLiveStream, IconLocation, IconPhone, IconShare, IconShareLink, IconTwitter, IconVideo, IconXing, IconYouTube, IconPinterest } from "../../../ui/Icons"
import { ContentScrollContainer } from "../../../ui/ScrollContainer"
import Linkify from "react-linkify"
import { usePrivacyPolicyModal } from "../../../ui/PrivacyPolicyModal"
import { BasisPremiumType, getBasisPremiumConfig } from "../../../branding/BasisPremiumBranding"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { useMeetingContext } from "../../../conference/context/MeetingContext"
import { useChimeContext } from "../../../conference/context/ChimeContext"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { isExplorationOrPostEventPhase, isExplorationPhase, isLivePhase } from "../../../utils/EventPhaseChecker"
import { Staff, StaffEntry, StaffType } from "../../../ui/StaffEntry"
import { trackVisit, getAccessStatusForAllVirtualCafes, UserRestrictedAreaAccess, BackendServiceError } from "../../../backendServices/BackendServices"
import ScrollBarComponent from "../../../ui/ScrollBar"
import { FormattedDateExtensionDiv, FormattedDatePar, HeaderTitle } from "./StyledComponents"
import { useRecommendOrganizationModal } from "../../../ui/RecommendOrganizationModal"
import { isEventDateLive } from "../../../utils/DateUtils"
import { useCategoriesState } from "../../../globalStates/CategoriesState"
import { buildDetailLink, DetailNavLink } from "../DetailNavLink"
import { PointsBadge } from "../../program/EventDateEntry"
import BookmarkWithToggle from "../../../ui/BookmarkWithToggle"
import LiveEvent from "../../../ui/LiveEvent"
import { DetailPageEntityRows, EntityRowData, DetailPageEntityRow } from "./DetailPageEntityRows"
import MeetingRoomGroup from "../../lounges/MeetingRoomGroup"
import { Media } from "./Media"
import moment from "moment"
import TileRow from "../../../ui/TileRow"
import { useAppState } from "../../../globalStates/AppState"
import Categories from "./Categories"
import { getIamPartOf } from "../../../globalStates/IAmPartOf"
import queryString from 'query-string'
import VizSensor from 'react-visibility-sensor'
import { format } from "date-fns"
import { hasAvailableStaff, joinShowroom } from "../OrganizationDetailPageContent"
import { SocialMediaType } from "../../myprofile/MyProfilePageContentBranding"
import { DetailSection, InfoContentOrderType } from "../DetailPageBranding"
import { useLocation } from "react-router-dom"

const germanLocale = require('date-fns/locale/de');


const OrganizationDetailPageContentRoot = styled.div`
    padding: 0px;
    font-family: ${branding.font1};

    @media(max-width: 1400px) {
       padding: 0px 100px;
    }

`

const CompanyLogoDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    width: 150px;
    height: 150px;
    padding: 20px;
    background: #fff;

    @media(max-width: 1600px) {
        width: 120px;
        height: 120px;
        padding: 25px;
    }

    @media(max-width: 1400px) {
        width: 100px;
        height: 100px;
        padding: 15px;
    }

    @media(max-width: 1200px) {
        width: 80px;
        height: 80px;
        padding: 10px;
    }
`

const CompanyLogo = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`

const RowTileParent = styled.div`
    margin: 0 -15px;
`

interface DetailPageSectionsProps {
    type: EntityType
    guestUserBannerRef?: React.RefObject<HTMLDivElement>
    handleScroll?(value: number): void
    onAccessDenied?: () => void
    entity: Company | Product | Trademark
    adjustHeightWith?: number
}

const DetailPageSections: React.FunctionComponent<DetailPageSectionsProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const appState = useAppState()
    const lang = useLanguageState().getLanguage()

    //Main content container reference
    const containerRef = useRef<HTMLDivElement>(null)
    const [containerOffsetLeft, setContainerOffsetLeft] = useState<number>(0)
    const [offsetLeft, setOffsetLeft] = useState<number | null>(null)
    const isNetworkingOpen = appState.isNetworkingOpen()

    useEffect(() => {
        setOffsetLeft(containerRef.current?.offsetLeft || null)
    }, [])

    useEffect(() => {
        setContainerOffsetLeft(containerRef.current?.offsetLeft!)
    }, [offsetLeft, isNetworkingOpen])

    const [accessList, setAccessList] = useState<UserRestrictedAreaAccess[]>([])
    const [meetingRoomGroups, setMeetingRoomGroups] = useState<any[]>([])

    let sectionOrder: DetailSection[] = []
    let infoContentOrder: InfoContentOrderType[] = []
    let organizationId: string | undefined
    let organization: Company | undefined
    let product: Product | undefined
    let trademark: Trademark | undefined
    let organizations: Company[] = []
    let products: Product[] = []
    let trademarks: Trademark[] = []
    let liveChannels: Channel[] = []
    let liveSessions: EventDate[] = [] // for live eventdates without channel (e.g. roundtable)
    let previousSessions: EventDate[] = []
    let upcomingSessions: EventDate[] = []
    let iframe: string
    let config: any;

    useEffect(() => {
        getAccessStatusForAllVirtualCafes()
            .then((response) => {
                if ((response as BackendServiceError).httpStatus) {

                } else {
                    setAccessList(response as UserRestrictedAreaAccess[])
                    setMeetingRoomGroups(strings.meetingRoomGroups)
                }
            })
        // eslint-disable-next-line
    }, [])

    /*function getRandomArray(arr: any[], n: number) {
        var result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len)
            throw new RangeError("getRandom: more elements taken than available");
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }*/

    function filterProducts(organization: Company, products: Product[]): Product[] {
        let allowedNumProducts: number = -1
        switch (organization.basisPremium) {
            case BasisPremiumType.NONE:
                allowedNumProducts = branding.organizationDetailPageContent.numberOfProductsNone
                break;

            case BasisPremiumType.BASIC:
                allowedNumProducts = branding.organizationDetailPageContent.numberOfProductsBasic
                break;

            case BasisPremiumType.STANDARD:
                allowedNumProducts = branding.organizationDetailPageContent.numberOfProductsStandard
                break;
            case BasisPremiumType.PREMIUM:
                allowedNumProducts = branding.organizationDetailPageContent.numberOfProductsPremium
                break;
            case BasisPremiumType.ULTIMATE:
                allowedNumProducts = branding.organizationDetailPageContent.numberOfProductsUltimate
        }
        if (allowedNumProducts > -1) {
            return products.slice(0, allowedNumProducts)
        }
        else {
            return products
        }
    }

    function filterTrademarks(organization: Company, trademarks: Trademark[]): Trademark[] {
        let allowedNumTrademarks: number = -1
        switch (organization.basisPremium) {
            case BasisPremiumType.NONE:
                allowedNumTrademarks = branding.organizationDetailPageContent.numberOfTrademarksNone
                break;

            case BasisPremiumType.BASIC:
                allowedNumTrademarks = branding.organizationDetailPageContent.numberOfTrademarksBasic
                break;

            case BasisPremiumType.STANDARD:
                allowedNumTrademarks = branding.organizationDetailPageContent.numberOfTrademarksStandard
                break;
            case BasisPremiumType.PREMIUM:
                allowedNumTrademarks = branding.organizationDetailPageContent.numberOfTrademarksPremium
                break;
            case BasisPremiumType.ULTIMATE:
                allowedNumTrademarks = branding.organizationDetailPageContent.numberOfTrademarksUltimate
        }
        if (allowedNumTrademarks > -1) {
            return trademarks.slice(0, allowedNumTrademarks)
        }
        else {
            return trademarks
        }
    }

    switch (props.type) {
        case "organization":
            organization = props.entity as Company
            config = getBasisPremiumConfig(organization?.basisPremium! as BasisPremiumType);
            products = filterProducts(organization, organization.products)
            trademarks = filterTrademarks(organization, organization.trademarks)
            iframe = lang === "en" ? organization.iframeUrl : organization.iframeUrlDe
            sectionOrder = branding.organizationDetailPageContent.orgaDetailPageMainContentOrder
            infoContentOrder = branding.organizationDetailPageContent.orgaDetailPageInfoSectionOrder
            organizationId = props.entity.id
            const now = moment()
            if (organization.eventDates) {
                organization.eventDates.forEach(eventDate => {
                    if (isEventDateLive(eventDate)) {
                        if (eventDate.channel)
                            liveChannels.push(eventDate.channel)
                        else
                            liveSessions.push(eventDate)
                    } else if (moment(eventDate.date + " " + eventDate.start).isAfter(now)) {
                        upcomingSessions.push(eventDate)
                    } else if (moment(eventDate.date + " " + eventDate.end).isBefore(now)) {
                        previousSessions.push(eventDate)
                    }
                })
            }
            break;
        case "product":
            product = props.entity as Product
            organizations = product.organizations
            sectionOrder = branding.productDetailPageContent.sections
            infoContentOrder = branding.productDetailPageContent.infoContentOrder
            if ((props.entity as Product).organizations?.length)
                organizationId = (props.entity as Product).organizations[0].id
            break;
        case "trademark":
            trademark = props.entity as Trademark
            organizations = trademark.organizations
            sectionOrder = branding.trademarkDetailPageContent.sections
            infoContentOrder = branding.trademarkDetailPageContent.infoContentOrder
            if ((props.entity as Trademark).organizations?.length)
                organizationId = (props.entity as Trademark).organizations[0].id
            break;
        default: return null
    }

    return <>
        <ContentScrollContainer handleScroll={props.handleScroll} adjustForHeaderWith={props.guestUserBannerRef && props.guestUserBannerRef.current ? (props.guestUserBannerRef.current.clientHeight + (props.adjustHeightWith ?? 0)) + "px" :
            ((props.adjustHeightWith ?? 0) + "px")}>
            <OrganizationDetailPageContentRoot>
                <Container ref={containerRef} className="mt-5" style={{ color: branding.mainInfoColor }}>
                    {sectionOrder.map((item, index) => {

                        /* Company logo and detail information */
                        if (item === DetailSection.DETAIL_INFO) {
                            return <DetailInfo key={index} order={infoContentOrder} company={organization} {...props.entity} />
                        }

                        /* Company team */
                        if (item === DetailSection.TEAM && organization?.persons?.length) {
                            return (
                                config.teamMembersVisible && <Row key={index} className="mt-5 mb-5">
                                    <TeamSessionsTemplate
                                        type="team"
                                        team={organization.persons}
                                        organizationId={organization.id}
                                        organizationName={organization.name}
                                        onAccessDenied={props.onAccessDenied} />
                                </Row>
                            )
                        }

                        /* Now live section */
                        if (item === DetailSection.LIVE_SESSIONS && (liveChannels.length || liveSessions.length)) {
                            return (
                                <>
                                    {liveChannels && liveChannels.length > 0 && <RowTileParent key={index}>
                                        <TileRow
                                            title={strings.receptionPage.liveSessions}
                                            type="orgaDetail"
                                            tileRowMargin="0"
                                            offsetLeft={containerOffsetLeft}
                                            scrollBar
                                            childWidth={480 + 20}
                                            childCount={liveChannels.length}
                                        >
                                            {liveChannels.map((channel, index) =>
                                                <VizSensor key={index} partialVisibility={true}>
                                                    {({ isVisible }) => isVisible ?
                                                        <LiveEvent
                                                            key={index}
                                                            id={index.toString()}
                                                            title={channel.name}
                                                            thumbnailUrl={channel.thumbnailUrl}
                                                            channelId={channel.id}
                                                            channelIcon={IconLiveStream({ fill: "#fff" })}
                                                        />
                                                        : <div style={{ width: 480, height: 270 }}></div>
                                                    }
                                                </VizSensor>
                                            )}
                                        </TileRow>
                                    </RowTileParent>
                                    }
                                    {
                                        liveSessions && liveSessions.length > 0 &&
                                        <Row key={index} className="mt-5 pb-5">
                                            <TeamSessionsTemplate
                                                type="session"
                                                sessions={liveSessions}
                                                organizationId={organizationId!}
                                                live={true} />
                                        </Row>
                                    }
                                </>
                            )
                        }

                        /* Upcoming sessions */
                        if (item === DetailSection.UPCOMING_SESSIONS && upcomingSessions?.length) {
                            return (<Row key={index} className="mt-5 pb-5">
                                <TeamSessionsTemplate
                                    type="session"
                                    sessions={upcomingSessions}
                                    organizationId={organizationId!}
                                    past={false} />
                            </Row>
                            )
                        }

                        /* Previous sessions */
                        if (item === DetailSection.PREVIOUS_SESSIONS && previousSessions?.length) {
                            return (<Row key={index} className="mt-5 pb-5">
                                <TeamSessionsTemplate
                                    type="session"
                                    sessions={previousSessions}
                                    organizationId={organizationId!}
                                    past={true}
                                />
                            </Row>
                            )
                        }

                        /* Categories */
                        if (item === DetailSection.CATEGORIES && organization?.categories.length) {
                            return (
                                <Categories key={index} categories={organization.categories} />
                            )
                        }

                        /* Virtual cafes */
                        if (item === DetailSection.VIRTUAL_CAFES && organization) {
                            if (organization.basisPremium === BasisPremiumType.NONE)
                                return null

                            const orgaMeetingRoomGroups = meetingRoomGroups.filter(x => x.organizationId === organization!.id || x.secondOrganizationId === organization!.id)
                            return (
                                orgaMeetingRoomGroups.length !== 0 && <RowTileParent key={index}>
                                    <TileRow
                                        title={strings.organizationDetailPageContent.virtualCafesTitle}
                                        height="220px"
                                        tileRowMargin="0"
                                        type="orgaDetail"
                                        offsetLeft={containerOffsetLeft}
                                        scrollBar
                                        childWidth={500 + 20}
                                        childCount={orgaMeetingRoomGroups.length}
                                    >
                                        {orgaMeetingRoomGroups.map(meetingRoomGroup =>
                                            <MeetingRoomGroup key={meetingRoomGroup.id} source="ORG_DETAIL" accessStatus={(accessList.find((e: UserRestrictedAreaAccess) => e.restrictedAreaId === meetingRoomGroup.id))?.status || " "} meetingRoomGroupConfig={meetingRoomGroup} />
                                        )}
                                    </TileRow>
                                </RowTileParent>
                            )
                        }

                        /* Company Links */
                        if (item === DetailSection.LINKS && props.entity.links?.length) {
                            return (<Row key={index} className="mt-5 pb-5">
                                <Links
                                    organizationId={organizationId}
                                    links={props.entity.links}
                                    tooltipMessage={"Copy to clipboard"}
                                />
                            </Row>
                            )
                        }

                        /* Media */
                        if (item === DetailSection.MEDIA && props.entity.attachments?.length) {
                            return (<Row key={index} className="mt-5 pb-5">
                                <Media
                                    media={props.entity.attachments}
                                    organizationId={organizationId}
                                />
                            </Row>
                            )
                        }

                        /* Contact information */
                        if (item === DetailSection.COMPANY_INFORMATION_TITLE) {
                            return (
                                <Row key={index} className="mt-5 pb-1" style={{ marginLeft: "5px", marginRight: "5px" }}>
                                    <HeaderTitle>{strings.organizationDetailPageContent.companyInformationTitle}</HeaderTitle>
                                </Row>
                            )
                        }

                        /* Contact information */
                        if (item === DetailSection.ADDRESS_PHONE_EMAIL && organization) {
                            return (
                                <div key={index}>
                                    <CompanyAddressPhoneEmail company={organization} />
                                </div>
                            )
                        }

                        /* Founding year, size, industry */
                        if (item === DetailSection.COMPANY_DETAILS && organization) {
                            return (
                                <div key={index} className="mt-5 pb-5">
                                    <CompanyDetails company={organization} />
                                </div>
                            )
                        }

                        /* Parent exhibitor */
                        if (item === DetailSection.PARENT && organization && organization.parent) {
                            const rowData: EntityRowData = organization.parent
                            return <>
                                <div key={index} className="p-0 mb-4">
                                    <HeaderTitle>{strings.organizationDetailPageContent.parentSectionTitle}</HeaderTitle>
                                </div>
                                <DetailPageEntityRow type={"organization"} rowData={rowData} key={rowData.id} />
                            </>
                        }

                        /* Co-Exhibitors */
                        if (item === DetailSection.CO_EXHIBITORS && organization && organization.children && organization.children.length > 0) {
                            return <DetailPageEntityRows key={index} type="organization" headerTitle={strings.organizationDetailPageContent.coExhibitorsSectionTitle} rowsData={organization.children} />
                        }

                        if (item === DetailSection.COUPONS && organization?.coupons?.length) {
                            return <>
                                {
                                    <Row key={index} className="mt-5 pb-5">
                                        <TeamSessionsTemplate
                                            type="coupon"
                                            coupons={organization.coupons}
                                            organizationId={organization.id}
                                        />
                                    </Row>
                                }
                            </>
                        }

                        if (item === DetailSection.PRODUCTS && products?.length && (branding.configuration.detailSectionToggle === false || organization?.showProducts)) {
                            return <DetailPageEntityRows key={index} type="product" headerTitle={strings.sideIconBar.productsSearchDrawerText} rowsData={products} />
                        }

                        if (item === DetailSection.TRADEMARKS && trademarks?.length && (branding.configuration.detailSectionToggle === false || organization?.showTrademarks)) {
                            return <DetailPageEntityRows key={index} type="trademark" headerTitle={strings.sideIconBar.trademarksSearchDrawerText} rowsData={trademarks} />
                        }

                        if (item === DetailSection.ORGANIZATIONS && organizations?.length) {
                            return <DetailPageEntityRows key={index} type="organization" headerTitle={strings.sideIconBar.companiesSearchDrawerText} rowsData={organizations} />
                        }

                        if (item === DetailSection.SHOWCASE && iframe) {
                            return <IframeSection src={iframe} title={organization?.id + "-iframe"} />
                        }



                        return null
                    })
                    }


                </Container>
            </OrganizationDetailPageContentRoot>
        </ContentScrollContainer>
    </>
}

export default DetailPageSections

/* #region Company address, phone, email info */
interface CompanyAddressPhoneEmailProps {
    company: Company
}
const CompanyAddressPhoneEmail: React.FunctionComponent<CompanyAddressPhoneEmailProps> = (props) => {

    const componentDecorator = (href: string, text: string, key: number) => (
        <a style={{ fontWeight: "bold", color: branding.mainInfoColor }} href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );

    return (
        <CompanyAddressPhoneEmailRoot className="d-flex mt-3">
            <CompanyAddresRoot>
                <div className="mr-3">{IconLocation({ fill: branding.sideIconBar.sideIconColorDark })}</div>
                <CompanyAddresInfo>
                    <p>{props.company.name}</p>
                    <p>{props.company.adress1}</p>
                    <p>{props.company.postCode} {props.company.city}</p>
                    <p>{props.company.country}</p>
                </CompanyAddresInfo>
            </CompanyAddresRoot>
            <div>
                {props.company.phone && <div className="d-flex mb-3 align-items-center">
                    <div className="mr-3">{IconPhone({ fill: branding.sideIconBar.sideIconColorDark })}</div>
                    <PhoneEmailParagraph>
                        {props.company.phone}
                    </PhoneEmailParagraph>
                </div>}
                {props.company.email && <div className="d-flex mb-3 align-items-center">
                    <div className="mr-3">{IconEmail({ fill: branding.sideIconBar.sideIconColorDark })}</div>
                    <PhoneEmailParagraph fontWeight={branding.organizationDetailPageContent.companyLinksFontWeight}>
                        <Linkify componentDecorator={componentDecorator}>
                            {props.company.email}
                        </Linkify>
                    </PhoneEmailParagraph>
                </div>}
                {props.company.web && <div className="d-flex align-items-center">
                    <div className="mr-3">{IconLink({ fill: branding.sideIconBar.sideIconColorDark })}</div>
                    <PhoneEmailParagraph fontWeight={branding.organizationDetailPageContent.companyLinksFontWeight}>
                        <Linkify componentDecorator={componentDecorator}>
                            {props.company.web}
                        </Linkify>
                    </PhoneEmailParagraph>
                </div>}
            </div>
        </CompanyAddressPhoneEmailRoot>
    )
}
/* #endregion */

/* #region  Company Detail Info */
const CompanyTeaser = styled.p`
    font-size: 12px;
    font-weight: 400;
    white-space: pre-line;
`

const TalkWithTeamDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
    border: 1px solid ${branding.mainInfoColor};
    border-radius: 5px;
    height: 100px;
    font-size: 16px;
    position: relative;

    @media(max-width: 1600px){
        font-size: 14px;
    }
`

const JoinButtonCol = styled(Col)`
    background: ${branding.mainInfoColor};
    font-family: ${branding.font1};
    border-radius: 25px;
    height: 70%; 
    color: #FFF;
    display: flex; 
    justify-content: center;
    align-items: center; 
    cursor: pointer;
    ${props => props.disabled ? "opacity: 0.5; pointer-events: none;" : ""}
`
const ExplorationPhaseMessage = styled.div`
    position: absolute;
    bottom: 0;
    left: 110px;
    color: rgb(189, 189, 189);
    font-family: ${branding.font1};
    @media(max-width: 1600px){
        left: 80px;
        font-size: 14px;
    }

    @media(max-width: 1400px){
        font-size: 12px;
        left: 50px;
    }
`
interface DetailInfoProps {
    order: InfoContentOrderType[]
    company?: Company
    description?: Description
    pictureURL?: string
    name?: string
    initials?: string
    socialMedias?: SocialMedia[]
}
const DetailInfo: React.FunctionComponent<DetailInfoProps> = (props) => {
    const chime = useChimeContext()
    const meeting = useMeetingContext()
    const strings = useLanguageState().getStrings()
    const loggedInUser = useLoggedInState().user()
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    const queryParams: any = queryString.parse(window.location.search)
    const company = props.company
    let config = getBasisPremiumConfig(props.company?.basisPremium! as BasisPremiumType);
    return (
        <Row>
            <Col xs={2}>
                <CompanyLogoDiv>
                    {props.pictureURL ? <CompanyLogo src={props.pictureURL} alt="" /> : <h2>{props.initials}</h2>}
                </CompanyLogoDiv>
            </Col>
            <Col xs={10}>
                <h3 style={{fontSize: "22px", fontWeight: 800 }}>{props.name}</h3>
                {
                    props.order.map((item, index) => {
                        if (item === InfoContentOrderType.ADDRESS_PHONE_EMAIL && company) {
                            return (
                                <CompanyAddressPhoneEmail key={index} company={company} />
                            )
                        }

                        if (item === InfoContentOrderType.SOCIAL_MEDIA && props.socialMedias?.length) {
                            return <CompanySocialMedia key={index} socialMedias={props.socialMedias} />
                        }

                        if (item === InfoContentOrderType.DESCRIPTION && props.description?.text) {
                            return <CompanyTeaser key={index} className="mt-3">{props.description?.text.split('\\n').map((item, i) => <span key={i}>{item}<br /></span>)}</CompanyTeaser>

                        }

                        if (item === InfoContentOrderType.TALK_WITH_TEAM &&
                            (isExplorationOrPostEventPhase || isLivePhase) && !branding.organizationDetailPageContent.removeExpoShowroomDuringPostEvent &&
                            company && config.expoShowroomEnabled && hasAvailableStaff(company)) {
                            return <TalkWithTeamDiv key={index}>
                                <Col xs={1}>{IconCameraJoin({ width: "30", height: "30", fill: branding.sideIconBar.sideIconColorDark })}</Col>
                                <Col style={{fontSize:"16px", fontWeight:500}} xs={strings.organizationDetailPageContent.joinButton.length > 14 ? 8 : 9}>{strings.organizationDetailPageContent.talkWithTeamBefString} {company.name} {strings.organizationDetailPageContent.talkWithTeamAftString}</Col>
                                <JoinButtonCol xs={strings.organizationDetailPageContent.joinButton.length > 14 ? 3 : 2} onClick={() => showPrivacyPolicyModal(company.id, (accepted) => {
                                    joinShowroom(company, loggedInUser!, chime, meeting)
                                })} disabled={!hasAvailableStaff(company) || (isExplorationOrPostEventPhase && !(getIamPartOf(queryParams) === "onboarding"))}>
                                    <p className="user-select-none" style={{ fontSize: strings.organizationDetailPageContent.joinButton.length > 14 ? "12px" : "16px", marginBottom: "0px" }}>{strings.organizationDetailPageContent.joinButton}</p>
                                </JoinButtonCol>
                                {isExplorationOrPostEventPhase &&
                                    <ExplorationPhaseMessage className="mt-2">{isExplorationPhase ? strings.organizationDetailPageContent.explorationPhasePlaceholder : strings.organizationDetailPageContent.postPhasePlaceholder}</ExplorationPhaseMessage>}
                            </TalkWithTeamDiv>
                        }

                        if (item === InfoContentOrderType.COMPANY_DETAILS && company) {
                            return (
                                <CompanyDetails key={index} company={company} />
                            )
                        }

                        return null
                    })
                }
            </Col>
            <PrivacyModal />
        </Row>
    )
}
/* #endregion */

/* #region  Company social media */

const CompanyAddressPhoneEmailRoot = styled.div`
    user-select: "none";
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: default;
    margin-right: 5px;
`

const CompanyAddresRoot = styled.div`
    display: flex;
    margin-right: 150px;
`

const CompanyAddresInfo = styled.div`
    line-height: 17px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-family: ${branding.font1};
`

const PhoneEmailParagraph = styled.p<{ fontWeight?: string }>`
    font-size: 12px;
    font-family: ${branding.font1};
    margin-bottom: 0px;
    & a {
        font-weight: ${props => props.fontWeight ?? 'normal'}!important;
    }
`

const SocialMediaIcon = styled.div`
    cursor: pointer;

    a{
        color: unset;
        text-decoration: none;
    }
`

interface CompanySocialMediaProps {
    socialMedias: SocialMedia[]
}

const CompanySocialMedia: React.FunctionComponent<CompanySocialMediaProps> = (props) => {

    const facebook: SocialMedia | undefined = props.socialMedias.find((socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.FACEBOOK)
    const twitter: SocialMedia | undefined = props.socialMedias.find((socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.TWITTER)
    const instagram: SocialMedia | undefined = props.socialMedias.find((socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.INSTAGRAM)
    const youTube: SocialMedia | undefined = props.socialMedias.find((socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.YOUTUBE)
    const linkedIn: SocialMedia | undefined = props.socialMedias.find((socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.LINKEDIN)
    const xing: SocialMedia | undefined = props.socialMedias.find((socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.XING)
    const pinterest: SocialMedia | undefined = props.socialMedias.find((socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.PINTEREST)

    function handleLinkClick(link: string, e: React.MouseEvent<HTMLAnchorElement>) {
        if (!link)
            e.currentTarget.removeAttribute("href");
    }
    if (!facebook && !twitter && !instagram && !youTube && !linkedIn && !xing && !pinterest)
        return <></>

    return (
        <div className="d-flex mt-5 mb-5">

            {branding.organizationDetailPageContent.availableSocialMedias.map((item, index) => {
                if (item === SocialMediaType.FACEBOOK) {
                    return <>
                        {facebook && facebook.link && facebook.link.length > 0 &&
                            <SocialMediaIcon key={index} className="mr-5">
                                <a href={facebook.link} onClick={(e) => handleLinkClick(facebook.link, e)} target="_blank" rel="noopener noreferrer">{IconFacebook({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}</a>
                            </SocialMediaIcon>
                        }
                    </>
                }

                if (item === SocialMediaType.TWITTER) {
                    return <>
                        {twitter && twitter.link && twitter.link.length > 0 &&
                            <SocialMediaIcon key={index} className="mr-5">
                                <a href={twitter.link} onClick={(e) => handleLinkClick(twitter.link, e)} target="_blank" rel="noopener noreferrer">{IconTwitter({ width: "20", height: "20", fill: branding.sideIconBar.sideIconColorDark })}</a>
                            </SocialMediaIcon>
                        }
                    </>
                }

                if (item === SocialMediaType.LINKEDIN) {
                    return <>
                        {linkedIn && linkedIn.link && linkedIn.link.length > 0 &&
                            <SocialMediaIcon key={index} className="mr-5">
                                <a href={linkedIn.link} onClick={(e) => handleLinkClick(linkedIn.link, e)} target="_blank" rel="noopener noreferrer">{IconLinkedIn({ width: "20", height: "20", fill: branding.sideIconBar.sideIconColorDark })}</a>
                            </SocialMediaIcon>
                        }
                    </>
                }

                if (item === SocialMediaType.XING) {
                    return <>
                        {xing && xing.link && xing.link.length > 0 &&
                            <SocialMediaIcon key={index} className="mr-5">
                                <a href={xing.link} onClick={(e) => handleLinkClick(xing.link, e)} target="_blank" rel="noopener noreferrer">{IconXing({ width: "20", height: "20", fill: branding.sideIconBar.sideIconColorDark })}</a>
                            </SocialMediaIcon>
                        }
                    </>
                }

                if (item === SocialMediaType.INSTAGRAM) {
                    return <>
                        {instagram && instagram.link && instagram.link.length > 0 &&
                            <SocialMediaIcon key={index} className="mr-5">
                                <a href={instagram.link} onClick={(e) => handleLinkClick(instagram.link, e)} target="_blank" rel="noopener noreferrer">{IconInstagram({ width: "20", height: "20", fill: branding.sideIconBar.sideIconColorDark })}</a>
                            </SocialMediaIcon>
                        }
                    </>
                }

                if (item === SocialMediaType.YOUTUBE) {
                    return <>
                        {youTube && youTube.link && youTube.link.length > 0 &&
                            <SocialMediaIcon key={index} className="mr-5">
                                <a href={youTube.link} onClick={(e) => handleLinkClick(youTube.link, e)} target="_blank" rel="noopener noreferrer">{IconYouTube({ width: "20", height: "20", fill: branding.sideIconBar.sideIconColorDark })}</a>
                            </SocialMediaIcon>
                        }
                    </>
                }

                if (item === SocialMediaType.PINTEREST) {
                    return <>
                        {pinterest && pinterest.link && pinterest.link.length > 0 &&
                            <SocialMediaIcon key={index} className="mr-5">
                                <a href={pinterest.link} onClick={(e) => handleLinkClick(pinterest.link, e)} target="_blank" rel="noopener noreferrer">{IconPinterest({ width: "20", height: "20", fill: branding.sideIconBar.sideIconColorDark })}</a>
                            </SocialMediaIcon>
                        }
                    </>
                }

                return null
            })
            }

        </div>
    )
}

/* #endregion */

/* #region  Founding year, size, industry*/
const CompanyAdditionalInfoDiv = styled.div`
    margin-top: 20px;
    font-size: 16px;
    font-family: ${branding.font1};
`

interface CompanyDetailsProps {
    company: Company

}
const CompanyDetails: React.FunctionComponent<CompanyDetailsProps> = (props) => {
    const strings = useLanguageState().getStrings();
    const company = props.company
    return (
        <>
            {company.foundingYear && <CompanyAdditionalInfoDiv>
                <span>{strings.organizationDetailPageContent.foundingYearTitle}: </span>
                <span>{company.foundingYear}</span>
            </CompanyAdditionalInfoDiv>}

            {company.size && <CompanyAdditionalInfoDiv>
                <span>{strings.organizationDetailPageContent.companySizeTitle}: </span>
                <span>{company.size}</span>
            </CompanyAdditionalInfoDiv>}

            {company.industry && <CompanyAdditionalInfoDiv>
                <span>{strings.organizationDetailPageContent.industryTitle}: </span>
                <span>{company.industry}</span>
            </CompanyAdditionalInfoDiv>}
        </>
    )
}


/* #endregion */


/* #region  Team and session template component */
interface ScrollableDivProps {
    width: string
}
export const ScrollableDiv = styled.div<ScrollableDivProps>`
    max-height: 350px;
    width: ${props => props.width};
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    ::-webkit-scrollbar {
        display: none;
    }
`
const SessionsContentCol = styled(Col)`
    border-bottom: 1px solid ${branding.mainInfoColor};
    padding: 20px 0;
`


interface TeamSessionsTemplateProps {
    team?: Person[]
    sessions?: EventDate[]
    coupons?: any[]
    type: "team" | "session" | "coupon"
    organizationId: string
    organizationName?: string
    onAccessDenied?: () => void,
    past?: boolean
    live?: boolean
}

const TeamSessionsTemplate: React.FunctionComponent<TeamSessionsTemplateProps> = (props) => {
    const strings = useLanguageState().getStrings();
    const scrollRef = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState(0);
    const [value, setValue] = useState(0);
    const maxScrollRange = scrollRef.current?.scrollHeight! - scrollRef.current?.clientHeight!;
    const width = maxScrollRange !== 0 ? "95%" : "100%";
    const [staff, setStaff] = useState<Staff[] | undefined>()
    const userState = useLoggedInState()
    const profileId = userState.user()?.profileId
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()

    let sessions = props.sessions
    const location = useLocation()
    let currentLocation = location.pathname;
    let coupons = props.coupons

    useEffect(() => {
        scrollRef.current?.scrollTo(0, position)
    }, [position])

    useEffect(() => {
        setStaff(props.team?.filter(person => person.visible).map(person => {
            return { firstName: person.firstName, lastName: person.lastName, id: person.id, type: StaffType.PERSON, logoUrl: person.logoUrl || person.userLogoUrl, position: person.position, company: person.organization, sotUserId: person.userId, visible: person.visible, showroomStandby: person.showroomStandby, title: person.title, categories: person.categories }
        }))
    }, [props.organizationId]) //eslint-disable-line

    if ((props.type === "team" && (!props.team || props.team.length === 0)) ||
        (props.type === "session" && (!props.sessions || props.sessions.length === 0)))
        return null

    const staffLength = staff !== undefined ? staff.length : 0
    let headerTitle = props.type === "team" ?
        props.organizationName + " (" + staffLength + ")" :
        (props.type === "session" ? strings.organizationDetailPageContent.upcomingSessionsTitle + " (" + props.sessions!.length + ")" : strings.sideIconBar.goodiebagMenuText)

    if (props.live)
        headerTitle = strings.organizationDetailPageContent.liveSessionsTitle + " (" + props.sessions!.length + ")"

    if (props.past) {
        headerTitle = props.type === "team" ?
            props.organizationName + " (" + staffLength + ")" : strings.organizationDetailPageContent.pastSessionsTitle + " (" + props.sessions!.length + ")"
    }

    return (
        <Col xs={12}>
            <Row style={{ marginRight: "5px", marginLeft: "5px" }}>
                <Col xs={12} className="p-0 mb-4">
                    <HeaderTitle>{headerTitle}</HeaderTitle>
                </Col>
                <ScrollableDiv ref={scrollRef} onScroll={() => setValue(scrollRef.current?.scrollTop!)} width={width}>
                    {staff && staff.map((person, index) => {
                        return <StaffEntry onAccessDenied={() => { if (props.onAccessDenied) { props.onAccessDenied() } }} key={index} staff={person} organizationId={props.organizationId} backOffice={false} />
                    })}

                    {sessions && sessions.map(session => {
                        return (
                            <SessionsContentCol xs={12} key={session.name}>
                                <SessionData session={session} />
                            </SessionsContentCol>
                        )
                    })}
                    {coupons && coupons.map(coupon => {
                        return (
                            <CouponData key={coupon.id} companyId={props.organizationId} {...coupon} onAction={() => {
                                showPrivacyPolicyModal(props.organizationId, (accepted) => {
                                    if(coupon.url !== null && !(coupon.url?.includes(currentLocation))) {
                                    window.open(coupon.url, "_blank")
                                    trackVisit(profileId!, props.organizationId, "COUPON", coupon.title)
                                    }
                                })
                            }} />
                        )
                    })}
                </ScrollableDiv>
                <ScrollBarComponent value={value} maxScrollRange={maxScrollRange ? maxScrollRange : 0} onScroll={(val: number) => { setPosition(val); setValue(val); }} isVertical={true}></ScrollBarComponent>
            </Row>
            <PrivacyModal />
        </Col>
    )
}

const SessionRow = styled(Row)`
    line-height: 30px;
`
const ShareBookmarkDiv = styled.div`
    display: flex; 
    justify-content: flex-end;
    font-size: 20px;
`
const ShareIcon = styled.div`
    cursor: pointer;
    margin-right: 20px;
    color: ${branding.primaryColor}; 
`

interface SessionDataProps {
    session: EventDate
}

const SessionData: React.FunctionComponent<SessionDataProps> = (props) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const lang = languageState.getLanguage()

    const { showRecommendOrganizationModal, OrganizationModal } = useRecommendOrganizationModal()

    function getFormattedDate(date: string, start: string) {
        return format(moment(date).toDate(), strings.eventTiming.eventDaysFormatPatternEvtDetailPage, { locale: lang === 'de' ? germanLocale : null }) + (lang === "de" ? " um " : " at ") + start //have to find a better way to do this but this works for now
    }
    const isLive = isEventDateLive(props.session)

    const categoriesState = useCategoriesState()
    const [pointsBadgeData, setPointsBadgeData] = useState<Category>();

    useEffect(() => {
        if (categoriesState.getCategories().length)
            setPointsBadgeData(categoriesState.getCategoryByAlias(branding.programSchedule.pointsBadgeCategoryAlias))
        // eslint-disable-next-line
    }, [])

    return (
        <DetailNavLink
            name={props.session.name}
            id={isLive && props.session.channel ? props.session.channel.id : props.session.id}
            type={isLive && props.session.channel ? "channel" : "eventdate"}>
            <SessionRow>
                <Col style={{marginTop: "6px"}} xs={1}>{IconVideo({ fill: branding.sideIconBar.sideIconColorDark })}</Col>
                <Col xs={9}>
                    <FormattedDatePar>{props.session.name}</FormattedDatePar>
                    <FormattedDateExtensionDiv>{getFormattedDate(props.session.date, props.session.start)}</FormattedDateExtensionDiv>
                    <FormattedDateExtensionDiv>{props.session.location}</FormattedDateExtensionDiv>
                    {(pointsBadgeData && props.session.categories?.includes(branding.programSchedule.pointsBadgeCategoryAlias)) &&
                        <PointsBadge
                            className={'ml-2'}
                            textColor={pointsBadgeData.colorText}
                            fillColor={pointsBadgeData.colorFill}
                            borderColor={pointsBadgeData.colorBorder} >
                            {strings.programSchedule.pointsBadgeText}
                        </PointsBadge>}
                </Col>
                <Col xs={2}>
                    <ShareBookmarkDiv>
                        <ShareIcon onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            showRecommendOrganizationModal(ShareTargetType.EVENTDATE, props.session.id, buildDetailLink(props.session.id, props.session.name, "eventdate"))
                        }}>
                            {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
                        </ShareIcon>
                        <BookmarkWithToggle newBookmarkItem={true} fontSize={"25px"} color={branding.sideIconBar.sideIconColorDark} stroke={branding.sideIconBar.sideIconColorDark} favIconBasic={true} type="eventdate" id={props.session.id} />
                    </ShareBookmarkDiv>
                </Col>
            </SessionRow>
            <OrganizationModal />
        </DetailNavLink>
    )
}



/* #region Coupon Data */
const CouponDetails = styled.div`
    cursor: pointer;
    padding-left: 10px;
`

const CouponNameLabel = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 1rem;
    margin-top: 2px;
`

const CouponDescriptionLabel = styled.div`
    font-size: 12px;
    line-height: 0.8rem;
`

interface CouponDataProps {
    id: string;
    companyId: string;
    title: string;
    teaser: string;
    description: string;
    url: string;
    onAction: () => void
}

const CouponData: React.FunctionComponent<CouponDataProps> = (props) => {
    const location = useLocation()
    let currentLocation = location.pathname;
    const goToCoupon = () => {
        props.onAction()
    }

    return (
        <SessionsContentCol>
            <SessionRow style={{ pointerEvents: props.url === null || (props.url?.includes(currentLocation)) ? "none" : "auto" }} onClick={() => goToCoupon()}>
                <Col xs={1}>
                    {IconCoupon({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                </Col>
                <Col xs={11}>
                    <CouponDetails>
                        <CouponNameLabel>{props.title}</CouponNameLabel>
                        <CouponDescriptionLabel>{props.teaser || props.description}</CouponDescriptionLabel>
                    </CouponDetails>
                </Col>
            </SessionRow>
        </SessionsContentCol>
    )
}
/* #endregion */

/* #region  Links */
const LinkNameUrl = styled.p`
    font-size: 16px;
    display: inline-block;
    cursor: pointer;
    white-space: normal;
    word-break: break-word;
    margin-bottom: 0px;

    @media (max-width: 1600px) {
        font-size: 16px;   
    }

    &:hover {
        opacity: 0.3;
    }
`
const LinkRow = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${branding.mainInfoColor};
`
const CopyToClipboardDiv = styled.div`
    cursor: pointer;
`

interface LinksProps {
    organizationId?: string
    links: Link[]
    tooltipMessage: string
    colXs?: number
}

export const Links: React.FunctionComponent<LinksProps> = (props) => {
    const strings = useLanguageState().getStrings();
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    const loggedInUserId = useLoggedInState().user()?.profileId
    if (!props.links || props.links.length === 0)
        return null

    return (
        <Col xs={props.colXs ?? 12}>
            <Row className="mb-4" style={{ marginLeft: "5px", marginRight: "5px" }}>
                <HeaderTitle>{props.links.length > 1 ? strings.organizationDetailPageContent.linksTitle + " (" + props.links.length + ")" : "Link (1)"}</HeaderTitle>
            </Row>
            {
                props.links.map((link, i) => <LinkItem link={link} key={i} onCopyLink={() => {
                    if (props.organizationId)
                        showPrivacyPolicyModal(props.organizationId, (accepted) => {
                            navigator.clipboard.writeText(link.url)
                        })
                    else navigator.clipboard.writeText(link.url)
                }}
                    onFollowLink={() => {
                        if (props.organizationId)
                            showPrivacyPolicyModal(props.organizationId, (accepted) => {
                                trackVisit(loggedInUserId!, props.organizationId!, "LINK", link.url)
                                window.open(link.url, "_blank")
                            })
                        else window.open(link.url, "_blank")
                    }}
                    tooltipMessage={props.tooltipMessage} />)
            }
            <PrivacyModal />
        </Col >
    )
}


interface LinkProps {
    link: Link
    tooltipMessage: string
    onCopyLink: () => void
    onFollowLink: () => void
}

const LinkItem: React.FunctionComponent<LinkProps> = (props) => {
    const [text, setText] = useState(props.link.text)
    const [tooltip, setTooltip] = useState(props.tooltipMessage)

    useEffect(() => {
        setText(props.link.text)
    }, [props.link])


    return (
        <LinkRow className="mt-3 pt-3 pb-3" style={{ marginLeft: "5px", marginRight: "5px" }}>
            <Col xs={1} className="p-0">{IconLink({ fill: branding.sideIconBar.sideIconColorDark })}</Col>
            <Col xs={9} onMouseEnter={() => setText(props.link.url)} onMouseLeave={() => setText(props.link.text)} >
                <LinkNameUrl onClick={props.onFollowLink}>{text}</LinkNameUrl>
            </Col>
            <Col xs={2} className="d-flex justify-content-end p-0">
                <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                            {tooltip}
                        </Tooltip>}>
                    <CopyToClipboardDiv onClick={() => {
                        props.onCopyLink()
                        setTooltip('copied')
                    }} onMouseLeave={() =>
                        setTimeout(() => {
                            setTooltip(props.tooltipMessage)
                        }, 500)
                    }>{IconShareLink({ fill: branding.sideIconBar.sideIconColorDark })}</CopyToClipboardDiv>
                </OverlayTrigger>
            </Col>
        </LinkRow>
    )
}

const IframeButtonArea = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
`

const IframeButton = styled.button`
    display: flex;
    width: 150px;
    height: 50px;
    font-size: 14px;
    align-items: center;
    border: 1px solid;
    background: 0;
    justify-content: center;
`
interface IframeSectionProps {
    src: string
    title: string
}

const IframeSection: React.FunctionComponent<IframeSectionProps> = (props) => {

    const strings = useLanguageState().getStrings();
    const iframeRef = useRef<HTMLIFrameElement>(null);

    function openFullscreen() {
        if (iframeRef?.current?.requestFullscreen) {
            iframeRef.current.requestFullscreen();
        } else {
            // this only happens in safari case
            const iframeSafari = iframeRef.current as unknown as HTMLElement & {
                webkitRequestFullscreen(): Promise<void>
            };
            if (iframeSafari?.webkitRequestFullscreen)
                iframeSafari.webkitRequestFullscreen()
        }

        console.log(iframeRef)
    }

    function openInNewTab() {
        window.open(props.src, "_blank")
    }

    return <Row className="mt-5 pb-5 d-flex flex-column" style={{ marginLeft: "5px", marginRight: "5px" }}>
        <HeaderTitle>{strings.organizationDetailPageContent.showcase}</HeaderTitle>
        <iframe id="orgaIframe" ref={iframeRef} className="mt-5" style={{ height: "600px" }} title={props.title} src={props.src} allowFullScreen={true}></iframe>
        <IframeButtonArea className="mx-auto w-50 mt-3">
            <IframeButton onClick={() => openFullscreen()}>{strings.organizationDetailPageContent.fullscreenButton}</IframeButton>
            <IframeButton onClick={() => openInNewTab()}>{strings.organizationDetailPageContent.newTabButton}</IframeButton>
        </IframeButtonArea>
    </Row>
}
/* #endregion */
