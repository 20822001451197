import branding, { EventTiming } from '../branding/branding'
import moment, { Moment } from "moment"
import queryString from 'query-string'

/**
 * @see https://confluence.corussoft.de/display/DEP/Event+Phases
 */
export enum EventPhase {
    EVENT_ANNOUNCEMENT = "EVENT_ANNOUNCEMENT", // the page / domain is ready and shows that there is a fair in the near future 
    TICKET_SALE = "TICKET_SALE", // the ticket shop is open and you can navigate to the store to accquire a ticket but you are not able to log in at the moment
    ONBOARDING = "ONBOARDING", // Privileged persons like exhibitor staff and speakers get access to the login screen by using a special url and are guided through the functionalities which are relevant for them.
    EXPLORATION = "EXPLORATION", // the login is open so you are able to log in. Inside of the application you have limited functionality (live feature are deactivated but you see placesholders / coming soon phrases)
    SOFT_OPENING = "SOFT_OPENING", // all functionalities except the live streams are available.
    LIVE = "LIVE", // the real shit happens
    POST_EVENT = "POST_EVENT" // the app provides video on demand content and community features
}


export interface EventState {
    phase: EventPhase,
}

const timeTravelLocalStorageKey = "eventGuide-timeTravel"
const forcePhaseLocalStorageKey = "eventGuide-forcePhase"

// let eventNowStart: Moment | undefined = undefined
// let clientNowRef: Moment | undefined = undefined

let timeTravelAnchor: Moment | undefined = undefined
let timeTravelOffset: number | undefined = undefined

function calcCurrentEventPhase(eventTiming: EventTiming): EventPhase {
    const queryParams: any = queryString.parse(window.location.search)
    const forcePhase = getForcePhase(queryParams)
    if(forcePhase) {
        return forcePhase
    }

    const ticketShopOpenMoment = moment(eventTiming.ticketShopOpenDateTime)
    const onboardingOpenMoment = moment(eventTiming.onboardingOpenDateTime)
    const loginOpenMoment = moment(eventTiming.loginOpenDateTime)
    const softOpeningMoment = moment(eventTiming.softOpeningDateTime)
    const eventStartMoment = moment(eventTiming.eventStartDateTime)
    const eventEndMoment = moment(eventTiming.eventEndDateTime)

    let now = getNowFromQueryParams(queryParams)

    if (now.isBefore(ticketShopOpenMoment)) {
        return EventPhase.EVENT_ANNOUNCEMENT
    } else if (now.isBefore(onboardingOpenMoment)) {
        return EventPhase.TICKET_SALE
    } else if (now.isBefore(loginOpenMoment)) {
        return EventPhase.ONBOARDING
    } else if (now.isBefore(softOpeningMoment)) {
        return EventPhase.EXPLORATION
    } else if (now.isBefore(eventStartMoment)) {
        return EventPhase.SOFT_OPENING
    } else if (now.isBefore(eventEndMoment)) {
        return EventPhase.LIVE
    } else {
        return EventPhase.POST_EVENT
    }
}


function getForcePhase(queryParams: any) : EventPhase | undefined {
    try {
        if (queryParams.force_phase) {
            const forcePhase = queryParams.force_phase as EventPhase
            if(Object.values(EventPhase).includes(forcePhase)) {
                localStorage.setItem(forcePhaseLocalStorageKey, forcePhase)
                return forcePhase
            } else {
                localStorage.removeItem(forcePhaseLocalStorageKey)
            }
        }

        const forcePhaseString = localStorage.getItem(forcePhaseLocalStorageKey)
        
        if(!forcePhaseString)
            return undefined
        const forcePhase = forcePhaseString as EventPhase
        return Object.values(EventPhase).includes(forcePhase) ? forcePhase : undefined
    } catch (securityError) {
        // No Cookies! Oh noes! Nothing works!
        return undefined
    }
}

export function getNow() {
    const queryParams: any = queryString.parse(window.location.search)
    const timeTravelNow = getNowFromQueryParams(queryParams)
    if (!timeTravelAnchor) {
        timeTravelAnchor = timeTravelNow
        timeTravelOffset = timeTravelNow.diff(moment())
    }
    return moment().add(timeTravelOffset)
}

function getNowFromQueryParams(queryParams: any) {
    if (queryParams.time_travel) {
        const targetTime = moment(queryParams.time_travel)
        if (targetTime.isValid()) {
            try {
                localStorage.setItem(timeTravelLocalStorageKey, targetTime.format())
            } catch (securityError) {
                // No Cookies! Oh noes! Nothing works!
            }
            return targetTime
        } else {
            localStorage.removeItem(timeTravelLocalStorageKey)
        }
    }

    let storedTargetTimeString;
    try {
        storedTargetTimeString = localStorage.getItem(timeTravelLocalStorageKey)
    } catch (securityError) {
        // No Cookies! Oh noes! Nothing works!
    }

    if (storedTargetTimeString) {
        const storedTargetTime = moment(storedTargetTimeString)
        if (storedTargetTime.isValid()) {
            return storedTargetTime
        } else {
            localStorage.removeItem(timeTravelLocalStorageKey)
        }
    }

    return moment()
}

export const eventState: EventState = { phase: calcCurrentEventPhase(branding.eventTiming) }

export default eventState
