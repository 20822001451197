import { createState, useState, self } from '@hookstate/core';
import { Category } from '../backendServices/Types';



export interface CategoryState {
    categories: Array<Category>
}

export const useCategoriesState = createState({} as CategoryState)[self].map(s => () => {
    const state = useState(s)
    return ({
        setCategoriesState: (newState: { categories: Array<Category>}) => {
            state[self].set(newState)
        },
        getCategories: (): Array<Category> => {
            const categories = state[self].get().categories
            return categories && categories.length > 0 ? categories : []
        },
        getCategoryByAlias: (alias: string): Category | undefined => {
            const categories = state[self].get().categories
            return categories && categories.length > 0 ? categories.find(x => x.alias === alias) : undefined
        },
    })
})