// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useChimeContext } from '../context/ChimeContext';
import { useLanguageState } from '../../globalStates/LanguageState';
import { IconClose } from '../../ui/Icons';
import { meetingPageRoute } from '../../navigationArea/RoutePaths';
import { useRouteMatch } from 'react-router-dom';

const RemainingTimeRoot = styled.div<{$isMeetingPage: boolean}>`
  margin: ${props => props.$isMeetingPage ? '22px 0 0 0' : 'initial'};
  position: absolute;
  /* position: ${props => props.$isMeetingPage ? 'initial' : 'absolute'}; */
  bottom: ${props => props.$isMeetingPage ? 'auto' : '42px'};
  width: ${props => props.$isMeetingPage ? '100%' : 'inherit'};
  text-indent: ${props => props.$isMeetingPage ? '25px' : '11px'};
  color: ${props => props.$isMeetingPage ? '#fff' : '#212529'};
`

const TimeLine = styled.div<{ $length: number, $color: string, $isMeetingPage: boolean }>`
  margin-top: 5px;
  width: 100%;
  height: 5px;
  background-color: #ffffff4c;
  position: ${props => props.$isMeetingPage ? 'absolute' : 'initial'};
  bottom: ${props => props.$isMeetingPage ? '43px' : 'none'};

  &:before {
    content: '';
    display: block;
    width: ${props => props.$length}%;
    height: 5px;
    background-color: ${props => props.$color}
  }
`

const AlertPopup = styled.div<{$isMeetingPage: boolean}>`
  position: absolute;
  top: ${props => props.$isMeetingPage ? '-80px' : '74px'};
  box-shadow: ${props => props.$isMeetingPage ? 'none' : '0px 5px 15px 1px rgba(0, 0, 0, 0.4)'};
  text-indent: 0;
  width: ${props => props.$isMeetingPage ? 'initial' : 'inherit'};
  height: ${props => props.$isMeetingPage ? 'initial' : '56px'};
  padding: ${props => props.$isMeetingPage ? '10px 40px 10px 20px' : '7px 40px 10px 20px'};

  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 6px;
`
const AlertClose = styled.div`
  position: absolute;
  right: 10px;
  top: 9px;
  cursor: pointer;
`

export default function RemainingTime() {
  const chime = useChimeContext()
  const strings = useLanguageState().getStrings()
  const [alertVisible, setAlertVisible] = useState(true)
  const isOnMeetingPage = useRouteMatch(meetingPageRoute)!==null;

  if ((chime.getKind() !== "call" && chime.getKind() !== "calenderEntry") || !chime.getTimeLeft() || !chime.getMaxDuration())
    return null

  const secondsLeft = chime.getTimeLeft()!
  const remainingMinutes = Math.floor(secondsLeft / 60)
  const remainingSeconds = Math.floor(secondsLeft % 60)
  const remainingPercent = 100 - (secondsLeft / chime.getMaxDuration()! * 100)
  const color = secondsLeft < 60 ? "red" : "green"
  // eslint-disable-next-line
  const text = strings.conferenceTexts.timerRemaining.replace("${minutes}", "" + remainingMinutes).replace("${seconds}", remainingSeconds < 10 ? "0" + remainingSeconds : "" + remainingSeconds)
  // eslint-disable-next-line
  const alertText = strings.conferenceTexts.timerUpAlert.replace("${seconds}", "" + remainingSeconds)
  
  return (
    <RemainingTimeRoot $isMeetingPage={isOnMeetingPage}>
      {text}
      <TimeLine $length={remainingPercent} $color={color} $isMeetingPage={isOnMeetingPage}/>
      {secondsLeft < 60 && alertVisible && <AlertPopup $isMeetingPage={isOnMeetingPage}>

        {alertText}
        <AlertClose onClick={(event) => {
          setAlertVisible(false);
          event.stopPropagation();
          event.preventDefault();
          }}>
          {IconClose}
        </AlertClose>

      </AlertPopup>}
    </RemainingTimeRoot>
  );
}
