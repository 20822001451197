import React from "react"
import { Button, Modal } from "react-bootstrap"
import styled from "styled-components"
import branding from "../branding/branding"
import { useLanguageState } from "../globalStates/LanguageState"

const DeleteModal = styled(Modal)`
    background-color: rgba(0,0,0,0.3);
`

const DeleteButton = styled(Button)`
    margin-left: 16px;
    border-radius: 20px;
    font-size: 12px;
    
`
const DeleteMeetingTitle = styled.span`
 font-family: ${branding.font1};
 color: ${branding.mainInfoColor ?? "#000"};
`
const DeleteMeetingRoot = styled.div`
 font-family: ${branding.font1};
 color: ${branding.mainInfoColor ?? "#000"};
 `

interface DeleteCalendarEntryModalProps {
    type: "cancel" | "decline"
    backdrop: string
    animation: boolean
    show: boolean
    deleteCalendarEntry: () => void
    setShowConfirmationDialog: (show: boolean) => void
}

const DeleteCalendarEntryModal: React.FC<DeleteCalendarEntryModalProps> = (props) => {

    const strings = useLanguageState().getStrings()

    const modalTitle = props.type === "cancel" ? strings.calendarEntryModalPageContent.deleteMeeting : strings.calendarEntryListView.declineButtonTitle

    const modalText = props.type === "cancel" ? strings.calendarEntryModalPageContent.cancelText : strings.calendarEntryListView.declineMeetingText

    const modalButtonTitle = props.type === "cancel" ? strings.calendarEntryModalPageContent.deleteMeeting : strings.calendarEntryListView.declineTitle

    return <DeleteModal
        centered
        backdrop={props.backdrop}
        animation={props.animation}
        show={props.show}>
        <Modal.Header>
            <Modal.Title>
                <DeleteMeetingTitle>{modalTitle}</DeleteMeetingTitle>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body><DeleteMeetingRoot>{modalText}</DeleteMeetingRoot></Modal.Body>
        <Modal.Footer>
            <DeleteMeetingRoot>
                <DeleteButton variant="outline-dark" onClick={() => { props.setShowConfirmationDialog(false) }}>
                    {strings.calendarEntryModalPageContent.cancel}
                </DeleteButton>
                <DeleteButton variant="danger" style={{ backgroundColor: branding.dangerButtonColor }} onClick={() => props.deleteCalendarEntry()} >
                    {modalButtonTitle}
                </DeleteButton>
            </DeleteMeetingRoot>
        </Modal.Footer>
    </DeleteModal>
}



export default DeleteCalendarEntryModal        