
import React, { useEffect, useState } from 'react';
import { Modal, Button as button } from 'react-bootstrap';
import styled from 'styled-components/macro';
import useSound from 'use-sound';
import { HookOptions } from 'use-sound/dist/types';
import { MeetingParticipant } from '../backendServices/BackendServices';
import { IconEndCall, IconAcceptCall, IconCalendarEntry } from '../ui/Icons';
import { Button } from './components/Button';
import { useMeetingContext } from './context/MeetingContext';
import branding from "../branding/branding";
import { MeetingStatusCode, useChimeContext } from './context/ChimeContext';
import { useLanguageState } from '../globalStates/LanguageState';
import { useLoggedInState } from '../globalStates/LoggedInUser';

const CallRowsRoot = styled.div`
position: absolute;
top: 10px;
right: 10px;
z-index: 1100;
`

interface HailingOverlayProps {
  audioFileFormat: string
}

export default function HailingOverlay(props: HailingOverlayProps) {
  const loggedInUser = useLoggedInState()
  const profileId = loggedInUser.user()?.profileId
  const [play, { stop }] = useSound(
    "/ringing." + props.audioFileFormat,
    {
      volume: 0.2,
      interrupt: true,
      loop: true,
    } as HookOptions // Typed, because HookOptions does not expose all underlying options https://github.com/goldfire/howler.js#options
  );
  const meeting = useMeetingContext()
  const outgoingInvites = meeting.getOutgoingInvites()
  const incomingInvites = meeting.getIncomingInvites()
  const numInvites = incomingInvites.length + outgoingInvites.length

  useEffect(() => {
    if (profileId)
      meeting.subscribeToCalls(profileId)
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (numInvites > 0)
      play()
    else
      stop()
  }, [numInvites]); // eslint-disable-line react-hooks/exhaustive-deps

  if (numInvites <= 0)
    return null

  return (<CallRowsRoot>
    {outgoingInvites.map((invite) => <CallRow key={invite.id} invite={invite} />)}
    {incomingInvites.map((invite) => <CallRow key={invite.id} invite={invite} incoming={true} />)}
  </CallRowsRoot>)
}

const CallEntryRoot = styled.div`
display: flex;
flex-flow: row;
justify-content: initial;
align-items: center;
height: 80px;
width: 320px;
padding: 15px 20px;
cursor: pointer;
color: #fff;
background-color: #000;
border-bottom: 2px solid #fff;
margin-bottom: 10px;
border-radius: 5px;

& > :nth-child(1) {
  min-width: 40px;
}

& > :nth-child(3) {
  flex-grow: 1;
  padding: 0 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
`
const Text = styled.div`
flex-grow: 1;
`
const CallType = styled.div`
color: #808080;
font-family: ${branding.font1};
font-style: normal;
font-weight: 300;
font-size: 10px;
line-height: 12px;
`

const Name = styled.div`
font-family: ${branding.font1};
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 17px;
color: #FFFFFF;    
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`

const ButtonWrapper = styled.div<{ wide?: boolean }>`
display: flex;
flex-flow: row;
justify-content: center;
width: ${props => props.wide ? "100px" : "45px"};
text-align: right;

& button {
  width: 40px;
  height: 40px;
  margin: 0;

  &:nth-child(2) {
    margin-left: 20px;
  }
}
`
const MainModal = styled(Modal)`
.modal-header {
display: inline-flex;
border-bottom: none;
}

.modal-body{
font-size: 17px;
display: flex;
}

.modal-footer {
  justify-content: space-between;
  border: none;
}

.close{
outline: 0;
position: absolute;
right: 20px;
top: 15px;
font-size: 46px;
font-weight: 10;
line-height: 1.66666667;
color: #000;
width: 30px;
padding: 0 2px;
background: none;
border-style: none;
margin-right: 1px;
margin-top: -16px;
}


.btn-primary  {
width: 40%;
display: inline-block;
/* color: white !important; */
/* background-color: #000; */
/* border: 2px solid black; */
border-radius: 20px;

    /* :hover {
    background-color: #e8e8e8 !important; 
    color: black !important;
    } */
}

.btn-secondary  {
  display: inline-block;
  /* color: black !important;
  background-color: white; */
  border: none;
  /* border-color: white;
  border-radius: none; */
  }
`
const TitleImage = styled.div`
  font-size: 30px;
`
const Title = styled.div`
  margin-left: 20px;
  margin-top: 9px;
  font-size: 22px;
`
// const CancelButton = styled.div`
//     width: 60px;
//     height: 40px;
//     justify-content: left;
//     font-size: 18px;
//     border: none;
//     background: white;
//     color: #FFF;
//     border-radius: 25px;
//     cursor: pointer;
// `
// const ConfirmButton = styled.div`
//     width: 200px;
//     height: 40px;
//     cursor: pointer;
//     font-size: 18px;

// `
const CancelButton = styled.div`
  width: 30%;
  height: 30px;
  cursor: pointer;
  font-size: 12px;
  font-family: ${branding.font1};
  color: ${branding.primaryColor};
  
  &:hover{
      text-decoration: none;
  }
`
const ConfirmButton = styled(button)`
  width: 40%;
  height: 30px;
  color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
  background-color: ${branding.sayHelloModal.submitBtnPrimaryBgColor}!important;
  border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor}!important;
  border-radius: 20px;
  font-size: 12px;
  font-family: ${branding.font1};

  :hover {
      background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor} !important;
      color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor} !important; 
  }
`
interface CallRowProps {
  invite: MeetingParticipant
  incoming?: boolean
}
function CallRow(props: CallRowProps) {
  const [showConfirmCall, setShowConfirmCall] = useState(false)
  const chime = useChimeContext()
  const meeting = useMeetingContext()
  const strings = useLanguageState().getStrings()

  const { invite, incoming } = props
  const inviter = props.incoming ? invite.inviter : invite.invitee

  return <CallEntryRoot key={invite.id}>
    <Text>
      <CallType>{incoming ? strings.conferenceTexts.incomingCall : strings.conferenceTexts.outgoingCall}</CallType>
      <Name>{inviter.name}</Name>
    </Text>
    <ButtonWrapper wide={incoming}>
      {incoming && <Button icon={IconAcceptCall({ fill: "#fff" })} tooltipPlacement="bottom" tooltip={strings.conferenceTexts.acceptCall} color="#fff" backgroundColor="#00B300" onClick={() => {
        if (chime.getMeetingStatus().meetingStatus === MeetingStatusCode.Succeeded)
          setShowConfirmCall(true)
        else {
          meeting.acceptInvite(invite.id)
          window.sessionStorage.setItem("currentTabCall", invite.id)   
        }
      }} />}
      {incoming && <Button icon={IconEndCall({ fill: "#fff" })} tooltipPlacement="bottom" tooltip={strings.conferenceTexts.declineCall} color="#fff" backgroundColor="red" onClick={() => meeting.declineInvite(invite.id)} />}
      {!incoming && <Button icon={IconEndCall({ fill: "#fff" })} tooltipPlacement="bottom" tooltip={strings.conferenceTexts.cancelCall} color="#fff" backgroundColor="red" onClick={() => meeting.cancelInvite(invite.id)} />}
    </ButtonWrapper>
    {props.incoming && showConfirmCall && <MainModal
      show={true}
      onHide={() => setShowConfirmCall(false)}
      backdrop="static"
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ display: "inline-flex" }}>
          <TitleImage>{IconCalendarEntry({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}</TitleImage>
          <Title>{strings.conferenceTexts.changingRoomConfirmationTitle}</Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{strings.conferenceTexts.changingRoomConfirmationText}</Modal.Body>
      <Modal.Footer>
        <CancelButton onClick={() => setShowConfirmCall(false)} className="d-flex align-items-center">{strings.globalTexts.cancel}</CancelButton>
        {/* <CancelButton>
        <BootstrapButton variant="secondary" onClick={() => setShowConfirmCall(false)}>
          {strings.globalTexts.cancel}
        </BootstrapButton>
      </CancelButton> */}
        <ConfirmButton type="submit" onClick={() => {
          chime.setIsMeetingChangeAccepted(true)
          meeting.acceptInvite(invite.id)
          setShowConfirmCall(false)
        }} className="d-flex align-items-center justify-content-center">
          {strings.conferenceTexts.changingRoomConfirmationAccept ?? strings.globalTexts.confirm}
        </ConfirmButton>
        {/* <ConfirmButton>
        <BootstrapButton variant="primary" onClick={() => {
          chime.setIsMeetingChangeAccepted(true)
          meeting.acceptInvite(invite.id)
          setShowConfirmCall(false)
        }}>
          {strings.conferenceTexts.changingRoomConfirmationAccept ?? strings.globalTexts.confirm}
        </BootstrapButton>
      </ConfirmButton> */}
      </Modal.Footer>
    </MainModal>
    }
  </CallEntryRoot>
}