/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://rtahf6mghvarzjn5zez4geoz2i.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_RiXbb5Q1U",
    "aws_user_pools_web_client_id": "6llbl7bvi5732tco4e7qq0roj5",
    "oauth": {}
};


export default awsmobile;
