import React, { useCallback, useEffect, useRef, useState } from "react"
import { ConversationListEntry, correctUnreadCounterConversations, loadConversationListEntries, loadConversationListEntry } from "../backendServices/BackendServices";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import { useAppState } from "../globalStates/AppState";
import { ConversationEntry } from "./ConversationEntry";
import { ChatConversationParam } from "./ChatPage";
import { API, graphqlOperation } from "aws-amplify";
import Observable from 'zen-observable-ts'
import { useLanguageState } from "../globalStates/LanguageState";
import { ConversationType, OnUserConversationUpdatedLightSubscription, OnUserConversationDeletedLightSubscription } from "../API";
import { onUserConversationDeletedLight, onUserConversationUpdatedLight } from "../graphql/ownSubscriptions";
import ConversationCreationPage, { StartNewChatButton } from "./ConversationCreation";
import { NextPageLoader } from "./CommunicationArea";
import styled from "styled-components";
import VisibilitySensor from "react-visibility-sensor"
import ScrollContainer from "../ui/ScrollContainer";
import GuestModal from "../ui/GuestModal";
import branding from "../branding/branding";

const ListContainer = styled.div`
    /* display: flex;
    flex-direction: column;
    padding-top: 5px;
    height: calc(100% - 60px); /* -- height of tab switchers */
    /* overflow-x: hidden;
    background: blanchedalmond; */

    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: auto !important;
    padding: 0px 0 0px 0;
    width: 100%;
    max-width: 100%;
`

interface ConversationListContentProps {
    conversations: number
}

const ConversationListContent: React.FunctionComponent<ConversationListContentProps> = (props) => {

    const appState = useAppState()
    const profileId = useLoggedInState().user()!.profileId
    const userState = useLoggedInState()
    const languageState = useLanguageState()
    const strings = languageState.getStrings()

    const [showNewChatPage, setShowNewChatPage] = useState<boolean>(false)
    const [conversations, setConversations] = useState<ConversationListEntry[]>([])
    const [nextPageToken, setNextPageToken] = useState<string | undefined>()
    const [needsReload, setNeedsReload] = useState<boolean>(true)

    const [showGuestModal, setShowGuestModal] = useState<boolean>(false)

    const loadNextPage = async () => {
        const result = await loadConversationListEntries(profileId, branding.configuration.calendarEntryParticipantLimit, nextPageToken);
        if (result) {
            const [loadedConversations, nextToken] = result
            
            setNextPageToken(nextToken)
            setConversations(oldConvos => oldConvos.concat(loadedConversations ?? []))
            setNeedsReload(false)
        }
    }

    function resetList() {
        setConversations([])
        setNextPageToken(undefined)
        setNeedsReload(true)
    }

    function countUnreadConversations(conversations: ConversationListEntry[]) : number {
        var unreadCount = 0

        conversations.forEach(conversation => {
            if (!conversation.lastReadTime || conversation.lastReadTime < conversation.lastMessageTime)
                unreadCount++
        })

        return unreadCount
    }

    useEffect(() => {
        if (conversations.length > 0) {
            const unreadCount = countUnreadConversations(conversations)
            
            if ((nextPageToken && props.conversations < unreadCount) || (!nextPageToken && props.conversations !== unreadCount)) {
                const secureDifference = unreadCount - props.conversations

                if (secureDifference !== 0) {
                    correctUnreadCounterConversations(profileId, secureDifference)
                }
            }
        }
    },[props.conversations, conversations.length]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (needsReload) {
            loadNextPage()
        }
    }, [profileId, needsReload]) // eslint-disable-line react-hooks/exhaustive-deps

    const onConversationsUpdatedHandler = useCallback(async (data: OnUserConversationUpdatedLightSubscription) => {
        const conversation = data.onUserConversationUpdated?.conversation
        if (!conversation || !conversation.mostRecentMessageCreatedAt) { // mostRecentMessageCreatedAt is set by the lambda and for newly created conversations initially null (lambda not finished executing yet)
            return
        }
        const updatedConversations = [...conversations]
        const existingConvIndex = updatedConversations.findIndex(conv => conv.id === conversation.id)
        if (existingConvIndex >= 0) {
            const [existingConv] = updatedConversations.splice(existingConvIndex, 1)

            const updatedConversation = {
                id: existingConv.id,
                type: existingConv.type,
                userConversationId: existingConv.userConversationId,
                isMuted: existingConv.isMuted,
                title: existingConv.type === ConversationType.PRIVATE ? existingConv.title : conversation.name ?? undefined,
                opponentIds: existingConv.opponentIds,
                pictureUrls: existingConv.pictureUrls,
                opponentNames: existingConv.opponentNames,
                lastMessage: conversation.mostRecentMessage ?? undefined,
                lastMessageTime: new Date(conversation.mostRecentMessageCreatedAt)
            }
            updatedConversations.splice(existingConvIndex, 0, updatedConversation)
        } else {
            const newConversation = await loadConversationListEntry(profileId, conversation.id, branding.configuration.calendarEntryParticipantLimit)
            if (newConversation) {
                updatedConversations.unshift(newConversation)
            } else {
                resetList()
                return
            }
        }
        updatedConversations.sort((a, b) => {
            if (a.lastMessageTime < b.lastMessageTime) {
                return 1
            }
            if (b.lastMessageTime < a.lastMessageTime) {
                return -1
            }
            return 0
        })
        setConversations(updatedConversations)
    }, [profileId, conversations]) // eslint-disable-line react-hooks/exhaustive-deps

    const onConversationsUpdatedHandlerRef = useRef(onConversationsUpdatedHandler)
    onConversationsUpdatedHandlerRef.current = onConversationsUpdatedHandler

    useEffect(() => {
        const onUserConversationUpdatedHandle = (API.graphql(graphqlOperation(onUserConversationUpdatedLight, { userId: profileId })) as Observable<any>).subscribe({
            next: (resp: any) => {
                onConversationsUpdatedHandlerRef.current(resp.value.data)
            }
        })
        return () => onUserConversationUpdatedHandle.unsubscribe()
    }, [profileId])

    useEffect(() => {
        const onDeleteUserConversationHandle = (API.graphql(graphqlOperation(onUserConversationDeletedLight, { userId: profileId })) as Observable<any>).subscribe({
            next: (resp: any) => {
                const data = resp.value.data as OnUserConversationDeletedLightSubscription
                const conversationId = data.onUserConversationDeleted?.conversationId
                setConversations(convos => convos.filter(convo => convo.id !== conversationId))
            }
        })
        return () => onDeleteUserConversationHandle.unsubscribe()
    }, [profileId])

    const showChat = (conversation: ConversationListEntry) => {
        const param = ChatConversationParam.conversationByConversationId(conversation.type, conversation.id)
        appState.setShowChatsTab(param)
    }

    if (showNewChatPage) {
        return <ConversationCreationPage close={() => setShowNewChatPage(false)} />
    }


    return (
        <>
            <StartNewChatButton title={strings.communicationArea.chatStartNewChat} onClick={() => {
                if (userState.user() && userState.user()?.type === "guest" && userState.user()?.invitingOrganization) {
                    setShowGuestModal(true)
                    return
                }
                setShowNewChatPage(true)
            }} />
            <ScrollContainer>
                <ListContainer>
                    {conversations.map((conversation) => {
                        const defaultName = conversation.type === ConversationType.CALL ? strings.chatBranding.callChatDefaultName : conversation.type === ConversationType.GROUP ? strings.chatBranding.groupChat : undefined
                        let defaultTitle = defaultName ? defaultName + " (" + (conversation.opponentIds.length + 1) + ")" : ""
                        return (
                            <ConversationEntry title={conversation.title ?? defaultTitle}
                                key={conversation.id}
                                conversationType={conversation.type}
                                opponentIds={conversation.opponentIds}
                                logoUrls={conversation.pictureUrls}
                                altNames={conversation.opponentNames}
                                lastMessage={conversation.lastMessage}
                                lastMessageTime={conversation.lastMessageTime}
                                isMuted={conversation.isMuted}
                                userConversationId={conversation.userConversationId}
                                onClick={() => showChat(conversation)} />
                        );
                    })}
                    {nextPageToken && (
                        <VisibilitySensor
                            partialVisibility={true}
                            onChange={(isVisible) => {
                                if (isVisible) loadNextPage();
                            }}
                        >
                            <NextPageLoader />
                        </VisibilitySensor>
                    )}
                </ListContainer>
            </ScrollContainer>
            {showGuestModal && <GuestModal close={() => setShowGuestModal(false)} />}
        </>
    );
}


export default ConversationListContent
