import React, { useEffect, useState, Fragment } from "react"
import { TabEmpty, TabRoot, SubTabRoot, MultiSwitchRoot, NextPageLoader, NetworkingListType, SearchBarWidth } from "./CommunicationArea"
import { ContactEntry, ContactEntryType } from "./ContactEntry"
import styled from "styled-components"
import CenteredLoader from "../ui/CenteredLoader"
import CrsMultiSwitch from "../ui/CrsMultiSwitch"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useLanguageState } from "../globalStates/LanguageState"
import { useAppState } from "../globalStates/AppState"
import SearchBar from "../ui/SearchBar"
import InfiniteScroll from "react-infinite-scroll-component"
import NetworkingTabErrorBoundary from "../contentArea/errorPages/NetworkingTabErrorBoundary"
import {
    ContactItem,
    loadContactListData,
    loadContactRequestListData,
    loadRelevantProfilesListData,
    BackendServiceError,
    ContactListResponse,
    loadContactBlockedtListData,
    exportContactsAsCsv,
    exportContactsAsVCard
} from "../backendServices/BackendServices"
import branding from '../branding/branding'
import { useFavoriteState } from "../globalStates/Favorites"
import { Button } from "../conference/components/Button"
import { IconDownload } from "../ui/Icons"
import { useContactState } from "./ContactState"
import OrganizationEntry from "./OrganizationEntry"
import * as _ from "lodash"
import { defaultLogger as logger } from "../globalStates/AppState"
import { detect } from "detect-browser"
import NetworkingToggle from "./components/NetworkingToggle"

const SearchBarRootArea = styled.div`
  & .MuiIconButton-root:focus {
    outline: none;
  }

  & .MuiIconButton-root:hover {
    background: none;
  }
  

  .MuiInput-underline:after {
    border-bottom: 1px solid;
    border-color: ${branding.mainInfoColor ?? "black"};
  } 
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid;
    border-color: ${branding.mainInfoColor ?? "black"};
 }
`;

const SearchBarRoot = styled.div`
  text-align: center;
  margin-bottom: 5px;
  .MuiInputBase-input{
      font-size: 14px;
      font-family: ${branding.font1};
  }
  #searchIcon svg{
    color: ${branding.primaryColor ?? "black"};
  }
`;

const DownloadIconsRoot = styled.div`
    display: flex;
    justify-content: flex-end; 
    align-items: center; 
    margin: 10px 20px 0 15px;
`

const TabContentArea = styled.div`
  margin-top: 15px;
  width: 100%;
`;

const TabSectionHeader = styled.div`
  margin-left: 15px;
  margin-top: 25px;
  margin-bottom: 8px;
  margin-right: 20px;
  font-size: 14px;
  font-family: ${branding.font1};
  color: ${branding.mainInfoColor ?? "black"};
  display: flex;
  justify-content: space-between;
`;

const TabActivateNetworking = styled.div`
  padding-top: 30px;
  margin-left: 7%;
  margin-right: 7%;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family:${branding.font1};
  font-size: 15px;
`;


export interface badgeWrapperProps {
    xPos: number
}
const BadgeContentArea = styled.div<badgeWrapperProps>`
    position: absolute;
    top: -15px;
    left: ${props => props.xPos}px;
    z-index: 99;
`
const BadgeUnreadCounter = styled.span`
  position: relative;
  background-color: ${branding.communicationArea.badgeUnreadCounterBgColor ?? "#000"};
  border-radius: 50%;
  margin-left: 1px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${branding.communicationArea.badgeUnreadCounterBorderColor ?? "#fff"};
  font-size: 10px;
`
const BadgeRow = styled.div`
    display: flex;
    position: relative;
`


interface BadgeTabsProps {
    badgeParams: { x: number, count: number }[]
}
const BadgeTabs: React.FunctionComponent<BadgeTabsProps> = (props) => {

    return (
        <BadgeRow>{
            props.badgeParams.map((params, idx) => {
                return (
                    <BadgeContentArea key={idx} xPos={params.x}>
                        {(params.count <= 0) ? null : <h6 style={{ fontSize: "14px" }}><BadgeUnreadCounter className="badge badge-pill badge-dark">{params.count}</BadgeUnreadCounter></h6>}
                    </BadgeContentArea>
                )
            })}
        </BadgeRow>
    )
}
interface NetworkingTabProps {
    requests: number
    contacts: number
}
const NetworkingTab: React.FunctionComponent<NetworkingTabProps> = (props) => {
    const appState = useAppState()
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const itemList = [{ label: strings.communicationArea.allPeopleTabTitle, value: NetworkingListType.RELEVANT }, { label: strings.communicationArea.requestsTabTitle, value: NetworkingListType.REQUESTS }, { label: strings.communicationArea.contactsTabTitle, value: NetworkingListType.CONTACTS }]
    const selectedItem = appState.communicationCenterDisplayParam ?? NetworkingListType.RELEVANT

    const errorMessage = selectedItem === NetworkingListType.RELEVANT ? strings.communicationArea.relevantListErrorMessage :
        selectedItem === NetworkingListType.REQUESTS ? strings.communicationArea.requestListErrorMessage :
            selectedItem === NetworkingListType.CONTACTS ? strings.communicationArea.contactListErrorMessage :
                ""

    const reloadButtonText = selectedItem === NetworkingListType.RELEVANT ? strings.communicationArea.reloadButtonText :
        selectedItem === NetworkingListType.REQUESTS ? strings.communicationArea.reloadButtonText :
            selectedItem === NetworkingListType.CONTACTS ? strings.communicationArea.reloadButtonText :
                ""

    return (
        <TabRoot>
            <MultiSwitchRoot>
                <BadgeTabs badgeParams={[{ x: 130, count: props.requests }, { x: 222, count: props.contacts }]} />
                <CrsMultiSwitch items={itemList} activeItem={selectedItem} onItemClick={appState.setShowPeopleTab} style={{ mainWidth: branding.crsMultiSwitcher.crsMultiSwitcherMainWidth, msRootItem: { padding: '1px' } }} />
            </MultiSwitchRoot>
            <TabContentArea className="rs-nav-content">
                <NetworkingTabErrorBoundary errorMessage={errorMessage} reloadButtonText={reloadButtonText}>
                    <NetworkingTabSwitch eventKey={selectedItem} contacts={props.contacts} requests={props.requests} />
                </NetworkingTabErrorBoundary>
            </TabContentArea>
        </TabRoot>
    );
}

export default NetworkingTab;

interface NetworkingTabSwitchProps {
    eventKey: NetworkingListType
    contacts: number
    requests: number
}

export function NetworkingTabSwitch(props: NetworkingTabSwitchProps) {
    switch (parseInt(props.eventKey.toString())) {
        case NetworkingListType.RELEVANT: return <RelevantTab />
        case NetworkingListType.REQUESTS: return <RequestsTab requests={props.requests} />
        case NetworkingListType.CONTACTS: return <ContactsTab contacts={props.contacts} />
    }
    return <Fragment />
}

export interface GenericTabProps {
    emptyList: string
    hasDownload?: (groupNumber: number) => boolean
    isRequest?: (groupNumber: number) => boolean
    doDownloadCsv?: () => void
    doDownloadVCard?: () => void
    setReload?: () => void
    unreadCounter: number
    isReload?: boolean
    getSectionHeader: (groupNaumber: number) => string
    fetchData: (profileId: string, searchString: string, page: number) => Promise<ContactListResponse | BackendServiceError>
    tabContainsFavorites?: boolean
    tabContainsNetworkingRestriction?: boolean
    tabType: ContactEntryType
}

const GenericTab: React.FunctionComponent<GenericTabProps> = (props) => {
    const [contacts, setContacts] = useState<ContactItem[]>([])
    const [hasNext, setHasNext] = useState<boolean>()
    const [searchString, setSearchString] = useState("")
    const [page, setPage] = useState(0);
    const userState = useLoggedInState();
    const languageState = useLanguageState();
    const strings = languageState.getStrings();
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const contactState = useContactState()
    const favoriteState = useFavoriteState()
    const browser = detect()
    const appState = useAppState()

    const exportContactsAsCsv = branding.communicationArea.enableExportContactsAsCsv
    const exportContactsAsVCard = branding.communicationArea.enableExportContactsAsVCard

    const [networkingEnabled, setNetworkingEnabled] = useState<boolean>(userState.user()?.matchActive || false)

    useEffect(() => {
        if (userState.user() && networkingEnabled && props.tabContainsNetworkingRestriction && refresh) {

            setTimeout(() => {
                loadData(searchString, page)
            }, 1000)

            setIsLoaded(true)

        }
        // eslint-disable-next-line
    }, [refresh])

    useEffect(() => {
        if (props.tabContainsNetworkingRestriction && !(userState.user()?.matchActive)) {
            setIsLoaded(true)
            return
        }

        const timeout = setTimeout(() => {
            loadData(searchString, page)
        }, 600)

        return () => clearTimeout(timeout)

    }, [searchString, page, props.unreadCounter, appState.lobbyNetworking, languageState.getLanguage()]);  // eslint-disable-line react-hooks/exhaustive-deps


    function changeNetworkingState() {
        setNetworkingEnabled(true)
        setRefresh(true)
    }

    let content: JSX.Element = <div />

    if (!isLoaded) {
        content = <CenteredLoader />;
    }
    else {
        let groupNumber: number = -2;

        const noSearchResultsContent: JSX.Element = <TabEmpty>
            {strings.communicationArea.noSearchResultsTitle}
        </TabEmpty>

        const noContactsContent: JSX.Element = <TabEmpty>
            {props.emptyList}
        </TabEmpty>

        const contactsLoadedContent: JSX.Element = <InfiniteScroll
            dataLength={contacts.length}
            next={() => setPage(page + 1)}
            hasMore={hasNext as boolean}
            loader={<NextPageLoader />}
            scrollableTarget="scrollcontainer"
        >
            {contacts.map((contact, index) => {
                let addSectionHeader = false;
                if (contact.group > groupNumber) {
                    addSectionHeader = true;
                    groupNumber = contact.group
                }
                return (contact.sotUser ?
                    <div key={index}>
                        {addSectionHeader &&
                            <TabSectionHeader>
                                <div>{props.getSectionHeader(groupNumber)}</div>
                            </TabSectionHeader>}
                        <ContactEntry contact={contact.sotUser} key={contact.sotUser.id} setReload={props.isReload ? () => loadData(searchString, 0) : props.setReload} isIncomingRequest={props.isRequest && props.isRequest(groupNumber)} entryType={props.tabType} />

                    </div> :
                    contact.orgaConnectionRequest && contact.orgaConnectionRequest.sotUserId === userState.user()?.profileId ?
                        <div key={index}>
                            {addSectionHeader &&
                                <TabSectionHeader>
                                    <div>{props.getSectionHeader(groupNumber)}</div>
                                </TabSectionHeader>}
                            <OrganizationEntry organizationId={contact.orgaConnectionRequest.orgaId} />
                        </div> : null
                );
            })}
        </InfiniteScroll>;
        // <ContactEntry isIncomingRequest={contact.group === 0 || contact.group === 1}  />

        if (!(userState.user()?.matchActive) && props.tabContainsNetworkingRestriction) {
            content = <div>
                <TabActivateNetworking>
                    {strings.communicationArea.activateNetworkingText}

                </TabActivateNetworking>
                <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                    <NetworkingToggle customEnabled={networkingEnabled} customFunction={() => changeNetworkingState()} />
                </div>
                {networkingEnabled && <NextPageLoader />}
            </div>
        }
        else {

            content = contacts.length > 0 ? contactsLoadedContent : (searchString.length > 0 ? noSearchResultsContent : noContactsContent)
        }
    }

    const handleSearch = (value: string) => {
        setPage(0)
        setSearchString(value)
    }

    return (
        <SearchBarRootArea>
            <SearchBarRoot>
                <SearchBar setSearchParam={handleSearch} searchValue={searchString} placeholder={strings.communicationArea.searchBarTextPerson ?? strings.sideIconBar.searchBarPlaceholder} width={SearchBarWidth} />
                {props.tabType === ContactEntryType.CONTACT && contacts && contacts.length > 0 && (exportContactsAsCsv || exportContactsAsVCard) &&
                    <DownloadIconsRoot>
                        {exportContactsAsVCard && props.doDownloadCsv && <Button margin={3} size={browser && browser.name === "safari" ? 30 : 15} icon={IconDownload({ fill: branding.sideIconBar.sideIconColorDark })} tooltip={strings.communicationArea.exportVCards} onClick={e => props.doDownloadVCard!()} backgroundColor="#ffffff" />}
                        {exportContactsAsCsv && props.doDownloadVCard && <Button className="ml-3" margin={3} size={browser && browser.name === "safari" ? 30 : 15} icon={IconDownload({ fill: branding.sideIconBar.sideIconColorDark })} tooltip={strings.communicationArea.exportCsv} onClick={e => props.doDownloadCsv!()} backgroundColor="#ffffff" />}
                    </DownloadIconsRoot>}
            </SearchBarRoot>
            <SubTabRoot id="scrollcontainer" tabType={props.tabType}>
                {content}
            </SubTabRoot>
        </SearchBarRootArea>
    );

    function loadData(searchString: string, page: number) {
        const profileId: string | undefined = userState.user()?.profileId;
        if (profileId === undefined) {
            return;
        }
        page === 0 ? setIsLoaded(false) : setIsLoaded(true);

        props.fetchData(profileId, searchString, page)
            .then((data) => {
                if (!data) {
                    setIsLoaded(true)
                    return
                }

                if ((data as BackendServiceError).httpStatus) {
                    // TODO ERROR
                } else {
                    const clr: ContactListResponse = data as ContactListResponse
                    contactState.setAll(clr.contacts
                        .filter(c => c.sotUser)
                        .map(c => {
                            return { id: c.sotUser.id, connectionStatus: c.sotUser.myConnectionStatus, userType: c.sotUser.type }
                        }))

                    if (props.tabContainsFavorites) {
                        clr.contacts.forEach(function (contact) {
                            if (contact.sotUser) {
                                contact.isBookmarked = favoriteState.is("person", contact.sotUser.person) || favoriteState.is("person", contact.sotUser.id)
                                if (contact.isBookmarked) {
                                    contact.group = -1
                                }
                            }
                        })
                        let contactsFull: ContactItem[] = []

                        let favorites = clr.contacts.filter(function (contact) {
                            return contact.group === -1
                        })

                        favorites = _.uniqBy(favorites, function (p) { return p.sotUser.id })

                        let contactsWithoutFavorites = clr.contacts.filter(function (contact) {
                            return contact.group !== -1 && contact.sotUser.id !== userState.user()?.profileId
                        })

                        contactsFull = favorites.concat(contactsWithoutFavorites)
                        setContacts(page === 0 ? contactsFull : contacts.concat(clr.contacts))
                    }
                    else {
                        setContacts(page === 0 ? clr.contacts : contacts.concat(clr.contacts))
                    }

                    setHasNext(clr.hasNextPage)
                }
                setIsLoaded(true)
            }).catch(err => {
                logger.error({ message: "failed loading networking data", errorMessage: err.message, errorStack: err.stack });
                setIsLoaded(true)
            });
    }
};



const RelevantTab: React.FunctionComponent = () => {
    const strings = useLanguageState().getStrings();

    function getSectionHeader(groupNumber: number) {
        let titleString: string = ""

        switch (groupNumber) {
            case 0: {
                titleString = strings.communicationArea.relevantTabRelevantSectionTitle
                break
            }
            case -1: {
                titleString = strings.communicationArea.contactsTabFavoritesSectionTitle
                break
            }
            default: {
                titleString = strings.communicationArea.relevantTabAllSectionTitle
            }
        }
        return titleString
    }


    function fetchDdata(profileId: string, searchString: string, page: number) {
        return loadRelevantProfilesListData(profileId, {
            searchString: searchString,
            itemsPerPage: 25,
            page: page
        })
    }

    return (
        <GenericTab tabContainsFavorites={!branding.communicationArea.relevantTabDisableFavorites} tabContainsNetworkingRestriction={true} getSectionHeader={getSectionHeader} fetchData={fetchDdata} emptyList={""} unreadCounter={0} tabType={ContactEntryType.RELEVANT} isReload />
    )
};

interface RequestsTabProps {
    requests: number
}

const RequestsTab: React.FunctionComponent<RequestsTabProps> = (props) => {
    const strings = useLanguageState().getStrings();

    function getSectionHeader(groupNumber: number) {
        let titleString: string = ""

        switch (groupNumber) {
            case 0: {
                titleString = strings.communicationArea.requestsTabAwaitingReplySectionTitle
                break
            }
            case 1: {
                titleString = strings.communicationArea.requestsTabMyOrganizationRequestsSectionTitle
                break
            }
            case 2: {
                titleString = strings.communicationArea.requestsTabMyPendingRequestsSectionTitle
                break
            }
            case 3: {
                titleString = strings.communicationArea.requestsTabMyExhibitorRequestsSectionTitle
                break
            }
        }

        return titleString
    }

    function fetchDdata(profileId: string, searchString: string, page: number) {
        return loadContactRequestListData(profileId, {
            searchString: searchString,
            itemsPerPage: 25,
            page: page,
            ddbCounter: props.requests
        })
    }

    return (
        <GenericTab getSectionHeader={getSectionHeader} fetchData={fetchDdata} emptyList={strings.communicationArea.requestsTabEmpty} tabType={ContactEntryType.REQUEST} isReload isRequest={(groupNumber: number) => groupNumber === 0 || groupNumber === 1} unreadCounter={props.requests} />
    )
};

interface ContactsTabProps {
    contacts: number
}

const ContactsTab: React.FunctionComponent<ContactsTabProps> = (props) => {
    const strings = useLanguageState().getStrings();

    const loggedInUserId = useLoggedInState().user()?.profileId || ""

    function getSectionHeader(groupNumber: number) {
        let titleString: string = ""

        switch (groupNumber) {
            case -1: {
                titleString = strings.communicationArea.contactsTabFavoritesSectionTitle
                break
            }
            case 0: {
                titleString = strings.communicationArea.contactsTabMyPersonalContactsSectionTitle
                break
            }
            case 1: {
                titleString = strings.communicationArea.contactsTabMyOrganizationsContactsSectionTitle
                break
            }
        }
        return titleString
    }

    function fetchDdata(profileId: string, searchString: string, page: number) {
        return loadContactListData(profileId, {
            searchString: searchString,
            itemsPerPage: 25,
            page: page,
            ddbCounter: props.contacts
        })
    }
    return (
        <GenericTab
            tabContainsFavorites={true}
            getSectionHeader={getSectionHeader}
            fetchData={fetchDdata}
            emptyList={strings.communicationArea.contactsTabEmpty}
            unreadCounter={props.contacts}
            tabType={ContactEntryType.CONTACT}
            hasDownload={(groupNumber: number) => true}
            doDownloadCsv={() => exportContactsAsCsv(loggedInUserId, { forceCollaborationFeatureActive: false })}
            doDownloadVCard={() => exportContactsAsVCard(loggedInUserId, { forceCollaborationFeatureActive: false })}
            isReload
        />
    )
};

export const BlockedTab: React.FunctionComponent = () => {
    const strings = useLanguageState().getStrings();

    function getSectionHeader(groupNumber: number) {
        let titleString: string = ""

        switch (groupNumber) {
            case 0: {
                titleString = strings.communicationArea.blockingSectionTitle
                break
            }
            case 1: {
                titleString = strings.communicationArea.blockedSectionTitle
                break
            }
        }

        return titleString
    }

    function fetchDdata(profileId: string, searchString: string, page: number) {

        return loadContactBlockedtListData(profileId, {
            searchString: searchString,
            itemsPerPage: 25,
            page: page
        })
    }

    return (
        <GenericTab getSectionHeader={getSectionHeader} fetchData={fetchDdata} emptyList={strings.communicationArea.blockedTabEmpty} unreadCounter={0} tabType={ContactEntryType.BLOCKED} />
    )
};


