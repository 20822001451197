import React from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import branding from "./branding/branding"
import { useLanguageState } from "./globalStates/LanguageState"
import { systemCheckPageRoute } from "./navigationArea/RoutePaths"
import LanguageToggler from "./ui/LanguageToggle"
import { ContentScrollContainer } from "./ui/ScrollContainer"

const PreLoginAppSiteRoot = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    overflow: hidden;
    height: 100vh;
    font-family: ${branding.font1};
`

const PreLoginAppStyleOverlay = styled.div`
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgb(229, 229, 229, 0.9);
`

const PreLoginAppStyleContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("/branding/login-bg.jpg");
    background-size: cover;
    width: 100%;
    z-index: 2;
`

const HeaderRoot = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    position: relative;
    z-index: 10;

    & img {
        max-width: ${branding.loginRegistrationSite.centerLoginLogoMaxWidth ?? "150px"};
        max-height: ${branding.loginRegistrationSite.centerLoginLogoMaxHeight ?? "50px"};
        object-fit: contain;
        user-select: none;
    }
`

const InfoRoot = styled.div`
    position: relative;
    height: 100vh;
`

const InfoContainer = styled.div` 
    margin: 0;
    position: absolute;
    bottom: 30%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 90%;

    @media (min-width: 700px) {
        width: 600px;
    }

    @media (max-height: 900px) {
        bottom: 0;
        top: 50%;
    }
`

const ScrollContainerRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
`

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const Subtitle = styled.div`
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const Text = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const ButtonsContainer = styled.div`
    margin-top: 40px;
`

const SimpleButton = styled.div`
    color: ${branding.loginRegistrationSite.mobileLoginButtonColor};
    cursor: pointer;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        text-decoration: underline;
    }
`

const FooterRoot = styled.div`
    bottom: 0;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
`

const FooterContainer = styled.div`
    text-decoration: underline;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
    font-family: ${branding.font1};
    font-size: 14px;
    text-align: center;
    margin-bottom: 14px;
    cursor: pointer;
`

const MobilePreLoginApp: React.FunctionComponent = () => {
    const strings = useLanguageState().getStrings()
    const ticketSalePhasePageTitle = strings.ticketSalePhasePageTitle || "";

    const history = useHistory()

    const onBuyTicketButtonClick = () => {
        window.open(`${strings.ticketSale.ticketsUrl}`, "_blank")
    }

    const onCheckYourSystemButtonClick = () => {
        history.push(systemCheckPageRoute)
    }

    const onImprintButtonClick = () => {
        window.open(`${strings.ticketSale.imprintLinkUrl}`, "_blank")
    };

    return <PreLoginAppSiteRoot>
        <PreLoginAppStyleContainer>
            <PreLoginAppStyleOverlay>
                <HeaderRoot>
                    <img src="./branding/logo_dark.png" alt={strings.configuration.ogTitle} />
                    <LanguageToggler margin="0" color={branding.loginRegistrationSite.mobileLoginTextColor} fullToggler={true} />
                </HeaderRoot>
                <InfoRoot>
                    <ScrollContainerRoot>
                        <ContentScrollContainer>
                            <InfoContainer>
                                <Title>
                                    {ticketSalePhasePageTitle}
                                </Title>
                                <Subtitle>
                                    {strings.ticketSale.leadDescriptionText}
                                </Subtitle>
                                <Text>
                                    {strings.ticketSale.checkYourSystemDescriptionText}
                                </Text>
                                <ButtonsContainer>
                                    <SimpleButton>
                                        <p onClick={onCheckYourSystemButtonClick}>
                                            {strings.ticketSale.checkYourSystemButton}
                                        </p>
                                    </SimpleButton>
                                    {
                                        branding.ticketSale.getYourTicketVisible &&
                                        <SimpleButton>
                                            <p onClick={onBuyTicketButtonClick}>
                                                {strings.ticketSale.getYourTicketNowButton}
                                            </p>
                                        </SimpleButton>
                                    }
                                </ButtonsContainer>
                            </InfoContainer>
                        </ContentScrollContainer>
                    </ScrollContainerRoot>
                </InfoRoot>
                <FooterRoot>
                    <FooterContainer onClick={onImprintButtonClick}>
                        {strings.ticketSale.imprintLink}
                    </FooterContainer>
                </FooterRoot>
            </PreLoginAppStyleOverlay>
        </PreLoginAppStyleContainer>
    </PreLoginAppSiteRoot>
}

export default MobilePreLoginApp