import React from 'react';
import styled from 'styled-components';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import branding from '../../branding/branding';
import { VGIcon } from '../../ui/Icons';

const ButtonRoot = styled.button<ButtonProps>`
  padding: 0;
  display: ${props => props.display ? props.display : "flex"};
  justify-content: center;
  align-items: center;
  width: ${props => props.size ? props.size : 50}px;
  height: ${props => props.size ? props.size : 50}px;
  border-radius: 50%;
  border: none;
  margin: ${props => props.margin ? props.margin : 10}px;
  font-size: 20px;
  ${props => props.backgroundColor ? "background-color: " + props.backgroundColor : ""};
  color: ${props => props.color ? props.color : (branding.primaryColor ?? "#2d2d2d")};
  

  &:before{
    border-right: ${props => props.disabled ? "none" : "1px solid #D9D9D9"};
  }
`
type ButtonProps = {
  icon: VGIcon
  tooltip: string
  tooltipPlacement?: "top" | "auto" | "auto-start" | "auto-end" | "bottom" | "right" | "left" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end"
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  backgroundColor?: string
  color?: string
  disabled?: boolean
  size?: number
  margin?: number
  className?: string
  display?: string
};
export function Button(props: ButtonProps) {
  const key = props.tooltip.replace(/[^a-zA-Z0-9]/ig, "")
  return <OverlayTrigger key={key}
    placement={props.tooltipPlacement ? props.tooltipPlacement : "top"}
    overlay={<Tooltip id={`tooltip-${key}`} style={{ fontFamily: branding.font1 }}>{props.tooltip}</Tooltip>
    }>
    <ButtonRoot {...props}
      type="button"
      onClick={props.onClick}
      disabled={props.disabled}
      className={props.className}
    >
      {props.icon}
    </ButtonRoot>
  </OverlayTrigger>
}
