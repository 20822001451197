import React from "react"
import styled from "styled-components"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import branding from "../branding/branding"
import Highlighter from "react-highlight-words"
import { Modal } from "react-bootstrap"

const GuestModalRoot = styled(Modal)`
    .modal-content {
        padding: 10px;
        border-radius: 0px;
        font-family: ${branding.font1};
    }
`

const GuestModalBody = styled(Modal.Body)`
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 2.5rem 1rem; 
    font-family: ${branding.font1};
`

const GuestModalBodyText = styled.p`
    font-size: 14px;
    text-align: justify;
    font-family: ${branding.font1};

    @media (max-width: 1400px) {
        font-size: 12px;
    }
`

const GetTicketButton = styled.div`
    width: 150px;
    height: 30px;
    font-size: 12px;
    border: 1px solid ${branding.primaryColor};
    background: ${branding.primaryColor};
    color: #FFF;
    border-radius: 25px;
    cursor: pointer;
    font-family: ${branding.font1};
`

const CancelButton = styled.div`
  font-family: ${branding.font1};
     width: 150px;
    height: 30px;
    cursor: pointer;
    font-size: 12px;
    
    &:hover{
        text-decoration: none;
    }
`


interface GuestModalProps {
    close: () => void

}


const GuestModal: React.FunctionComponent<GuestModalProps> = (props) => {
    const userLink = useLoggedInState()
    const strings = useLanguageState().getStrings()

    const onBuyTicketClick = () => {
        window.open(strings.systemCheck.ticketUrl, "_blank");
        props.close()
    }

    const replacePlaceholderText = (name: string) => {
        var template = strings.globalTexts.guestRestrictionMessage
        var contentString = template.split('{$invitingOrganizationName}').join(name)

        return contentString
    }

    const invitingOrganizationName: string = userLink.user()?.invitingOrganization?.name || ""

    return <GuestModalRoot show={true}
        onHide={() => props.close()}
        backdrop="static"
        centered
        animation={false}>
        <GuestModalBody>
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }} className="row">
                <GuestModalBodyText>
                    <Highlighter
                        activeIndex={-1}
                        searchWords={[invitingOrganizationName]}
                        autoEscape={true}
                        highlightStyle={{ display: "inline", backgroundColor: "transparent", fontWeight: "bold", padding: 0 }}
                        textToHighlight={replacePlaceholderText(invitingOrganizationName)}
                    />
                </GuestModalBodyText>
            </div>
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }} className="row d-flex justify-content-between mt-5">
                <CancelButton onClick={() => props.close()} className="d-flex align-items-center"> {strings.globalTexts.cancel}</CancelButton>
                <GetTicketButton onClick={() => onBuyTicketClick()} className="d-flex align-items-center justify-content-center">{strings.loginRegistrationSite.buyTicketButton}</GetTicketButton>
            </div>
        </GuestModalBody>
    </GuestModalRoot>


}


export default GuestModal

