
const iAmPartOfLocalStorageKey = "eventGuide-iAmPartOf"

export function getIamPartOf(queryParams: any) : string | undefined | null {
  try {
      if (queryParams.iampartof) {
          const iampartof = queryParams.iampartof
            localStorage.setItem(iAmPartOfLocalStorageKey, iampartof)
            return iampartof
      }

      return localStorage.getItem(iAmPartOfLocalStorageKey)
  } catch (securityError) {
      // No Cookies! Oh noes! Nothing works!
      return undefined
  }
}