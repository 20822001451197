import React from 'react'
import styled from 'styled-components'
import { Alert } from 'react-bootstrap'

const icons = {
    'primary': null,
    'secondary': null,
    'success': 9989,
    'danger': 10062,
    'warning': null,
    'info': null,
    'dark': null,
    'light': null
}


const MessagePanelRoot = styled(Alert)`
    margin-top: 0px !important;
    overflow: hidden;

    span{
        font-size: 10px;
    }
`

interface MessagePanelProps {
    type: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
    showIcon?: boolean
    description: any
    show: boolean
}

const MessagePanel = (props: MessagePanelProps) => {
    const iconCode = icons[props.type]
    const icon = props.showIcon && iconCode ? String.fromCodePoint(iconCode!) : null

    return (
        <MessagePanelRoot className={props.show ? 'fade in' : 'fade'} show={props.show} variant={props.type} transition={false}>
            <div className="d-flex justify-content-between align-items-center">
                {props.showIcon && <div className="pr-4"><span role="img" aria-label={props.type}>{icon}</span></div>}
                {props.description}
            </div>
        </MessagePanelRoot>

    )
}

export default MessagePanel