import React from "react"
import styled, { keyframes } from "styled-components"
import { IconThreeDotsMenu, VGIcon } from "../ui/Icons"
import branding from "../branding/branding"
import { OverlayTrigger, Tooltip } from "react-bootstrap"


const MenuContainer = styled.div<{ expandedHeight: number }>`
    font-family: ${branding.font1};
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    transition: height 0.2s ease-out;
    height: ${props => props.expandedHeight}px;
    padding-top: 10px;
    
    &.collapsed {
        height: 0px;
        padding-top: 0px;
    }

    & svg {
        width: 20px;
        height: 20px;
        color: ${branding.primaryColor};
    }

    &.big svg {
        width: 25px;
        height: 25px;
    }

    &.big div {
        font-size: inherit !important;
    }
`

const Menu = styled.div`
    width: 100%;
    flex-grow: 1;
    position: relative;
`

const LabeledIconButtonContainer = styled.div`
    position: relative;
    width: 100%;
`

const IconButtonsContainer = styled.div`
    display: table;
    table-layout: fixed;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.collapsed {
        display:none;
    }
    & :nth-child(1) {
        animation-delay: 100ms;
    }
    & :nth-child(2) {
        animation-delay: 200ms;
    }
`
const opacityAnimation = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

const IconButtonsRow = styled.div`
    display: table-row;
    & > * {
        display: table-cell;
    }

    animation-name: ${opacityAnimation};
    animation-duration: 0.4s;
    animation-timing-function: linear;
    animation-fill-mode: backwards;
    padding-bottom: 10px;

    @media (max-width: 1600px) {
            white-space: normal;
            word-break: break-word;
        }
`


const LabeledIconButtonContent = styled.div`
    margin: 0 1px 10px 1px;
    padding: 9px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;

    &:hover { background-color: #F2F2F2 }
    &.disabled {
        opacity: .4;
        pointer-events: none;
    }
    &.disabled:hover {
        background-color: transparent;
    }
`
const LabeledIconButtonIcon = styled.div`
    margin-bottom: 10px;
`
const LabeledIconButtonLabel = styled.div`
    font-size: 10px; /* was 12px */
    line-height: 12px;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
    text-align: center;

  @media only screen and (max-width: 1400px){
    font-size: 8.5px;
    word-break: keep-all;
  }
  @media only screen and (max-width: 1100px){
    font-size: 7px;
    word-break: keep-all;
  }
`

export interface MenuItemProps {
    title: string
    icon: any
    disabled?: boolean
    hint?: string
    onClick: () => void
    notVisible?: boolean
}


export interface ContextMenuProps {
    collapsed: boolean
    className?: string
    itemsPerRow?: number
    items: () => MenuItemProps[],
    onItemClickHandler?: Function,
    noLabels?: boolean
}



const ContextMenu: React.FC<ContextMenuProps> = ({ collapsed, className, items, itemsPerRow = 3, onItemClickHandler, noLabels = false }) => {
    const clsName = (collapsed ? "collapsed" : "expanded") + (className ? " " + className : "")
    const itemList = (itemArr: MenuItemProps[]) => {
        const x: any[] = []
        for (var i = 0; i < itemArr.length; i = i + itemsPerRow) {
            x.push(
                <IconButtonsRow key={i}>
                    {itemArr.slice(i, i + itemsPerRow).map((item, index) => item && createItem(item, index))}
                </IconButtonsRow>
            )
        }
        return x;
    }

    const createItem = (item: MenuItemProps, index: number) => {
        return (
            <LabeledIconButtonContainer key={index}>
                {item.hint && <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="option-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1, fontSize: "14px" }}>
                            {item.hint && !item.disabled ? item.hint as any : ""}
                        </Tooltip>}>
                    <LabeledIconButtonContent onClick={item.disabled ? () => { } : (event) => {
                        event.stopPropagation()
                        item.onClick()
                        if (onItemClickHandler)
                            onItemClickHandler()
                    }} className={item.disabled ? "disabled" : ""}>
                        <LabeledIconButtonIcon>{item.icon}</LabeledIconButtonIcon>
                        {noLabels || <LabeledIconButtonLabel>{item.title}</LabeledIconButtonLabel>}
                    </LabeledIconButtonContent>
                </OverlayTrigger>}
                {!item.hint &&
                    <LabeledIconButtonContent onClick={item.disabled ? () => { } : (event) => {
                        event.stopPropagation()
                        item.onClick()
                        if (onItemClickHandler)
                            onItemClickHandler()
                    }} className={item.disabled ? "disabled" : ""}>
                        <LabeledIconButtonIcon>{item.icon}</LabeledIconButtonIcon>
                        {noLabels || <LabeledIconButtonLabel>{item.title}</LabeledIconButtonLabel>}
                    </LabeledIconButtonContent>
                }
            </LabeledIconButtonContainer >
        )
    }

    var itemArr = collapsed ? [] : items()
    //hiding controls from the context menu depending on branding values
    if (itemArr && itemArr.length > 0) {
        branding.contextMenuBranding.contextMenuItemsVisibility.map((item, index) => {
            if (itemArr[index])
                itemArr[index].notVisible = !item.visible
            return null
        })
    }

    itemArr = itemArr.filter(x => !x.notVisible)
    const rows = Math.ceil(itemArr.length / itemsPerRow)

    return <MenuContainer expandedHeight={rows * (noLabels ? 40 : 80) + 10} className={clsName + " context-menu-container"}>
        {!collapsed &&
            <Menu>
                <IconButtonsContainer className={collapsed ? "collapsed" : "expanded"}>
                    {itemList(itemArr)}
                </IconButtonsContainer>
            </Menu>
        }
    </MenuContainer>
}

export default ContextMenu


const HoverButtonContainer = styled.div`
  background-color: transparent;
  padding: 10px 10px;
  transition: background-color 0.7s;
  border-radius: 25px;
  color: ${branding.primaryColor};
  fill: white;
  cursor: pointer;
  &:hover { background-color: hsl(0,0%,80%); }

  &.invisible {
      visibility: hidden;
  }
  &.selected svg {
    fill: ${branding.primaryColor};
  }
`

export interface HoverButtonProps {
    onClick: () => void,
    Icon?: VGIcon,
    selected: boolean
}

export const HoverButton: React.FC<HoverButtonProps> = ({ onClick, Icon, selected }) => {

    return (
        <HoverButtonContainer className={selected ? "selected" : ""} onClick={(event) => { event.preventDefault(); event.stopPropagation(); onClick(); }}>{Icon || IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}</HoverButtonContainer>
    )
}
