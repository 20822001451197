import { useEffect, useState } from "react";
import { EntityType, Exhibitor, Product, Suggestion, Trademark } from "../backendServices/Types";
import { defaultLogger as logger, useAppState } from "../globalStates/AppState";
import branding from "../branding/branding";
import { ExhibitorListRequestParameter, loadExhibitorsData } from "../backendServices/BackendServices";
import { backOff } from "exponential-backoff";
import { useFavoriteState } from "../globalStates/Favorites";
import { useLanguageState } from "../globalStates/LanguageState";

const pageSize = 27

interface SearchParameters {
    page: number
    order: string
    alpha: string | null
    basispremium: number | null
    isStartup: boolean
    showOnlyBookmarks: boolean
    searchValue?: string
    searchTitle?: string
    entityType: EntityType
    favorites: string
    sponsorFilter?: string
    firstFilterCategory?: string | null
    secondFilterCategory?: string | null
}


export enum SectionType {
    TOP = "top", ALL = "all"
}
export const sectionOrder = [SectionType.TOP, SectionType.ALL]

export interface Section {
    type: SectionType
    count: number
    results: (Exhibitor | Product | Trademark)[]
    hasMoreData: boolean
}

export type Sections = {
    [key in SectionType]?: Section
}

function calcInitSearchParameters(favorites: string, isSponsorPage?: boolean, showOnlyBookmarks?: boolean, isMediaPartnerPage?: boolean,isStartupPage?: boolean, suggestion?: Suggestion, firstFilterCategory?: string, secondFilterCategory?: string): SearchParameters {
    function getSponsorFilter() {
        if (isSponsorPage) {
            const aliases = branding.sponsorsPageContent.partnersSponsorsCategoryAliases
            const aliasesWithPrefix = aliases.map(alias => 'cat_' + alias)
            return aliasesWithPrefix.join(", ")
        }
        else if (isMediaPartnerPage) {
            const aliases = branding.mediaPartnerPageContent.mediaPartnerCategoryAliases
            const aliasesWithPrefix = aliases.map(alias => 'cat_' + alias)
            return aliasesWithPrefix.join(", ")
        }
        else
            return undefined
    }


    return {
        page: 0,
        order: 'lexic',
        alpha: null,
        isStartup: isStartupPage ? true : false,
        basispremium: isStartupPage ? -1 : null,
        showOnlyBookmarks: showOnlyBookmarks ? true : false,
        favorites: favorites,
        searchValue: suggestion?.value,
        searchTitle: suggestion?.title,
        entityType: suggestion?.type ? suggestion.type : "organization",
        sponsorFilter: getSponsorFilter(),
        firstFilterCategory: null,
        secondFilterCategory: null
    }
}

export const useSearch = (isSponsorPage?: boolean, showOnlyBookmarks?: boolean, isMediaPartnerPage?: boolean, isStartupPage?: boolean, firstFilterCategory?: string, secondFilterCategory?: string) => {
    const favoriteState = useFavoriteState()
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const suggestion = useAppState().getSuggestParam()

    const [searchParams, setSearchParams] = useState<SearchParameters>(calcInitSearchParameters(favoriteState.get("organization"), isSponsorPage, showOnlyBookmarks, isMediaPartnerPage, isStartupPage,suggestion, firstFilterCategory, secondFilterCategory))
    const [results, setResults] = useState<Sections>({})
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isInit, setIsInit] = useState(false)

    useEffect(() => {
        fetchData(searchParams)
        setIsInit(true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const suggestionHash = [suggestion?.type, suggestion?.value].filter(Boolean).join("")
    useEffect(() => {
        if (!isInit)
            return
        setSearchParams({
            ...searchParams,
            page: 0,
            alpha: null,
            basispremium: isStartupPage ? -1 : null,
            searchValue: suggestion?.value,
            searchTitle: suggestion?.title,
            entityType: suggestion?.type ? suggestion.type : searchParams.entityType,
            firstFilterCategory: null,
            secondFilterCategory: null
        })
    }, [suggestionHash]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isInit)
            return
        fetchData(searchParams)
    }, [searchParams]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isInit)
            return
        fetchData({ ...searchParams, page: 0 })
    }, [lang]) // eslint-disable-line react-hooks/exhaustive-deps

    // handling data load
    const fetchData = async (searchParams: SearchParameters) => {
        // If there are no favorites and we only want to see those, then we don't need to load anything.
        if (searchParams.showOnlyBookmarks && !favoriteState.get("organization")) {
            setResults({})
            return
        }

        // Only work with existing results if we are on a next page
        const existingResults = searchParams.page > 0 ? results : {}

        // List of data loaders for the different sections
        const loadSection = []
        for (let sectionType of sectionOrder) {
            // only load data if we either do not have results, or we have more incoming
            if (!existingResults[sectionType] || existingResults[sectionType]?.hasMoreData) {
                const requestParams = getRequestParams(sectionType, searchParams)
                loadSection.push(loadExhibitors(sectionType, requestParams))
            }
        }

        // If there is no section to load we can end here
        if (loadSection.length === 0)
            return

        // wait for all requests
        const loadedSections = await Promise.all(loadSection)

        // handle request results
        const newResults: Sections = {}
        for (let sectionType of sectionOrder) {
            let matchedSection: Section | null = null
            for (let loadedSection of loadedSections) {
                if (loadedSection.type === sectionType) {
                    matchedSection = loadedSection
                    break
                }
            }
            // No new data loaded. Use the existing one
            if (!matchedSection) {
                newResults[sectionType] = existingResults[sectionType]
                continue
            }
            // entirly new results.
            if (!existingResults[sectionType])
                newResults[sectionType] = { type: sectionType, count: matchedSection.count, results: matchedSection.results, hasMoreData: matchedSection.hasMoreData }
            // existing entries and we got more results
            else if (results[sectionType]?.hasMoreData)
                newResults[sectionType] = { type: sectionType, count: matchedSection.count, results: [...existingResults[sectionType]!.results, ...matchedSection.results], hasMoreData: matchedSection.hasMoreData }

        }
        if (!isInit)
            setIsInit(true)
        setResults(newResults)
        setIsLoading(false)
    }



    return {
        searchParams: searchParams,
        results: results,
        isLoading: isLoading,
        searchFunctions: {
            doSearch: fetchData,
            nextPage: () => {
                setSearchParams({
                    ...searchParams,
                    page: searchParams.page + 1,
                })
            },
            setBookmarksOnly: (showOnlyBookmarks: boolean) => {
                setSearchParams({
                    ...searchParams,
                    favorites: favoriteState.get(searchParams.entityType),
                    page: 0,
                    showOnlyBookmarks: showOnlyBookmarks,
                })
            },
            setEntityFilter: (entityType: EntityType) => {
                setSearchParams({
                    ...searchParams,
                    favorites: favoriteState.get(searchParams.entityType),
                    showOnlyBookmarks: false,
                    page: 0,
                    entityType: entityType,
                })
            },
            setAlpha: (alpha: string | null) => {
                setSearchParams({
                    ...searchParams,
                    page: 0,
                    alpha: alpha,
                })
            },
            setIsBasisPremium: ( basisPremium: number | null) => {
                setSearchParams({
                    ...searchParams,
                    page: 0,
                    basispremium: isStartupPage ? -1 : basisPremium
                })
            },
            setFirstFilterCategory: (alias: string | null) => {
                setSearchParams({
                    ...searchParams,
                    page: 0,
                    firstFilterCategory: alias,
                })
            },
            setSecondFilterCategory: (alias: string | null) => {
                setSearchParams({
                    ...searchParams,
                    page: 0,
                    secondFilterCategory: alias,
                })
            },
            hasMoreData: () => {
                if (Object.keys(results).length === 0)
                    return true
                const sectionsWithMoreData = Object.values(results).filter((result) => result && result.hasMoreData)
                if (sectionsWithMoreData.length > 0) {
                    return true
                }
                return false
            }
        }
    };
};

function getRequestParams(sectionType: SectionType, searchParams: SearchParameters): ExhibitorListRequestParameter {
    const filterList = []
    if (sectionType === SectionType.TOP) {
        filterList.push("featured_")
        searchParams.order = "totl" //order organizations by totl value and then alphabetically
    } else {
        searchParams.order = "lexic"
    }

    let entityFilter = ""
    if (searchParams.showOnlyBookmarks && searchParams.favorites){
        entityFilter = searchParams.favorites
    }
    else {
        switch (searchParams.entityType) {
            case "product":
                entityFilter = 'entity_prod'
                break
            case "trademark":
                entityFilter = 'entity_trad'
                break
            default:
                entityFilter = 'entity_orga'
        }
    }
    filterList.push(entityFilter)

    if (searchParams.sponsorFilter) {
        filterList.push(searchParams.sponsorFilter)
    }

    if (searchParams.searchValue) {
        filterList.push(searchParams.searchValue)
    }

    if (searchParams.firstFilterCategory) {
        filterList.push("cat_" + searchParams.firstFilterCategory)
    }

    if (searchParams.secondFilterCategory) {
        filterList.push("cat_" + searchParams.secondFilterCategory)
    }

    const requestParams: ExhibitorListRequestParameter = {
        numresultrows: pageSize,
        startresultrow: searchParams.page * pageSize,
        filterlist: filterList,
        order: searchParams.order
    }
    if (searchParams.alpha)
        requestParams.alpha = searchParams.alpha

    if (searchParams.basispremium && searchParams.isStartup)
    requestParams.basispremium = searchParams.basispremium

    return requestParams
}

// actual data loading 
async function loadExhibitors(sectionType: SectionType, requestParams: ExhibitorListRequestParameter): Promise<Section> {
    const resp = await backOff(() => loadExhibitorsData(requestParams), {
        retry: (error: any, attemptNumber: number) => {
            logger.error({ message: "ExhibitorsPageContent loadExhibitors attempt " + attemptNumber + " failed.", errorMessage: error.message, errorStack: error.stack })
            return true
        }
    })

    return { type: sectionType, count: resp.count, results: resp.exhibitors, hasMoreData: resp.count > (requestParams.startresultrow + pageSize) }
}