import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-bootstrap"
import { IconBookmarkToggle, IconListView, IconCardView, IconBookmarkFilled, IconCloseFilled } from "./Icons"
import { useLanguageState } from "../globalStates/LanguageState"
import { ViewMode } from "../contentArea/ExhibitorsPageContent"
import useWindowDimensions from "./WindowDimensionsHook"
import branding from "../branding/branding"
import { useAppState } from "../globalStates/AppState"

/* #region  Bookmark button */
const BookmarkButtonDiv = styled.div`
    cursor: pointer;
`

interface BookmarkButtonProps {
    bookmarkFilter: boolean
    setBookmarkFilter: (value: boolean) => void
}

export const BookmarkButton = (props: BookmarkButtonProps) => {
    function handleClick() {
        props.setBookmarkFilter(!props.bookmarkFilter);
    }
    return (
        <BookmarkButtonDiv onClick={handleClick}>
            {props.bookmarkFilter ? branding.defaultToggleIcon ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark }) : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark }) : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark })}
        </BookmarkButtonDiv>
    )
}
/* #endregion */


const SearchBookmarkHeaderRoot = styled(Row)`
    color: ${branding.primaryColor};
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    svg {
      color: ${branding.primaryColor};
    }

    flex-direction: column-reverse;
    align-items: flex-end;
   
`

const BookmarkButtonCol = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
`

export const BookmarksViewTitle = styled.span`
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    font-family: ${branding.font1};

    @media (max-width: 1600px) {
            font-size: 10px;
    }
`

export const SearchbarItem = styled.div<{ direction?: string, display: string }>`
    display: ${props => props.display};
    flex-direction: ${props => props.direction ? props.direction : "column"};
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const CurrentSearchParent = styled(Col)`
    display: flex;
    justify-content: flex-start;

    @media (max-width: 1400px) {
        justify-content: flex-end;
    }
`

const CurrentSearch = styled.div`
    width: fit-content;
    max-width: 80%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid ${branding.exhibitorsPageContent.searchAllBorderColor};
    background: ${branding.exhibitorsPageContent.searchAllBgColor};
    font-family: ${branding.font1};
    font-size: 18px;
    border-radius: 25px;
    color: ${branding.exhibitorsPageContent.searchAllColor};
    font-weight: bold;

    @media (max-width: 1800px) {
        font-size: 14px;
    }

    @media (max-width: 1600px) {
        font-size: 12px;
    }

    @media (max-width: 1400px) {
        max-width: 100%;
    }
`

const CurrentSearchText = styled.div`
    width: 97%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;

    @media (max-width: 1400px) {
        margin-right: 0;
    }
`
const ClearCurrentSearch = styled.div`
    cursor: pointer;
    margin-left: 2px;
    margin-top: -3px;
`


interface SearchBookmarkHeaderProps {
    bookmarkFilter: boolean
    placeholder: string
    bookmarkText: string
    setBookmarkFilter: (value: boolean) => void
    viewMode?: ViewMode
    onViewModeChange?: (viewMode: ViewMode) => void
    responsive?: boolean
    searchBarWidth?: string
    hideBookmarkButton?: boolean
    currentSearchText?: string
    clearCurrentSearch?: () => void
    isShowfloor?: boolean
}

const SearchBookmarkHeader: React.FunctionComponent<SearchBookmarkHeaderProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const windowSize = useWindowDimensions();
    const appState = useAppState()
    const globalSearchParam = appState.getSuggestParam()
    const searchText = globalSearchParam?.title

    const onToggleViewModeClicked = () => {
        if (props.viewMode === ViewMode.LIST)
            props.onViewModeChange!(ViewMode.TILES)
        else
            props.onViewModeChange!(ViewMode.LIST)
    }

    const bookmarkColSizeShowFloor = windowSize.width < 1500 ? 5 : 4
    const bookmarkColSizeOther = windowSize.width < 1400 ? 4 : 6

    const viewModeSet = props.viewMode !== undefined
    return (
        <SearchBookmarkHeaderRoot className="headerRoot">
            <CurrentSearchParent className="p-0" xs={windowSize.width < 1500 ? 7 : 8}>
                {props.isShowfloor && searchText && <CurrentSearch>
                    <CurrentSearchText>"{searchText}"</CurrentSearchText>
                    <ClearCurrentSearch onClick={() => { if (props.clearCurrentSearch) props.clearCurrentSearch() }}>{IconCloseFilled({ fill: branding.exhibitorsPageContent.currentSearchCloseIconFill, stroke: branding.exhibitorsPageContent.currentSearchCloseIconStroke })}</ClearCurrentSearch>
                </CurrentSearch>}
            </CurrentSearchParent>
            <BookmarkButtonCol xs={props.isShowfloor ? bookmarkColSizeShowFloor : bookmarkColSizeOther}>
                {viewModeSet &&
                    <SearchbarItem className="mr-4" display={props.responsive && windowSize.width < 1350 ? "none" : "flex"} onClick={onToggleViewModeClicked}>
                        {props.viewMode === ViewMode.TILES ? IconListView({ fill: branding.sideIconBar.sideIconColorDark }) : IconCardView({ fill: branding.sideIconBar.sideIconColorDark })}
                        <BookmarksViewTitle className="mt-2">{props.viewMode === ViewMode.TILES ? strings.exhibitorsPageContent.listView : strings.exhibitorsPageContent.cardView}</BookmarksViewTitle>
                    </SearchbarItem>
                }
                {!props.hideBookmarkButton &&
                    <SearchbarItem direction={"column-reverse"} display={"flex"}>
                        {/* <BookmarksViewTitle className={viewModeSet ? "mt-2" : "mr-2"}>{props.bookmarkText}</BookmarksViewTitle> */}
                        <BookmarksViewTitle className={"mt-2"}>{props.bookmarkText}</BookmarksViewTitle>
                        <BookmarkButton bookmarkFilter={props.bookmarkFilter} setBookmarkFilter={props.setBookmarkFilter} />
                    </SearchbarItem>}
            </BookmarkButtonCol>
        </SearchBookmarkHeaderRoot>
    )
}

export default SearchBookmarkHeader
