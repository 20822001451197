import React, { useState } from "react";
import styled from "styled-components";
import { useLanguageState } from "../globalStates/LanguageState";
import { SlidoConfigBranding } from "../branding/ThirdPartyResources";
import branding from "../branding/branding"
import { useLoggedInState } from "../globalStates/LoggedInUser";
import ChatPage, { ChatConversationParam, calcConversationId, DisplayStyle } from "../communicationArea/ChatPage";
import { ConversationType } from "../API";
import CrsMultiSwitch from '../ui/CrsMultiSwitch';

const TabRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  flex-grow: 1;
  border-left: 1px solid black;
  background: white;
`;

const ChatTabNavigationArea = styled.div`
  display: flex;
  align-items: stretch;
  margin-top:20px;
  min-width: 80%;
  /* width: calc(100% - 60px); */
  height:30px;
  min-height: 30px;
  padding: 1px;
  font-size: 12px;
  font-family: ${branding.font1};

  &>* {
    flex-grow: 1;
    flex-basis: 100px;
  }
     
`

const TabContentArea = styled.div`
  margin-top: 15px;
  overflow: auto;
  width: 100%;
  height: calc(100% - 40px); /* Depends on header and other stuff from above! */
`;

enum ChatRoomType {
  CHAT,
  POLL,
  QANDA
}

const ChatPageWrapper = styled.div`
    background: white;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
`

interface ChatTabProps {
  visible: boolean
  roomId: string
}

function ChatTab(props: ChatTabProps) {
  return (
    <ChatPageWrapper style={{ display: props.visible ? undefined : "none" }}>
      <ChatPage
        param={ChatConversationParam.conversationByConversationId(ConversationType.PUBLIC, calcConversationId(props.roomId))}
        displayStyle={DisplayStyle.PINBOARD} />
    </ChatPageWrapper>
  );
};

function SlidoTab(props: slidoProps) {
  const userState = useLoggedInState()
  const currentUserName = [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ");
  const eventCode = branding.configuration.topicName === branding.thirdPartyResources.find(elem => elem.id === "slido")?.config.topic ? branding.thirdPartyResources.find(elem => elem.id === "slido")?.config.eventCode : "";

  const src = props.type === ChatRoomType.POLL ?
    "https://app.sli.do/event/" + eventCode + "/live/polls?section=" + props.uuid + "&user_name=" + currentUserName :
    "https://app.sli.do/event/" + eventCode + "/live/questions?section=" + props.uuid + "&user_name=" + currentUserName

  return (
    <div style={{ display: props.visible ? undefined : "none", height: "100%" }}>
      <iframe title="Slido Poll and Questions" src={src} height="100%" width="100%" style={{ borderWidth: '0px' }}></iframe>
    </div>
  );
};

interface slidoProps {
  uuid?: string
  visible: boolean
  type: ChatRoomType
}

function getUUID(slidoName?: string) {
  if (slidoName === undefined) return "";
  let slidoRoomsConfig: SlidoConfigBranding | undefined = branding.thirdPartyResources.find(elem => elem.id === "slido")?.config;
  return slidoRoomsConfig?.slidoRooms.find(elem => elem.id === slidoName)?.uuid ?? "";
}

function ChatTabWrapper(props: { eventKey: ChatRoomType, roomId: string, uuid?: string }) {
  return <><ChatTab roomId={props.roomId} visible={props.eventKey === ChatRoomType.CHAT} />
    {/* props.eventKey === ChatRoomType.POLL && <SlidoTab uuid={props.uuid} visible={props.eventKey === ChatRoomType.POLL} type={ChatRoomType.POLL} />*/}
    {props.eventKey === ChatRoomType.QANDA && <SlidoTab uuid={props.uuid} visible={props.eventKey === ChatRoomType.QANDA} type={ChatRoomType.QANDA} />}
  </>
}

interface ChatRoomAreaProps {
  chatRoom: string
  slidoName?: string
  hideQnA?: boolean
}

const LiveChatTitleRoot = styled.div`
    font-family: ${branding.font1};
    position: relative;
    display: flex;
    display: -webkit-box;
    border: ${branding.crsMultiSwitcher.crsMultiSwitcherBorder};
    border-radius: 15px;
    padding: 1px;
    /* font-size: 1.1rem; */
    flex-basis: 0;
    user-select: none;
    height: 30px;
    color: ${branding.crsMultiSwitcher.crsMultiSwitcherNotActiveColor}; 

`
const LiveChatTitle = styled.div`
  position: absolute;
  font-family:${branding.font1};
  text-align: center;
  font-style: normal;
  font-weight: 400;
  border-radius: 20px;
  font-size: ${branding.crsMultiSwitcher.crsMultiSwitcherMultiSwitchItemFontSize ?? "12px"};
  background-color: ${branding.crsMultiSwitcher.crsMultiSwitcherActiveBgColor};
  color: ${ branding.crsMultiSwitcher.crsMultiSwitcherActiveColor};
  padding: .3rem 1rem;
  cursor: pointer;
  width: 99.7%;
  height: calc(100% - 1px - 1px);
  text-align: center;
`;

const ChatRoomAreaTab: React.FunctionComponent<ChatRoomAreaProps> = (props) => {
  const [eventKey, setEventKey] = useState<ChatRoomType>(branding.eventDateDetailPageContent.chatVisible ? ChatRoomType.CHAT : (branding.eventDateDetailPageContent.pollsVisible ? ChatRoomType.QANDA : ChatRoomType.CHAT))
  const languageState = useLanguageState()
  const strings = languageState.getStrings()
  const multiSwitchData = [{ value: ChatRoomType.CHAT, label: strings.communicationArea.livechat }, { value: ChatRoomType.POLL, label: strings.communicationArea.poll }]


  return (
    <TabRoot className="chat-tabroot">
        <ChatTabNavigationArea>
        {(branding.eventDateDetailPageContent.chatVisible && !branding.eventDateDetailPageContent.pollsVisible) && <LiveChatTitleRoot><LiveChatTitle>{strings.communicationArea.livechat}</LiveChatTitle></LiveChatTitleRoot>}
        {(branding.eventDateDetailPageContent.chatVisible && branding.eventDateDetailPageContent.pollsVisible) &&<CrsMultiSwitch items={multiSwitchData} activeItem={eventKey} onItemClick={setEventKey} style={{ mainWidth: "80%", msRootItem: { fontSize: "12px" }, msItem: { position: "relative", zIndex: 1 }, msActiveItem: { backgroundColor: branding.crsMultiSwitcher.crsMultiSwitcherActiveBgColor, color: branding.crsMultiSwitcher.crsMultiSwitcherActiveColor, position: "absolute", zIndex: 0 } }} />}
        </ChatTabNavigationArea>
      <TabContentArea className="rs-nav-content">
        <ChatTabWrapper eventKey={eventKey} roomId={props.chatRoom} uuid={getUUID(props.slidoName)} />
      </TabContentArea >
    </TabRoot >
  );
};

export default ChatRoomAreaTab;
