import React from "react"
import styled from "styled-components"

const ToggleSwitchRoot = styled.div<{ sliderBgColor: string }>`
    width: 44px;

    & .switch{
        position: relative;
        display: inline-block;
        width: 44px;
        height: 25px;
    }

    & .switch input{
        opacity: 0;
        width: 0;
        height: 0;
    }

    & .slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${props => props.sliderBgColor};
        border-radius: 12px;
    }

    & .slider:before{
        position: absolute;
        content: "";
        height: 19px;
        width: 19px;
        left: 3px;
        bottom: 3px;
        background-color: #FFF;
        -webkit-transition: .2s ease-in-out;
        transition: .2s ease-in-out;
        border-radius: 50%;
    }

    & input.checked + .slider{
        background-color: ${props => props.sliderBgColor};
    }

    & input.checked + .slider:before{
        -webkit-transform: translateX(19px);
        -ms-transform: translateX(19px);
        transform: translateX(19px);
        -webkit-transition: transform .2s ease-in-out;
        transition: transform .2s ease-in-out;
    }
`


interface ToggleSwitchProps {
    isEnabled: boolean | undefined,
    onClick?: () => void
    activeColor?: string
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = React.memo((props) => {
    const checkedClassName = props.isEnabled ? "checked" : "";
    const sliderBgColor = props.isEnabled ? (props.activeColor ? props.activeColor : "#00B300") : "#D9D9D9";

    return (
        <ToggleSwitchRoot sliderBgColor={sliderBgColor}>
            <label className="switch">
                <input type="checkbox" className={checkedClassName} onChange={props.onClick} />
                <span className="slider"></span>
            </label>
        </ToggleSwitchRoot>
    );
})

export default ToggleSwitch
