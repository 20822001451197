import React, { SyntheticEvent, useState } from 'react';

const CustomLogoImage = () => {
    const logo = process.env.PUBLIC_URL + '/branding/logo.png'
    
    const [height, setHeight] = useState(200);
    const [width, setWidth] = useState(300);

    const onImgLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
        // example corussoft logo dimensions: 600 x 116px
        const target = event.target as HTMLImageElement
        const ratio = target.naturalWidth / target.naturalHeight        // 600 / 116
        const customHeight = 100
        // keep logo standard height and adjust width so that original image ratio is preserved
        setHeight(customHeight)
        setWidth(customHeight * ratio)
    }

    return (
        <img 
            src={logo}
            onLoad={onImgLoad}
            className="vjs-button vjs-logo"  
            alt="Logo" 
            style={{                
                width: width,
                height: height}}
        >
        </img>
         
    );

};

export default CustomLogoImage;