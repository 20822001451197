import React, { useState } from 'react'
import styled from "styled-components"
import { Category } from '../../../backendServices/Types'
import { Row } from "react-bootstrap"
import { HeaderTitle } from './StyledComponents'
import { useLanguageState } from '../../../globalStates/LanguageState'
import branding from "../../../branding/branding"
import SimpleTreeComponent, { TreeData } from './SimpleTreeComponent'


interface CategoriesProps {
    categories: Category[]
}

const CategoryItem = styled.div<{ backgroundColor: string, textColor: string, backgroundActiveColor: string, textActiveColor: string }>`
    display: inline-block;
    padding: 8px 15px;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    font-size: 14px;
    font-weight: 700;
    &.active {
        background-color: ${props => props.backgroundActiveColor};
        color: ${props => props.textActiveColor};
    }
    margin: 5px;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
`

interface CategoryGroupProps {
    category: Category
}

const CategoryGroup = (props: CategoryGroupProps) => {
    const lang = useLanguageState().getLanguage()
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)

    const onCategorySelect = (category: Category) => {
        if (selectedCategory?.key === category.key) {
            setSelectedCategory(null)
        } else {
            setSelectedCategory(category)
        }
    }

    return (
        <Row className="mt-5 pb-5" style={{ marginLeft: "5px", marginRight: "5px" }}>
            <div style={{ width: '100%' }}>
                <HeaderTitle className="mb-3">{props.category.label}</HeaderTitle>
                {props.category.nodes?.map(category => {
                    return <>
                        <CategoryItem
                            key={category.id}
                            className={category.key === selectedCategory?.key ? 'active' : ''}
                            backgroundColor={branding.organizationDetailPageContent.categoryFillColor}
                            backgroundActiveColor={branding.organizationDetailPageContent.categoryActiveFillColor}
                            textColor={branding.organizationDetailPageContent.categoryTextColor}
                            textActiveColor={branding.organizationDetailPageContent.categoryActiveTextColor}
                            onClick={() => onCategorySelect(category)}>{lang === "de" ? category.label : category.labelDe}</CategoryItem>
                    </>
                })}
                {selectedCategory && <SimpleTreeComponent treeData={selectedCategory.nodes!.map(node => {
                    return {
                        key: node.key!,
                        label: node.label!,
                        labelDe: node.labelDe!,
                        nodes: node.nodes as unknown as TreeData[]
                    }
                })} />}
            </div>
        </Row>
    )
}

const Categories = (props: CategoriesProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()

    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)

    const onCategorySelect = (category: Category) => {
        if (selectedCategory?.key === category.key) {
            setSelectedCategory(null)
        } else {
            if(category.nodes?.length) {
                setSelectedCategory(category)
            } else {
                setSelectedCategory(null)
            }
        }
    }


    if (branding.organizationDetailPageContent.rootCategories?.length) {
        return (
            <>
                {props.categories.map(category => {
                    return <CategoryGroup category={category} />
                })}
            </>
        )
    } else {
        return (
            <Row className="mt-5 pb-5" style={{ marginLeft: "5px", marginRight: "5px" }}>
                <div style={{ width: '100%' }}>
                    <HeaderTitle className="mb-3"> {strings.organizationDetailPageContent.categoriesSectionTitle} ({props.categories.length})</HeaderTitle>
                    {props.categories.map(category => {
                        return <CategoryItem key={category.id}
                            className={category.key === selectedCategory?.key ? 'active' : ''}
                            backgroundColor={branding.organizationDetailPageContent.categoryFillColor}
                            backgroundActiveColor={branding.organizationDetailPageContent.categoryActiveFillColor}
                            textColor={branding.organizationDetailPageContent.categoryTextColor}
                            textActiveColor={branding.organizationDetailPageContent.categoryActiveTextColor}
                            onClick={() => onCategorySelect(category)}
                        >{lang === "de" ? category.labelDe : category.label}</CategoryItem>
                    })}
                    {selectedCategory && <SimpleTreeComponent treeData={selectedCategory.nodes!.map(node => {
                        return {
                            key: node.key!,
                            label: node.label!,
                            labelDe: node.labelDe!,
                            nodes: node.nodes as unknown as TreeData[]
                        }
                    })} />}
                </div>
            </Row>
        )
    }

}


export default Categories;