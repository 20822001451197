import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import { IconSearch } from "../ui/Icons"
import branding from "../branding/branding"
import { useAppState } from "../globalStates/AppState"
import { useLanguageState } from "../globalStates/LanguageState"
import { ConditionalWrapper } from "../ui/ConditionalWrapper"
import GuestUserBannerSharedState from "../contentArea/guestUserBanner/GuestUserBannerSharedState"
import SuggestBox, { StaticSuggestionId } from "../ui/SuggestBox"
import { showfloorPageRoute, networkingPageRoute, programPageRoute } from "./RoutePaths"
import { buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import { EntityType } from "../backendServices/Types"

const TopBarRootContainer = styled.div`
    position: relative;
    width: 100%;
    height: 0;
`

const TopBarRoot = styled.div<{
    backgroundColor: string,
    foregroundColor: string,
    background?: string,
    border?: boolean,
    position: string
    top: string
}> `
    position: ${prop => prop.position};
    width: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    height: 60px;
    z-index: 10001;
    border-bottom: ${prop => prop.border === false ? 0 : "1px solid " + prop.foregroundColor};
    background: ${props => props.background ?? ""};
    color: ${props => props.foregroundColor};
    background-color: ${props => props.backgroundColor};
    font-family: ${branding.font1};
    & a:hover,& a:active,& a:link,& a:visited {
        color: ${props => props.foregroundColor};
    }
    top: ${props => props.top};
`

const IconContainer = styled.div`
    width: 50px;
    cursor: pointer;
    padding: 18px 30px 18px 18px;
    margin-left: 10px;
`

const SearchTitle = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    z-index: 11;
    flex: 1;
    cursor: pointer;
    white-space: nowrap;
    align-items: center;
    font-family: ${branding.topBar.searchTitleFontFamily};
    font-size: ${branding.topBar.searchTitleFontSize};
    font-weight: ${branding.topBar.searchTitleFontWeight};
    text-transform: ${branding.topBar.searchTitleTextTranform};
    line-height: 24px;
    letter-spacing: ${branding.topBar.searchTitleLetterSpacing};
    margin-left: 2px;
    margin-bottom: 1px;
`
export enum TopBarMode {
    IN_PAGE, // default
    ABOVE_PAGE, // the top bar hovers over the content of the page
}

interface TopBarProps {
    border?: boolean
    mode?: TopBarMode
    textColor?: string
    backgroundColor?: string
    background?: string
    setRef?: Function
}

const TopBar: React.FunctionComponent<TopBarProps> = (props) => {
    const appState = useAppState()
    const strings = useLanguageState().getStrings()
    const history = useHistory()
    //Add banner state
    const { guestUserBannerRef } = GuestUserBannerSharedState()
    const topBannerRef = useRef<HTMLDivElement>(null)


    const position = props.mode === TopBarMode.ABOVE_PAGE ? "absolute" : "sticky"
    const top = guestUserBannerRef && guestUserBannerRef.current && guestUserBannerRef.current.clientHeight !== 0 ? "50px" : "0";
    let textColor: string, backgroundColor: string


    switch (props.mode) {
        case TopBarMode.ABOVE_PAGE:
            textColor = branding.topBar.textColor
            backgroundColor = "transparent"
            break
        default:
            textColor = props.textColor!
            backgroundColor = props.backgroundColor!
    }


    useEffect(() => {
        if (topBannerRef && topBannerRef.current && props.setRef)
            props.setRef(topBannerRef)
        // eslint-disable-next-line
    }, [topBannerRef])

    function getPath(type: EntityType | undefined) {
        switch (type) {
            case "organization":
            case "product":
            case "trademark":
                return showfloorPageRoute
            case "networking_user":
                return networkingPageRoute
            case "eventdate":
                return programPageRoute
            default:
                return showfloorPageRoute
        }
    }

    return <ConditionalWrapper wrapper={(children) => props.mode === TopBarMode.ABOVE_PAGE ? <TopBarRootContainer children={children} /> : null}>
        <div></div>
        <TopBarRoot ref={topBannerRef} className={appState.isNetworkingOpen() ? "active" : "networkingCollapsed"} foregroundColor={textColor!} backgroundColor={backgroundColor!} border={props.border} position={position} top={top} background={props.background}>

            <IconContainer className="ic-container" onClick={() => appState.setSuggestSearchVisible(true)}>
                {IconSearch({ fill: "currentColor" })}
            </IconContainer>
            {!appState.isSuggestSuggestSearchVisible() && <SearchTitle onClick={() => appState.setSuggestSearchVisible(true)}>
                {strings.globalTexts.globalSearchTitle}
            </SearchTitle>
            }
            {appState.isSuggestSuggestSearchVisible() && <SuggestBox onBlur={() => appState.setSuggestSearchVisible(false)} onSuggestionChanged={(suggestion) => {
                // If we have an entity suggestion and it's not "show more"
                appState.setSuggestSearchVisible(false)
                if (suggestion.id === StaticSuggestionId.SEARCH_ALL) {
                    suggestion.title = suggestion.value
                } else if (suggestion.type && suggestion.id !== StaticSuggestionId.SHOWMORE_ENTITIES) {
                    history.push({
                        pathname: buildDetailLink(suggestion.id, suggestion.title, suggestion.type),
                        search: history.location.search,
                    });

                    return
                }
                appState.setSuggestParam(suggestion)
                history.push({
                    pathname: getPath(suggestion.type),
                    state: { isSuggestion: true }
                })
            }} />}
        </TopBarRoot>
    </ConditionalWrapper>
}

export default TopBar

TopBar.defaultProps = {
    textColor: branding.mainInfoColor ?? "#000",
    backgroundColor: "#fff",
    iconColor: branding.primaryColor ?? "#000"
} as Partial<TopBarProps>
