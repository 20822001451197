import React from "react";
import styled from "styled-components";
import { AvatarType } from "../backendServices/BackendServices"
import { AvatarWithDefault } from "./AvatarWithDefault";
import branding from "../branding/branding";
import AvatarDefaultContent from './AvatarDefaultContent'
import PresenceIndicator from "./PresenceIndicator";
export interface avatarTypeProps {
    type: AvatarType
    bordercolor: string
}

export interface badgeProps {
    bgcolor: string
    btop: number
    bright: number
    bwidth: number
    bfontsize: number
    bpaddingtop: number
}
export interface badgeWrapperProps {
    bsize: number
}

export interface badgePresenceProps {
    bgcolor: string
    badgesize?: number
    top?: number
    right?: number
}

export const BadgeAvatarTypeVisitor: avatarTypeProps = {
    type: AvatarType.VISITOR,
    bordercolor: "#00FFFFFF",
}

export const BadgeAvatarTypeSpeaker: avatarTypeProps = {
    type: AvatarType.SPEAKER,
    bordercolor: branding.dangerButtonColor
}

export const BadgeAvatarTypeExhibitor: avatarTypeProps = {
    type: AvatarType.EXHIBITOR,
    bordercolor: "#262eff", /** red #ff0000*/
}


const BadgeWrapperWithStatus = styled.div <badgeWrapperProps> `
    min-width:${props => props.bsize}px;
    width:${props => props.bsize}px;
    height:${props => props.bsize}px;
    border:1px solid;
    border-radius: 50%;
    padding: 2px 0px 0px 2px;

    &.visitor {
        border-color: transparent;
    }

    &.speaker {
        border-color:  ${branding.dangerButtonColor};
    }

    &.exhibitor {
        border-color: #262eff;
    }
`

const AvatarContainer = styled.div`
    width: 55px;
    position: relative;
`;

interface PictureUrl {
    pictureUrl: string,
    alt: string
}

export interface AvatarWithPresenceStateProps {
    userId?: string
    userType?: any
    content: any | PictureUrl
    avatarSize: number
    badgeSize?: number
    badgeTop?: number
    badgeLeft?: number
    badgeRight?: number
    showAvatarBadge?: boolean
    initPresenceByList?: boolean
}

export const AvatarWithPresenceState: React.FC<AvatarWithPresenceStateProps> = (props) => {
    const userId = props.userId

    if (!userId)
        return null

    const pictureUrlContent = props.content as PictureUrl

    const content: JSX.Element | null = pictureUrlContent.pictureUrl || pictureUrlContent.alt
        ? <AvatarWithDefault size={props.avatarSize - 6} alt={pictureUrlContent.alt} src={pictureUrlContent.pictureUrl} badgesize={(props.badgeSize !== null ? (props.badgeSize) : undefined)} badgeTop={(props.badgeTop !== null ? (props.badgeTop) : undefined)} badgeRight={(props.badgeRight !== null ? (props.badgeRight) : undefined)} badgeLeft={(props.badgeLeft !== null ? (props.badgeLeft) : undefined)} backgroundSize={'cover'}  />
        : React.isValidElement(props.content) ? props.content : <AvatarDefaultContent />

    return <AvatarContainer className="avt-cont">
        <BadgeWrapperWithStatus
            className={props.userType === "attendee" ? 'visitor' : props.userType === "speaker" ? 'speaker' : props.userType === "staff" ? 'exhibitor' : 'visitor'}
            bsize={props.avatarSize}
        >{content}
            {(props.showAvatarBadge && props.badgeSize !== null) &&
                <PresenceIndicator userId={userId} size={props.badgeSize} top={props.badgeTop} right={props.badgeRight} />
            }
        </BadgeWrapperWithStatus>
    </AvatarContainer>
}

export default AvatarWithPresenceState
