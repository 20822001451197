import React from "react";
import styled from "styled-components";
import { Category } from "../backendServices/Types";
import branding from "../branding/branding"

export type BadgeType = "A" | "B"

const FlexBadgeArea = styled.div`
    display: flex;
    flex-wrap: wrap;
`
interface DivBadgeProps {
    bgImage: string
    bgColor: string
    borderColor: string
    textColor: string
}

export const DivBadge = styled.div<DivBadgeProps>`
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
    width: fit-content;
    height: 30px;
    min-width: 70px;
    text-align: center;
    border-style: solid;
    border-radius: 5px;
    border-width: thin;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("${props => props.bgImage}");
    background-color: ${props => props.bgColor};
    border-color: ${props => props.borderColor};
    color: ${props => props.textColor};
`

interface BadgeListProps {
    categories: Category[]
    type: BadgeType
    mainInfoColor?: boolean
}
export const BadgeArea: React.FC<BadgeListProps> = (props) => {

    if (props.categories && props.categories.length > 0)
        return (
            <FlexBadgeArea>
                {props.categories.map((category) => {
                    if (category.badgeType === props.type)
                        return (
                            <DivBadge key={category.id + category.badgeName}
                                bgImage={category.iconSmallUrl}
                                bgColor={category.colorFill}
                                borderColor={props.mainInfoColor ? branding.mainInfoColor : category.colorBorder}
                                textColor={props.mainInfoColor ? branding.mainInfoColor : category.colorText}>
                                {category.iconSmallUrl ? null : category.badgeName}
                            </DivBadge>
                        )
                    else return null
                })}
            </FlexBadgeArea>
        )
    else
        return null
}
export default BadgeArea
