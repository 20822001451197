import { createState, self, State, useState } from '@hookstate/core';
import { throttle } from 'lodash';

interface StateValues {
    activeSpeakers: string[]
}

const getStartValues = (): StateValues => {
    return {
        activeSpeakers: []
    }
}
const state = createState<StateValues>(getStartValues())

export interface ActiveSpeakerContext {
    getActiveSpeakers: () => string[],
    isActiveSpeaker: (attendeeId?: string | null) => boolean
}

const useStateWrapper = (state: State<StateValues>) => {
    const activeSpeakerCallback = throttle((activeSpeakers: string[]) => {
        state[self].set(prev => {
            prev.activeSpeakers = activeSpeakers
            return prev
        })
    }, 1000)

    return ({
        getActiveSpeakers: () => {
            return state[self].value.activeSpeakers
        },
        isActiveSpeaker: (attendeeId?: string | null) => {
            return attendeeId ? state[self].value.activeSpeakers.indexOf(attendeeId) > -1 : false
        },
        setActiveSpeakers: (activeSpeakers: string[]) => {
            activeSpeakerCallback(activeSpeakers)
        }
    })
}

export const accessActiveSpeakerContext = useStateWrapper(state)
export const useActiveSpeakerContext = (): ActiveSpeakerContext => useState(state)[self].map(useStateWrapper)