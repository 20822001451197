import React, { useMemo } from "react"
import styled from "styled-components"
import { useLanguageState } from "../globalStates/LanguageState"
import branding from "../branding/branding"
import CrsMultiSwitch from "../ui/CrsMultiSwitch"
import { Row } from "react-bootstrap";



const FilterBarRoot = styled(Row)`
    margin: 0;
    padding: 5x;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${branding.mainInfoColor};
`
const FilterBarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 20px; /* added */
    /* width: 97%; */
    
`

interface FilterBarProps {
    activeFilter: string | null
    onFilterChanged(value: string | null): void
}

const FilterBar: React.FunctionComponent<FilterBarProps> = (props: FilterBarProps) => {
    const strings = useLanguageState().getStrings()

    const filterItems = useMemo(() => {
        const items = "#ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map(c => { return { value: c, label: c } }) as { value: string | null; label: string }[]
        items.unshift({ value: null, label: "" })
        return items
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // "All" text changes on language change. Therefor this cannot be part of the memo
    filterItems[0].label = strings.filterBar.allFilterTitle

    return (
        <FilterBarRoot>
            <FilterBarContainer>
                <CrsMultiSwitch items={filterItems} activeItem={props.activeFilter} onItemClick={props.onFilterChanged} style={{ mainWidth: "100%", border: "1px solid transparent", msRootItem: { fontSize: '0.8rem' }, msItem: { position: "relative", zIndex: 1 }, msActiveItem: { backgroundColor: branding.crsMultiSwitcher.crsMultiSwitcherDefaultBgColor, color: branding.crsMultiSwitcher.crsMultiSwitcherDefaultColor, position: "absolute", zIndex: 0 } }} />
            </FilterBarContainer>
        </FilterBarRoot>
    )
}

export default FilterBar;
