import { createState, useState, self } from '@hookstate/core';
import { CrsAlertType } from '../ui/CrsAlert';

interface AlertData {
    type: CrsAlertType,
    message: string,
    duration?: number // value in ms 
}

interface AlertState {
    showAlert: boolean,
    alertData: AlertData | null
}

export const useAlertState = createState({} as AlertState)[self].map(s => () => {
    const state = useState(s)
    return ({
        show: (newState: { message: string, type: CrsAlertType, duration?: number }) => {
            state[self].set({ ...{ showAlert: true }, ...{ alertData: newState} })
        },
        isAlertActive: (): boolean => {
            return state[self].get().showAlert
        },
        hideAlert: (): void => {
            state[self].set({showAlert: false, alertData: null})
        },
        getAlertData: (): AlertData | null => {
            return state[self].get().alertData;
        }
    })
})