import React from "react";
import { updateProfileData } from "../../backendServices/BackendServices";
import branding from "../../branding/branding";
import { useLanguageState } from "../../globalStates/LanguageState";
import { useLoggedInState } from "../../globalStates/LoggedInUser";
import { IconNetworking } from "../../ui/Icons";
import ToggleSwitch from "../../ui/ToggleSwitch";
import { SettingsAreaRow } from "../SettingsTab";

interface NetworkingToggleProps {
    customEnabled?: boolean
    customFunction?: () => void
}

export default function NetworkingToggle(props: NetworkingToggleProps) {
    const userState = useLoggedInState()
    const strings = useLanguageState().getStrings()

    async function toggleNetworkingState() {
        if (userState.user()) {

            if (props.customFunction) {
                props.customFunction()
            }

            const res = await updateProfileData({ profileId: userState.user()!.profileId, profileData: { ...userState.user(), matchActive: !userState.isMatchActive(), id: userState.user()?.profileId } })
            userState.setMatchActive(res.content.matchActive)


        }
    }


    if (!branding.configuration.networkingToggle)
        return null
    return <SettingsAreaRow>
        {IconNetworking({ fill: branding.sideIconBar.sideIconColorDark })}
        <p>{strings.navigationArea.networkingItemTitle}</p>
        <ToggleSwitch isEnabled={props.customEnabled || userState.isMatchActive()} onClick={toggleNetworkingState} />
    </SettingsAreaRow>
}