import React, { useState } from "react"
import styled from "styled-components"
import { syncFavorites, trackVisit } from "../backendServices/BackendServices"
import { Company, ShareTargetType } from "../backendServices/Types"
import branding from "../branding/branding"
import { buildDetailLink, DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { useFavoriteState } from "../globalStates/Favorites"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { IconShare, IconMeetingSchedule, IconCall, IconBookmarkToggle, IconBookmarkFilled15, IconDownloadVCard } from "../ui/Icons"
import CalendarEntryModal, { CalendarEntryModalViewMode } from "./CalendarEntryModal"
import RecommendOrganizationModal from "./RecommendOrganizationModal"
import SayHelloModal from "./SayHelloModal"
import moment from "moment"
import { saveExhibitorVCard } from "../communicationArea/VCard"
import { hasAccessToOrganization } from "../utils/Authorization"

const OrgInfoDiv = styled.div`
    margin-right: -10px;
`
const OrgContactDiv = styled.div`
    margin-top: 10px;
`
const OrgFunctionDiv = styled.div`
    margin-top: 10px;
`
const HeaderIconSpan = styled.span`
    padding-right: 5px;
`
const HeaderItemsTitle = styled.div`
    cursor: pointer;
    font-size: 14px; 
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor ?? "#000"};
    @media(max-width: 1600px) {
       font-size: 10px;
    }

    @media(max-width: 1400px) {
       font-size: 8px;
    }

    @media(max-width: 1200px) {
       font-size: 6px;
    }
`
const CompanyLogoDiv = styled(DetailNavLink)`
    width: 150px;
    height: 150px;
    background: white;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-top: 20px;
    margin-right: 20px;

    @media(max-width: 1600px) {
        width: 120px;
        height: 120px;
    }

    @media(max-width: 1400px) {
        width: 100px;
        height: 100px;
    }

    @media(maxWidth: 1200px) {
        width: 80px;
        height: 80px;
    }
`
const CompanyLogo = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`

export interface OrgaLogoWithConnectionFuncProps {
    organization: Company
}

export const OrgaLogoWithConnectionFunc: React.FC<OrgaLogoWithConnectionFuncProps> = (props) => {

    const strings = useLanguageState().getStrings()
    const language = useLanguageState().getLanguage()

    const loggedInUser = useLoggedInState().user()
    const favorites = useFavoriteState()

    const link = buildDetailLink(props.organization.id, props.organization.name, 'organization')

    // eslint-disable-next-line
    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState(false);
    const [showRequestOrganizationModal, setShowRequestOrganizationModal] = useState<boolean>(false)
    const [showRecommendOrganizationModal, setShowRecommendOrganizationModal] = useState<boolean>(false)


    const updateBookmarkedStatus = () => {
        if (props.organization.id) {
            if (loggedInUser?.profileId) {
                favorites.toggle("organization", props.organization.id)
                syncFavorites({
                    profileId: loggedInUser.profileId,
                    body: {
                        currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        lastSyncTime: favorites.getLastSyncTime(),
                        changedFavorites: [{
                            id: props.organization.id,
                            kind: ("organization").toUpperCase(),
                            deleted: favorites.is("organization", props.organization.id) ? false : true,
                            lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }]
                    }
                }).then((resp) => {
                    favorites.setLastSyncTime(new Date())
                }).catch((e: { message: React.SetStateAction<string> }) => {
                    // syncFavorites failed, logged in BackendServices
                })
            }
        }
    }

    function getVCardNote() {
        var date = moment()

        var template = strings.organizationDetailPageContent.vCardNoteTemplate
        var contentString = template.split('{$eventGuideName}').join(strings.configuration.ogTitle)
        contentString = contentString.split('{$date}').join(language === "en" ? date.format("MMMM DD, YYYY") : date.format("DD. MMMM YYYY"))

        return contentString
    }

    function doVCard(organization: Company) {
        saveExhibitorVCard(organization, getVCardNote())
    }

    return (
        <OrgInfoDiv>
            <CompanyLogoDiv type="organization" id={props.organization.id} name={props.organization.name} source="EVENTDATE">
                <CompanyLogo src={props.organization.pictureURL ? props.organization.pictureURL : "/branding/rsz_defaultgrey.png"} />
            </CompanyLogoDiv>
            <OrgContactDiv>
                <HeaderItemsTitle>
                    {strings.organizationDetailPageContent.companyContactToTitle}
                    <br />
                    <b>{props.organization.name}</b>
                </HeaderItemsTitle>
            </OrgContactDiv>
            <OrgFunctionDiv>
                <div onClick={() => {
                    setShowRequestMeetingModal(true)
                    trackVisit(loggedInUser!.profileId, props.organization.id, "CALENDARENTRY#CLICK")
                }} >
                    <HeaderItemsTitle>
                        <HeaderIconSpan>{IconMeetingSchedule({ stroke: branding.sideIconBar.sideIconColorDark ?? "#000", fill: branding.sideIconBar.sideIconColorDark ?? "#000", width: "15" })}</HeaderIconSpan>
                        {strings.organizationDetailPageContent.requestMeeting}
                    </HeaderItemsTitle>
                </div>
                <div onClick={() => {
                    setShowRequestOrganizationModal(true)
                    trackVisit(loggedInUser!.profileId, props.organization.id, "INTEREST#CLICK")
                }} >
                    <HeaderItemsTitle>
                        <HeaderIconSpan>{IconCall({ stroke: branding.sideIconBar.sideIconColorDark ?? "#000", fill: branding.sideIconBar.sideIconColorDark ?? "#000", width: "15" })}</HeaderIconSpan>
                        {strings.organizationDetailPageContent.registerInterest}
                    </HeaderItemsTitle>
                </div>
                <div onClick={() => {
                    setShowRecommendOrganizationModal(true)
                    trackVisit(loggedInUser!.profileId, props.organization.id, "RECOMMENDATION#CLICK")
                }} >
                    <HeaderItemsTitle>
                        <HeaderIconSpan> {IconShare({ stroke: branding.sideIconBar.sideIconColorDark ?? "#000", fill: branding.sideIconBar.sideIconColorDark ?? "#000", width: "15" })}</HeaderIconSpan>
                        {strings.organizationDetailPageContent.recommend}
                    </HeaderItemsTitle>
                </div>
                <div onClick={() => { updateBookmarkedStatus() }}>
                    <HeaderItemsTitle>
                        <HeaderIconSpan>{favorites.is("organization", props.organization.id) ? branding.defaultToggleIcon ? IconBookmarkFilled15({ fill: branding.sideIconBar.sideIconColorDark }) : IconBookmarkToggle({ width: "15", fill: branding.sideIconBar.sideIconColorDark }) : IconBookmarkToggle({ width: "15", fill: branding.sideIconBar.sideIconColorDark })}</HeaderIconSpan>
                        {strings.organizationDetailPageContent.bookmark}
                    </HeaderItemsTitle>
                </div>
                {branding.organizationDetailPageContent.vCardDownloadAvailable &&
                    hasAccessToOrganization(loggedInUser!, props.organization.id) &&
                    <div onClick={() => { doVCard(props.organization) }}>
                        <HeaderItemsTitle>
                            <HeaderIconSpan>{IconDownloadVCard({ width: "15", height: "15", fill: branding.sideIconBar.sideIconColorDark })}</HeaderIconSpan>
                            {strings.contactEntry.downloadVCardTitle}
                        </HeaderItemsTitle>
                    </div>}
            </OrgFunctionDiv>
            {showRequestMeetingModal && <CalendarEntryModal viewMode={CalendarEntryModalViewMode.CREATE} organization={props.organization.id} close={() => setShowRequestMeetingModal(false)} />}
            {showRequestOrganizationModal && <SayHelloModal organizationModal={true} targetId={props.organization.id || ""} handleClose={() => setShowRequestOrganizationModal(false)}></SayHelloModal>}
            {showRecommendOrganizationModal && <RecommendOrganizationModal targetId={props.organization.id || ""} type={ShareTargetType.ORGANIZATION} link={`https://${window.location.hostname}` + link} sotUser={[]} close={() => setShowRecommendOrganizationModal(false)}></RecommendOrganizationModal>}
        </OrgInfoDiv>
    )
}

export default OrgaLogoWithConnectionFunc
