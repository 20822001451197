import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Company, ShareTargetType } from "../backendServices/Types";
import { loadExhibitorData, syncFavorites } from "../backendServices/BackendServices";
import CenteredLoader from "../ui/CenteredLoader";
import { DetailNavLink, buildDetailLink } from "../contentArea/detailPages/DetailNavLink";
import branding from "../branding/branding";
import ContextMenu, { HoverButton, MenuItemProps } from "../ui/ContextMenu";
import { IconCameraJoin, IconMeetingSchedule, IconCall, IconShare, IconBookmarkToggle, IconBookmarkFilled, IconDownloadVCard } from "../ui/Icons";
import { useLanguageState } from "../globalStates/LanguageState";
import { useFavoriteState } from "../globalStates/Favorites";
import CalendarEntryModal, { CalendarEntryModalViewMode } from "../ui/CalendarEntryModal";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import SayHelloModal from "../ui/SayHelloModal";
import { useChimeContext } from "../conference/context/ChimeContext";
import { useMeetingContext } from "../conference/context/MeetingContext";
import { joinShowroom } from "../contentArea/detailPages/OrganizationDetailPageContent";
import RecommendOrganizationModal from "../ui/RecommendOrganizationModal";
import moment from "moment";
import { isExplorationOrPostEventPhase } from "../utils/EventPhaseChecker";
import { Divider } from "./ContactEntry"
import { saveExhibitorVCard } from "./VCard"
import { hasAccessToOrganization } from "../utils/Authorization"
import { getIamPartOf } from "../globalStates/IAmPartOf";
import queryString from 'query-string'

export interface OrganizationEntryBranding {

}

const OrganizationEntryRoot = styled.div`
  
`;

const HoverRoot = styled.div`
    &:hover {
        background-color: rgba(240, 240, 240, 1);
    }
    
`;

const OrganizationEntryContainer = styled.div`
  padding-left: 15px;
  padding-right: 10px;
`;

const OrganizationDataRoot = styled(DetailNavLink)`
  display: flex;
  color: ${branding.mainInfoColor ?? "black"};
`;

const OrganizationName = styled.div`
  width: 100%;    
  margin-top: 25px;
  margin-left: 10px;
  font-family: ${branding.font1};
  font-weight: bold;
  font-size: 0.9375rem; /** 15px */
`;

const OrganizationLogoDiv = styled.div`
    width: 60px;
    height: 60px;
    background: white;
    border: 1px solid #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
`

const OrganizationLogo = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`



const AvatarContainer = styled.div`
    margin-top: 7px;
    margin-bottom: 8px;
`

const ContextButtonContainer = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`

const CenteredLoaderRoot = styled.div`
    height: 100px;
`;


interface OrganizationEntryProps {
    organizationId: string
};
const OrganizationEntry: React.FunctionComponent<OrganizationEntryProps> = (props) => {
    const [organization, setOrganization] = useState<Company>();
    const [contextMenuCollapsed, setContextMenuCollapsed] = useState<boolean>(true)
    const strings = useLanguageState().getStrings()
    const language = useLanguageState().getLanguage()
    const favoriteState = useFavoriteState()
    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState(false);
    const [showRequestOrganizationModal, setShowRequestOrganizationModal] = useState(false)
    const [showRecommendOrganizationModal, setShowRecommendOrganizationModal] = useState(false)
    const userState = useLoggedInState()
    const user = userState.user()
    const chime = useChimeContext()
    const meeting = useMeetingContext()
    const queryParams: any = queryString.parse(window.location.search)

    useEffect(() => {
        loadExhibitorData({
            organizationid: props.organizationId,
            hideNewsdata: true
        }).then((resp) => {
            setOrganization(resp.content)
        })
        // eslint-disable-next-line
    }, [props.organizationId]);

    function onJoinShowroom() {
        if (organization && user) {
            joinShowroom(organization, user, chime, meeting)
        }
    }

    function onRequestMeeting() {
        setShowRequestMeetingModal(true)
    }

    function onRequestContact() {
        setShowRequestOrganizationModal(true)
    }

    function onRecommendOrganization() {
        setShowRecommendOrganizationModal(true);
    }

    function getVCardNote() {
        var date = moment()

        var template = strings.organizationDetailPageContent.vCardNoteTemplate
        var contentString = template.split('{$eventGuideName}').join(strings.configuration.ogTitle)
        contentString = contentString.split('{$date}').join(language === "en" ? date.format("MMMM DD, YYYY") : date.format("DD. MMMM YYYY"))

        return contentString
    }

    function doVCard(organization: Company) {
        saveExhibitorVCard(organization, getVCardNote())
    }

    function onBookmark() {
        if (organization && user) {
            let date = new Date()
            date.setMinutes(date.getMinutes() - 1)
            favoriteState.toggle("organization", organization.id)
            const isFavorite = favoriteState.is("organization", organization.id)
            syncFavorites({
                profileId: user.profileId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //"2020-01-01 12:00:00", 
                    lastSyncTime: favoriteState.getLastSyncTime(),
                    changedFavorites: [{
                        id: organization.id,
                        kind: "ORGANIZATION",
                        deleted: !isFavorite,
                        lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    }]
                }
            }).then(() => {
                favoriteState.setLastSyncTime(new Date())
            });
        }
    }

    const content: JSX.Element = organization ?
        <OrganizationEntryRoot>
            <HoverRoot>
                <OrganizationEntryContainer>
                    <OrganizationDataRoot type="organization" id={organization.id} name={organization.name}>
                        <AvatarContainer>
                            <OrganizationLogoDiv>
                                <OrganizationLogo src={organization.pictureURL} alt={organization.name} />
                            </OrganizationLogoDiv>
                        </AvatarContainer>
                        <OrganizationName>{organization.name}</OrganizationName>
                        <ContextButtonContainer>
                            <HoverButton onClick={() => setContextMenuCollapsed(!contextMenuCollapsed)} selected={!contextMenuCollapsed} />
                        </ContextButtonContainer>
                    </OrganizationDataRoot>

                </OrganizationEntryContainer>
            </HoverRoot>
            <ContextMenu collapsed={contextMenuCollapsed} items={() => {
                const menuItems: MenuItemProps[] = []

                // Join showroom
                menuItems.push({
                    disabled: isExplorationOrPostEventPhase && !(getIamPartOf(queryParams) === "onboarding"),
                    title: strings.organizationDetailPageContent.joinShowroom,
                    icon: IconCameraJoin({ fill: branding.sideIconBar.sideIconColorDark }),
                    onClick: () => onJoinShowroom()
                })

                // Request meeting
                menuItems.push({
                    title: strings.organizationDetailPageContent.requestMeeting,
                    icon: IconMeetingSchedule({ fill: branding.sideIconBar.sideIconColorDark }),
                    onClick: () => onRequestMeeting()
                })

                // Request contact
                menuItems.push({
                    title: strings.organizationDetailPageContent.registerInterest,
                    icon: IconCall({ fill: branding.sideIconBar.sideIconColorDark }),
                    onClick: () => onRequestContact()
                })

                // Recommend organization
                menuItems.push({
                    title: strings.organizationDetailPageContent.recommend,
                    icon: IconShare({ fill: branding.sideIconBar.sideIconColorDark }),
                    onClick: () => onRecommendOrganization()
                })

                // Bookmark organization
                const isBookmarked = favoriteState.is("organization", organization.id)
                menuItems.push({
                    title: strings.organizationDetailPageContent.bookmark,
                    icon: isBookmarked ? branding.defaultToggleIcon ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark }) : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark }) : IconBookmarkToggle({ fill: "#fff" }),
                    onClick: () => onBookmark()
                })

                //Download vCard
                if (branding.organizationDetailPageContent.vCardDownloadAvailable) {
                    menuItems.push({
                        disabled: !(hasAccessToOrganization(userState.user()!, organization.id)),
                        title: strings.contactEntry.downloadVCardTitle,
                        icon: IconDownloadVCard({ fill: branding.sideIconBar.sideIconColorDark }),
                        onClick: () => doVCard(organization)
                    })
                }

                return menuItems
            }} />
            <Divider />
            {showRequestMeetingModal && user && <CalendarEntryModal viewMode={CalendarEntryModalViewMode.CREATE} organization={organization.id} close={() => setShowRequestMeetingModal(false)} />}
            {showRequestOrganizationModal && <SayHelloModal organizationModal={true} targetId={organization.id} handleClose={() => setShowRequestOrganizationModal(false)} />}
            {showRecommendOrganizationModal && <RecommendOrganizationModal targetId={organization.id} type={ShareTargetType.ORGANIZATION} link={`https://${window.location.hostname}` + buildDetailLink(organization.id, organization.name, 'organization')} sotUser={[]} close={() => setShowRecommendOrganizationModal(false)} />}
        </OrganizationEntryRoot> :
        // Organization not loaded yet, show loading indicator
        <CenteredLoaderRoot>
            <CenteredLoader />
        </CenteredLoaderRoot>

    return content
}

export default OrganizationEntry

