import React, { useEffect, useState } from "react"
import { NavLink, useHistory, useParams } from 'react-router-dom';
import styled from "styled-components"
import TopBar from "../navigationArea/TopBar";
import Breadcrumb from "../navigationArea/Breadcrumb";
import { useLanguageState } from "../globalStates/LanguageState";
import { ContentScrollContainer } from "../ui/ScrollContainer"
import { calcBreadcrumbLocations } from "../tracking/RouteTracker";
import ChatRoomAreaTab from "../chatRoomArea/ChatRoomArea";
import Schedule from './program/Schedule';
import { loadChannelFirstEventDate, BackendServiceError, EventDateChannelFirstDetailsResponse, getChannelInfo, ChannelResponse } from "../backendServices/BackendServices";
import { ShareTargetType, EventDate } from "../backendServices/Types";
import RecommendOrganizationModal from "../ui/RecommendOrganizationModal";
import { EventDateDetailBody } from "./detailPages/EventDateDetailPageContent";
import CenteredLoader from "../ui/CenteredLoader";
import moment from 'moment';
import { calcConversationId } from "../communicationArea/ChatPage";
import BackendError from "../ui/BackendError";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import { useChimeContext, MeetingStatusCode } from "../conference/context/ChimeContext";
import GuestUserBanner from "./guestUserBanner/GuestUserBanner";
import GuestUserBannerSharedState from "./guestUserBanner/GuestUserBannerSharedState";
import VideoPlayer from "./videoPlayer/VideoPlayer";
import { useAppState } from "../globalStates/AppState";
import { hasAccessToOrganization } from "../utils/Authorization";
import { programPageRoute } from "../navigationArea/RoutePaths"
import { usePrivacyPolicyModal } from "../ui/PrivacyPolicyModal";
import { accessMyPresenceState, EventType } from "../ui/PresenceIndicator";
import branding from "../branding/branding";
import { momentWithoutTimezoneFromTimezonedMoment } from "../utils/DateUtils";
import { useOnlineUsers } from "../tracking/LoungeUsers";
import { IconRoundTable } from "../ui/Icons";


const VideoPageContentRoot = styled.div<{ guestBannerHeight: number }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 110px - ${props => props.guestBannerHeight + "px"});
    max-height: calc(100% - 110px - ${props => props.guestBannerHeight + "px"});

    .ScrollbarsCustom-Content {
        padding: 0 !important;
    }
    & .eventDate-root .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
        display: none;
    }
`


interface VideoPageContentProps {
}

function ContentSwitch(props: {
    channelId?: string,
    streamUrl: string,
    eventDate?: EventDate,
    nextEventDate?: EventDate,
    calculatedChange: number,
    comingUpVisible?: boolean,
    breakoutDisabled?: boolean
    guestBannerHeight: number
    setTargetId: (target: string) => void,
    setLink: (link: string) => void,
    setType: (type: ShareTargetType) => void,
    setShowRecommendOrganizationModal: (show: boolean) => void
}) {
    let eventDateDetailContent = null;
    const { showPrivacyPolicyModal } = usePrivacyPolicyModal()

    if (props.eventDate) {
        eventDateDetailContent =
            <>
                {
                    branding.configuration.breakoutEnabled && props.eventDate.breakoutAccess &&
                    <JoinBreakout eventDate={props.eventDate} disabled={props.breakoutDisabled} />
                }
                {
                    props.comingUpVisible &&
                    <ComingUp nextEventDate={props.nextEventDate} calculatedChange={props.calculatedChange} />
                }
                <EventDateDetailBody
                    eventDate={props.eventDate}
                    setShowRecommendOrganizationModal={props.setShowRecommendOrganizationModal}
                    setLink={props.setLink}
                    setTargetId={props.setTargetId}
                    setType={props.setType}
                    removeChat={true}
                    removePreviewImage={true}
                    guestBannerHeight={props.guestBannerHeight}
                    showPrivacyPolicyModal={showPrivacyPolicyModal} /></>
    }
    else {
        eventDateDetailContent = <CenteredLoader />
    }
    // const demoVideo5Url = "https://7ratuu6d7oivlv.data.mediastore.eu-central-1.amazonaws.com/testChannel/index.m3u8"

    if (props.channelId !== undefined) {
        return <VideoPageContentRoot guestBannerHeight={props.guestBannerHeight}>
            <ContentScrollContainer containerWidth={props.eventDate ? "75%" : "100%"} adjustForHeaderWith={110 + props.guestBannerHeight + "px"}>
                <VideoPlayer src={props.streamUrl} controls={true} live={true} />
                {props.eventDate && eventDateDetailContent}
            </ContentScrollContainer>
            {props.eventDate && <div style={{ width: "25%" }}>
                <ChatRoomAreaTab chatRoom={props.eventDate.id} slidoName={props.eventDate.channel?.slidoName ?? props.eventDate.location} />
            </div>}
        </VideoPageContentRoot>
    } else {
        return <Schedule category="masterclass" guestBannerHeight={props.guestBannerHeight} />
    }
}

export function calculateEnd(currentEventEndDate: string, timezone: any) {
    return moment(moment(currentEventEndDate)).diff(momentWithoutTimezoneFromTimezonedMoment(moment(), timezone))
}

export function calculateBreakoutRemainingTime(currentEventEndDate: string, breakoutTime: number, timezone: any) {
    return moment(moment(currentEventEndDate)).subtract(breakoutTime).diff(momentWithoutTimezoneFromTimezonedMoment(moment(), timezone))
}

const VideoPageContent: React.FC<VideoPageContentProps> = (props) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const locations = calcBreadcrumbLocations(strings)
    const chime = useChimeContext()
    const logedUser = useLoggedInState().user()
    const { channelId }: any = useParams()
    const [eventDate, setEventDate] = useState<EventDate>()
    const [error, setError] = useState<string>()
    const history = useHistory()
    const [targetId, setTargetId] = useState<string>("")
    const [link, setLink] = useState<string>("")
    const [type, setType] = useState<ShareTargetType>()
    const [breakoutDisabled, setBreakoutDisabled] = useState<boolean>(true)
    const [showRecommendOrganizationModal, setShowRecommendOrganizationModal] = useState(false)
    const timezone = useAppState().timezone

    //add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()

    const [streamUrl, setStreamUrl] = useState<string>()
    const [channelName, setChannelName] = useState<string>("")

    const [nextEventDate, setNextEventDate] = useState<EventDate>()

    const [calculatedChange, setCalculatedChange] = useState<number>(0)
    const [comingUpVisible, setComingUpVisible] = useState<boolean>(false)

    //privacy modal
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal(!branding.configuration.showPrivacyModelForEventDates)

    useEffect(() => {
        if (eventDate?.organizationId)
            showPrivacyPolicyModal(eventDate?.organizationId, () => { })
    }, [eventDate?.organizationId]) //eslint-disable-line


    function calculateChange(currentEventEndDate: string, nextEventStartDate: string) {
        const changeDiff = moment(moment(nextEventStartDate)).diff(moment(currentEventEndDate))

        if (changeDiff <= (10 * 60 * 1000)) {
            return changeDiff / 2
        }
        else {
            return changeDiff - (branding.videoPageContentBranding.minutesBeforeSwitchingToTheNextEvent * 60 * 1000)
        }
    }

    const randomUrl = (urls: string[]) => {
        return urls[Math.floor(Math.random() * urls.length)]
    }

    const appState = useAppState()

    useEffect(() => {
        const loadChannel = (eventDate?: EventDate) => {
            getChannelInfo(channelId).then((data) => {
                if ((data as BackendServiceError).httpStatus) {
                    // TODO ERROR
                } else {
                    data = data as ChannelResponse
                    const streamUrl = data.redundantUrl ? randomUrl([data.url, data.redundantUrl]) : data.url
                    const channelName = data.name ?? "Channel"
                    appState.setLiveStreamChannel({
                        id: data.channelId,
                        url: streamUrl,
                        eventDate: eventDate
                    })
                    setStreamUrl(streamUrl)
                    setChannelName(channelName)
                }
            })
        }

        function loadData() {
            loadChannelFirstEventDate(channelId).then((data) => {
                if ((data as BackendServiceError).httpStatus) {
                    const backendError = data as BackendServiceError
                    if (backendError.httpStatus === 501) {
                        // no current event date
                        loadChannel()
                    } else {
                        setError(backendError.httpStatusText)
                    }

                } else {
                    const resp = data as EventDateChannelFirstDetailsResponse
                    loadChannel(resp.currentEventDate)
                    setEventDate(resp.currentEventDate)
                    if (resp.nextEventDate) {
                        setNextEventDate(resp.nextEventDate)

                        const remainingCurrentEventDate = calculateEnd(resp.currentEventDate.enddatetime, timezone)
                        if (remainingCurrentEventDate > 0) {
                            remainingCurrentEventDateTimer = window.setTimeout(() => {  // Trigger on event end
                                const changeExecution = calculateChange(resp.currentEventDate.enddatetime, resp.nextEventDate!.startdatetime)

                                setCalculatedChange(changeExecution)
                                setComingUpVisible(true)

                                changeExecutionTimer = window.setTimeout(() => { //Trigger data load
                                    loadData()
                                    setComingUpVisible(false)
                                }, changeExecution);
                            }, remainingCurrentEventDate);
                        }
                    }
                }
            }).catch(err => setError(err.message))
        }

        let remainingCurrentEventDateTimer: number
        let changeExecutionTimer: number

        accessMyPresenceState.updateMyPresence(EventType.EVENT_BEGIN)

        loadData()
        return () => {
            clearTimeout(remainingCurrentEventDateTimer)
            clearTimeout(changeExecutionTimer)
            // dont change presence only if currently in call
            if (chime.getMeetingStatus().meetingStatus !== MeetingStatusCode.Succeeded) {
                accessMyPresenceState.updateMyPresence(EventType.EVENT_END)
            }
        }

        // eslint-disable-next-line
    }, [channelId])

    useEffect(() => {
        appState.setLiveStreamChannel(Object.assign({}, appState.liveStreamChannel, {eventDate: eventDate}))
        // eslint-disable-next-line
    }, [eventDate])

    useEffect(() => {

        const breakoutTimeBeforeEnd = branding.videoPageContentBranding.minutesBeforeBreakoutEnabled * 60 * 1000 // minutes remain to enable breakout button * 60 * 1000
        let remainingCurrentEventDateTimer: number

        if (eventDate && calculateEnd(eventDate.enddatetime, timezone) <= breakoutTimeBeforeEnd) {
            setBreakoutDisabled(false)
        } else if (eventDate && breakoutDisabled === true) {
            const remainingTimeUntilBreakoutEnabled = calculateBreakoutRemainingTime(eventDate.enddatetime, breakoutTimeBeforeEnd, timezone)
            if (remainingTimeUntilBreakoutEnabled > 0) {
                window.setTimeout(() => {
                    setBreakoutDisabled(false)
                }, remainingTimeUntilBreakoutEnabled);
            }
        } else {
            setBreakoutDisabled(true)
        }

        return () => {
            if (remainingCurrentEventDateTimer)
                clearTimeout(remainingCurrentEventDateTimer)
        }

    }, [eventDate]) //eslint-disable-line


    const organizationId = eventDate?.organizationId;
    if (eventDate && (!logedUser || (logedUser.type === "guest" && (!organizationId || !hasAccessToOrganization(logedUser, organizationId))))) {
        history.push(programPageRoute) // TODO better behaviour for guests with missing access (show dialog)
        setTimeout(() => appState.setLiveStreamChannel(null), 500) // Quick fix, directly setting to null leads to infinite rerender // TODO real solution
    }

    return <>
        <GuestUserBanner setRef={setGuestUserBannerRef} />
        <TopBar />
        <div style={{ marginTop: "0px", borderBottom: "1px solid currentColor" }}>
            <Breadcrumb breadcrumb={[{ to: locations[0], name: channelName }]} />
        </div>
        {!error && streamUrl &&
            <ContentSwitch channelId={calcConversationId(channelId)} setLink={setLink} setType={setType} setTargetId={setTargetId} eventDate={eventDate} nextEventDate={nextEventDate} streamUrl={streamUrl}
                setShowRecommendOrganizationModal={setShowRecommendOrganizationModal}
                guestBannerHeight={guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0}
                breakoutDisabled={breakoutDisabled} calculatedChange={calculatedChange} comingUpVisible={comingUpVisible} />}
        {error && <div style={{ marginTop: "25%" }}><BackendError /></div>}
        {showRecommendOrganizationModal && <RecommendOrganizationModal targetId={targetId} type={type as ShareTargetType} link={`https://${window.location.hostname}` + link} sotUser={[]} close={() => setShowRecommendOrganizationModal(false)}></RecommendOrganizationModal>}
        <PrivacyModal />
    </>
}

interface JoinBreakoutProps {
    eventDate: EventDate
    disabled?: boolean
}

const JoinBreakout: React.FC<JoinBreakoutProps> = (props) => {
    const onlineUsers = useOnlineUsers()
    const participants = onlineUsers.getUsersInRoomOrLounge(`br_${props.eventDate.id}`)
    const strings = useLanguageState().getStrings()

    useEffect(() => {
        onlineUsers.subscribe("breakout#")
        return () => {
            onlineUsers.unsubscribe()
        }
    }, []) //eslint-disable-line

    return (
        <JoinBreakoutRoot>
            <JoinBreakoutIcon>{IconRoundTable({ width: "25", height: "25", fill: branding.mainInfoColor })}</JoinBreakoutIcon>
            <JoinBreakoutMessage>{strings.videoPageContentBranding.joinBreakoutMessage}</JoinBreakoutMessage>
            <JoinBreakoutButtonArea>
                <JoinBreakoutButton to={`/meeting/br_${props.eventDate.id}/createorjoin`} disabled={props.disabled || participants.length === branding.configuration.breakoutParticipantLimit}>{strings.videoPageContentBranding.joinBreakoutButtonText}</JoinBreakoutButton>
                <JoinBreakoutParticipantsCounter> {`(${participants.length} / ${branding.configuration.breakoutParticipantLimit}) `}{branding.videoPageContentBranding.participantsCounterText}</JoinBreakoutParticipantsCounter>
            </JoinBreakoutButtonArea>
        </JoinBreakoutRoot>
    )
}

interface ComingUpProps {
    nextEventDate?: EventDate
    calculatedChange: number
}

const ComingUp: React.FC<ComingUpProps> = (props) => {
    const [millisecondsLeft, setMillisecondsLeft] = useState<number>(props.calculatedChange)

    const [time, setTime] = useState(moment(props.calculatedChange).format("mm:ss"))

    const strings = useLanguageState().getStrings()

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(moment(millisecondsLeft - 1000).format("mm:ss"))

            setMillisecondsLeft(millisecondsLeft - 1000)

        }, 1000)

        return () => clearInterval(interval)
    })

    return (
        <JoinBreakoutRoot style={{ borderTop: "none" }}>
            <JoinBreakoutIcon style={{ width: "25px" }}></JoinBreakoutIcon>
            <JoinBreakoutMessage>
                {strings.videoPageContentBranding.comingUpText}<br />{props.nextEventDate?.name || ""}
            </JoinBreakoutMessage>
            <JoinBreakoutButtonArea>
                <JoinBreakoutMessage style={{ marginLeft: "auto", marginRight: "auto" }}>{time}</JoinBreakoutMessage>
            </JoinBreakoutButtonArea>
        </JoinBreakoutRoot>
    )
}

const JoinBreakoutRoot = styled.div`
    display: flex;
    flex: 1;
    flex-flow: row;
    border: 1px solid ${branding.mainInfoColor};
    border-right: none;
    height: 120px;
    justify-content: space-evenly;
    align-items: center;
`

const JoinBreakoutMessage = styled.div`
    display:flex;
    flex-flow: row;
    flex: 0.5;
    font-family: ${branding.font1};
    font-size: 20px;
    height: 75px;

    @media(max-width: 1600px){
        font-size: 15px;
    }
    @media(max-width: 1250px){
        font-size: 12px;
    }
`
const JoinBreakoutButtonArea = styled.div`
    display:flex;
    flex-flow: column;
    width: 240px;
    margin-top: 8px;
`

const JoinBreakoutButton = styled(NavLink) <{ disabled?: boolean }>`
    display: flex;
    border: 1px solid ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};
    border-radius: 40px;
    height: 50px;
    justify-content: center;
    align-items: center;
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    opacity: ${props => props.disabled ? "50%" : "100%"};
    font-family: ${branding.font1};
    background-color: ${branding.videoPageContentBranding.joinBreakoutButtonColor};
    color: ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};

    &:hover, &:active, &:link, &:focus {
        text-decoration: none;
        color: ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};
    }

`

const JoinBreakoutIcon = styled.div`
    display: flex;
    margin-left: -20px;
`

const JoinBreakoutParticipantsCounter = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    font-family: ${branding.font1};
    font-size: 12px;
`


export default VideoPageContent