export function findLanguageName (languageCode: string, lang: string): string {
    const languageName: string = (lang === "en" ? getLanguages(lang).find((country: any) => country.code === languageCode)?.en :getLanguages(lang).find((country: any) => country.code === languageCode)?.de) || ""
    return languageName
}

export interface Language {
    en: string
    de: string
    code: string
}

function sortAlphabetically(lang: string): Language[] {
    return languages.sort(function (a: Language, b: Language) {
        if(lang === "de"){
            return a.de.localeCompare(b.de)
        }
        else{
            return a.en.localeCompare(b.en)
        }
        
    });
}

export function getListOfLanguagesString(codes: any[], lang: string): string{
  let listOfLanguagesString: string = ""

  codes.forEach((code, index) => {

    if(index === codes.length - 1) {
      listOfLanguagesString = listOfLanguagesString.concat(findLanguageName(code, lang))
    }

    else {
      listOfLanguagesString = listOfLanguagesString.concat(findLanguageName(code, lang), ", ")
    }
  })

  return listOfLanguagesString
}

export function getLanguages(lang: string): Array<Language>{
    return sortAlphabetically(lang)
}

const languages: Array<any> = [
    {
      code: "ab",
      en: "Abkhaz",
      de: "Abchasisch"
    },
    {
      code: "aa",
      en: "Afar",
      de: "Afar"
    },
    {
      code: "af",
      en: "Afrikaans",
      de: "Afrikaans"
    },
    {
      code: "ak",
      en: "Akan",
      de: "Akan"
    },
    {
      code: "sq",
      en: "Albanian",
      de: "Albanisch"
    },
    {
      code: "am",
      en: "Amharic",
      de: "Amharisch"
    },
    {
      code: "ar",
      en: "Arabic",
      de: "Arabisch"
    },
    {
      code: "an",
      en: "Aragonese",
      de: "Aragonesisch"
    },
    {
      code: "hy",
      en: "Armenian",
      de: "Armenisch"
    },
    {
      code: "as",
      en: "Assamese",
      de: "Assamesisch"
    },
    {
      code: "av",
      en: "Avaric",
      de: "Avaric"
    },
    {
      code: "ae",
      en: "Avestan",
      de: "Avestisch"
    },
    {
      code: "ay",
      en: "Aymara",
      de: "Aymara"
    },
    {
      code: "az",
      en: "Azerbaijani",
      de: "Aserbaidschanisch"
    },
    {
      code: "bm",
      en: "Bambara",
      de: "Bambara"
    },
    {
      code: "ba",
      en: "Bashkir",
      de: "Bashkir"
    },
    {
      code: "eu",
      en: "Basque",
      de: "Baskisch"
    },
    {
      code: "be",
      en: "Belarusian",
      de: "Weißrussisch"
    },
    {
      code: "bn",
      en: "Bengali",
      de: "Bengalisch"
    },
    {
      code: "bh",
      en: "Bihari",
      de: "Bihari"
    },
    {
      code: "bi",
      en: "Bislama",
      de: "Bislama"
    },
    {
      code: "bs",
      en: "Bosnian",
      de: "Bosnisch"
    },
    {
      code: "br",
      en: "Breton",
      de: "Bretonisch"
    },
    {
      code: "bg",
      en: "Bulgarian",
      de: "Bulgarisch"
    },
    {
      code: "my",
      en: "Burmese",
      de: "Birmanisch"
    },
    {
      code: "ca",
      en: "Catalan",
      de: "Katalanisch"
    },
    {
      code: "ch",
      en: "Chamorro",
      de: "Chamorro"
    },
    {
      code: "ce",
      en: "Chechen",
      de: "Tschetschenisch"
    },
    {
      code: "ny",
      en: "Chichewa",
      de: "Chichewa"
    },
    {
      code: "zh",
      en: "Chinese",
      de: "Chinesisch"
    },
    {
      code: "cv",
      en: "Chuvash",
      de: "Tschuwaschisch"
    },
    {
      code: "kw",
      en: "Cornish",
      de: "Kornisch"
    },
    {
      code: "co",
      en: "Corsican",
      de: "Korsisch"
    },
    {
      code: "cr",
      en: "Cree",
      de: "Cree"
    },
    {
      code: "hr",
      en: "Croatian",
      de: "Kroatisch"
    },
    {
      code: "cs",
      en: "Czech",
      de: "Tschechisch"
    },
    {
      code: "da",
      en: "Danish",
      de: "Dänisch"
    },
    {
      code: "dv",
      en: "Divehi",
      de: "Dhivehi"
    },
    {
      code: "nl",
      en: "Dutch",
      de: "Niederländisch"
    },
    {
      code: "dz",
      en: "Dzongkha",
      de: "Dzongkha"
    },
    {
      code: "en",
      en: "English",
      de: "Englisch"
    },
    {
      code: "eo",
      en: "Esperanto",
      de: "Esperanto"
    },
    {
      code: "et",
      en: "Estonian",
      de: "Estnisch"
    },
    {
      code: "ee",
      en: "Ewe",
      de: "Ewe"
    },
    {
      code: "fo",
      en: "Faroese",
      de: "Färöisch"
    },
    {
      code: "fj",
      en: "Fijian",
      de: "Fidschi"
    },
    {
      code: "fi",
      en: "Finnish",
      de: "Finnisch"
    },
    {
      code: "fr",
      en: "French",
      de: "Französisch"
    },
    {
      code: "ff",
      en: "Fulah",
      de: "Fulfulde"
    },
    {
      code: "gl",
      en: "Galician",
      de: "Galicisch"
    },
    {
      code: "ka",
      en: "Georgian",
      de: "Georgisch"
    },
    {
      code: "de",
      en: "German",
      de: "Deutsch"
    },
    {
      code: "el",
      en: "Greek",
      de: "Griechisch"
    },
    {
      code: "gn",
      en: "Guarani",
      de: "Guarani"
    },
    {
      code: "gu",
      en: "Gujarati",
      de: "Gujarati"
    },
    {
      code: "ht",
      en: "Haitian",
      de: "Haitianisch"
    },
    {
      code: "ha",
      en: "Hausa",
      de: "Hausa"
    },
    {
      code: "he",
      en: "Hebrew",
      de: "Hebräisch"
    },
    {
      code: "hz",
      en: "Herero",
      de: "Otjiherero"
    },
    {
      code: "hi",
      en: "Hindi",
      de: "Hindi"
    },
    {
      code: "ho",
      en: "Hiri Motu",
      de: "Hiri Motu"
    },
    {
      code: "hu",
      en: "Hungarian",
      de: "Ungarisch"
    },
    {
      code: "ia",
      en: "Interlingua",
      de: "Interlingua"
    },
    {
      code: "id",
      en: "Indonesian",
      de: "Indonesisch"
    },
    {
      code: "ie",
      en: "Interlingue",
      de: "Interlingue"
    },
    {
      code: "ga",
      en: "Irish",
      de: "Irisch"
    },
    {
      code: "ig",
      en: "Igbo",
      de: "Igbo"
    },
    {
      code: "ik",
      en: "Inupiaq",
      de: "Inupiaq"
    },
    {
      code: "io",
      en: "Ido",
      de: "Ido"
    },
    {
      code: "is",
      en: "Icelandic",
      de: "Isländisch"
    },
    {
      code: "it",
      en: "Italian",
      de: "Italienisch"
    },
    {
      code: "iu",
      en: "Inuktitut",
      de: "Inuktitut"
    },
    {
      code: "ja",
      en: "Japanese",
      de: "Japanisch"
    },
    {
      code: "jv",
      en: "Javanese",
      de: "Javanisch"
    },
    {
      code: "kl",
      en: "Greenlandic",
      de: "Grönländisch"
    },
    {
      code: "kn",
      en: "Kannada",
      de: "Kannada"
    },
    {
      code: "kr",
      en: "Kanuri",
      de: "Kanuri"
    },
    {
      code: "ks",
      en: "Kashmiri",
      de: "Kashmiri"
    },
    {
      code: "kk",
      en: "Kazakh",
      de: "Kasachisch"
    },
    {
      code: "km",
      en: "Khmer",
      de: "Khmer"
    },
    {
      code: "ki",
      en: "Kikuyu",
      de: "Kikuyu"
    },
    {
      code: "rw",
      en: "Kinyarwanda",
      de: "Kinyarwanda"
    },
    {
      code: "ky",
      en: "Kyrgyz",
      de: "Kirgisisch"
    },
    {
      code: "kv",
      en: "Komi",
      de: "Komi"
    },
    {
      code: "kg",
      en: "Kongo",
      de: "Kikongo"
    },
    {
      code: "ko",
      en: "Korean",
      de: "Koreanisch"
    },
    {
      code: "ku",
      en: "Kurdish",
      de: "Kurdisch"
    },
    {
      code: "kj",
      en: "Kwanyama, Kuanyama",
      de: "oshiKwanyama"
    },
    {
      code: "la",
      en: "Latin",
      de: "Latein"
    },
    {
      code: "lb",
      en: "Luxembourgish",
      de: "Luxemburgisch"
    },
    {
      code: "lg",
      en: "Ganda",
      de: "Luganda"
    },
    {
      code: "li",
      en: "Limburgish",
      de: "Limburgisch"
    },
    {
      code: "ln",
      en: "Lingala",
      de: "Lingála"
    },
    {
      code: "lo",
      en: "Lao",
      de: "Laotisch"
    },
    {
      code: "lt",
      en: "Lithuanian",
      de: "Litauisch"
    },
    {
      code: "lu",
      en: "Luba-Katanga",
      de: "Kiluba"
    },
    {
      code: "lv",
      en: "Latvian",
      de: "Lettisch"
    },
    {
      code: "gv",
      en: "Manx",
      de: "Manx"
    },
    {
      code: "mk",
      en: "Macedonian",
      de: "Mazedonisch"
    },
    {
      code: "mg",
      en: "Malagasy",
      de: "Malagasy"
    },
    {
      code: "ms",
      en: "Malay",
      de: "Malaiisch"
    },
    {
      code: "ml",
      en: "Malayalam",
      de: "Malayalam"
    },
    {
      code: "mt",
      en: "Maltese",
      de: "Maltesisch"
    },
    {
      code: "mi",
      en: "Maori",
      de: "Maori"
    },
    {
      code: "mr",
      en: "Marathi",
      de: "Marathi"
    },
    {
      code: "mh",
      en: "Marshallese",
      de: "Marshallesisch"
    },
    {
      code: "mn",
      en: "Mongolian",
      de: "Mongolisch"
    },
    {
      code: "na",
      en: "Nauru",
      de: "Nauruisch"
    },
    {
      code: "nv",
      en: "Navajo",
      de: "Navajo"
    },
    {
      code: "nb",
      en: "Norwegian - Bokmål",
      de: "Norwegisch - Bokmål"
    },
    {
      code: "nd",
      en: "North Ndebele",
      de: "Nord-Ndebele"
    },
    {
      code: "ne",
      en: "Nepali",
      de: "Nepali"
    },
    {
      code: "ng",
      en: "Ndonga",
      de: "Ndonga"
    },
    {
      code: "nn",
      en: "Norwegian - Nynorsk",
      de: "Norwegisch - Nynorsk"
    },
    {
      code: "no",
      en: "Norwegian",
      de: "Norwegisch"
    },
    {
      code: "ii",
      en: "Nuosu",
      de: "Yi"
    },
    {
      code: "nr",
      en: "South Ndebele",
      de: "Süd-Ndebele"
    },
    {
      code: "oc",
      en: "Occitan",
      de: "Okzitanisch"
    },
    {
      code: "oj",
      en: "Ojibwa",
      de: "Ojibwe"
    },
    {
      code: "cu",
      en: "Church Slavic",
      de: "Kirchenslawisch"
    },
    {
      code: "om",
      en: "Oromo",
      de: "Oromo"
    },
    {
      code: "or",
      en: "Oriya",
      de: "Oriya"
    },
    {
      code: "os",
      en: "Ossetian",
      de: "Ossetisch"
    },
    {
      code: "pa",
      en: "Punjabi",
      de: "Pandschabi"
    },
    {
      code: "pi",
      en: "Pali",
      de: "Pali"
    },
    {
      code: "fa",
      en: "Persian",
      de: "Persisch"
    },
    {
      code: "pl",
      en: "Polish",
      de: "Polnisch"
    },
    {
      code: "ps",
      en: "Pashto",
      de: "Paschtunisch"
    },
    {
      code: "pt",
      en: "Portuguese",
      de: "Portugiesisch"
    },
    {
      code: "qu",
      en: "Quechua",
      de: "Quechua"
    },
    {
      code: "rm",
      en: "Romansh",
      de: "Bündnerromanisch"
    },
    {
      code: "rn",
      en: "Kirundi",
      de: "Kirundi"
    },
    {
      code: "ro",
      en: "Romanian",
      de: "Rumänisch"
    },
    {
      code: "ru",
      en: "Russian",
      de: "Russisch"
    },
    {
      code: "sa",
      en: "Sanskrit",
      de: "Sanskrit"
    },
    {
      code: "sc",
      en: "Sardinian",
      de: "Sardisch"
    },
    {
      code: "sd",
      en: "Sindhi",
      de: "Sindhi"
    },
    {
      code: "se",
      en: "Northern Sami",
      de: "Nordsamisch"
    },
    {
      code: "sm",
      en: "Samoan",
      de: "Samoanisch"
    },
    {
      code: "sg",
      en: "Sango",
      de: "Sango"
    },
    {
      code: "sr",
      en: "Serbian",
      de: "Serbisch"
    },
    {
      code: "gd",
      en: "Scottish Gaelic",
      de: "Schottisch-gälisch"
    },
    {
      code: "sn",
      en: "Shona",
      de: "Shona"
    },
    {
      code: "si",
      en: "Sinhala",
      de: "Singhalesisch"
    },
    {
      code: "sk",
      en: "Slovak",
      de: "Slowakisch"
    },
    {
      code: "sl",
      en: "Slovene",
      de: "Slowenisch"
    },
    {
      code: "so",
      en: "Somali",
      de: "Somali"
    },
    {
      code: "st",
      en: "Southern Sotho",
      de: "Sesotho"
    },
    {
      code: "es",
      en: "Spanish",
      de: "Spanisch"
    },
    {
      code: "su",
      en: "Sundanese",
      de: "Sundanesisch"
    },
    {
      code: "sw",
      en: "Swahili",
      de: "Swahili"
    },
    {
      code: "ss",
      en: "Swati",
      de: "Siswati"
    },
    {
      code: "sv",
      en: "Swedish",
      de: "Schwedisch"
    },
    {
      code: "ta",
      en: "Tamil",
      de: "Tamil"
    },
    {
      code: "te",
      en: "Telugu",
      de: "Telugu"
    },
    {
      code: "tg",
      en: "Tajik",
      de: "Tadschikisch"
    },
    {
      code: "th",
      en: "Thai",
      de: "Thai"
    },
    {
      code: "ti",
      en: "Tigrinya",
      de: "Tigrinya"
    },
    {
      code: "bo",
      en: "Tibetan",
      de: "Tibetisch"
    },
    {
      code: "tk",
      en: "Turkmen",
      de: "Turkmenisch"
    },
    {
      code: "tl",
      en: "Tagalog",
      de: "Tagalog"
    },
    {
      code: "tn",
      en: "Tswana",
      de: "Setswana"
    },
    {
      code: "to",
      en: "Tonga",
      de: "Tongaisch"
    },
    {
      code: "tr",
      en: "Turkish",
      de: "Türkisch"
    },
    {
      code: "ts",
      en: "Tsonga",
      de: "Xitsonga"
    },
    {
      code: "tt",
      en: "Tatar",
      de: "Tatarisch"
    },
    {
      code: "tw",
      en: "Twi",
      de: "Twi"
    },
    {
      code: "ty",
      en: "Tahitian",
      de: "Tahitianisch"
    },
    {
      code: "ug",
      en: "Uyghur",
      de: "Uigurisch"
    },
    {
      code: "uk",
      en: "Ukrainian",
      de: "Ukrainisch"
    },
    {
      code: "ur",
      en: "Urdu",
      de: "Urdu"
    },
    {
      code: "uz",
      en: "Uzbek",
      de: "Usbekisch"
    },
    {
      code: "ve",
      en: "Venda",
      de: "Tshivenda"
    },
    {
      code: "vi",
      en: "Vietnamese",
      de: "Vietnamesisch"
    },
    {
      code: "vo",
      en: "Volapük",
      de: "Volapük"
    },
    {
      code: "wa",
      en: "Walloon",
      de: "Wallonisch"
    },
    {
      code: "cy",
      en: "Welsh",
      de: "Walisisch"
    },
    {
      code: "wo",
      en: "Wolof",
      de: "Wolof"
    },
    {
      code: "fy",
      en: "Western Frisian",
      de: "Westfriesisch"
    },
    {
      code: "xh",
      en: "Xhosa",
      de: "isiXhosa"
    },
    {
      code: "yi",
      en: "Yiddish",
      de: "Jiddisch"
    },
    {
      code: "yo",
      en: "Yoruba",
      de: "Yoruba"
    },
    {
      code: "za",
      en: "Zhuang",
      de: "Zhuang"
    },
    {
      code: "zu",
      en: "Zulu",
      de: "isiZulu"
    }
  ]