import React from "react";
import styled from "styled-components";
import BookmarkWithToggle from "./BookmarkWithToggle";
import { IconCameraJoin, IconMeetingSchedule, IconShare, IconCall } from "./Icons";
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink";
import { Container, Row, Col } from "react-bootstrap";
import { Exhibitor, Product, Trademark } from "../backendServices/Types";
import { useLanguageState } from "../globalStates/LanguageState"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import useWindowDimensions from "./WindowDimensionsHook";
import { Sections, sectionOrder, SectionType } from "../contentArea/useSearch";
import branding from "../branding/branding";
import { UserOrganizationVisitSource } from "../backendServices/BackendServices";

interface ResultListLayoutProps {
    sections: Sections
    onAction: (action: "EXPO" | "MEETING" | "INTEREST" | "RECOMMEND", organization: Exhibitor) => void
    isSponsor: boolean
    isMediaPartner: boolean
    isStartup: boolean
    searchKrit?: string
}

const ContainerRoot = styled(Container)`
    @media (max-width: 1600px) {
        padding: 20px 80px;
    }
`

const ResultRowRoot = styled(Row)`
    font-family: ${branding.font1};
    border-bottom: 1px solid ${branding.mainInfoColor};
    padding: 20px 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 12px;
    line-height: 17px;
    svg {
        color: ${branding.primaryColor};
    }
`

const ResultLogoRoot = styled.div`
    width: 60px;
    height: 60px;
    background: white;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
`

const ResultLogo = styled.img`
    object-fit: contain;
    max-height: 100%;
`

const ResultIconsRoot = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    align-self: center;
`
const EntityTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 5px;
`

const EntityDescription = styled.div`
    font-size: 14px;
    display: inline-block;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
`

const ResultIcons = styled.div`
    cursor: pointer;
`
const SectionHeaderTitle = styled.div`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor ?? "#000"};
    font-size: 18px;
    font-weight: bold;
    margin-top: 32px;
`

export const ResultListLayout: React.FC<ResultListLayoutProps> = (props) => {
    const strings = useLanguageState().getStrings();
    const windowSize = useWindowDimensions();

    function handleAction(action: "EXPO" | "MEETING" | "INTEREST" | "RECOMMEND", organization: Exhibitor, event: any) {
        if (props.onAction) {
            event.preventDefault()
            event.stopPropagation()
            props.onAction(action, organization)
        }
    }


    return (
        <ContainerRoot>
            {
                sectionOrder.map((sectionType) => {
                    const section = props.sections[sectionType]!
                    if (!section || section.count === 0)
                        return null
                    const titleSponsors = `${strings.receptionPage.sponsorsTitle}(${section.count.toString()})`
                    const titleTemplate = props.isSponsor ? titleSponsors : props.isMediaPartner ? titleSponsors :
                        section.type === SectionType.TOP ? strings.exhibitorsPageContent.sectionHeaderTop :
                            (section.results[0] && section.results[0].type === "product" ?
                                strings.exhibitorsPageContent.sectionHeaderAllProducts : (section.results[0] && section.results[0].type === "trademark" ?
                                    strings.exhibitorsPageContent.sectionHeaderAllTrademarks : strings.exhibitorsPageContent.sectionHeaderAllExhibitors)
                            )

                    const src: UserOrganizationVisitSource = props.isSponsor ? 'SPONSORS' : section.type === SectionType.TOP ? 'FLOORSPONSOR' : 'FLOOR'
                    //in some brandings the "all" section has exhibitors in the title
                    //so titles for three sections needed to be separated

                    const title = titleTemplate.split('{$count}').join(section.count.toString())
                    return <>
                        <SectionHeaderTitle>{title}</SectionHeaderTitle>
                        {
                            section.results.map((resultItem) => {
                                const resultEntity: Exhibitor | Product | Trademark = resultItem as Exhibitor | Product | Trademark
                                let entityDescription = ""
                                let columnLogoSize = windowSize.width < 1250 ? 2 : 1
                                let columnTitleSize = 9
                                let columnActionsSize = 1
                                let organization: Exhibitor | undefined
                                //let product: Product | undefined
                                //let trademark: Trademark | undefined
                                switch (resultEntity.type) {
                                    case "organization":
                                        organization = resultEntity
                                        entityDescription = organization.teaser
                                        columnTitleSize = windowSize.width < 1250 ? 5 : 6
                                        columnActionsSize = 5
                                        break
                                    case "product":
                                        //product = resultEntity
                                        entityDescription = resultEntity.descriptionTeaser
                                        break
                                    case "trademark":
                                        //trademark = resultEntity
                                        entityDescription = resultEntity.descriptionTeaser
                                        break
                                    default: return null
                                }
                                return (
                                    <DetailNavLink key={resultEntity.id} id={resultEntity.id} type={resultEntity.type} name={resultEntity.name} source={src} searchKrit={props.searchKrit}>
                                        <ResultRowRoot>
                                            <Col className="p-0" xs={columnLogoSize}>
                                                <ResultLogoRoot>
                                                    <ResultLogo src={resultEntity.pictureURL} />
                                                </ResultLogoRoot>
                                            </Col>
                                            <Col style={{ color: branding.exhibitorsPageContent.exhibitorsColInfoTextColor }} className="pl-4" xs={columnTitleSize}>
                                                <EntityTitle>{resultEntity.name}</EntityTitle>
                                                <EntityDescription>{entityDescription}</EntityDescription>
                                            </Col>
                                            <ResultIconsRoot xs={columnActionsSize}>
                                                {organization && <OverlayTrigger
                                                    placement="bottom"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                                            {strings.contactEntry.startCallHint}
                                                        </Tooltip>}>
                                                    <ResultIcons className="mr-4" onClick={(e) => handleAction('EXPO', organization!, e)}>{IconCameraJoin({ fill: branding.sideIconBar.sideIconColorDark })}</ResultIcons>
                                                </OverlayTrigger>}

                                                {(organization && branding.exhibitorsPageContent.packagesWithMeetingsEnabled.includes(organization?.basisPremium)) && <OverlayTrigger
                                                    placement="bottom"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                                            {strings.contactEntry.requestMeetingHint}
                                                        </Tooltip>}>
                                                    <ResultIcons className="mr-4" onClick={(e) => handleAction('MEETING', organization!, e)}>{IconMeetingSchedule({ fill: branding.sideIconBar.sideIconColorDark })}</ResultIcons>
                                                </OverlayTrigger>}

                                                {organization && <OverlayTrigger
                                                    placement="bottom"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                                            {strings.organizationDetailPageContent.registerInterest}
                                                        </Tooltip>}>
                                                    <ResultIcons className="mr-4" onClick={(e) => handleAction('INTEREST', organization!, e)}>{IconCall({ fill: branding.sideIconBar.sideIconColorDark, stroke: branding.sideIconBar.sideIconColorDark })}</ResultIcons>
                                                </OverlayTrigger>}

                                                {organization && <OverlayTrigger
                                                    placement="bottom"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                                            {strings.contactEntry.shareHint}
                                                        </Tooltip>}>
                                                    <ResultIcons className="mr-4" onClick={(e) => handleAction('RECOMMEND', organization!, e)}>{IconShare({ fill: branding.sideIconBar.sideIconColorDark })}</ResultIcons>
                                                </OverlayTrigger>}

                                                <OverlayTrigger
                                                    placement="bottom"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                                            {strings.organizationDetailPageContent.bookmarkPerson}
                                                        </Tooltip>}>
                                                    <ResultIcons><BookmarkWithToggle newBookmarkItem={true} fontSize={"25px"} color={branding.sideIconBar.sideIconColorDark} type={resultEntity.type} id={resultEntity.id} /></ResultIcons>
                                                </OverlayTrigger>

                                            </ResultIconsRoot>
                                        </ResultRowRoot>
                                    </DetailNavLink>
                                )
                            })
                        }
                    </>
                })
            }
        </ContainerRoot>
    )
}