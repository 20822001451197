import React from 'react'
import styled from 'styled-components'
import TreeMenu from 'react-simple-tree-menu';
import '../../../../node_modules/react-simple-tree-menu/dist/main.css';

const SimpleTreeRoot = styled.div`
    padding-top: 20px;
    & .rstm-tree-item-group {
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-bottom: unset;

        & li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        }
        & li.rstm-tree-item--active, li.rstm-tree-item--focused {
            background-color: white;
            box-shadow: unset;
            font-weight: 700;
            color: #000;
        }
    }

`
export interface TreeData {
    key: string,
    label: string,
    labelDe: string,
    nodes?: Array<TreeData>
}

interface SimpleTreeDataProps {
    treeData: Array<TreeData>
}

const SimpleTreeComponent = (props: SimpleTreeDataProps) => {
    return (
        <SimpleTreeRoot>
            {props.treeData.length && <TreeMenu data={props.treeData} hasSearch={false}/>}
        </SimpleTreeRoot>
    )
}

export default SimpleTreeComponent

