import React from "react";
import styled from "styled-components";
import branding from "../branding/branding";
import AvatarDefaultContent from "./AvatarDefaultContent";
export const AvatarStyled = styled.div <{ src?: string, size: number, backgroundSize?: string }>`
    position: relative;
    background-image: url("${(p) => p.src}");
    background-size:  ${props => props.backgroundSize ? props.backgroundSize : "contain"};
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${props => props.src ? "transparent" : "lightgrey"};
    padding: 0;
    width: ${(p) => p.size}px;
    height: ${(p) => p.size}px;
    text-align: center;
    line-height: ${(p) => p.size}px;
    color: white;
    font-weight: normal;
    font-size: ${(p) => p.size / 2.2}px;
    border-radius: 50%;
`
const StatusContainer = styled.div <{ badgesize: number, badgetop: number, badgeright: number, badgeLeft?: number }>`
    width: ${(p) => p.badgesize}px;
    height: ${(p) => p.badgesize}px; 
    position: absolute;
    right: ${(p) => p.badgeright}px;
    top: ${(p) => p.badgetop}px;
    left: ${(p) => p.badgeLeft}px;
    border: solid 1px white;
    border-radius: 100%;
    background-clip: content-box;
    background-color: #bdbdbd;
    color: white;
`;

interface AvatarProps {
    src?: string
    size?: number
    alt: string
    badgesize?: number
    badgeTop?: number
    badgeRight?: number
    badgeLeft?: number
    showBadge?: boolean
    className?: string
    backgroundSize?: string
}

export function encodePictureUrl(pictureUrl: string | null | undefined) {
    if (pictureUrl) {
        return pictureUrl.replace(branding.configuration.sotName, encodeURI(branding.configuration.sotName));
    }
}

export const AvatarWithDefault: React.FC<AvatarProps> = React.memo((props) => {
    const size = props.size ?? 40
    const badgesize = props.badgesize ?? 15
    const badgetop = props.badgeTop ?? 22
    const badgeright = props.badgeRight ?? 18
    const srcLength = props.src ? props.src.length : 0
    const alt = (props.alt) ? props.alt.replace(/\s+/g, " ").split(" ").map((value, index) => index <= 1 && value && value.length > 0 ? value.substr(0, 1).toUpperCase() : null).join("") : "";

    return (
        <AvatarStyled {...props} src={encodePictureUrl(props.src)} size={size} className={props.className + " avatar-styled" } backgroundSize={props.backgroundSize}>
            {srcLength > 0 ? "" : alt || <AvatarDefaultContent />}
            {props.showBadge && <StatusContainer badgesize={badgesize} badgetop={badgetop} badgeright={badgeright} />}
        </AvatarStyled>
    )
})
