import React from 'react'
import { EventDate } from '../backendServices/Types'
import { useLanguageState } from '../globalStates/LanguageState'
import { compareByStartTimeEventDate } from '../contentArea/program/Schedule'
import { eventTimezoneName, getTimeToLiveString, isEventDateLive } from '../utils/DateUtils'
import CrsPersons from './CrsPersons'
import styled from 'styled-components'
import { DetailNavLink } from '../contentArea/detailPages/DetailNavLink'
import { vgLive } from './Icons'
import branding from '../branding/branding'
import moment from 'moment'



/*********************************************************************************************
* TILE SESSION DETAILS TIME
**********************************************************************************************/


const SessionTimeDivLive = styled.div<{ addTopMargin: boolean }>`
    background-color: #FFF;
    border-radius: 25px;
    display: inline-flex;
    justify-content:center;
    width: max-content;
    height: 20px;
    border: 1px solid white;
    padding: 15px;
    margin-bottom: 5px;
    align-items: center;
    color: #000;
    margin-top: ${props => props.addTopMargin === true ? "20px" : "0px"};
`

const DotLive = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 10px;
    animation: blinker 1.8s linear infinite;
    background-color: ${branding.dangerButtonColor};

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
`

const LiveTitle = styled.span`
    display: inline-block;
    margin-top: 6px;
`

const NextSessionDiv = styled.div`
    display: flex;
    align-items: center;
`

const NextSessionTitle = styled.h6`
    margin-left: 10px;
    margin-bottom: 0px;
`

interface TileSessionDetailsTimeProps {
    isLive: boolean
    time: string
    channelId?: string
    color?: string
    addTopMargin: boolean
}

const TileSessionDetailsTime: React.FC<TileSessionDetailsTimeProps> = (props) => {
    if (props.isLive && props.channelId) {
        return (
            <DetailNavLink type="channel" id={props.channelId} name={""}>
                <SessionTimeDivLive addTopMargin={props.addTopMargin}>
                    <DotLive />
                    <LiveTitle>
                        <h6 style={{ fontSize: "13px" }}>{props.time}</h6>
                    </LiveTitle>
                </SessionTimeDivLive>
            </DetailNavLink >
        )
    } else {
        return (
            <NextSessionDiv>
                {vgLive({ stroke: props.color ? props.color : "#fff", width: "20px", height: "20px" })}
                <NextSessionTitle>{props.time}</NextSessionTitle>
            </NextSessionDiv>
        )
    }
}


/*********************************************************************************************
* TILE SESSION DETAILS
**********************************************************************************************/

const SessionDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const SessionDetailsRoot = styled.div<{ color: string }>`
    display: flex;
    color: ${props => props.color};
    margin-bottom: 10px;
`

interface TileSessionDetailsProps {
    eventdate: EventDate
    isLive: boolean
    time: string
    color?: string
    addTopMargin: boolean
}

const TileSessionDetails: React.FC<TileSessionDetailsProps> = (props) => {
    if (props.eventdate) {
        return (
            <SessionDetailsRoot color={props.color ? props.color : "#fff"}>
                <TileSessionDetailsTime {...props} channelId={props.eventdate.channel?.id} addTopMargin={props.addTopMargin} />
            </SessionDetailsRoot>
        )
    } else {
        return null
    }
}


/*********************************************************************************************
* MEETING DETAILS
**********************************************************************************************/

interface MeetingDetailsProps {
    content: any
    color?: string
}

const MeetingDetails: React.FunctionComponent<MeetingDetailsProps> = (props) => {
    const eventDates: EventDate[] | undefined = props.content?.eventdates as EventDate[] || undefined
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const language = languageState.getLanguage()

    if (eventDates) {
        const eventDate = eventDates.sort(compareByStartTimeEventDate).find(eventDate => moment.tz(eventTimezoneName).isBefore(moment(eventDate.date + " " + eventDate.end)))
        if (eventDate) {
            const time = getTimeToLiveString(eventDate, strings, language)
            if (isEventDateLive(eventDate)) {
                return (
                    <SessionDetailsContainer>
                        <TileSessionDetails eventdate={eventDate} isLive={true} time={time} color={props.color || "#fff"} addTopMargin={eventDate.persons.length > 0} />
                        <div style={{ marginBottom: "10px" }}>
                            <CrsPersons layoutType={1} showModalPopup={false} persons={eventDate.persons} personsToShow={2} />
                        </div>
                    </SessionDetailsContainer>
                )
            } else {
                return (
                    <div style={{ marginBottom: "35px" }}>
                        <TileSessionDetails eventdate={eventDate} isLive={false} time={time} color={props.color || "#fff"} addTopMargin={false} />
                    </div>
                )
            }
        }
    }

    return null
}


export default MeetingDetails;