import React from "react";
import ConversationListContent from "./ConversationListPage";
import { CommunicationCenterDisplayMode, useAppState } from "../globalStates/AppState";
import { useLanguageState } from "../globalStates/LanguageState";
import ChatPage from "./ChatPage";
import NetworkingTabErrorBoundary from "../contentArea/errorPages/NetworkingTabErrorBoundary";

interface ChatsTabProps {
  conversations: number
}
const ChatsTab: React.FunctionComponent<ChatsTabProps> = (props) => {
  const appState = useAppState();
  const languageState = useLanguageState();
  const strings = languageState.getStrings();

  const errorMessage = strings.communicationArea.chatListErrorMessage
  const reloadButtonText = strings.communicationArea.reloadButtonText

  if (appState.communicationCenterDisplayMode === CommunicationCenterDisplayMode.CHATS && appState.communicationCenterDisplayParam) {
    return (
      <NetworkingTabErrorBoundary
        errorMessage={strings.communicationArea.chatErrorMessage}
        reloadButtonText={reloadButtonText}
      >
        <div style={{ height: "calc(100vh - 100px)" }}>
          <ChatPage />
        </div>
      </NetworkingTabErrorBoundary>
    );
  }

  return (
    <NetworkingTabErrorBoundary
      errorMessage={errorMessage}
      reloadButtonText={reloadButtonText}
    >
      <ConversationListContent conversations={props.conversations} />
    </NetworkingTabErrorBoundary>
  );
};

export default ChatsTab;

