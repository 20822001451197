import { DefaultActiveSpeakerPolicy } from "amazon-chime-sdk-js";

export default class EGActiveSpeakerPolicy extends DefaultActiveSpeakerPolicy {
    private maxHistoryEntries = 5
    private volumeHistory: { [attendeeId: string]: number[] } = {}

    /**
     * Should update every 200ms (https://github.com/aws/amazon-chime-sdk-js/blob/709c13e458804a9006fb27ed50157bc7b54b43d7/src/activespeakerdetector/DefaultActiveSpeakerDetector.ts#L42)
     */
    calculateScore(attendeeId: string, volume: number | null, muted: boolean | null): number {
        // no score when muted
        if (muted) {
            this.volumeHistory[attendeeId] = []
            return 0
        }

        // No volume means no volume change, when attendee or muted state changed. in this case we assume 0 volume, because the attendee is not speaking
        if (!volume)
            volume = 0

        if (!this.volumeHistory[attendeeId])
            // initialize the history
            this.volumeHistory[attendeeId] = []
        else {
            let zeroCount = 0
            // Count the mute times
            for (let historyEntry of this.volumeHistory[attendeeId]) {
                if (historyEntry === 0)
                    zeroCount++
            }

            // Add the volume to the end of the history
            if (this.volumeHistory[attendeeId].length >= this.maxHistoryEntries) {
                this.volumeHistory[attendeeId].shift();
            }
            this.volumeHistory[attendeeId].push(volume);

            // if there were more than 2 zero volumes in the history we assume the speaker is silent
            if (zeroCount > 2) {
                this.volumeHistory[attendeeId] = []
                return 0
            }
        }

        return super.calculateScore(attendeeId, volume, muted)
    }
}