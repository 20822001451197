import branding, { TrackingBranding } from "./branding"
import { defaultLogger as logger } from "../globalStates/AppState"
import { Company } from "../backendServices/Types"

export enum BasisPremiumType {
    NONE = "NONE",
    BASIC = "BASIC",
    STANDARD = "STANDARD",
    PREMIUM = "PREMIUM",
    ULTIMATE = "ULTIMATE",
    SPONSOR = "SPONSOR"
}

export enum TileSize {
    SMALL = "SMALL",
    QUARTER = "QUARTER",
    HALF = "HALF",
    THREE_QUARTERS = "THREE_QUARTERS",
    FULL = "FULL"
}

export interface BasisPremiumBranding {
    /**
     * @title BasisPremiumConfig for NONE
     * @description BasisPremiumConfig for NONE
     * @title_de BasisPremiumConfig für NONE
     * @description_de BasisPremiumConfig für NONE
     */
    NONE: BasisPremiumConfig
    /**
     * @title BasisPremiumConfig for BASIC
     * @description BasisPremiumConfig for BASIC
     * @title_de BasisPremiumConfig für BASIC
     * @description_de BasisPremiumConfig für BASIC
     */
    BASIC: BasisPremiumConfig
    /**
     * @title BasisPremiumConfig for STANDARD
     * @description BasisPremiumConfig for STANDARD
     * @title_de BasisPremiumConfig für STANDARD
     * @description_de BasisPremiumConfig für STANDARD
     */
    STANDARD: BasisPremiumConfig
    /**
     * @title BasisPremiumConfig for PREMIUM
     * @description BasisPremiumConfig for PREMIUM
     * @title_de BasisPremiumConfig für PREMIUM
     * @description_de BasisPremiumConfig für PREMIUM
     */
    PREMIUM: BasisPremiumConfig
    /**
     * @title BasisPremiumConfig for ULTIMATE
     * @description BasisPremiumConfig for ULTIMATE
     * @title_de BasisPremiumConfig für ULTIMATE
     * @description_de BasisPremiumConfig für ULTIMATE
     */
     ULTIMATE: BasisPremiumConfig
    /**
     * @title BasisPremiumConfig for SPONSOR
     * @description BasisPremiumConfig for SPONSOR
     * @title_de BasisPremiumConfig für SPONSOR
     * @description_de BasisPremiumConfig für SPONSOR
     */
    SPONSOR: BasisPremiumConfig
}

export interface BasisPremiumConfig {
    /**
     * @title Tile size
     * @description Size of organization tiles in the showfloor
     * @title_de Kachelgröße
     * @description_de Größe der Ausstellerkacheln im Showfloor
     */
    tileSize: TileSize
    /**
     * @title Tile background image
     * @description Hide/Show background image for the tile
     * @title_de Kachel Hintergrundbild
     * @description_de Zeigt/Versteckt das Hintergrundbild einer Kachel
     */
    tileBackgroundImageVisible: boolean
    /**
     * @title Dis/Enable showroom feature
     * @description Dis/enable showroom feature
     * @title_de (De)aktiviert das Showroom-Feature
     * @description_de (De)aktiviert das Showroom-Feature
     */
    expoShowroomEnabled: boolean
     /**
     * @title Dis/Enable team members
     * @description Dis/Enable team members visibility inside of the Exhibitors details page
     * @title_de Dis/Enable team members
     * @description_de Dis/Enable team members visibility inside of the Exhibitors details page
     */
    teamMembersVisible: boolean

}

export function getDefaultBasisPremiumConfig(): BasisPremiumConfig {
    return {
        tileSize: TileSize.SMALL,
        tileBackgroundImageVisible: false,
        expoShowroomEnabled: true,
        teamMembersVisible: true
    }
}

export function getTrackingBrandingForOrganization(organization?: Company) {

    let trackingBranding: TrackingBranding = branding.trackingBrandingNone

    if (organization && organization.basisPremium === BasisPremiumType.BASIC) {
        trackingBranding = branding.trackingBrandingBasic
    }
    else if (organization && organization.basisPremium === BasisPremiumType.STANDARD) {
        trackingBranding = branding.trackingBrandingStandard
    }
    else if (organization &&organization.basisPremium === BasisPremiumType.PREMIUM) {
        trackingBranding = branding.trackingBrandingPremium
    }
    else if (organization && organization.basisPremium === BasisPremiumType.ULTIMATE){
        trackingBranding = branding.trackingBrandingUltimate
    }

    return trackingBranding
}

const loggedMissungConfig: BasisPremiumType[] = []
export function getBasisPremiumConfig(basisPremium: BasisPremiumType): BasisPremiumConfig {
    if (branding.basisPremium[basisPremium]) {
        return branding.basisPremium[basisPremium]!!
    }
    if (loggedMissungConfig.indexOf(basisPremium) < 0) {
        logger.error({ message: `No config found for basisPremium value >${basisPremium}<` })
        loggedMissungConfig.push(basisPremium)
    }
    return getDefaultBasisPremiumConfig()
}