import { PressMediaPageContentBranding } from '../contentArea/pressMedia/PressMediaPageContentBranding';
import { ContextMenuBranding } from './../ui/ContextMenuBranding';
import { GuestUserBannerBranding, TopBannerConfig } from '../contentArea/guestUserBanner/GuestUserBannerBranding';
import { OrganizationDetailPageContentBranding, PersonDetailPageContentBranding, ProductDetailPageContentBranding, TrademarkDetailPageContentBranding, EventDateDetailPageContentBranding } from './../contentArea/detailPages/DetailPageBranding';
import { SayHelloModalBranding } from './../ui/SayHelloModalBranding';
import { FilterBarBranding } from '../ui/FilterBarBranding';
import { MyProfilePageContentBranding } from '../contentArea/myprofile/MyProfilePageContentBranding';
import { NavigationAreaBranding } from '../navigationArea/NavigationAreaBranding';
import { ReceptionPageSiteBranding, MySchedulePageBranding } from '../contentArea/reception/ReceptionPageContentBranding';
import { GlobalStatePopupTextsBranding } from './../globalStates/GlobalStatePopupTextsBranding'
import { LoginRegistrationSiteBranding } from '../contentArea/loginregistration/LoginRegistrationSiteBranding'
import { ErrorSiteBranding } from '../contentArea/errorPages/ErrorSiteBranding'
import { CommunicationAreaBranding } from '../communicationArea/CommunicationAreaBranding'
import { ContactEntryBranding } from '../communicationArea/ContactEntryBranding'
import { SideIconBarBranding } from '../navigationArea/SideIconBar/SideIconBarBranding';
import { NetworkingAreaBranding } from '../contentArea/networkingGraph/NetworkingPageContentBranding'
import { CalendarEntryModalBranding } from '../ui/CalendarEntryModalBranding';
import { CalendarEntryListViewBranding } from '../communicationArea/CalendarEntryListViewBranding';
import { MeetingRoomGroupType, DeviceSwitcherBranding, ConferenceTexts, RosterBranding } from '../conference/AudioVideoBranding';
import { ProgramScheduleBranding, ProgramSpeakersBranding, ProgramPageContentBranding } from '../contentArea/program/ProgramBranding';
import { SystemCheckBranding } from '../systemCheck/SystemCheckBranding';
import { GlobalTextsBranding } from '../globalStates/GlobalTextsBranding'
import { ThirdPartyResources } from './ThirdPartyResources';
import { TicketSaleBranding } from '../PreLoginAppBranding';
import { CalendarEntryExportBranding } from '../communicationArea/CalendarEntryExportBranding';
import { NotificationBranding } from '../conference/context/NotificationContextBranding';
import { RecommendModalBranding } from '../ui/RecommendOrganizationModalBranding';
import { BackofficeStaffBranding, BackofficeContentBranding, BackofficeLVisitorsBranding, BackofficeStaffReqBranding, BackofficeMeetingsBranding, BackofficeReportingBranding, BackofficeLoungeBranding } from '../contentArea/backoffice/BackofficeBranding';
import { TopBannerBranding } from '../contentArea/advertisingBanner/TopBannerBranding';
import { CrsMultiSwitcherBranding } from '../ui/CrsMultiSwitchBranding';
import { SettingsTabBranding } from '../communicationArea/SettingsTabBranding';
import { BasisPremiumBranding } from './BasisPremiumBranding';
import { ConversationEntryBranding } from '../communicationArea/ConversationEntryBranding';
import { CrsTimePickerBranding } from '../ui/CrsTimePicker/CrsTimePickerBranding';
import { MediaPartnerPageContentBranding, RulesPageContentBranding, IFrameTestPageContentBranding, SponsorsPageContentBranding, CouponsBranding, NewsPageContentBranding, ProductsPageContentBranding, TrademarksPageContentBranding, ExhibitorsPageContentBranding, BusinessAreaPageContentBranding, HelpPageContentBranding } from '../contentArea/ContentAreaPageBranding';
import { BrandingImpl } from '../BrandingConfiguration';
import { ChatBranding } from '../communicationArea/ChatBranding';


export interface Branding {
    /**
     * @title App & General Configuration
     * @description Configuration information for the app to be runnable
     * @title_de App & Allgemeine Konfiguration
     * @description_de Konfigurationsinformationen, damit die App überhaupt läuft
     */
    configuration: Configuration
    /**
     * @title Presence Configuration
     * @description Configuration options for the presence state of users
     * @title_de Online-Status Konfiguration
     * @description_de Einstellungsmöglichkeiten für die Anzeige des Online-Status der Benutzer
     */
    presenceConfiguration: PresenceConfiguration
    /**
     * @title Dates for the different phases
     * @description Dates and other settings for the timing of the different app phases (e.g. live start date, onboarding start date, ...)
     * @title_de Daten der einzelnen Phasen
     * @description_de Daten und andere Einstellungen für die unterschiedlichen Phasen (z.b. Datum des Live-Betrieb starts, Datum des onboarding starts)
     */
    eventTiming: EventTiming
    /**
     * // TODO use time to live value returned from backend on going live instead
     * @title Greenroom live delay
     * @description Time in seconds for the greenroom to go live after pressing live button
     * @title_de Greenrom Live Verzögerung
     * @description_de Zeit in Sekunden bevor Greenroom tatsächlich live geht nach Button Klick
     */
    greenroomGoLiveFollowupDelaySec: number
    /**
     * @title Page Title
     * @description Title displayed in browser window for the app
     * @title_de Seitentitel
     * @description_de Titel im Browser-Fenter für die App
     */
    pageTitle: string
    /**
     * @title Page Title for Sale Phase
     * @description Title displayed in browser window for the app
     * @title_de Seitentitel während Sale Phase
     * @description_de Titel im Browser-Fenter für die App
     */
    ticketSalePhasePageTitle: LocalizedString
    /**
     * @title Available Languages 
     * @description Choosable Languages
     * @title_de Verfügbare Sprachen
     * @description_de Sprachen die für die App zur Verfügung stehen
     */
    availableLanguages: string[]
    /**
     * @title Global Popup Texts
     * @description Global Popup Texts
     * @title_de Globale Popup Texte
     * @description_de Globale Popup Texte
     */
    globalStatePopupTexts: GlobalStatePopupTextsBranding
    /**
     * @title Login / Registration Configuration
     * @description Configuration options for login/registration process
     * @title_de Login / Registrierung Konfiguration
     * @description_de Konfigurationsoptionen für die Login/Registrierungsseite
     */
    loginRegistrationSite: LoginRegistrationSiteBranding
    /**
     * @title Error Page Configuration
     * @description Configuration options for the error page
     * @title_de Fehlerseiten Konfiguration
     * @description_de Einstellungsmöglichkeiten für die Fehlerseite
     */
    errorSite: ErrorSiteBranding
    /**
     * @title BasisPremiumConfig per Value
     * @description BasisPremium dependend configuration options
     * @title_de BasisPremiumConfig pro Wert
     * @description_de BasisPremium abhängige Einstellungsmöglichkeiten
     */
    basisPremium: BasisPremiumBranding
    /**
    * @title Communication Area Configuration
    * @description Configuration options for the communication area
    * @title_de Kommunikationszenter Konfiguration
    * @description_de Einstellungsmöglichkeiten für das Kommunikationszenter
    */
    communicationArea: CommunicationAreaBranding
    /**
    * @title Chat Configuration
    * @description Configuration options for the chat
    * @title_de Chat Konfiguration
    * @description_de Configuration options for the chat
    */
    chatBranding: ChatBranding
    /**
    * @title Contact Entry Configuration
    * @description Configuration options for the contact entry 
    * @title_de Contact Entry Konfiguration
    * @description_de Configuration options for the contact entry
    */
    contactEntry: ContactEntryBranding
    /**
    * @title Device switcher Configuration
    * @description Configuration options for the device switcher
    * @title_de Device switcher Konfiguration
    * @description_de Configuration options for the device switcher
    */
    deviceSwitcher: DeviceSwitcherBranding
    /**
    * @title Side Icon bar Configuration
    * @description Configuration options for the side Icon bar 
    * @title_de Side Icon bar Konfiguration
    * @description_de Configuration options for the side Icon bar
    */
    sideIconBar: SideIconBarBranding
    /**
    * @title Navigation area Configuration
    * @description Configuration options for the navigation area 
    * @title_de Navigation area Konfiguration
    * @description_de Configuration options for the navigation area
    */
    navigationArea: NavigationAreaBranding
    /**
    * @title Sponsors page Configuration
    * @description Configuration options for the sponsors page 
    * @title_de Sponsors page Konfiguration
    * @description_de Configuration options for the sponsors page
    */
    sponsorsPageContent: SponsorsPageContentBranding
    /**
    * @title Media & Partners page Configuration
    * @description Configuration options for the media & partners page 
    * @title_de Media & Partners page Konfiguration
    * @description_de Configuration options for the media & partners page
    */
    mediaPartnerPageContent: MediaPartnerPageContentBranding
    /**
    * @title Calendar entry modal page Configuration
    * @description Configuration options for the calendar entry modal page 
    * @title_de Calendar entry modal page Konfiguration
    * @description_de Configuration options for the calendar entry modal page
    */
    calendarEntryModalPageContent: CalendarEntryModalBranding
    /**
    * @title Calendar entry list view Configuration
    * @description Configuration options for the calendar entry list view 
    * @title_de Calendar entry list view Konfiguration
    * @description_de Configuration options for the calendar entry list view
    */
    calendarEntryListView: CalendarEntryListViewBranding
    /**
    * @title Calendar entry export Configuration
    * @description Configuration options for the calendar entry export 
    * @title_de Calendar entry export Konfiguration
    * @description_de Configuration options for the calendar entry export
    */
    calendarEntryExport: CalendarEntryExportBranding
    /**
    * @title My profile page content configuration
    * @description Configuration options for my profile page content 
    * @title_de My profile page content Konfiguration
    * @description_de Konfiguration options for my profile page content
    */
    myProfilePageContent: MyProfilePageContentBranding
    /**
    * @title Person Detail Page Content configuration
    * @description Configuration options for person detail page content 
    * @title_de Person Detail Page Content configuration
    * @description_de Configuration options for person detail page content
    */
    personDetailPageContent: PersonDetailPageContentBranding
    /**
    * @title Say hello modal configuration
    * @description Configuration options for Say hello modal 
    * @title_de Say hello modal configuration
    * @description_de Configuration options for Say hello modal
    */
    sayHelloModal: SayHelloModalBranding
    /**
    * @title Filter bar configuration
    * @description Configuration options for filter bar 
    * @title_de Filter bar configuration
    * @description_de Configuration options for filter bar
    */
    filterBar: FilterBarBranding
    organizationDetailPageContent: OrganizationDetailPageContentBranding
    backofficeContent: BackofficeContentBranding
    backofficeStaff: BackofficeStaffBranding
    backofficeStaffReq: BackofficeStaffReqBranding
    backofficeMeetings: BackofficeMeetingsBranding
    backofficeVisitors: BackofficeLVisitorsBranding
    backofficeLounge: BackofficeLoungeBranding
    backofficeReporting: BackofficeReportingBranding
    productDetailPageContent: ProductDetailPageContentBranding
    trademarkDetailPageContent: TrademarkDetailPageContentBranding
    eventDateDetailPageContent: EventDateDetailPageContentBranding
    productsPageContent: ProductsPageContentBranding
    newsPageContent: NewsPageContentBranding
    trademarksPageContent: TrademarksPageContentBranding
    exhibitorsPageContent: ExhibitorsPageContentBranding
    programPageContent: ProgramPageContentBranding
    programSchedule: ProgramScheduleBranding
    programSpeakers: ProgramSpeakersBranding
    pressMediaPageContent: PressMediaPageContentBranding
    businessAreaPageContent: BusinessAreaPageContentBranding
    systemCheck: SystemCheckBranding
    receptionPage: ReceptionPageSiteBranding
    meetingRoomMaxTiles: number
    meetingRoomGroupsDisablePictureOverlay: boolean
    meetingRoomGroupsTitleFontStyle: string
    meetingRoomGroupsHosterFontStyle: string
    meetingRoomGroupsDescriptionFontStyle: string
    meetingRoomGroups: MeetingRoomGroupType[]
    conferenceTexts: ConferenceTexts
    conferenceListTiles: ConferenceListTiles
    thirdPartyResources: ThirdPartyResources[]
    /**
    * @title Theme color 
    * @description First color theme
    * @title_de Theme color
    * @description_de First color theme
    */
    theme1Color: string
    /**
    * @title Theme color 
    * @description Second color theme
    * @title_de Theme color
    * @description_de Second color theme
    */
    theme2Color: string
    /**
    * @title Connect with all participants button visiblity 
    * @description Enable / disable visibility of Connect with all participants button inside of the roster 
    * @title_de Connect with all participants button visiblity
    * @description_de Enable / disable visibility of Connect with all participants button inside of the roster 
    */
    hasConnectWithAllParticipants: boolean
    /**
    * @title Primary color 
    * @description Mostly used as dark background color 
    * @title_de Primary color
    * @description_de Mostly used as dark background color 
    */
    primaryColor: string
    /**
    * @title Secondary color 
    * @description Mostly used as light background color  
    * @title_de Secondary color
    * @description_de Mostly used as light background color   
    */
    secondaryColor: string
     /**
    * @title Main info color 
    * @description Mostly used as color for various border colors / text colors / backgroud colors inside of the application 
    * @title_de Main info color
    * @description_de Mostly used as color for various border colors / text colors / backgroud colors inside of the application   
    */
    mainInfoColor: string
    /**
    * @title Main info dark color 
    * @description  Color used as light color on dark background for message panel border color
    * @title_de Main info dark color
    * @description_de  Color used as light color on dark background for message panel border color 
    */
    mainInfoDarkColor: string
    /**
    * @title Chat list color 
    * @description  Text color for chat list color
    * @title_de Chat list color
    * @description_de  Text color for chat list color 
    */
    chatListColor: string
    chatListBackgroundCommunicationAreaColor: string
    chatInputColor: string
    chatInputColorTime: string
    chatDarkenInputColor: string
    /**
    * @title Side icon bar drawer screen overlay color 
    * @description Darken overlay background color showed when drawer is opened   
    * @title_de Side icon bar drawer screen overlay color
    * @description_de Darken overlay background color showed when drawer is opened    
    */
    darkenOverlayColor: string
    communicationAreaSearchPlaceholderColor: string
    communicationAreaDefaultAvatarColor: string
    /**
    * @title Secondary text color 
    * @description Mostly used as light text color  
    * @title_de Secondary text color
    * @description_de Mostly used as light text color   
    */
    secondaryTextColor: string
    languageTogglerColor: string
    darkenThemeBackgroundColor: string
    darkenThemeHoverColor: string
    lightThemeHoverColor: string
    theme3ColorPrimary: string
    theme3ColorSecondary: string
    crsMultiSwitcher: CrsMultiSwitcherBranding
    helpPage: HelpPageContentBranding
    moreButtonColor1: string
    moreButtonColor2: string
    video1: string
    video2: string
    video3: string
    font1: string
    font2: string
    loaderBgColor: string
    loaderSpinColor: string
    customScrollbarBgColor: string
    customScrollbarBorderColor: string
    customScrollbarWidth: string
    customScrollbarHeight: string
    dangerButtonColor: string
    networkingArea: NetworkingAreaBranding
    filterButtonColor: string
    globalTexts: GlobalTextsBranding
    ticketSale: TicketSaleBranding
    notification: NotificationBranding
    backendError: BackendErrorBranding
    recommendModal: RecommendModalBranding
    topSponsors: TopSponsor[]
    ideaSponsors: TopSponsor[]
    topicBadges: TopicBadge[]
    /**
    * @title Category badges 
    * @description Contains aliases of categories with has type = Interest and synthetic=true to be displayed as badge  
    * @title_de Kategorie Abzeichen
    * @description_de Enthält Aliase von Kategorien mit dem Typ = Interesse und synthetisch = true, um als Abzeichen angezeigt zu werden
    */
    categoryBadges: string[]
    press: LocalizedString
    coupons: CouponsBranding
    topBanner: TopBannerBranding
    guestUserBanner: GuestUserBannerBranding
    topBannerConfig: TopBannerConfig
    rulesPage: RulesPageContentBranding
    topBar: TopBarBranding
    roster: RosterBranding
    settingsTab: SettingsTabBranding
    mySchedule: MySchedulePageBranding
    hubspotScriptTag: string
    gtmNoScript: string
    gtmScriptDataLayer: string
    gtmTagScript: string
    gtmFairNumber: string
    cookieOneTrustScript: string
    jiraSupportDeskData: JiraSupportDeskData,
    showPersonNameInUrl: boolean
    videoBackground: string
    showroomMeetingDuration: number
    defaultToggleIcon: boolean
    conversationEntry: ConversationEntryBranding
    globalOptIn: boolean
    trackingBrandingNone: TrackingBranding
    trackingBrandingBasic: TrackingBranding
    trackingBrandingStandard: TrackingBranding
    trackingBrandingPremium: TrackingBranding
    trackingBrandingUltimate: TrackingBranding
    csvExportBranding: CSVExportBranding
    timePickerBranding: CrsTimePickerBranding
    contextMenuBranding: ContextMenuBranding
    dropdownStyle: DropdownStyle
    iframeTest: IFrameTestPageContentBranding
    videoPlayerBranding: VideoPlayerBranding
    videoPageContentBranding: VideoPageContentBranding

    searchBarFontFamily: string
    searchBarFontSize: string
    searchBarLetterSpacing: string
    searchBarFontWeight: string
    searchBarTextTransform: string
}

export interface JiraSupportDeskData {
    visible: boolean,
    key: string
}

export interface DropdownStyle {
    primaryColor: string
    primary25Color: string
    primary50Color: string
    primary75Color: string
}

export interface TopicBadge {
    topicName: string
    badgeText: string
    textColor: string
    backgroundColor: string
    borderColor: string
}

export interface EventTiming {
    /**
     * @title Ticketshop Opening
     * @description Time for when ticketshop is open. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     * @title_de Ticketshop Öffnung
     * @description_de Zeitpunkt der Öffnung des Ticketshops. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     */
    ticketShopOpenDateTime: string
    /**
     * @title Onboarding Start
     * @description Time for when onboarding starts. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     * @title_de Onboarding Beginn
     * @description_de Zeitpunkt für den Start des Onboardings. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     */
    onboardingOpenDateTime: string
    /**
     * @title Reporting Start
     * @description Time for when reportings starts. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     * @title_de Reporting Beginn
     * @description_de Zeitpunkt für den Start des Reportings. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     */
    reportingOpenDateTime: string
    /**
     * @title Login Start
     * @description Time for when login is possible. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     * @title_de Login Beginn
     * @description_de Zeitpunkt für die Öffnung des Logins. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     */
    loginOpenDateTime: string
    /**
     * @title Softopening Start
     * @description Time for when softopening starts. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     * @title_de Softopening Beginn
     * @description_de Zeitpunkt für den Start des Softopening. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     */
    softOpeningDateTime: string
    /**
     * @title Begin of Fair
     * @description Date for the event start. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     * @title_de Beginn der Veranstaltung
     * @description_de Zeitpunkt für Veranstaltungsstart. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     */
    eventStartDateTime: string
    /**
     * @title End of Fair
     * @description Date for the fair end. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     * @title_de Ende der Veranstaltung
     * @description_de Zeitpunkt für Veranstaltungsende. Format ISO_8601 "2000-01-01T00:00:00+02:00"
     */
    eventEndDateTime: string
    /**
     * @title Default eventdate starttime 
     * @description Default starttime for eventdates without starttime. Format HH:mm
     * @title_de Default EventDate Startzeit
     * @description_de Default Startzeit von Eventdates ohne Startzeit. Format HH:mm
     */
    eventDateDefaultStartTime: string
    /**
     * @title Default eventdate time 
     * @description Default starttime for eventdates without starttime. Format HH:mm
     * @title_de Default EventDate Startzeit
     * @description_de Default Startzeit von Eventdates ohne Startzeit. Format HH:mm
     */
    eventDateDefaultEndTime: string
    /**
     * @title Days with Events
     * @description List of days where events are running. Format ISO_8601 "2020-09-25T00:00:00+02:00"
     * @title_de Tage mit Events
     * @description_de Liste mit Tagen an denen Events stattfinden. Format ISO_8601 "2020-09-25T00:00:00+02:00"
     */
    eventDays: string[]
    /**
     * @title Days where Calendar Entries are allowed
     * @description List of days where calendar entries are allowed. Format ISO_8601 "2020-09-25T00:00:00+02:00"
     * @title_de Tage an denen Kalender Einträge erlaubt sind
     * @description_de Liste mit Tagen an denen Kalender Einträge erlaubt sind. Format ISO_8601 "2020-09-25T00:00:00+02:00"
     */
    meetingDays: string[]
    /**
     * @title Event Days Format Pattern
     * @description Pattern for formatting event dates. (e.g. EEEE, MMMM d, yyyy)
     * @title_de Event Tage Format
     * @description_de Format zur Formatierung von Event Tage Daten. (z.B. EEEE, d. MMMM yyyy)
     */
    eventDaysFormatPattern: LocalizedString
    /**
     * @title Event Days Format Pattern (short)
     * @description Pattern for formatting event dates. (e.g. MMMM d, yyyy)
     * @title_de Event Tage Format (kurz)
     * @description_de Format zur Formatierung von Event Tage Daten. (z.B. d. MMMM yyyy)
     */
    eventDaysFormatPatternShort: LocalizedString
    /**
     * @title Event Days Format Pattern with Time
     * @description Pattern for formatting event dates with time. (e.g. dddd, MMMM d [at] HH:mm)
     * @title_de Event Tage Format mit Zeit
     * @description_de Format zur Formatierung von Event Tage Daten mit Zeit. (z.B. dddd d. MMMM [um] HH:mm)
     */
    eventDaysFormatPatternWithTime: LocalizedString
    /**
     * @title Event Days Format Pattern Detail Page
     * @description Pattern for formatting event dates on detail page. (e.g. dddd, MMMM d [at] HH:mm)
     * @title_de Event Tage Format Detail Seite
     * @description_de Format zur Formatierung von Event Tage Daten auf Detailseite. (z.B. dddd d. MMMM [um] HH:mm)
     */
    eventDaysFormatPatternEvtDetailPage: LocalizedString
}

export type LocalizedString = string & { __localizedBrand?: undefined }
export type ColorString = string & { __colorBrand?: undefined }

export interface PluralString {
    singular: LocalizedString
    plural: LocalizedString
}
export interface ResolvedPluralString {
    singular: string
    plural: string
}

export function getPluralString(pluralString: ResolvedPluralString, count: number, countPlaceHolder?: string) {
    const stringResource = count === 1 ? pluralString.singular : pluralString.plural
    if (countPlaceHolder) {
        return stringResource.split(`{$${countPlaceHolder}}`).join(`${count}`)
    }
    return stringResource
}

export interface MeetingDurationType {
    /**
     * @title Timelimit for call
     * @description Timelimit for call
     * @title_de Zietlimit für Anrufe
     * @description_de Zeitlimit für Anrufe
     */
    call: number
    /**
     * @title Timelimit for calenderEntry
     * @description Timelimit for calls from a calendar entry
     * @title_de Zietlimit für Kalender Einträge
     * @description_de Zeitlimit für Anrufe in einem Kalender Eintrag
     */
    calenderEntry: number
}

export interface Configuration {
    /**
     * @title topic name
     * @description Name of the topic for the data access (e.g. 2021_hh)
     * @title_de topic name
     * @description_de Name des topics für den Datenzugriff (z.b. 2021_hh)
     */
    topicName: string
    /**
     * @title userpool name
     * @description Name of the userpool for the user data access
     * @title_de userpool Name
     * @description_de Name des userPool für den Benutzerdaten Zugriff
     */
    userPoolName: string
    /**
     * @title seriesOfTopics Name
     * @description Name for the seriesoftopics, needed for authentification
     * @title_de seriesOfTopics Name
     * @description_de Name des seriesoftopics, wird für die Benutzer-Authentifikation
     */
    sotName: string
    /**
     * @title seriesOfTopics accessToken
     * @description AccessToken for the seriesoftopics, needed for authentification
     * @title_de seriesOfTopics accessToken
     * @description_de AccessToken des seriesoftopics, wird für die Benutzer-Authentifikation
     */
    sotAccessToken: string
    /**
     * @title Networking Toggle
     * @description Display toggle for turning on and off the networking feature for the user
     * @title_de Networking Button
     * @description_de Zeigt den Button zum ein-/ausschalten des Networking Features für den Nutzer
     */
    networkingToggle: boolean
    /**
     * @title Open Graph Title
     * @description Title for the meta-tag og:title
     * @title_de Open Graph Titel
     * @description_de Titel für das og:title meta-tag
     */
    ogTitle: LocalizedString
    /**
     * @title Open Graph Description
     * @description Title for the meta-tag og:title
     * @title_de Open Graph Beschreibung
     * @description_de Titel für das og:description meta-tag
     */
    ogDescription: LocalizedString
    /**
     * @title google meta-tag
     * @description Content for the meta-tag "google"
     * @title_de google meta-tag
     * @description_de Inhalt für das meta-tag "google"
     */
    browserTranslation: string
    /**
     * // TODO delete me when DEP-1966 done
     * @title Speaker Person Functions
     * @description Person Functions which identifies a person as a speaker
     * @title_de Speaker Personen Funktionen
     * @description_de Funktionsnamen die eine Person als Speaker ausweisen
     */
    speakerPersonFunctions: string[]
    /**
     * // TODO delete me when DEP-1966 done
     * @title Default Staff Person Function
     * @description default staff person function when adding a person as staff member
     * @title_de Default Staff Personen Funktionen
     * @description_de Default Funktion für Personen die als Staff hinzugefügt werden
     */
    defaultStaffPersonFunction: string
    /**
     * @title Calendar Entry Participant Limit
     * @description Limit for participants in a calendar entry. It has to be equal or greater then Chat Participant Limit(chatParticipantLimit)
     * @title_de Kalendar Eintrag Personen Limit
     * @description_de Limit für Anzahl an Personen in einem Kalender Eintrag. Muss größer oder gleich des Chat Personen Limit sein (chatParticipantLimit)
     */
    calendarEntryParticipantLimit: number
    /**
     * @title Show Privacy Modal for Event Dates
     * @description Show Privacy Modal for Event Dates
     * @title_de Zeige das Privatsphäre Popup für Events
     * @description_de Zeige das Privatsphäre Popup für Events
     */
    showPrivacyModelForEventDates: boolean
    /**
     * @title Chat Participant Limit
     * @description Limit for participants in a chat
     * @title_de Chat Personen Limit
     * @description_de Limit für die Anzahl an Personen in einem Chat
     */
    chatParticipantLimit: number
    /**
     * @title Virtual Cafe Participant Limit
     * @description Limit for participants in a virtual cafe
     * @title_de Virtual Cafe Personen Limit
     * @description_de Limit für die Anzahl an Personen in einem Virtual Cafe
     */
    virtualCafeParticipantLimit: number
    /**
     * @title Greenroom Participant Limit
     * @description Limit for participants in a greenroom
     * @title_de Greenroom Personen Limit
     * @description_de Limit für die Anzahl an Personen in einem Greenroom
     */
    greenRoomParticipantLimit: number
    /**
     * @title Breakout Participant Limit
     * @description Limit for participants in a breakout
     * @title_de Breakout Personen Limit
     * @description_de Limit für die Anzahl an Personen in einem Breakout
     */
    breakoutParticipantLimit: number
    /**
     * @title Showroom Participant Limit
     * @description Limit for participants in a showroom
     * @title_de Showroom Personen Limit
     * @description_de Limit für die Anzahl an Personen in einem Showroom
     */
    showRoomParticipantLimit: number
    /**
     * @title Roundtable Participant Limit
     * @description Limit for participants in a roundtable
     * @title_de Roundtable Personen Limit
     * @description_de Limit für die Anzahl an Personen in einem Roundtable
     */
    roundTableParticipantLimit: number
    /**
     * @title Roundtable Open Before
     * @description Time in minutes the roundtable is open before it starts
     * @title_de Roundtable Offen vor Start
     * @description_de Zeit die der Roundtable offen ist, bevor er wirklich startet
     */
    roundTableOpenBeforeMinutes: number
    /**
     * @title Roundtable Open After
     * @description Time in minutes the roundtable is open after it ends
     * @title_de Roundtable Offen nach Ende
     * @description_de Zeit die der Roundtable offen ist, nachdem er wirklich endet
     */
    roundTableOpenAfterMinutes: number
    /**
     * @title Meeting Durations for calls
     * @description Max duration for calls
     * @title_de Meeting Dauer
     * @description_de Max Zeit für Anrufe
     * @usedInBackend
     */
    meetingDurations: MeetingDurationType
    /**
     * @title Filter for EventDate relevant persons
     * @description Filter persons whicht are related to eventdates in speaker component and list on lobby
     * @title_de Filter EventDate relevante Personen
     * @description_de Filter Personen die mit EventDates verknüpft sind in Speaker Komponente und Liste in Lobbys
     */
    eventDateParticipation: boolean
    /**
     * @title Backoffice Toggles for Detailpage Information
     * @description Show toggles in backoffice for show/hide of products / trademarks on organization detailpage
     * @title_de Backoffice Buttons für Detailpage Informationen
     * @description_de Zeige Buttons im Backoffice für das ein-/ausblenden von Produkten und Trademarks
     */
    detailSectionToggle: boolean
    /**
     * @title Internal Email Adresses
     * @description Email adresses for identifing users as internal for analytics
     * @title_de Interne Email Adressen
     * @description_de E-Mail Adressen um Benutzer für analytics als intern zu markieren 
     */
    internEmailAdresses: string[]
    /**
     * @title Breakout room enable
     * @description Breakout room enable
     * @title_de Breakout Raum aktivieren
     * @description_de Breakout Raum aktivieren
     */
    breakoutEnabled: boolean
}

export interface PresenceConfiguration {
    /**
     * @title Activate Presence State
     * @description (de)activate the presence feature
     * @title_de Online-Status für Benutzer aktivieren
     * @description_de (De)Aktiviert das Online-Status Feature 
     */
    usePresence: boolean
    /**
     * @title Offline after x ms
     * @description Number of milliseconds before a user is marked as offline. Also used as interval for the update of the localusers presence.
     * @title_de Offline nach x ms
     * @description_de Anzahl an Millisekunden bevor ein Benutzer als Offline angezeigt wird. Wird auch als Interval für das Update des Online-Status des lokalen Benutzers genutzt.
     */
    offlineAfterXMillis: number
    /**
     * @title Min Request Debounce Millis
     * @description Minimum of milliseconds between retrieving presence states
     * @title_de Min Request Debounce Millis
     * @description_de Minimum an Millisekunden zwischen Online-Status Abfragen
     */
    minRequestDebounceMillis: number
    /**
     * @title Debounce Increase Factor
     * @description Factor for increasing retrieving times when an error occurs
     * @title_de Debounce Erhöhungs Faktor
     * @description_de Faktor zur Erhöhung der Verzögerung zwischen Online-Status Abfragen, wenn ein Fehler auftritt
     */
    requestDebounceIncFactor: number
    /**
     * @title Debounce Decrease Factor
     * @description Factor for decreasing retrieving times when everything is working fine
     * @title_de Debounce Verringerungs Faktor
     * @description_de Faktor zur Verringerung der Verzögerung zwischen Online-Status Abfragen, wenn alles ok ist
     */
    requestDebounceDecFactor: number
}

export interface ConferenceListTiles {
    gradientDivBackgroundFirst: string
    gradientDivBackgroundSecond: string
    headerStickyBackground: string
    cardBackground: string
    corouselBackground: string
    sponsorsBackground1: string
    sponsorsBackground2: string
    sponsorsBackground3: string
    sponsorsBackground4: string
    sponsorsBackground5: string
}

export interface TopSponsor {
    id: string
    name: string
    logo: string
    useCustomName: boolean
    /**
     * @title Order
     * @description Order of sponsors. -1 is default and this way everything will be ordered as coming from backend
     * @title_de Reihenfolge
     * @description_de Reihenfolge der Sponsoren. -1 ist der Defaultwert und führt dazu das die Reihenfolge des Backends verwendet
     */
    order: number
}
export interface TopBarBranding {
    fontWeight: string
    textColor: string
    background: string
    searchTitleColor: string
    searchTitleFontFamily: string
    searchTitleFontSize: string
    searchTitleFontWeight: string
    searchTitleTextTranform: string
    searchTitleLetterSpacing: string
    breadcrumbTextFontFamily: string
    breadcrumbTextFontSize: string
    breadcrumbTextFontWeight: string
    breadcrumbTextTransform: string
    breadcrumbLetterSpacing: string
}

export interface TrackingBranding {
    detailpage: boolean
    showroom: boolean
    calendarEntry: boolean
    interest: boolean
    recommend: boolean
    lounge: boolean
    links: boolean
    media: boolean
    stream: boolean
    schedule: boolean
    coupon: boolean
    press: boolean
    magazine: boolean
    zipCode: boolean
    city: boolean
    showVisitorsTab: boolean
    showGuestsSelect: boolean
    showVisitorsSelect: boolean
    showLeadsSelect: boolean
    showContactsSelect: boolean
    showDbSelect: boolean
    showReportTab: boolean

    /**
     * @title Enable download of visitors
     * @description Enable download of visitors lists
     * @title_de Enable download of visitors
     * @description_de Enable download of visitors lists
     */
    enableDownloadVisitors: boolean

     /**
     * @title Enable download of statistics
     * @description Enable download of statistics reports
     * @title_de Enable download of statistics
     * @description_de Enable download of statistics reports
     */
    enableDownloadStatistics: boolean
}

export interface CSVExportBranding {
    firstName: LocalizedString
    lastName: LocalizedString
    company: LocalizedString
    jobTitle: LocalizedString
    zipCode: LocalizedString
    city: LocalizedString
    country: LocalizedString
    email: LocalizedString
    phone: LocalizedString
    interests: LocalizedString
    optIn: LocalizedString
    detailPage: LocalizedString
    showroom: LocalizedString
    requestedMeeting: LocalizedString
    registeredInterest: LocalizedString
    recommendation: LocalizedString
    virtualCafe: LocalizedString
    virtualSpace: LocalizedString
    link: LocalizedString
    media: LocalizedString
    livestream: LocalizedString
    conferenceSlot: LocalizedString
    goodieBag: LocalizedString
    lastInteraction: LocalizedString
    markedBy: LocalizedString
    markedAt: LocalizedString
    press: LocalizedString
    magazine: LocalizedString
}

export interface VideoPlayerBranding {
    /**
     * @title Picture-In-Picture-Player double click hint
     * @description Hint that is displayed when mouse hovers over Picture-In-Picture-Player and should include page redirect hint to channel page on double click
     * @title_de Picture-In-Picture-Player Doppelklickhinweis
     * @description_de Hinweis, der angezeigt wird, wenn sich Cursor auf Picture-In-Picture-Player befindet, sollte dem Nutzer erklären, dass durch Doppelklick auf die Channelpage weitergeleitet wird
     */
    pipDoubleClickHint: LocalizedString
    /**
     * @title Picture-In-Picture-Player Stream Error Message
     * @description Message that is displayed in Picture-In-Picture-Player when video streaming source has a problem
     * @title_de Picture-In-Picture-Player Videostreaming-Fehlermeldung
     * @description_de Fehlermeldung, die im Picture-In-Picture-Player angezeigt wird, wenn die Videostreamingquelle ein Problem hat
     */
    pipStreamErrorMessage: LocalizedString
    /**
     * @title Video-Player Stream Error Message
     * @description Message that is displayed in Video-Player when video streaming source has a problem
     * @title_de Video-Player Videostreaming-Fehlermeldung
     * @description_de Fehlermeldung, die im Video-Player angezeigt wird, wenn die Videostreamingquelle ein Problem hat
     */
    videoPlayerStreamErrorMessage: LocalizedString
}

export interface VideoPageContentBranding {
    /**
     * @title Breakout message
     * @description Breakout message
     * @title_de Breakout-Nachricht
     * @description_de Breakout-Nachricht
     */
    joinBreakoutMessage: LocalizedString
    /**
     * @title Button text
     * @description Join breakout button
     * @title_de Schaltflächentext
     * @description_de Text für Join-Breakout-Schaltfläche
     */
    joinBreakoutButtonText: LocalizedString
    /**
     * @title Button color
     * @description Color of button
     * @title_de Knopffarbe
     * @description_de Farbe der Taste
     */
    joinBreakoutButtonColor: ColorString
    /**
     * @title Button text color
     * @description Text color for join breakout button
     * @title_de Schaltfläche Textfarbe
     * @description_de Textfarbe für Join-Breakout-Schaltfläche
     */
    joinBreakoutButtonTextColor: ColorString
    /**
     * @title Minutes before breakout enabled
     * @description Set minutes that breakout button will be enabled before ongoing event date end.
     * @title_de Minuten vor dem Ausbruch aktiviert
     * @description_de Legen Sie die Minuten fest, zu denen die Breakout-Schaltfläche vor dem Ende des laufenden Ereignisdatums aktiviert wird.
     */
    minutesBeforeBreakoutEnabled: number
    /**
     * @title Participants counter text
     * @description Participants counter text
     * @title_de Teilnehmer kontern den Text
     * @description_de Teilnehmer kontern den Text
     */
    participantsCounterText: LocalizedString
    /**
     * @title Coming up text
     * @description Text for "Coming up" message before switching to the next event
     * @title_de Coming up text
     * @description_de Text for "Coming up" message before switching to the next event
     */
     comingUpText: LocalizedString
     /**
     * @title Minutes before switching to the next event
     * @description Set how many minutes before should the channel switch to the next event
     * @title_de Minutes before switching to the next event
     * @description_de Set how many minutes before should the channel switch to the next event
     */
    minutesBeforeSwitchingToTheNextEvent: number
}

export interface BackendErrorBranding {
    errorMessage: LocalizedString
    errorButtonTitle: LocalizedString
    backendErrorColor: string
    backendErrorDarkColor: string
}

declare let branding: Branding
branding = new BrandingImpl(branding, "en")
const brandingImplCallable = branding as any

function getBranding(): Branding {
    return branding
}
export default getBranding()

export function setBrandingLanguage(lang: string) {
    brandingImplCallable.setLang(lang)
}