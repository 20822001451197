import { useState, RefObject } from "react";

export default function TopBannerSharedState() {
    const [showBanner, setShowBanner] = useState<boolean>(true)
    const [hideOnScroll, setHideOnScroll] = useState<boolean>(false)
    const [topBarRef, setTopBarRef] = useState<RefObject<HTMLDivElement>>()

    return {
        showBanner,
        setShowBanner,
        topBarRef,
        setTopBarRef,
        hideOnScroll,
        setHideOnScroll
    }
}
