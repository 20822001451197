import React, { useState, useEffect } from "react"
import styled from "styled-components";
import { useLanguageState } from "../globalStates/LanguageState";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import { getPrivacyUserAnswer, addPrivacyPolicyAnswer, BackendServiceError, createPrivacyUserQuestionId } from "../backendServices/BackendServices"
import { PrivacyUserAnswer } from "../backendServices/Types";
import Linkify from 'react-linkify';
import branding from "../branding/branding";
import ReactDOM from "react-dom";

enum AnswerOption {
    AGREE = "agree",
    DISAGREE = "disagree",
    NONE = "none"
}

const ModalRoot = styled.div`
    font-family: ${branding.font1};
    background: rgba(0, 0, 0, .6);
    position: absolute;
    color: ${branding.mainInfoColor};

    a{
        color: ${branding.mainInfoColor};
    }
`

const ModalDialog = styled.div`
    max-width: 700px;
`
const ModalContent = styled.div`
    border: 1px solid ${branding.mainInfoColor};
    border-radius: 0;
    padding: 30px 50px;
`

const ModalBody = styled.div`
`

const ModalBodyText = styled.p`
    font-size: 16px;

    @media (max-width: 1400px) {
        font-size: 14px;
    }
`

const ModalBodyTitle = styled.p`
    margin-bottom: 10px;
    font-size: 26px;

    @media (max-width: 1400px) {
        font-size: 22px;
    }
`

const AgreeButton = styled.div`
    width: 200px;
    height: 40px;
    font-size: ${branding.globalTexts.privacyPolicyFontSize ?? "15px"};
    border: 1px solid ${branding.primaryColor};
    /* background: #000; */
    color: ${branding.globalTexts.agreeBtnTextColor ?? "#fff"};;
    border-radius: 25px;
    cursor: pointer;
    background: ${branding.globalTexts.agreeBtnBackgroundColor ?? "#000"};
    :hover {
      background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor ?? "#e8e8e8"} !important; 
      color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor ?? "#000"} !important;}
`

const DisagreeButton = styled.div`
    width: 220px;
    height: 40px;
    cursor: pointer;
    font-size: ${branding.globalTexts.privacyPolicyFontSize ?? "15px"};
    color: ${branding.primaryColor};
    
    &:hover{
        text-decoration: none;
    }
`

interface PrivacyPolicyTextProps {
    privacyPolicyText: string
}

const PrivacyPolicyText: React.FunctionComponent<PrivacyPolicyTextProps> = (props) => {
    const strings = useLanguageState().getStrings().globalTexts
    let searchStr = strings.privacyPolicyBoldText;
    let index = props.privacyPolicyText.indexOf(searchStr);
    let first = props.privacyPolicyText.substring(0, index);
    let boldStr = <b>{searchStr}</b>;
    let second = props.privacyPolicyText.substring(index + searchStr.length, props.privacyPolicyText.length);

    return (
        <ModalBodyText>
            {branding.globalTexts.hasLinkAtTheEnd ?
                <Linkify>
                    {first}{boldStr}{second}{<a href={strings.privacyPolicyEndLink} key={" "} target="_blank" rel="noopener noreferrer">{strings.privacyPolicyEndLinkText}</a>}
                </Linkify> :
                <Linkify>
                    {first}{boldStr}{second}
                </Linkify>}
        </ModalBodyText>
    )
}


interface PrivacyPolicyModalProps {
    callback: (accepted: boolean) => void
    organizationId: string
}


const PrivacyPolicyModal: React.FunctionComponent<PrivacyPolicyModalProps> = (props) => {
    const questionId = createPrivacyUserQuestionId(props.organizationId)
    const userLink = useLoggedInState()
    const profileId = userLink.user()?.profileId
    const privacyUserAnswer = userLink.getPrivacyUserAnswer(questionId)
    const strings = useLanguageState().getStrings();
    const [isLoaded, setIsLoaded] = useState(privacyUserAnswer?.accepted);
    const [accepted, setAccepted] = useState(isLoaded);

    useEffect(() => {
        if (isLoaded && accepted) {
            props.callback(true)
        }
    }, [isLoaded, accepted]) // eslint-disable-line

    function addPrivacyAnswer(e: React.MouseEvent<HTMLElement>) {
        const answerText = e.currentTarget.id;
        if (answerText === AnswerOption.AGREE) {
            addPrivacyPolicyAnswer({
                sotUserId: profileId!,
                questionText: strings.globalTexts.privacyPolicyText,
                answerText: answerText,
                organizationId: props.organizationId
            }).then((response: PrivacyUserAnswer | BackendServiceError) => {
                if ((response as PrivacyUserAnswer).questionId) {
                    userLink.setPrivacyUserAnswer(response as PrivacyUserAnswer)
                    setAccepted(true);
                }
            })
        } else {
            props.callback(false)
        }
    }


    useEffect(() => {
        if (profileId && props.organizationId && !isLoaded)
            getPrivacyUserAnswer(profileId!, props.organizationId)
                .then((response: PrivacyUserAnswer | undefined) => {
                    setIsLoaded(true);
                    setAccepted(response?.accepted || false)
                    if (response) {
                        userLink.setPrivacyUserAnswer(response)
                    }
                })
        //eslint-disable-next-line
    }, [props.organizationId])

    if (!isLoaded) {
        return null
    } else if (accepted) {
        return null
    } else {
        return (
            <ModalRoot className="modal d-block" role="dialog">
                <ModalDialog className="modal-dialog modal-dialog-centered" role="document">
                    <ModalContent className="modal-content">
                        <ModalBody className="modal-body">
                            <div className="row">
                                <ModalBodyTitle>{strings.globalTexts.privacyPolicyTitle}</ModalBodyTitle>
                                <PrivacyPolicyText privacyPolicyText={strings.globalTexts.privacyPolicyText} />
                            </div>
                            <div className="row d-flex justify-content-between mt-5">
                                <DisagreeButton id={AnswerOption.DISAGREE} onClick={addPrivacyAnswer} className="d-flex align-items-center">{strings.globalTexts.privacyPolicyDisagreeText}</DisagreeButton>
                                <AgreeButton id={AnswerOption.AGREE} onClick={addPrivacyAnswer} className="d-flex align-items-center justify-content-center">{strings.globalTexts.privacyPolicyAgreeText}</AgreeButton>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </ModalDialog>
            </ModalRoot>
        )
    }
}

export default PrivacyPolicyModal


export const usePrivacyPolicyModal = (visibilityDisabled?: boolean) => {
    const [isVisible, setIsVisible] = useState(false)
    const [data, setData] = useState<{ organizationId: string, callback: (accepted: boolean) => void }>({ organizationId: "", callback: (accepted) => { } })

    const showPrivacyPolicyModal = (organizationId: string, callback: (accepted: boolean) => void) => {
        console.log(visibilityDisabled);
        if(visibilityDisabled)
        return 
        setData({ organizationId: organizationId, callback: callback })
        setIsVisible(true)
    }

    const PrivacyModal = () => (
        ReactDOM.createPortal(<React.Fragment>
            {isVisible && <PrivacyPolicyModal callback={(accepted) => {
                setIsVisible(false)
                data.callback(accepted)
            }} organizationId={data.organizationId} />}
        </React.Fragment>, document.body)
    )

    return {
        showPrivacyPolicyModal,
        PrivacyModal,
    }
}
