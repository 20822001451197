import { createState, self, useState } from "@hookstate/core";

interface contactsCache {
    [key: string]: { connectionStatus: string, userType: string }
}

export const useContactState = createState({} as contactsCache)[self].map(s => () => {

    const state = useState(s)
    return {
        getConnectionStatus: (id: string) => {
            return state[self].get()[id]?.connectionStatus
        },
        setConnectionStatus: (id: string, connectionStatus: string) => {
            if (connectionStatus !== state[self].get()[id]?.connectionStatus) {
                state[self].set(newState => {
                    newState[id] = { connectionStatus: connectionStatus, userType: newState[id]?.userType }
                    return newState
                })
            }
        },
        getUserType: (id: string) => {
            return state[self].get()[id]?.userType
        },
        setUserType: (id: string, userType: string) => {
            if (userType !== state[self].get()[id]?.userType) {
                state[self].set(newState => {
                    newState[id] = { userType: userType , connectionStatus: newState[id]?.connectionStatus }
                    return newState
                })
            }
        },
        setAll: (arr: {id: string, connectionStatus: string, userType: string}[]) => {
            state[self].set(newState => {
                arr.forEach(x => newState[x.id] = {connectionStatus: x.connectionStatus, userType: x.userType})
                return newState
            })
        }
    }
})
