import React from 'react'
import styled from 'styled-components';
import branding from '../branding/branding';
import { VGIcon } from './Icons';
import { DetailNavLink, buildDetailLink } from '../contentArea/detailPages/DetailNavLink';
import { NavLink } from 'react-router-dom';


const LiveTileRoot = styled(NavLink)`
    position: relative;
    display: flex;
    flex-flow: column;
    height: 270px;
    margin: 0px 10px 20px 0px;
    border: 1px solid #000;
    border-radius: 5px;

    &:after {
      border-radius: 5px;
    }

    &:hover, &:active, &:focus, &:visited {
      text-decoration: none;
    }

    & h2 {
      font-family: ${branding.font1};
        font-style: normal;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        display: flex;
        margin-left: 10px;
        margin-top: 3px;
        flex: 1;
        text-align: center;
    }
    &:before {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        border-radius: 5px;
    }

    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }  
`
const LiveTileHeader = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row;
  padding: 15px;
  width: 100%;
  color: white;
  z-index: 2;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  background-blend-mode: multiply;

`

interface LiveEventDateProps {
  id: string
  title: string
  channelId: string
  channelIcon: VGIcon
  thumbnailUrl: string
}

const LiveEvent: React.FunctionComponent<LiveEventDateProps> = (props) => {
  return (
    <LiveTileRoot to={buildDetailLink(props.channelId, props.title, "channel")}>
      <DetailNavLink type="channel" id={props.channelId} name={props.title}>
        <LiveTileHeader>
          <div>{props.channelIcon}</div>
          <h2>{props.title}</h2>
        </LiveTileHeader>
        < img src={props.thumbnailUrl} style={{ width: "480px", minWidth: "480px", height: "270px", cursor: "pointer", borderRadius: "5px" }} alt="" />
      </DetailNavLink>
    </LiveTileRoot>)
}

export default LiveEvent;